import React, {useState} from 'react'
import {Trans} from 'react-i18next';
import {Card} from 'react-materialize'

export default function CookieDisclaimer() {
    const [hideDiv, setHideDiv] = useState(false);
    return (
        <div className={`disclaimer-box ${hideDiv ? 'hide-disclaimer-div' : ''}`}>
            <Card>
                <div className="disclaimer-content">
                    <p>
                        <Trans>COOKIE_DISCLAIMER</Trans>
                        <a target='_blank' href="https://timelybills.app/privacy-policy" className="wrap-it"><Trans>PRIVACY_POLICY</Trans> </a>
                        <Trans>PAGE</Trans>.
                        <i className="material-icons close-disclaimer" onClick={() => setHideDiv(true)}> clear </i>
                    </p>
                </div>
            </Card>
        </div>
    )
}
