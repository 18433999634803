import {
    POST_FEEDBACK_FAILED,
    POST_FEEDBACK_SUCCESS,
    FETCH_TEMPLATE_SUCCESS,
    FETCH_TEMPLATE_FAILED,
} from '../actions/types';

const initialState = {
};

export default function (state = initialState, action) {
    switch (action.type) {
        case POST_FEEDBACK_SUCCESS:
            if(state.error) {
                delete state.error
            }
            return {
                ...state,
                successData: 'feedback submitted successfully'
            };
        case POST_FEEDBACK_FAILED:
            return {
                ...state,
                error: action.payload
            };
        case FETCH_TEMPLATE_SUCCESS:
            if(state.error) {
                delete state.error
            }
            return {
                ...state,
                templateBody: action.payload
            };
        case FETCH_TEMPLATE_FAILED:
            return {
                ...state,
                error: action.payload
            };
        default:
            delete state.FETCH_FEEDBACK_ACTION;
            return state;
    }
}