import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import Spinner from "./common/Spinner";
import CookieDisclaimer from './common/CookieDisclaimer'
import {
    Row,
    Col,
    Input,
    Card,
} from 'react-materialize';
import Swal from 'sweetalert2';
import PropTypes from 'prop-types';
import {fetchFeedbackDetails, submitFeedbackForm} from "../actions/templateAction";
import SuccessPage from './SuccessPage'
import {fetchProfile} from "../actions/authActions";
import {withTranslation} from 'react-i18next';
import Navbar from "./Navbar";

class Template extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fetchedFeedbackId: this.props.match.params.feedbackId,
            questions: [],
            spinnerLoading: true,
        };
        this.templateId = this.props.match.params.templateId;
        this.userEmail = '';
        this.userId = '';
        this.responseForm = {};
        this.templateBody = {};
        this.successFlag = false;
    }

    componentWillMount() {
        //calls the fetchFeedbackDetails action to call the API which returns the feedbackDetails.
        this.props.fetchFeedbackDetails(this.templateId, this.state.fetchedFeedbackId);
        //store the user information(id and email) if user is authenticated otherwise leave it as empty strings.
        if (this.props.auth.isAuthenticated) {
            this.userId = this.props.auth.user._id;
            this.userEmail = this.props.auth.user.email;
        }
        else {
            this.userEmail = '';
            this.userId = '';
        }
    }

    componentWillReceiveProps(nextProps) {
        //watching for response of fetchFeedbackDetails action i.e. templateData.templateBody
        if (nextProps.templateData.templateBody && Object.keys(this.templateBody).length === 0) {
            //saving the fetched data of fetchFeedbackDetails action in templateBody
            this.templateBody = nextProps.templateData.templateBody;
            // calling the this.setTemplateStates inorder to proceed
            this.setState({spinnerLoading : false});
            this.arrangingInSequence(nextProps.templateData.templateBody);
        }
        //watching for the successful response of the submitFeedbackForm action
        if (nextProps.templateData.successData) {
            //set the state false for loader component to stop being rendered
            this.setState({spinnerLoading : false});
            //fire a success alert box
            // this.getAlertBox(nextProps.templateData.successData, 'success');
            //Flag raised to show SuccessPage on the ui, see the render function
            this.successFlag = true;
        }
        //watching for the unsuccessful response of the submitFeedbackForm action
        if (nextProps.templateData.error) {
            let message = this.templateBody.errorMessage;
            if(nextProps.templateData.error.code) {
                const status = nextProps.templateData.error.code;
                if(status === 402) {
                    message = this.templateBody.alreadySubmittedError;
                }else if(status === 401) {
                    message = this.templateBody.mandatoryErrorMsg;
                }else if(status === 403) {
                    message = this.templateBody.invalidFeedbackId;
                }else if(status === 404) {
                    message = this.templateBody.feedbackExpiredError;
                }
            }
            //set the state false for loader component to stop being rendered
            this.setState({spinnerLoading : false});
            //fire an error alert box
            this.getAlertBox(message, 'error');
        }
    }
    arrangingInSequence = (templateBody) => {
        //dynamic sort will return a array of object(templateBody.questions) arranged according to the seq.
        templateBody.questions = templateBody.questions.sort(this.dynamicSort('seq'));
        this.setTemplateStates(templateBody);
    };
    //set the state.questions variable in order to render the form data,
    setTemplateStates = (templateBody) => {
        this.setState({questions: templateBody.questions});
        //make responseForm with the fetched templateBody
        this.makeResponseForm(templateBody)
    };

    //make the response form for submission
    makeResponseForm = (templateBody) => {
        //append the userEmail and userId if the user is currently authenticated otherwise it doesnt
        if (this.userEmail !== '' || this.userId !== '') {
            this.responseForm['userEmail'] = this.userEmail;
            this.responseForm['userId'] = this.userId;
        }
        if (this.state.fetchedFeedbackId)
            this.responseForm['feedbackId'] = this.state.fetchedFeedbackId;
        this.responseForm['templateId'] = templateBody.id;
        this.responseForm['questions'] = [];
        templateBody.questions.forEach(question => {
            //check whether the question have any default value attached to it.
            let defaultValue = '';
            question.answers.values.forEach(e => {
                if (e.default) {
                    defaultValue = e.value
                }
            });
            //For checkbox push an empty array other wise an empty string
            if (question.answers.type.toLowerCase() !== 'checkbox' &&  question.answers.type.toLowerCase() !== 'select') {
                //Pushing default value or empty string for type!==checkbox
                if (defaultValue) {
                    this.responseForm['questions'].push({
                        [question['id']]: defaultValue
                    })
                }
                else {
                    this.responseForm['questions'].push({
                        [question['id']]: ''
                    })
                }
            }
            else if(question.answers.type.toLowerCase() === 'select'){
                if (defaultValue) {
                    this.responseForm['questions'].push({
                        [question['id']]: defaultValue
                    })
                }
                else {
                    this.responseForm['questions'].push({
                        [question['id']]: question.answers.values[0].value
                    })
                }
            }
            else if(question.answers.type.toLowerCase() === 'checkbox'){
                //Pushing default values or empty Array for type===checkbox
                if (defaultValue) {
                    this.responseForm['questions'].push({
                        [question['id']]: [defaultValue]
                    })
                }
                else {
                    this.responseForm['questions'].push({
                        [question['id']]: []
                    })
                }
            }
        });
    };
    //validates form before submission
    validateForm = (e) => {
        this.setState({spinnerLoading : true});
        e.preventDefault();
        let counter = 0;
        this.state.questions.forEach(e => {
            //if there is a mandatory question present at all
            if(e.isMandatory===true){
                this.responseForm.questions.forEach(f=>{
                    //checks whether if the mandatory question is left
                    if(f.hasOwnProperty(e.id)){
                        if(f[e.id].length ===0){
                            this.setState({spinnerLoading : false});
                            //please fill out the mandatory questions
                            this.getAlertBox(this.templateBody.mandatoryErrorMsg, 'error');
                        }
                        else{
                            this.handleSubmit()
                        }
                    }
                });
            }
            //if there is a mandatory question present at all
            else{
                counter += 1;
            }
        });
        //if counter===this.state.questions.length means that there is no mandatory question
        if(counter===this.state.questions.length){
            let countered = 0;
            this.responseForm.questions.forEach(e => {
                let value = undefined;
                for (let key in e) {
                    if(e.hasOwnProperty(key)) {
                        value = e[key];
                        //atleast one question is filled
                        if (value.length !== 0) {
                            this.handleSubmit();
                        }
                        else{
                            countered += 1
                        }
                    }

                }
            });
            //if counterd===this.state.questions.length means that there is no question filled
            if(countered === this.state.questions.length){
                this.setState({spinnerLoading : false});
                //please fill any question
                this.getAlertBox(this.templateBody.minAnswerErrorMsg, 'error');
            }
        }
    };
    //Form Submission with this.reponseForm
    handleSubmit = () => {
        let  finalForm = {};
        //creating a copy of this.responseForm
        for (let key in this.responseForm) {
            if(this.responseForm.hasOwnProperty(key)) {
                finalForm[key] = this.responseForm[key]
            }
        }
        //selecting only those questions which have been answered
        let questionArray = [];
        finalForm.questions.forEach(e => {
            let value = undefined;
            for (let key in e) {
                if(e.hasOwnProperty(key)) {
                    value = e[key];
                    if (value.length !== 0) {
                        questionArray.push(e);
                    }
                }
            }
        });
        finalForm.questions = questionArray;
        this.props.submitFeedbackForm(finalForm);
    };

    //Handles the change of all the input type except Checkbox
    handleChange = (index, event, id) => {
        this.responseForm.questions[index][id] = event.target.value;
    };

    //Handles the change of input type Checkbox
    handleCheckboxChange = (index, event, id) => {
        //checks whether the option is checked or unchecked
        if(event.target.checked) {
            //if checked push into the array
            this.responseForm.questions[index][id].push(event.target.value);
        }
        else{
            //if unchecked spliced from the array
            const ind = this.responseForm.questions[index][id].indexOf(event.target.value);
            if (ind > -1) {
                this.responseForm.questions[index][id].splice(ind, 1);
            }
        }
    };

    //called to shoot an alert box
    getAlertBox = (message, type) => {
        const t = this.props.t;
        Swal.fire({
            title: t(message),
            position: 'bottom-start',
            toast: true,
            showConfirmButton: false,
            timer: 5000,
            background: type === 'success' ? '#8dc44e' : '#f1521c',
            customClass: {
                title: 'sweet-alert',
                container: 'sweet-alert-container'
            }
        });
    };

    // function for sorting an array of objects on a basis of a certain property
    dynamicSort =  (property) => {
        let sortOrder = 1;
        if (property[0] === '-') {
            sortOrder = -1;
            property = property.substr(1)
        }
        return function (a, b) {
            /* next line works with strings and numbers,
                         * and you may want to customize it to your needs
                         */
            let result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
            return result * sortOrder
        }
    };

    render() {
        const questions = this.state.questions;
        const spinnerLoading = this.state.spinnerLoading;
        return (
            <div className="template-page">
                <Navbar/>
                {/*if form submitted successfully then successPage displayed with the success message fetched from templateBody*/}
                {this.successFlag ? <SuccessPage title={this.templateBody.successMessage} hideSideNav={true} body={""}
                                                 link={"https://timelybills.app/"}/> :
                    <Row>
                        <Col s={12} m={12} l={12}>
                            <div className='container'>
                                <Card
                                    key={0}
                                    className='hoverable settings-section'>
                                    <div className="template-title">
                                        {this.templateBody.title ? <h3>{this.templateBody.title}</h3> : null}
                                    </div>
                                    <div className="template-subtitle">
                                        {this.templateBody.subtitle ? <p>{this.templateBody.subtitle}</p>: null}
                                    </div>
                                    <br/>
                                    <form onSubmit={this.validateForm} className="feedback-form">
                                        {questions.map((question, index) => {
                                            return (
                                                <div key={index} className={`question-body ${question.isMandatory ? "mandatory-asterisk" : ""}`}>
                                                    <p key={question.id} className="template-question"> {question.question}</p>
                                                    <Row className="template-row-margining">
                                                        {question.answers.type.toLowerCase() === 'select' ?
                                                            //Rendering for Question type Select
                                                                    <select className="template-select"
                                                                            defaultValue={''}
                                                                            onChange={(e) => this.handleChange(index, e, question.id)}
                                                                            name='select'>
                                                                        {question.answers.values.map((value, index) => {
                                                                            return <option key={index}
                                                                                           value={value.value}> {value.label} </option>
                                                                        })}
                                                                    </select>
                                                            : question.answers.type.toLowerCase() === 'text' || question.answers.type.toLowerCase() === 'textbox' ?
                                                                //Rendering for Question type Text
                                                                <Input s={12} m={6} l={4}
                                                                    type="text"
                                                                       placeholder={question.answers.values[0].label}
                                                                       onChange={(e) => this.handleChange(index, e, question.id)}/>
                                                                : question.answers.type.toLowerCase() === 'radio' ?
                                                                    //Rendering for Question type Radio
                                                                    question.answers.values.map((value, indexs) => {
                                                                        return (
                                                                            <div key={indexs}
                                                                                 className="radio-input-button"
                                                                                 style={{marginRight: '10px'}}
                                                                                 onChange={(e) => this.handleChange(index, e, question.id)}>
                                                                                <Input type="radio"
                                                                                       name={question.question}
                                                                                       className="reduced-space-label-symbol"
                                                                                       style={{marginRight: '10px'}}
                                                                                       label={value.label}
                                                                                       defaultChecked={value.default}
                                                                                       value={value.value}/>
                                                                            </div>
                                                                        )
                                                                    }) : question.answers.type.toLowerCase() === 'checkbox' ?
                                                                        //Rendering for Question type Checkbox
                                                                        question.answers.values.map((value, indexs) => {
                                                                            return (
                                                                                <div key={indexs}
                                                                                     className="radio-input-button"
                                                                                     style={{marginRight: '10px'}}
                                                                                     onChange={(e) => this.handleCheckboxChange(index, e, question.id)}>
                                                                                    <Input type="checkbox"
                                                                                           className="reduced-space-label-symbol"
                                                                                           label={value.label}
                                                                                           defaultChecked={value.default}
                                                                                           name={question.question}
                                                                                           value={value.value}/>
                                                                                </div>
                                                                            )
                                                                        }) : question.answers.type.toLowerCase() === 'textarea' ?
                                                                            //Rendering for Question type Textarea
                                                                            <Input s={12} m={6} l={4}
                                                                                   type="textarea"
                                                                                   placeholder={question.answers.values[0].label}
                                                                                   onChange={(e) => this.handleChange(index, e, question.id)}/>
                                                                            : ''}
                                                    </Row>
                                                </div>
                                            )
                                        })}
                                        <div className="submit-loader-button">
                                        {/*show loader i.e. Spinner component when the submit API is being awaited for the response */}
                                        {spinnerLoading  ? <Spinner/> : <input className="btn light-blue accent-4" type="submit"
                                                                               value={this.templateBody.submitButtonText}/>}
                                        {/*submit button with dynamic button text*/}
                                        </div>
                                    </form>
                                </Card>
                            </div>
                        </Col>
                    </Row>
                }
                <CookieDisclaimer />
            </div>
        )
    }
}


Template.propTypes = {
    auth: PropTypes.object.isRequired,
    templateData: PropTypes.object.isRequired,
    fetchProfile: PropTypes.func.isRequired,
    fetchFeedbackDetails: PropTypes.func.isRequired,
    submitFeedbackForm: PropTypes.func.isRequired
};
const mapStateToProps = (state) => ({
    auth: state.auth,
    userData: state.userData,
    templateData: state.templateData
});
export default withTranslation()(connect(mapStateToProps, {
    fetchProfile,
    fetchFeedbackDetails,
    submitFeedbackForm
})(withRouter(Template)));