import React, { Component } from 'react';
import {
    Row,
    Col
} from 'react-materialize';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import {withTranslation, Trans} from 'react-i18next';
import '../stylesheets/App.css';
import {iconUrl} from "../actions/config";
import {
    isIOS
} from "react-device-detect";

class SuccessPage extends Component {
    componentDidMount () {
        //link passed as props from feedback/template page
        if(this.props.auth.isAuthenticated && !this.props.link){
            this.props.history.push('/dashboard');
        }
    }

    render() {
        const {
            auth: {
                isAuthenticated,
            }
        } = this.props;
        return (
            <div className={isAuthenticated && !this.props.hideSideNav ? 'Sidenav' : ''}>
                <div className="vertical-center activate-page">
                    <Row>
                        <Col s={12} m={12} l={12}>
                            <div className='container'>
                                <div className="card darken-1">
                                    <div className="card-content center-align">
                                        <img src={iconUrl + 'icon_done.png'} alt="Done_Image"/>
                                        <span className="card-title"> <h3><Trans>{this.props.title}</Trans></h3></span>
                                        <br />
                                        {/*checks wether request ois from multiuser accept invite or not*/}
                                        {this.props.from && this.props.from==='acceptInvite'? <span> <h5><Trans>{this.props.body}</Trans>{this.props.ownerDetail}<Trans>FAMILY_GROUP</Trans></h5>
                                            <h5 style={{color: '#09AF00'}}><Trans>FAMILY_GROUP_SUBTITLE</Trans></h5></span>:<span> <h5><Trans>{this.props.body}</Trans></h5></span>}
                                        <br />
                                        <br />
                                        {/*link passed via feedback pages*/}
                                        {this.props.link ? <a href={this.props.link}><span><h5><Trans>GO_HOME</Trans></h5></span></a>
                                            : <a href={isIOS ? 'timelybills://' : 'https://web.timelybills.app/signin'}><span><h5><Trans>SIGN_IN_TIMELYBILLS</Trans></h5></span></a>
                                        }
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        );
    }
}

SuccessPage.propTypes = {
    auth:PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    auth: state.auth,
});

export default withTranslation()(connect(mapStateToProps,{})(withRouter(SuccessPage)));





