import {
    INCOME_DATA_LOADING,
    GET_INCOME_DATA_SUCCESS,
    INCOME_DATA_FAILED, INCOME_IMAGE_LOADING, GET_INCOME_IMAGE, INCOME_DATA_ACTION, GET_RECURRING_SUCCESS,
    GET_RECURRING_SUCCESS_INCOME,
} from './types';
import { handleErrors } from './authActions';
import * as api from '../services/ApiCall';
import {getCurrentUTCTime, getMidDayTime} from "../components/common/Methods";

const getLoadingDispatch = (type, state) => ({type, state});

export const getIncomeTransactions = (year) => dispatch => {
    dispatch(getLoadingDispatch(INCOME_DATA_LOADING, true));
    api.callPost('api/userdata/getMonthlyIncomeWithTransactions', year)
        .then(data => {
            dispatch({
                type: GET_INCOME_DATA_SUCCESS,
                payload: data.result
            });
            dispatch(getLoadingDispatch(INCOME_DATA_LOADING, false));
            dispatch(getLoadingDispatch(INCOME_DATA_ACTION, ''));
        })
        .catch(err => dispatch(handleErrors(err, INCOME_DATA_FAILED)));
};

export const addUpdateIncome = (data, year, cb) => dispatch => {
    dispatch(getLoadingDispatch(INCOME_DATA_LOADING, true));
    console.log(getLoadingDispatch(INCOME_DATA_ACTION, 'UPDATE_INCOME'))
    setTimeout(e=>dispatch(getLoadingDispatch(INCOME_DATA_LOADING, false)), 100)
    data.lastModifyTime = getCurrentUTCTime();
    if(data.time){
        data.time = getMidDayTime(data.time);
    }
    api.callPost('api/userdata/addUpdateIncome', data)
        .then(res => {
            dispatch(getIncomeTransactions({year: year}));
            if(!data._id) {
                dispatch(getLoadingDispatch(INCOME_DATA_ACTION, 'ADD_INCOME'));
            }else {
                dispatch(getLoadingDispatch(INCOME_DATA_ACTION, 'UPDATE_INCOME'));
            }
        })
        .catch(err => dispatch(handleErrors((err && err.data ? err.data : err), INCOME_DATA_FAILED)));
};

export const getMedia = (fileName, userId=false,cb) => dispatch => {
    dispatch(getLoadingDispatch(INCOME_IMAGE_LOADING, true));
    if(!userId) {
        const userDetail = JSON.parse(localStorage.getItem('profileDetails'));
        userId = userDetail.userId;
    }
    api.umediaGet(`media/${userId}/${fileName}`)
        .then((imageData) => {
            dispatch({
                type: GET_INCOME_IMAGE,
                payload: imageData
            });
            if(typeof cb === 'function') {
                cb(imageData)
            }
            dispatch(getLoadingDispatch(INCOME_IMAGE_LOADING, false));
        }).catch((err)=>{dispatch(getLoadingDispatch(INCOME_IMAGE_LOADING, false));})
};

export const deleteTransaction = (data, year, deleteSingleRecord = true) => dispatch => {
    dispatch(getLoadingDispatch(INCOME_DATA_LOADING, true));
    let dataObj = data;
    let apiURL = 'api/userdata/deleteRecurringTrx';
    if(deleteSingleRecord) {
        apiURL = 'api/userdata/deleteTransactionWeb';
        dataObj = {
            transactions: [
                {
                    _id: data.id
                }
            ]
        }
    }
    dataObj.lastModifyTime = getCurrentUTCTime();
    api.callPost(apiURL, dataObj)
        .then(res => {
            dispatch(getLoadingDispatch(INCOME_DATA_ACTION, 'DELETE_INCOME'));
            dispatch(getIncomeTransactions(year));
        })
        .catch(err => dispatch(handleErrors(err, INCOME_DATA_FAILED)));
};

export const uploadMedia = (dataFile, fileName, cb) => dispatch => {
    const formData = new FormData();
    formData.append('file', dataFile, fileName);
    dispatch(getLoadingDispatch(INCOME_IMAGE_LOADING, true));
    api.uMediaPost(formData)
        .then(data => {
            if(typeof cb === 'function') {
                cb();
            }
            dispatch(getLoadingDispatch(INCOME_DATA_ACTION, 'ADD_MEDIA'));
            dispatch(getLoadingDispatch(INCOME_IMAGE_LOADING, false));
        })
        .catch(err => {dispatch(getLoadingDispatch(INCOME_IMAGE_LOADING, false));dispatch(handleErrors((err && err.data ? err.data : err), INCOME_DATA_FAILED))});
};

export const getRecurringDataById = (recurringServerId, editableRecord) => dispatch => {
    dispatch(getLoadingDispatch(INCOME_DATA_LOADING, true));
    api.callPost('api/userdata/getRecurringParentTrx', recurringServerId)
        .then(({Transaction}) => {
            const recurringData = editableRecord ? {
                recurringCategoryId: Transaction.recurringCategoryId,
                recurringCount: Transaction.recurringCount,
                repeatTillCount: Transaction.repeatTillCount,
                repeatTillDate: Transaction.repeatTillDate
            } : Transaction;
            dispatch({
                type: GET_RECURRING_SUCCESS_INCOME,
                payload: recurringData
            });
            dispatch(getLoadingDispatch(INCOME_DATA_ACTION, 'GET_RECURRING'));
            dispatch(getLoadingDispatch(INCOME_DATA_LOADING, false));
        }).catch(err => dispatch(handleErrors(err, INCOME_DATA_FAILED)));
};