import React, { Component } from 'react';
import { withRouter, Redirect } from 'react-router-dom';

import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
    fetchProfile,
    logoutUser,
    getGroupUsersDetails,
    getCategories,
    getAccountTypes,
    getServiceProviders, getLoggedInUserAccountList,
} from "../actions/authActions";
import Spinner from "./common/Spinner";

import '../stylesheets/App.css'
import setAuthToken from "../utils/setAuthToken";
if (window.location.pathname === '/account') {
    localStorage.setItem('redirectToAccount', JSON.stringify(true));
  }
class LoadProfile extends Component {
    constructor(props) {
        super(props);
        this.state = {
			errors: {}
        };
        this.props.fetchProfile(this.getCategoryData);
    }

    getCategoryData = () => {
        const userSettings = JSON.parse(localStorage.getItem('profileDetails')) || {};
        setAuthToken(localStorage.jwtToken, userSettings.languageCode);
        this.props.getCategories();
        this.props.getAccountTypes();
        this.props.getLoggedInUserAccountList();
        this.props.getGroupUsersDetails();
        this.props.getServiceProviders();
    };

    componentWillReceiveProps(nextProps){
        if(nextProps.errors){
            this.setState({errors: nextProps.errors});
        }
    }

    onLogOutClick = (event) => {
        event.preventDefault();
        this.props.logoutUser();
    };
    render() {
        const {
            isAuthenticated,
            userSettings,
            settings,
            serviceProviders
        } = this.props.auth;
        return (
            <div>
                {
                    isAuthenticated ?
                    <div style={{padding: '20px'}}>
                        {serviceProviders && userSettings && settings && localStorage.getItem('redirectToAccount')?
                        <Redirect to='/account'/>
                        :serviceProviders && userSettings && settings
                            ?<Redirect to='/dashboard'/>
                            : <div className="loading-all-page backdrop-div">
                                <Spinner/>
                                <h1>Loading...</h1>
                            </div>
                        }
                    </div> :
                    <Redirect to='/signin'/>
                }
            </div>
        );
    }
}

LoadProfile.propTypes = {
    fetchProfile: PropTypes.func.isRequired,
    getCategories: PropTypes.func.isRequired,
    getAccountTypes: PropTypes.func.isRequired,
    getLoggedInUserAccountList: PropTypes.func.isRequired,
    getServiceProviders: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    loading: PropTypes.object.isRequired,
    logoutUser: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    auth: state.auth,
	errors: state.errors,
    loading: state.loading
});

export default connect(
    mapStateToProps,
    {
        fetchProfile,
        getCategories,
        getGroupUsersDetails,
        getAccountTypes,
        getLoggedInUserAccountList,
        getServiceProviders,
        logoutUser
    })(withRouter(LoadProfile));
