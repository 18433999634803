import React, { Component } from 'react';
import Navbar from "./Navbar";
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { forgotPassword } from "../actions/authActions";
import Spinner from "./common/Spinner";
import {withTranslation,Trans} from 'react-i18next';

import '../stylesheets/App.css'
import {Link} from "react-router-dom";

class ForgotPassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email:'',
            errors:{}
        };
        this.initialLoader = false;
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentWillReceiveProps(nextProps){
        if(nextProps.auth.isAuthenticated){
            this.props.history.push('/dashboard')
        }
        if(nextProps.errors){
            this.setState({errors: nextProps.errors});
        }
        if(!this.initialLoader){
            this.setState({errors: {}});
            this.initialLoader = true;
        }
    }

    handleChange(e){
        this.setState({[e.target.name]:e.target.value});
    }

    handleSubmit(e){
        e.preventDefault();
        const userData = {
            email:this.state.email,
        };
        this.props.forgotPassword(userData);
    }

    render() {
        const loading = this.props.loading.loading;
        return (
            <div>
                <Navbar/>
                <div className="vertical-center forgot-password-section">
                    <div className="row">
                        <div className="col m2 l3" />
                        <div className="col l6 m8 s12">
                            <div className="card darken-1">
                                <div className="card-content">
                                    <span className="card-title center-align"> <h3><Trans>FORGOT_PASSWORD</Trans></h3></span>
                                    <span className="center-align"> <h5><Trans>SEND_RECOVERY_LINK</Trans></h5></span>
                                    <form onSubmit={this.handleSubmit}>
                                        <div className="row">
                                            <div className="col m1" />
                                            <div className="input-field col m10 s12">
                                                <input id="icon_prefix" type="email" onChange={this.handleChange} name="email" required/>
                                                <label htmlFor="icon_prefix"><Trans>EMAIL</Trans></label>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col m1" />
                                            <div className="input-field col m10 s12">
                                                <span style={this.state.errors.code!==0 ? {color: "red"} : {color: "green"}}>
                                                    {this.state.errors.message ? this.state.errors.message : null}
                                                </span>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col m1" />
                                            <div className="input-field col l5 m5 s6">
                                                {/*<button className="btn waves-effect waves-light light-blue accent-4" type="submit" name="action" >Proceed</button>*/}
                                                {
                                                    loading ?
                                                        <Spinner/> :
                                                        <button
                                                            className="btn waves-effect waves-light light-blue accent-4"
                                                            type="submit"
                                                            name="action"><Trans>PROCEED</Trans>
                                                        </button>
                                                }
                                            </div>
                                            <div className="col m4 s2" />
                                            <div className="input-field col l5 m5 s12 orLink">
                                                <Link to="/signin" ><Trans>SIGN_IN</Trans></Link>
                                            </div>

                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

ForgotPassword.propTypes = {
    forgotPassword: PropTypes.func.isRequired,
    auth:PropTypes.object.isRequired,
    loading:PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
    auth: state.auth,
    errors: state.errors,
    loading:state.loading
});

export default withTranslation()  (connect(mapStateToProps,{forgotPassword})(ForgotPassword));
