import React, { Component } from 'react';
import Navbar from "./Navbar";

import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import {Trans, withTranslation} from 'react-i18next';

import '../stylesheets/App.css'

class passwordUpdateSuccess extends Component {

    componentDidMount () {
        if(this.props.auth.isAuthenticated){
            this.props.history.push('/dashboard');
        }
    }

    render() {
        return (
            <div>
                <Navbar/>
                <div className="vertical-center">
                    <div className="row">
                        <div className="col s2"></div>
                        <div className="col m8 s12 ">
                            <div className="card darken-1">
                                <div className="card-content center-align">
                                    <span className="card-title"> <h3><Trans>PASSWORD_UPDATED_SUCCESSFULLY</Trans></h3></span>
                                    <span> <h5><Trans>PLEASE_SIGN_IN_TO_CONTINUE</Trans></h5></span>
                                    <div className="row">
                                        <div className="col s3"></div>
                                        <div className="input-field col m6 s12">
                                            <a href="/signin">
                                                <button className="btn waves-effect waves-light light-blue accent-4" >Sign In</button>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

passwordUpdateSuccess.propTypes = {
    auth:PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
    auth: state.auth
});

export default withTranslation() (connect(mapStateToProps,{})(withRouter(passwordUpdateSuccess)));





