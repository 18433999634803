import React from 'react';
import { unescape }from 'lodash';
import _ from 'lodash';
import i18n from '../../local/i18n';
import {DEFAULT_CAT_2, DEFAULT_CAT_1, s3Url} from '../../actions/config';

export default (props) => {
	const actType =  JSON.parse(localStorage.getItem('accountTypes'));
    const acctObj = props.value
        ? actType.find(({code}) => code === props.value)
        : actType[0];
    let logoUrl = s3Url + acctObj.icon + '.png';

	const onChange = (e, b) => {
        const selectedAcct = actType.find(({code}) => code === +e.target.value);
		props.onChange(selectedAcct);
	};

    const style4Bg = {
        height: '30px'
    };

	const { t } = props;
	return <table
		className='table-category-selector'>
		<tbody>
			<tr>
				<td style={{width: '3.9rem'}}>
					<img
						src={logoUrl}
						alt="icon"
						className="leftMargin10 circle"
						style={style4Bg}>
					</img>
				</td>
				<td>
					<select
						onChange={onChange}
						value={props.value}
						disabled={props.disabled || false}
						placeholder={t('SELECT_CATEGORY')}
						style={{display: 'block'}}
					>
						{
							actType.map((act, key) =>
								<option
									value={act.code}
									key={key}
								>
									{unescape(act.NAME)}
								</option>
							)
						} 
					</select>
				</td>
			</tr>
		</tbody>
	</table>
}
