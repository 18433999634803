import {
    BUDGET_CATEGORY_DATA_LOADING,
    BUDGET_MONTHLY_DATA_LOADING,
    GET_MONTHLY_BUDGET_DATA_SUCCESS,
    GET_CATEGORY_BUDGET_DATA_SUCCESS,
    BUDGET_DATA_FAILED,
    BUDGET_DATA_ACTION
} from './types';
import { handleErrors } from './authActions';
import * as api from '../services/ApiCall';
import {getCurrentUTCTime} from "../components/common/Methods";
import { BUDGET_TYPE } from '../components/common/ConstData';

const getLoadingDispatch = (type, state) => ({type, state});

export const getCategoryBudgetList = (yearMonth) => dispatch => {
    dispatch(getLoadingDispatch(BUDGET_CATEGORY_DATA_LOADING, true));
    api.callPost('api/userdata/getCategoryBudgetList', yearMonth)
        .then(data => {
            dispatch({
                type: GET_CATEGORY_BUDGET_DATA_SUCCESS,
                payload: data.result
            });
            dispatch(getLoadingDispatch(BUDGET_DATA_ACTION, ''));
            dispatch(getLoadingDispatch(BUDGET_CATEGORY_DATA_LOADING, false));
        })
        .catch(err => dispatch(handleErrors((err && err.data ? err.data : err), BUDGET_DATA_FAILED)));
};

export const getMonthlyBudgetList = (year) => dispatch => {
    dispatch(getLoadingDispatch(BUDGET_MONTHLY_DATA_LOADING, true));
    api.callPost('api/userdata/getBudgetByYear', year)
        .then(data => {
            dispatch({
                type: GET_MONTHLY_BUDGET_DATA_SUCCESS,
                payload: data.result
            });
            dispatch(getLoadingDispatch(BUDGET_DATA_ACTION, ''));
            dispatch(getLoadingDispatch(BUDGET_MONTHLY_DATA_LOADING, false));
        })
        .catch(err => {
            const errdata = handleErrors((err && err.data ? err.data : err), BUDGET_DATA_FAILED);
            dispatch(errdata);
        });
};

export const addUpdateBudget = (data, year = new Date(data.month).getFullYear(), cb) => dispatch => {
    dispatch(getLoadingDispatch(BUDGET_MONTHLY_DATA_LOADING, true));
    let dataObj = {
        date: data.month,
        amount: data.amount
    };
    dataObj.lastModifyTime = getCurrentUTCTime();
    if(data.viewData && data.viewData._id) {
        dataObj = {
            date: data.year+'-'+(data.month+1),
            amount: data.amount,
            lastModifyTime: getCurrentUTCTime()
        };
    }
    api.callPost('api/userdata/addUpdateBudget', {...dataObj, familyShare: data.familyShare,
        editFuture: data.editFuture,
        carryForward: data.carryForward, type: BUDGET_TYPE.EXPENSE, budgetType: BUDGET_TYPE.EXPENSE})
        .then(res => {
            api.callPost('api/userdata/getBudgetByYear', {year: year})
                .then(data => {
                    dispatch({
                        type: GET_MONTHLY_BUDGET_DATA_SUCCESS,
                        payload: data.result
                    });
                    if(typeof cb === 'function' && !dataObj._id) {
                        cb();
                    }
                    dispatch(getLoadingDispatch(BUDGET_MONTHLY_DATA_LOADING, false));
                    if(dataObj._id) {
                        dispatch(getLoadingDispatch(BUDGET_DATA_ACTION, 'UPDATE_BUDGET'));
                    }else {
                        dispatch(getLoadingDispatch(BUDGET_DATA_ACTION, 'ADD_BUDGET'));
                    }
                    dispatch(getLoadingDispatch(BUDGET_DATA_ACTION, ''));
                });
        })
        .catch(err => dispatch(handleErrors((err && err.data ? err.data : err), BUDGET_DATA_FAILED)));
};

export const addUpdateCategoryBudget = (data, cb) => dispatch => {
    dispatch(getLoadingDispatch(BUDGET_CATEGORY_DATA_LOADING, true));
    let dataObj = {
        date:  data.selectedYear+'-'+(data.selectedMonth+1),
        amount: data.amount,
        categoryId: data.categoryId
    };
    dataObj.lastModifyTime = getCurrentUTCTime();
    if(data._id) {
        dataObj = {
            date: data.selectedYear+'-'+(data.selectedMonth+1),
            categoryId: data.categoryId,
            amount: data.amount,
            lastModifyTime: getCurrentUTCTime()
        };
    }
    data.carryForward =  BUDGET_TYPE.EXPENSE  === data.type ? data.carryForward : false;
    const yearMonth = {year: data.selectedYear, month: data.selectedMonth};
    api.callPost('api/userdata/addUpdateBudget', {...dataObj, type: data.type, editFuture: data.editFuture, carryForward: data.carryForward, familyShare: data.familyShare})
        .then(res => {
            api.callPost('api/userdata/getCategoryBudgetList', yearMonth)
                .then(data => {
                    dispatch({
                        type: GET_CATEGORY_BUDGET_DATA_SUCCESS,
                        payload: data.result
                    });
                    dispatch(getLoadingDispatch(BUDGET_CATEGORY_DATA_LOADING, false));
                    if(typeof cb === 'function' && !dataObj._id) {
                        cb();
                    }
                    if(dataObj._id) {
                        dispatch(getLoadingDispatch(BUDGET_DATA_ACTION, 'UPDATE_BUDGET'));
                    }else {
                        dispatch(getLoadingDispatch(BUDGET_DATA_ACTION, 'ADD_BUDGET'));
                    }
                    dispatch(getLoadingDispatch(BUDGET_DATA_ACTION, ''));
                });
        })
        .catch(err => dispatch(handleErrors((err && err.data ? err.data : err), BUDGET_DATA_FAILED)));
};

export const deleteTransaction = (data, cb) => dispatch => {
    dispatch(getLoadingDispatch(BUDGET_MONTHLY_DATA_LOADING, true));
    // let dataObj = {
    //     lastModifyTime: getCurrentUTCTime(),
    //     transactions: data.transactions,
    //     year: data.yearMonth.year
    // };
    let dataObj = {
        lastModifyTime: getCurrentUTCTime(),
        month: data.yearMonth.month,
        year: data.yearMonth.year,
        _id: data.transactions[0]._id,
        deleteFuture: data.deleteFuture
    };
    api.callPost('api/userdata/deleteBudget', dataObj)
        .then(res => {
            api.callPost('api/userdata/getBudgetByYear', {year: data.yearMonth.year})
                .then(data => {
                    dispatch({
                        type: GET_MONTHLY_BUDGET_DATA_SUCCESS,
                        payload: data.result
                    });
                    if(typeof cb === 'function' && !data.monthId) {
                        cb();
                    }
                    dispatch(getLoadingDispatch(BUDGET_MONTHLY_DATA_LOADING, false));
                    dispatch(getLoadingDispatch(BUDGET_DATA_ACTION, 'DELETE_BUDGET'));
                    dispatch(getLoadingDispatch(BUDGET_DATA_ACTION, ''));
                });
        })
        .catch(err => dispatch(handleErrors((err && err.data ? err.data : err), BUDGET_DATA_FAILED)));
};

export const deleteCategoryTransaction = (data, cb) => dispatch => {
    dispatch(getLoadingDispatch(BUDGET_CATEGORY_DATA_LOADING, true));
    let dataObj = {
        lastModifyTime: getCurrentUTCTime(),
        month: data.selectedMonth,
        year: data.selectedYear,
        _id: data._id,
        deleteFuture: data.deleteFuture
    };
    dataObj.lastModifyTime = getCurrentUTCTime();
    const yearMonth = {year: data.selectedYear, month: data.selectedMonth};
    api.callPost('api/userdata/deleteBudget', dataObj)
        .then(res => {
            api.callPost('api/userdata/getCategoryBudgetList', yearMonth)
                .then(data => {
                    dispatch({
                        type: GET_CATEGORY_BUDGET_DATA_SUCCESS,
                        payload: data.result
                    });
                    dispatch(getLoadingDispatch(BUDGET_CATEGORY_DATA_LOADING, false));
                    dispatch(getLoadingDispatch(BUDGET_DATA_ACTION, 'DELETE_BUDGET'));
                    dispatch(getLoadingDispatch(BUDGET_DATA_ACTION, ''));
                });
        })
        .catch(err => dispatch(handleErrors((err && err.data ? err.data : err), BUDGET_DATA_FAILED)));
};
