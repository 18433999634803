import React from 'react'
import ReactTooltip from "react-tooltip";
import {Trans} from 'react-i18next';


export default function CTooltip(props) {
    return (
        <ReactTooltip id={props.id}
                      type="info"
                      place="top"
        >
            <p><Trans>{props.text}</Trans></p>
        </ReactTooltip>
    )
}


export function CTooltipHtml(props) {
    return (
        <ReactTooltip id={props.id}
                      type="info"
                      place="top"
        >
            {props.content}
        </ReactTooltip>
    )
}