import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {withTranslation, Trans} from 'react-i18next';
import {
    clearErrors
} from "../actions/authActions";
import '../stylesheets/App.css';
import {iconUrl} from "../actions/config";

class Success extends Component {
    render() {
        return (
            <div>
                <div className="vertical-center">
                    <div className="row margin-0">
                        <div className="col m2 l3" />
                        <div className="col l6 m8 s12 ">
                            <div className="card darken-1">
                                <div className="card-content">
                                    <div className="center-align word-break">
                                        <img src={iconUrl + 'icon_done.png'}  height="100px" alt="UserImage"/>
                                        <span className="card-title"><h4><Trans>PASSWORD_SUCCESSFULLY</Trans></h4></span>
                                    </div>
                                    <div className="row">
                                        <div className="col s3" />
                                        <div className="input-field col m6 s12 item-center">
                                            <Link to="/dashboard">
                                                <Trans>GO_HOME</Trans>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

Success.propTypes = {
    auth:PropTypes.object.isRequired,
    loading:PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    auth: state.auth,
    loading: state.loading
});

export default withTranslation()(connect(mapStateToProps, {
    clearErrors,
})(withRouter(Success)));
