import React, { Component } from 'react';
import Navbar from "./Navbar";
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { resetPassword, differentPasswords } from "../actions/authActions";
import {withTranslation, Trans} from 'react-i18next';
import Swal from 'sweetalert2';

import '../stylesheets/App.css'

class ResetPassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pin:'',
            password:'',
            password2:'',
            errors:''
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount () {
        this.setState({pin:this.props.match.params.pin});
    }

    componentWillReceiveProps(nextProps){
        if(nextProps.auth.isAuthenticated){
            this.props.history.push('/dashboard')
        }
        if(nextProps.errors){
            this.setState({errors: nextProps.errors});
        }
    }

    handleChange(e){
        this.setState({[e.target.name]:e.target.value});
    }

    getAlertBox = (message, type) => {
        const { t } = this.props;
        Swal.fire({
            title: t(message),
            position: 'bottom-start',
            toast: true,
            showConfirmButton: false,
            timer: 2000,
            background: type==='success' ? '#8dc44e' : '#f1521c',
            customClass: {
                title: 'sweet-alert',
                container: 'sweet-alert-container'
            }
        });
    };

    handleSubmit(e){
        e.preventDefault();
        if(this.state.password === this.state.password2){
            const userData = {
                resetPinNumber:this.state.pin,
                newPassword:this.state.password,
                confirmPassword:this.state.password2,
            };
            this.props.resetPassword(userData, this.props.history);
        }else {
            this.getAlertBox('PASSWORD_MISMATCH', 'error');
        }
    }

    render() {

        return (
            <div>
                <Navbar />
                <div className="vertical-center reset-password-section">
                    <div className="row">
                        <div className="col m2 l3" />
                        <div className="col l6 m8 s12">
                            <div className="card darken-1">
                                <div className="card-content">
                                    <span className="card-title center-align"> <h3>
                                        <Trans>RESET_PASSWORD</Trans>
                                    </h3></span>
                                    <form onSubmit={this.handleSubmit}>

                                        <div className="row">
                                            <div className="col m1" />
                                            <div className="input-field col m10 s12">
                                                <input type="password" onChange={this.handleChange} name="password" required/>
                                                <label htmlFor="icon_prefix">
                                                    <Trans>NEW_PASSWORD</Trans>
                                                </label>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col m1" />
                                            <div className="input-field col m10 s12">
                                                <input type="password" onChange={this.handleChange} name="password2" required/>
                                                <label htmlFor="icon_prefix">
                                                    <Trans>CONFIRM_PASSWORD</Trans>
                                                </label>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col m1" />
                                            <div className="input-field col m10 s12">
                                                <span style={{color:"red"}}>{this.state.errors.message}</span>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col m1" />
                                            <div className="input-field col l11 m11 s12">
                                                <a href="/password-updated">
                                                    <button className="btn waves-effect waves-light light-blue accent-4" type="submit" name="action" >
                                                        <Trans>RESET_PASSWORD</Trans>
                                                    </button>
                                                </a>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

ResetPassword.propTypes = {
    resetPassword: PropTypes.func.isRequired,
    auth:PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
    auth: state.auth,
    errors: state.errors
});

export default withTranslation()(connect(mapStateToProps,{resetPassword, differentPasswords})(ResetPassword));
