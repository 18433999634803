import React, { Component } from "react";
import { withTranslation, Trans } from "react-i18next";
import { Row } from "react-materialize";
import "../stylesheets/App.css";
import EditCategoryBudget from "./EditCategoryBudget";
import { FormattedNumber } from "react-intl";
import { SHORT_MONTHS } from "./common/ConstData";
import * as Swal from "sweetalert2";
import { intNVL, isCreator } from "./common/Methods";
import { unescape } from "lodash";
import { iconUrl, RIGHT_ARROW_ICON } from "../actions/config";
import moment from "moment";

class CategoryBudgetDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editMode: false,
      categoryId: 0,
      amount: "",
      month: new Date().getMonth(),
      time: new Date(),
      _id: "",
    };
  }
  componentWillMount() {
    const allUserCategory = JSON.parse(localStorage.getItem("userCategories"));
    allUserCategory.some((category) => {
      if (category.type === 1) {
        this.setState({
          categoryId: category.categoryId,
        });
        return true;
      }
      return null;
    });
    if (this.props.viewData) {
      this.setState({
        categoryId: this.props.viewData.categoryId,
        amount: this.props.viewData.budgetAmt,
        month: this.props.viewData.month,
        time: this.props.viewData.time,
        _id: this.props.viewData._id,
        carryForward: this.props.viewData.carryForward,
        familyShare: this.props.viewData.familyShare,
      });
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.viewData) {
      this.setState({
        categoryId: nextProps.viewData.categoryId,
        amount: nextProps.viewData.budgetAmt,
        month: nextProps.viewData.month,
        carryForward: nextProps.viewData.carryForward,
        familyShare: nextProps.viewData.familyShare,
        time: nextProps.viewData.time,
        _id: nextProps.viewData._id,
      });
    }
  }

 

  backToDetail = () => {
    this.setState({
      editMode: false,
    });
  };
  getAlertBox = (message, type) => {
    const t = this.props.t;
    Swal.fire({
      title: t(message),
      position: "bottom-start",
      toast: true,
      showConfirmButton: false,
      timer: 2000,
      background: type === "success" ? "#8dc44e" : "#f1521c",
      customClass: {
        title: "sweet-alert",
        container: "sweet-alert-container",
      },
    });
  };
  setEditable() {
    if (this.props.viewData.disableHiddenFlag) {
      this.getAlertBox("THIS_CATEGORY_IS_HIDDEN", "error");
      return;
    }
    if (this.props.viewData.disableDeletedFlag) {
      this.getAlertBox("THIS_CATEGORY_IS_DELETED", "error");
      return;
    }
    const t = this.props.t;
    if (this.props.viewData.recurringCategoryId != 0) {
      Swal.fire({
        title: t("EDIT"),
        text: t("EDIT_INCOME_MESSAGE"),
        type: "question",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#3085d6",
        confirmButtonText: t("EDIT_ALL_FUTURE"),
        cancelButtonText: t("EDIT_THIS"),
      }).then(async (result) => {
        if (result.dismiss === "backdrop") {
          return;
        }
        if (result.value) {
          this.setState({
            editMode: true,
            editFuture: true,
          });
        } else {
          this.setState({
            editMode: true,
            editFuture: false,
          });
        }
      });
    
    } else {
      this.setState({
        editMode: true,
        editFuture: false,
      });
    }
  }

  createUpdateDate(date = new Date(), t) {
    const dateObj = new Date(date);
    let hours = dateObj.getHours();
    let minutes = dateObj.getMinutes();
    let ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? "0" + minutes : minutes;
    const strTime = hours + ":" + minutes + " " + ampm;

    return (
      t(SHORT_MONTHS[dateObj.getMonth()]) +
      ("0" + dateObj.getDate()).slice(-2) +
      ", " +
      dateObj.getFullYear() +
      "," +
      strTime
    );
  }

  onCategoryChange = (categoryId) => {
    this.setState({
      categoryId: categoryId,
    });
  };

  onSubmitBudget = (data) => {
    data = { ...data };
    data.carryForward = this.state.carryForward;
    data.familyShare = this.state.familyShare;
    this.props.onSubmit(data);
    this.setState({
      editMode: false,
    });
  };

  onTextChange = (key, e) => {
    this.setState({
      [key]: e.target.value,
    });
  };
  canEdit() {
    var min = moment(moment().format("YYYY-MM"));
    var max = moment(moment().add("2", "M").format("YYYY-MM"));
    var date = new Date();
    date.setFullYear(this.props.year);
    date.setMonth(this.props.month);
    var now = moment(date.toISOString());
    return now.isBetween(min, max);
  }

  render() {
    const {
      userSettings: { currencyDetails },
      viewData,
      t,
    } = this.props;
    return (
      <div>
        {!this.state.editMode ? (
          <div>
            <Row>
              <div className="col m12 static-text">
                <div className="input-field col s12">
                  <h3>{unescape(viewData.name)}</h3>
                </div>
              </div>
            </Row>
            <Row>
              <div className="col m12 static-text border-right">
                <div className="input-field col s6">
                  <h3>
                    <b>
                      <span className="nowrap">
                        <FormattedNumber
                          value={viewData.amount || 0}
                          style="currency"
                          currency={currencyDetails.currencyCode || "USD"}
                          minimumFractionDigits={2}
                          maximumFractionDigits={2}
                        />
                      </span>
                    </b>
                  </h3>
                  <div>
                    <h6>
                      <Trans>BUDGET</Trans>
                    </h6>

                    {viewData.carryForwardAmtAdded ? (
                      <h6>
                        <Trans>BUDGET_AMT</Trans>(
                        <span className="nowrap">
                          <FormattedNumber
                            value={viewData.budgetAmt || 0}
                            style="currency"
                            currency={currencyDetails.currencyCode || "USD"}
                            minimumFractionDigits={2}
                            maximumFractionDigits={2}
                          />
                        </span>
                        ) {" + "}
                        <Trans>ROLLOVER_AMT</Trans>(
                        <span className="nowrap">
                          <FormattedNumber
                            value={viewData.carryForwardAmount || 0}
                            style="currency"
                            currency={currencyDetails.currencyCode || "USD"}
                            minimumFractionDigits={2}
                            maximumFractionDigits={2}
                          />
                        </span>
                        )
                      </h6>
                    ) : null}
                  </div>
                </div>
                <div className="input-field col s6">
                  <h3>
                    <b>
                      <span className="nowrap">
                        <FormattedNumber
                          value={viewData.expenseincome || 0}
                          style="currency"
                          currency={currencyDetails.currencyCode || "USD"}
                          minimumFractionDigits={2}
                          maximumFractionDigits={2}
                        />
                      </span>
                    </b>
                  </h3>
                  <h6>
                    <Trans>{viewData.typeKey}</Trans>
                  </h6>
                </div>
              </div>
            </Row>
            <Row>
              <div className="col m12 static-text">
                <div
                  className={
                    "input-field col s12 left-value " +
                    (viewData.expenseincome > intNVL(viewData.amount)
                      ? "danger"
                      : "")
                  }
                >
                  <h3>
                    <b>
                      <span
                        className="nowrap"
                        style={{ color: viewData.overcolor }}
                      >
                        <FormattedNumber
                          value={Math.abs(
                            (viewData.amount || 0) -
                              intNVL(viewData.expenseincome || 0)
                          )}
                          style="currency"
                          currency={currencyDetails.currencyCode || "USD"}
                          minimumFractionDigits={2}
                          maximumFractionDigits={2}
                        />
                      </span>
                    </b>
                  </h3>
                  <h6>
                    <Trans>{viewData.overText}</Trans>
                  </h6>
                </div>
              </div>
            </Row>
            <Row>
              <div className="col m12 static-text">
                {viewData.carryForward === true ? (
                  <div>
                    <i
                      class="material-icons"
                      style={{
                        position: "relative",
                        top: "5px",
                        color: "#2196F3",
                      }}
                    >
                      check
                    </i>
                    <Trans>CARRY_FORWARED_AMTOUNT</Trans>
                  </div>
                ) : null}
              </div>
            </Row>
            {localStorage.getItem("familyShare") === "y" &&
            viewData.familyShare ? (
              <Row>
                <div>
                  <i
                    class="material-icons"
                    style={{
                      position: "relative",
                      top: "5px",
                      color: "#2196F3",
                    }}
                  >
                    check
                  </i>
                  <Trans>FAMILY_SHARE</Trans>
                </div>
              </Row>
            ) : null}
            {/* <Row>
              <div className="Delete-button">
                {this.canEdit() ? (
                  <div className="input-field col m6 s12">
                    <button
                      className="btn waves-effect waves-light light-blue accent-4"
                      onClick={this.setEditable.bind(this)}
                      type="button"
                      name="action"
                    >
                      <Trans>EDIT</Trans>
                      <i className="material-icons right">edit</i>
                    </button>
                  </div>
                ) : null}
                <div className="input-field col m6 s12">
                  <button
                    className="btn waves-effect red lighten-2 accent-4"
                    onClick={this.props.onDelete.bind(this, this.state)}
                    type="button"
                    name="action"
                  >
                    <Trans>DELETE</Trans>
                    <i className="material-icons right">delete</i>
                  </button>
                </div>
              </div>
            </Row> */}
            {!isCreator(
              viewData.createdUserId,
              this.props.userSettings.userId
            ) && localStorage.getItem(viewData.createdUserId) ? (
              <Row>
                <div className="static-text Delete-button border-top-0">
                  <div className="input-field col s2">
                    {this.props.auth &&
                    this.props.auth.groupImageList[viewData.createdUserId] ? (
                      <img
                        className="zero-margin  height-54"
                        src={
                          this.props.auth.groupImageList[viewData.createdUserId]
                        }
                      />
                    ) : (
                      <h5 className="zero-margin-detail">
                        {JSON.parse(
                          localStorage.getItem(viewData.createdUserId)
                        )
                          .ownerDetail.slice(0, 1)
                          .toUpperCase()}
                      </h5>
                    )}
                  </div>
                  <div className="input-field col s10">
                    <h3>
                      {
                        JSON.parse(localStorage.getItem(viewData.createdUserId))
                          .ownerDetail
                      }
                    </h3>
                    <h6>
                      <Trans>CREATED_BY</Trans>
                    </h6>
                  </div>
                </div>
                <p className="grey-text" style={{ margin: 0 }}>
                  <Trans>UPDATED</Trans>{" "}
                  {this.createUpdateDate(new Date(viewData.updated_at), t)}
                </p>
              </Row>
            ) : (
              <p className="grey-text" style={{ margin: 0 }}>
                <Trans>UPDATED</Trans>{" "}
                {this.createUpdateDate(viewData.updated_at, t)}
              </p>
            )}
          </div>
        ) : (
          <EditCategoryBudget
            {...this.props}
            {...this.state}
            onSubmitBudget={this.onSubmitBudget}
            backToDetail={this.backToDetail}
            onCategoryChange={this.onCategoryChange}
            onTextChange={this.onTextChange}
          />
        )}
      </div>
    );
  }
}

export default withTranslation()(CategoryBudgetDetail);
