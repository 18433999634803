import {
    CHANGE_PASSWORD_LOADING,
    CHANGE_PASSWORD_FAILED,
    CHANGE_PASSWORD_SUCCESS,
    USER_AVATAR_LOADING,
    USER_AVATAR_IMAGE,
    AVATAR_IMAGE_LOADING,
    AVATAR_DATA_FAILED,
    AVATAR_DATA_ACTION,
    AVATAR_DATA_SUCCESS
} from '../actions/types';

const initialState = {
    data: [],
    CHANGE_PASSWORD_LOADING: false,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case CHANGE_PASSWORD_SUCCESS:
            if (state.error) {
                delete state.error
            }
            state.CHANGE_PASSWORD_LOADING = false;
            state.CHANGE_PASSWORD_ACTION = 'UPDATE_PASSWORD';
            return {
                ...state,
                data: action.payload
            };
        case CHANGE_PASSWORD_FAILED:
            return {
                ...state,
                error: action.payload
            };
        case CHANGE_PASSWORD_LOADING:
            delete state.CHANGE_PASSWORD_ACTION;
            delete state.error;
            return {
                ...state,
                [action.type]: action.state
            };
        case USER_AVATAR_LOADING:
            if(state.error) {
                delete state.error
            }
            return{
                ...state,
                [action.type] : action.state
            };
        case USER_AVATAR_IMAGE:
            if(state.error) {
                delete state.error
            }
            return{
                ...state,
                [action.type] : action.payload
            };
        case AVATAR_IMAGE_LOADING:
            if(state.error) {
                delete state.error
            }
            return{
                ...state,
                [action.type] : action.state
            };
        case AVATAR_DATA_ACTION:
            if(state.error) {
                delete state.error
            }
            return{
                ...state,
                [action.type] : action.state
            };
        case AVATAR_DATA_FAILED:
            state[AVATAR_IMAGE_LOADING] = false;
            if(state.error) {
                delete state.error
            }
            return{
                ...state,
                [action.type] : action.state
            };
        case AVATAR_DATA_SUCCESS:
            if(state.error) {
                delete state.error
            }
            return{
                ...state,
                [action.type] : action.state
            };
        default:
            state.data = [];
            state.CHANGE_PASSWORD_LOADING = false;
            delete state.CHANGE_PASSWORD_ACTION;
            return state;
    }
}
