export const TZ_WITH_CURRENCY = {
  "Africa/Abidjan": "XOF",
  "Africa/Accra": "GHS",
  "Africa/Addis_Ababa": "ETB",
  "Africa/Algiers": "DZD",
  "Africa/Asmara": "ERN",
  "Africa/Asmera": "ERN",
  "Africa/Bamako": "XOF",
  "Africa/Bangui": "XAF",
  "Africa/Banjul": "GMD",
  "Africa/Bissau": "XOF",
  "Africa/Blantyre": "MWK",
  "Africa/Brazzaville": "XAF",
  "Africa/Bujumbura": "BIF",
  "Africa/Cairo": "EGP",
  "Africa/Casablanca": "MAD",
  "Africa/Ceuta": "EUR",
  "Africa/Conakry": "GNF",
  "Africa/Dakar": "XOF",
  "Africa/Dar_es_Salaam": "TZS",
  "Africa/Djibouti": "DJF",
  "Africa/Douala": "XAF",
  "Africa/El_Aaiun": "MAD",
  "Africa/Freetown": "SLL",
  "Africa/Gaborone": "BWP",
  "Africa/Harare": "ZWL",
  "Africa/Johannesburg": "ZAR",
  "Africa/Juba": "SSP",
  "Africa/Kampala": "UGX",
  "Africa/Khartoum": "SDG",
  "Africa/Kigali": "RWF",
  "Africa/Kinshasa": "CDF",
  "Africa/Lagos": "NGN",
  "Africa/Libreville": "XAF",
  "Africa/Lome": "XOF",
  "Africa/Luanda": "AOA",
  "Africa/Lubumbashi": "CDF",
  "Africa/Lusaka": "ZMW",
  "Africa/Malabo": "XAF",
  "Africa/Maputo": "MZN",
  "Africa/Maseru": "LSL",
  "Africa/Mbabane": "SZL",
  "Africa/Mogadishu": "SOS",
  "Africa/Monrovia": "LRD",
  "Africa/Nairobi": "KES",
  "Africa/Ndjamena": "XAF",
  "Africa/Niamey": "XOF",
  "Africa/Nouakchott": "MRO",
  "Africa/Ouagadougou": "XOF",
  "Africa/Porto-Novo": "XOF",
  "Africa/Sao_Tome": "STD",
  "Africa/Timbuktu": "XOF",
  "Africa/Tripoli": "LYD",
  "Africa/Tunis": "TND",
  "Africa/Windhoek": "NAD",
  "America/Adak": "USD",
  "America/Anchorage": "USD",
  "America/Anguilla": "XCD",
  "America/Antigua": "XCD",
  "America/Araguaina": "BRL",
  "America/Argentina/Buenos_Aires": "ARS",
  "America/Argentina/Catamarca": "ARS",
  "America/Argentina/ComodRivadavia": "ARS",
  "America/Argentina/Cordoba": "ARS",
  "America/Argentina/Jujuy": "ARS",
  "America/Argentina/La_Rioja": "ARS",
  "America/Argentina/Mendoza": "ARS",
  "America/Argentina/Rio_Gallegos": "ARS",
  "America/Argentina/Salta": "ARS",
  "America/Argentina/San_Juan": "ARS",
  "America/Argentina/San_Luis": "ARS",
  "America/Argentina/Tucuman": "ARS",
  "America/Argentina/Ushuaia": "ARS",
  "America/Aruba": "AWG",
  "America/Asuncion": "PYG",
  "America/Atikokan": "CAD",
  "America/Atka": "USD",
  "America/Bahia": "BRL",
  "America/Bahia_Banderas": "MXN",
  "America/Barbados": "BBD",
  "America/Belem": "BRL",
  "America/Belize": "BZD",
  "America/Blanc-Sablon": "CAD",
  "America/Boa_Vista": "BRL",
  "America/Bogota": "COP",
  "America/Boise": "USD",
  "America/Buenos_Aires": "ARS",
  "America/Cambridge_Bay": "CAD",
  "America/Campo_Grande": "BRL",
  "America/Cancun": "MXN",
  "America/Caracas": "VEF",
  "America/Catamarca": "ARS",
  "America/Cayenne": "EUR",
  "America/Cayman": "KYD",
  "America/Chicago": "USD",
  "America/Chihuahua": "MXN",
  "America/Coral_Harbour": "CAD",
  "America/Cordoba": "ARS",
  "America/Costa_Rica": "CRC",
  "America/Creston": "CAD",
  "America/Cuiaba": "BRL",
  "America/Curacao": "ANG",
  "America/Danmarkshavn": "DKK",
  "America/Dawson": "CAD",
  "America/Dawson_Creek": "CAD",
  "America/Denver": "USD",
  "America/Detroit": "USD",
  "America/Dominica": "XCD",
  "America/Edmonton": "CAD",
  "America/Eirunepe": "BRL",
  "America/El_Salvador": "USD",
  "America/Ensenada": "MXN",
  "America/Fort_Nelson": "CAD",
  "America/Fort_Wayne": "USD",
  "America/Fortaleza": "BRL",
  "America/Glace_Bay": "CAD",
  "America/Godthab": "DKK",
  "America/Goose_Bay": "CAD",
  "America/Grand_Turk": "USD",
  "America/Grenada": "XCD",
  "America/Guadeloupe": "EUR",
  "America/Guatemala": "GTQ",
  "America/Guayaquil": "USD",
  "America/Guyana": "GYD",
  "America/Halifax": "CAD",
  "America/Havana": "CUP",
  "America/Hermosillo": "MXN",
  "America/Indiana/Indianapolis": "USD",
  "America/Indiana/Knox": "USD",
  "America/Indiana/Marengo": "USD",
  "America/Indiana/Petersburg": "USD",
  "America/Indiana/Tell_City": "USD",
  "America/Indiana/Vevay": "USD",
  "America/Indiana/Vincennes": "USD",
  "America/Indiana/Winamac": "USD",
  "America/Indianapolis": "USD",
  "America/Inuvik": "CAD",
  "America/Iqaluit": "CAD",
  "America/Jamaica": "JMD",
  "America/Jujuy": "ARS",
  "America/Juneau": "USD",
  "America/Kentucky/Louisville": "USD",
  "America/Kentucky/Monticello": "USD",
  "America/Knox_IN": "USD",
  "America/Kralendijk": "USD",
  "America/La_Paz": "BOB",
  "America/Lima": "PEN",
  "America/Los_Angeles": "USD",
  "America/Louisville": "USD",
  "America/Lower_Princes": "ANG",
  "America/Maceio": "BRL",
  "America/Managua": "NIO",
  "America/Manaus": "BRL",
  "America/Marigot": "EUR",
  "America/Martinique": "EUR",
  "America/Matamoros": "MXN",
  "America/Mazatlan": "MXN",
  "America/Mendoza": "ARS",
  "America/Menominee": "USD",
  "America/Merida": "MXN",
  "America/Metlakatla": "USD",
  "America/Mexico_City": "MXN",
  "America/Miquelon": "EUR",
  "America/Moncton": "CAD",
  "America/Monterrey": "MXN",
  "America/Montevideo": "UYU",
  "America/Montreal": "CAD",
  "America/Montserrat": "XCD",
  "America/Nassau": "BSD",
  "America/New_York": "USD",
  "America/Nipigon": "CAD",
  "America/Nome": "USD",
  "America/Noronha": "BRL",
  "America/North_Dakota/Beulah": "USD",
  "America/North_Dakota/Center": "USD",
  "America/North_Dakota/New_Salem": "USD",
  "America/Nuuk": "DKK",
  "America/Ojinaga": "MXN",
  "America/Panama": "PAB",
  "America/Pangnirtung": "CAD",
  "America/Paramaribo": "SRD",
  "America/Phoenix": "USD",
  "America/Port-au-Prince": "HTG",
  "America/Port_of_Spain": "TTD",
  "America/Porto_Acre": "BRL",
  "America/Porto_Velho": "BRL",
  "America/Puerto_Rico": "USD",
  "America/Punta_Arenas": "CLP",
  "America/Rainy_River": "CAD",
  "America/Rankin_Inlet": "CAD",
  "America/Recife": "BRL",
  "America/Regina": "CAD",
  "America/Resolute": "CAD",
  "America/Rio_Branco": "BRL",
  "America/Rosario": "ARS",
  "America/Santa_Isabel": "MXN",
  "America/Santarem": "BRL",
  "America/Santiago": "CLP",
  "America/Santo_Domingo": "DOP",
  "America/Sao_Paulo": "BRL",
  "America/Scoresbysund": "DKK",
  "America/Shiprock": "USD",
  "America/Sitka": "USD",
  "America/St_Barthelemy": "EUR",
  "America/St_Johns": "CAD",
  "America/St_Kitts": "XCD",
  "America/St_Lucia": "XCD",
  "America/St_Thomas": "USD",
  "America/St_Vincent": "XCD",
  "America/Swift_Current": "CAD",
  "America/Tegucigalpa": "HNL",
  "America/Thule": "DKK",
  "America/Thunder_Bay": "CAD",
  "America/Tijuana": "MXN",
  "America/Toronto": "CAD",
  "America/Tortola": "USD",
  "America/Vancouver": "CAD",
  "America/Virgin": "USD",
  "America/Whitehorse": "CAD",
  "America/Winnipeg": "CAD",
  "America/Yakutat": "USD",
  "America/Yellowknife": "CAD",
  "Antarctica/Casey": "",
  "Antarctica/Davis": "",
  "Antarctica/DumontDUrville": "",
  "Antarctica/Macquarie": "AUD",
  "Antarctica/Mawson": "",
  "Antarctica/McMurdo": "",
  "Antarctica/Palmer": "",
  "Antarctica/Rothera": "",
  "Antarctica/South_Pole": "",
  "Antarctica/Syowa": "",
  "Antarctica/Troll": "",
  "Antarctica/Vostok": "",
  "Arctic/Longyearbyen": "NOK",
  "Asia/Aden": "YER",
  "Asia/Almaty": "KZT",
  "Asia/Amman": "JOD",
  "Asia/Anadyr": "RUB",
  "Asia/Aqtau": "KZT",
  "Asia/Aqtobe": "KZT",
  "Asia/Ashgabat": "TMT",
  "Asia/Ashkhabad": "TMT",
  "Asia/Atyrau": "KZT",
  "Asia/Baghdad": "IQD",
  "Asia/Bahrain": "BHD",
  "Asia/Baku": "AZN",
  "Asia/Bangkok": "THB",
  "Asia/Barnaul": "RUB",
  "Asia/Beirut": "LBP",
  "Asia/Bishkek": "KGS",
  "Asia/Brunei": "BND",
  "Asia/Calcutta": "INR",
  "Asia/Chita": "RUB",
  "Asia/Choibalsan": "MNT",
  "Asia/Chongqing": "CNY",
  "Asia/Chungking": "CNY",
  "Asia/Colombo": "LKR",
  "Asia/Dacca": "BDT",
  "Asia/Damascus": "SYP",
  "Asia/Dhaka": "BDT",
  "Asia/Dili": "USD",
  "Asia/Dubai": "AED",
  "Asia/Dushanbe": "TJS",
  "Asia/Famagusta": "EUR",
  "Asia/Gaza": "ILS",
  "Asia/Harbin": "CNY",
  "Asia/Hebron": "ILS",
  "Asia/Ho_Chi_Minh": "VND",
  "Asia/Hong_Kong": "HKD",
  "Asia/Hovd": "MNT",
  "Asia/Irkutsk": "RUB",
  "Asia/Istanbul": "TRY",
  "Asia/Jakarta": "IDR",
  "Asia/Jayapura": "IDR",
  "Asia/Jerusalem": "ILS",
  "Asia/Kabul": "AFN",
  "Asia/Kamchatka": "RUB",
  "Asia/Karachi": "PKR",
  "Asia/Kashgar": "CNY",
  "Asia/Kathmandu": "NPR",
  "Asia/Katmandu": "NPR",
  "Asia/Khandyga": "RUB",
  "Asia/Kolkata": "INR",
  "Asia/Krasnoyarsk": "RUB",
  "Asia/Kuala_Lumpur": "MYR",
  "Asia/Kuching": "MYR",
  "Asia/Kuwait": "KWD",
  "Asia/Macao": "MOP",
  "Asia/Macau": "MOP",
  "Asia/Magadan": "RUB",
  "Asia/Makassar": "IDR",
  "Asia/Manila": "PHP",
  "Asia/Muscat": "OMR",
  "Asia/Nicosia": "EUR",
  "Asia/Novokuznetsk": "RUB",
  "Asia/Novosibirsk": "RUB",
  "Asia/Omsk": "RUB",
  "Asia/Oral": "KZT",
  "Asia/Phnom_Penh": "KHR",
  "Asia/Pontianak": "IDR",
  "Asia/Pyongyang": "KPW",
  "Asia/Qatar": "QAR",
  "Asia/Qostanay": "KZT",
  "Asia/Qyzylorda": "KZT",
  "Asia/Rangoon": "MMK",
  "Asia/Riyadh": "SAR",
  "Asia/Saigon": "VND",
  "Asia/Sakhalin": "RUB",
  "Asia/Samarkand": "UZS",
  "Asia/Seoul": "KRW",
  "Asia/Shanghai": "CNY",
  "Asia/Singapore": "SGD",
  "Asia/Srednekolymsk": "RUB",
  "Asia/Taipei": "TWD",
  "Asia/Tashkent": "UZS",
  "Asia/Tbilisi": "GEL",
  "Asia/Tehran": "IRR",
  "Asia/Tel_Aviv": "ILS",
  "Asia/Thimbu": "BTN",
  "Asia/Thimphu": "BTN",
  "Asia/Tokyo": "JPY",
  "Asia/Tomsk": "RUB",
  "Asia/Ujung_Pandang": "IDR",
  "Asia/Ulaanbaatar": "MNT",
  "Asia/Ulan_Bator": "MNT",
  "Asia/Urumqi": "CNY",
  "Asia/Ust-Nera": "RUB",
  "Asia/Vientiane": "LAK",
  "Asia/Vladivostok": "RUB",
  "Asia/Yakutsk": "RUB",
  "Asia/Yangon": "MMK",
  "Asia/Yekaterinburg": "RUB",
  "Asia/Yerevan": "AMD",
  "Atlantic/Azores": "EUR",
  "Atlantic/Bermuda": "BMD",
  "Atlantic/Canary": "EUR",
  "Atlantic/Cape_Verde": "CVE",
  "Atlantic/Faeroe": "DKK",
  "Atlantic/Faroe": "DKK",
  "Atlantic/Jan_Mayen": "NOK",
  "Atlantic/Madeira": "EUR",
  "Atlantic/Reykjavik": "ISK",
  "Atlantic/South_Georgia": "GBP",
  "Atlantic/St_Helena": "SHP",
  "Atlantic/Stanley": "FKP",
  "Australia/ACT": "AUD",
  "Australia/Adelaide": "AUD",
  "Australia/Brisbane": "AUD",
  "Australia/Broken_Hill": "AUD",
  "Australia/Canberra": "AUD",
  "Australia/Currie": "AUD",
  "Australia/Darwin": "AUD",
  "Australia/Eucla": "AUD",
  "Australia/Hobart": "AUD",
  "Australia/LHI": "AUD",
  "Australia/Lindeman": "AUD",
  "Australia/Lord_Howe": "AUD",
  "Australia/Melbourne": "AUD",
  "Australia/NSW": "AUD",
  "Australia/North": "AUD",
  "Australia/Perth": "AUD",
  "Australia/Queensland": "AUD",
  "Australia/South": "AUD",
  "Australia/Sydney": "AUD",
  "Australia/Tasmania": "AUD",
  "Australia/Victoria": "AUD",
  "Australia/West": "AUD",
  "Australia/Yancowinna": "AUD",
  "Brazil/Acre": "BRL",
  "Brazil/DeNoronha": "BRL",
  "Brazil/East": "BRL",
  "Brazil/West": "BRL",
  "Canada/Atlantic": "CAD",
  "Canada/Central": "CAD",
  "Canada/Eastern": "CAD",
  "Canada/Mountain": "CAD",
  "Canada/Newfoundland": "CAD",
  "Canada/Pacific": "CAD",
  "Canada/Saskatchewan": "CAD",
  "Canada/Yukon": "CAD",
  "Chile/Continental": "CLP",
  "Chile/EasterIsland": "CLP",
  Cuba: "CUP",
  Egypt: "EGP",
  Eire: "EUR",
  "Europe/Amsterdam": "EUR",
  "Europe/Andorra": "EUR",
  "Europe/Astrakhan": "RUB",
  "Europe/Athens": "EUR",
  "Europe/Belfast": "GBP",
  "Europe/Belgrade": "RSD",
  "Europe/Berlin": "EUR",
  "Europe/Bratislava": "EUR",
  "Europe/Brussels": "EUR",
  "Europe/Bucharest": "RON",
  "Europe/Budapest": "HUF",
  "Europe/Busingen": "EUR",
  "Europe/Chisinau": "MDL",
  "Europe/Copenhagen": "DKK",
  "Europe/Dublin": "EUR",
  "Europe/Gibraltar": "GIP",
  "Europe/Guernsey": "GBP",
  "Europe/Helsinki": "EUR",
  "Europe/Isle_of_Man": "GBP",
  "Europe/Istanbul": "TRY",
  "Europe/Jersey": "GBP",
  "Europe/Kaliningrad": "RUB",
  "Europe/Kiev": "UAH",
  "Europe/Kirov": "RUB",
  "Europe/Lisbon": "EUR",
  "Europe/Ljubljana": "EUR",
  "Europe/London": "GBP",
  "Europe/Luxembourg": "EUR",
  "Europe/Madrid": "EUR",
  "Europe/Malta": "EUR",
  "Europe/Mariehamn": "EUR",
  "Europe/Minsk": "BYR",
  "Europe/Monaco": "EUR",
  "Europe/Moscow": "RUB",
  "Europe/Nicosia": "EUR",
  "Europe/Oslo": "NOK",
  "Europe/Paris": "EUR",
  "Europe/Podgorica": "EUR",
  "Europe/Prague": "CZK",
  "Europe/Riga": "EUR",
  "Europe/Rome": "EUR",
  "Europe/Samara": "RUB",
  "Europe/San_Marino": "EUR",
  "Europe/Sarajevo": "BAM",
  "Europe/Saratov": "RUB",
  "Europe/Simferopol": "RUB",
  "Europe/Skopje": "MKD",
  "Europe/Sofia": "BGN",
  "Europe/Stockholm": "SEK",
  "Europe/Tallinn": "EUR",
  "Europe/Tirane": "ALL",
  "Europe/Tiraspol": "MDL",
  "Europe/Ulyanovsk": "RUB",
  "Europe/Uzhgorod": "UAH",
  "Europe/Vaduz": "CHF",
  "Europe/Vatican": "EUR",
  "Europe/Vienna": "EUR",
  "Europe/Vilnius": "EUR",
  "Europe/Volgograd": "RUB",
  "Europe/Warsaw": "PLN",
  "Europe/Zagreb": "HRK",
  "Europe/Zaporozhye": "UAH",
  "Europe/Zurich": "CHF",
  GB: "GBP",
  "GB-Eire": "GBP",
  Hongkong: "HKD",
  Iceland: "ISK",
  "Indian/Antananarivo": "MGA",
  "Indian/Chagos": "USD",
  "Indian/Christmas": "AUD",
  "Indian/Cocos": "AUD",
  "Indian/Comoro": "KMF",
  "Indian/Kerguelen": "EUR",
  "Indian/Mahe": "SCR",
  "Indian/Maldives": "MVR",
  "Indian/Mauritius": "MUR",
  "Indian/Mayotte": "EUR",
  "Indian/Reunion": "EUR",
  Iran: "IRR",
  Israel: "ILS",
  Jamaica: "JMD",
  Japan: "JPY",
  Kwajalein: "USD",
  Libya: "LYD",
  "Mexico/BajaNorte": "MXN",
  "Mexico/BajaSur": "MXN",
  "Mexico/General": "MXN",
  NZ: "NZD",
  "NZ-CHAT": "NZD",
  Navajo: "USD",
  PRC: "CNY",
  "Pacific/Apia": "WST",
  "Pacific/Auckland": "NZD",
  "Pacific/Bougainville": "PGK",
  "Pacific/Chatham": "NZD",
  "Pacific/Chuuk": "USD",
  "Pacific/Easter": "CLP",
  "Pacific/Efate": "VUV",
  "Pacific/Enderbury": "AUD",
  "Pacific/Fakaofo": "NZD",
  "Pacific/Fiji": "FJD",
  "Pacific/Funafuti": "AUD",
  "Pacific/Galapagos": "USD",
  "Pacific/Gambier": "XPF",
  "Pacific/Guadalcanal": "SBD",
  "Pacific/Guam": "USD",
  "Pacific/Honolulu": "USD",
  "Pacific/Johnston": "USD",
  "Pacific/Kanton": "AUD",
  "Pacific/Kiritimati": "AUD",
  "Pacific/Kosrae": "USD",
  "Pacific/Kwajalein": "USD",
  "Pacific/Majuro": "USD",
  "Pacific/Marquesas": "XPF",
  "Pacific/Midway": "USD",
  "Pacific/Nauru": "AUD",
  "Pacific/Niue": "NZD",
  "Pacific/Norfolk": "AUD",
  "Pacific/Noumea": "XPF",
  "Pacific/Pago_Pago": "USD",
  "Pacific/Palau": "USD",
  "Pacific/Pitcairn": "NZD",
  "Pacific/Pohnpei": "USD",
  "Pacific/Ponape": "USD",
  "Pacific/Port_Moresby": "PGK",
  "Pacific/Rarotonga": "NZD",
  "Pacific/Saipan": "USD",
  "Pacific/Samoa": "WST",
  "Pacific/Tahiti": "XPF",
  "Pacific/Tarawa": "AUD",
  "Pacific/Tongatapu": "TOP",
  "Pacific/Truk": "USD",
  "Pacific/Wake": "USD",
  "Pacific/Wallis": "XPF",
  "Pacific/Yap": "USD",
  Poland: "PLN",
  Portugal: "EUR",
  ROC: "TWD",
  ROK: "KRW",
  Singapore: "SGD",
  Turkey: "TRY",
  "US/Alaska": "USD",
  "US/Aleutian": "USD",
  "US/Arizona": "USD",
  "US/Central": "USD",
  "US/East-Indiana": "USD",
  "US/Eastern": "USD",
  "US/Hawaii": "USD",
  "US/Indiana-Starke": "USD",
  "US/Michigan": "USD",
  "US/Mountain": "USD",
  "US/Pacific": "USD",
  "US/Samoa": "WST",
  "W-SU": "RUB",
};
