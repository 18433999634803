import React, { Component } from "react";
import glamorous from "glamorous";
import _ from 'lodash';

import Tab from "./Tab";

const ListTabs = glamorous.ul({
  paddingLeft: 0,
  listStyle: "none",
  margin: 0,
  border: '1px solid #f1eaea',
  boxShadow: '0 2px 2px 0 rgba(0,0,0,0.14), 0 1px 5px 0 rgba(0,0,0,0.12), 0 3px 1px -2px rgba(0,0,0,0.2)'
});

const TabTitleItem = glamorous.li(
  {
    display: "inline-block",
    paddingRight: 5,
    paddingLeft: 5,
    transition: "all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    padding: "16px 30px 16px 18px",
    cursor: "pointer",
    opacity: "0.4",
    ":hover": {
      opacity: 1
    }
  },
  props => {
    return (
      props.isActiveTab && {
        transition: "all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
        cursor: "default",
        opacity: 1
      }
    );
  }
);

const ActiveTabBorder = glamorous.div(
  {
    height: 4,
    backgroundColor: "#dd000b",
    position: "absolute",
    bottom: 0,
    transition: "all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    willChange: "left, width"
  },
  props => {
    return (
      props.activeTabElement && {
        width: props.activeTabElement.offsetWidth,
        left: props.activeTabElement.offsetLeft
      }
    );
  }
);

const TabAnchorItem = glamorous.a({
  textTransform: "capitalize",
  color: "#000000",
  fontWeight: 600
});

const TabsContainer = glamorous.div({
  position: "relative",
  borderBottom: "1px solid #dfdfdf"
});

const ReactTabs = glamorous.div({
  position: "relative"
});

class Tabs extends Component {
  static Tab = Tab;

  state = {
    tabs: [],
    prevActiveTab: {},
    activeTab: this.props.activeTab,
    tabsElements: []
  };

    componentWillReceiveProps(nextProps) {
        if(nextProps.reciveProps) {
            this.setState({
                activeTab: nextProps.activeTab
            })
        }
  }

  addTab = newTab => {
    let isNewTabFound;

    for (let i in this.state.tabs) {
      let tab = this.state.tabs[i];
      if (tab.id === newTab.id) {
        isNewTabFound = true;
        break;
      }
    }
      if(this.state.tabs) {
          this.state.tabs.map((tabData, key) => {
              if(tabData.id === newTab.id && !_.isEqual(this.state.tabs[key], newTab)) {
                  this.setState((prevState, props) => {
                      const tabs = prevState.tabs;
                      tabs[key] = newTab;
                      return {
                          tabs
                      };
                  });
              }
              return null;
          })
      }

    if (!isNewTabFound) {
      this.setState((prevState, props) => {
        return {
          tabs: prevState.tabs.concat(newTab)
        };
      });
    }
  };

  removeTab = tabId => {
    this.setState((prevState, props) => {
      return {
        tabs: prevState.tabs.filter(tab => tab.id !== tabId)
      };
    });
  };

  onClick = tab => event => {
    this.setState((prevState, props) => {
      return {
        prevActiveTab: prevState.activeTab,
        activeTab: tab
      };
    });
    if(typeof this.props.onClick === 'function') {
        this.props.onClick(tab);
    }
  };

  render() {
    return (
      <ReactTabs>
        <TabsContainer>
          <ListTabs>
            {this.state.tabs.map((tab, index) => (
              <TabTitleItem
                key={index}
                onClick={this.onClick(tab)}
                className={this.state.activeTab.id === tab.id ? 'active-tab' : ''}
                id={tab.id}
                innerRef={tabElement => {
                  if (!this.state.tabsElements[tab.id]) {
                    this.setState((prevState, props) => {
                      const tabsElements = prevState.tabsElements;
                      tabsElements[tab.id] = tabElement;

                      return {
                        tabsElements
                      };
                    });
                  }
                }}
                isActiveTab={this.state.activeTab.id === tab.id}
              >
                <TabAnchorItem>{tab.title}</TabAnchorItem>
              </TabTitleItem>
            ))}
          </ListTabs>

          {/*<ActiveTabBorder
            activeTabElement={this.state.tabsElements[this.state.activeTab.id]}
          />*/}
        </TabsContainer>

        {React.Children.map(this.props.children, child =>
          React.cloneElement(child, {
            activeTab: this.state.activeTab,
            addTab: this.addTab
          })
        )}
      </ReactTabs>
    );
  }
}

export default Tabs;

