import React, { Component } from 'react';
import {withTranslation, Trans} from "react-i18next";
import {iconUrl} from '../../actions/config';

let pageStyle = {
    backgroundSize:"cover",
    overflow:"hidden",
    color:"grey",
    height:"100vh"
};

class UnderMaintenance extends Component {
    render() {
        return(
            <div style={pageStyle}>
                <div className="vertical-center" >
                    <div className="row">
                        <div className="col m2 l3"> </div>
                        <div className="col l6 m8 s12">
                            <div className="center-align">
                                <h3 style={{marginTop:"70px", color:"#1976D2"}}><b> <Trans>UNDER_MAINTENAMCE</Trans></b></h3>
                                <span>
                                <h5>
                                    <Trans>ACCOUNT_UNDER_MAINTENAMCE</Trans>
                                </h5>
                            </span>
                                <br/>
                                <span>
                                <h5>
                                    <Trans>CONTACT_US_UNDER_MAINTENAMCE</Trans>
                                    <a href="mailto:support@timelybills.app" style={{Decoration: "none", color: "#1976D2;"}}> support@timelybills.app</a>
                                </h5>
                            </span>
                            </div>
                           
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default withTranslation()(UnderMaintenance);


