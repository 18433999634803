import React, { useState, useRef } from "react";
import ReactDOM from "react-dom";
import { unescape } from "lodash";
import _ from "lodash";

class DropdownModal extends React.Component {
    constructor(props) {
      super(props);
      this.tbdropdownelementcontinerbybs = document.createElement("div");
      this.tbdropdownelementcontinerbybs.setAttribute(
        "id",
        "tbdropdownelementcontinerbybs"
      );
      this.tbdropdownelementcontinerbybs.className = "tb-overlay";
      this.tbdropdownelementcontinerbybs.innerHTML = "";
      this.tbdropdownelementcontinerbybs.style.display = "none";
    }
    tbdropdownelementcontinerbybs;
    componentDidMount() {
      document
        .getElementsByTagName("body")[0]
        .appendChild(this.tbdropdownelementcontinerbybs);
    }
  
    componentDidUpdate(prevProps, prevState, snapshot) {
      if (this.props.children === undefined) {
        return;
      }
      this.tbdropdownelementcontinerbybs.style.display = this.props.open
        ? "block"
        : "none";
      this.tbdropdownelementcontinerbybs.onclick = (e) => {
        this.props.onClose();
      };
    }
  
    componentWillUnmount() {
      document
        .getElementsByTagName("body")[0]
        .removeChild(this.tbdropdownelementcontinerbybs);
    }
  
    render() {
      const bi = this.props.renderElement.current
        ? this.props.renderElement.current.getBoundingClientRect()
        : {};
       return (
        ReactDOM.createPortal(<div
              style={{
                position: "absolute",
                left: bi.left + "px",
                top: bi.top + 20 + "px",
              }}
            >{this.props.children}</div>, this.tbdropdownelementcontinerbybs)
      );
    }
  }
  
  export default  ({
    categories,
    languageCode,
    disabled = false,
    value,
    type,
    placeholder,
    onChange,
  }) => {
    let categoryGroup = _.groupBy(categories, "groupId");
    const [isOpen, setOpen] = useState(false);
    const valueItem = value
    ? categories.find((ca) => ca.categoryId === value && type === ca.type) || {}
    : {};
    const ref = useRef(null);
    categories = categories.filter((c) => c.groupId === undefined);
    categories = type === 2 ? [ ...categoryGroup['932']] : categories;
    categoryGroup = type === 2 ? {} : categoryGroup; 
  
    return (
      <>
        <DropdownModal open={isOpen} onClose={(e) => setOpen(false)} renderElement={ref}>
          <ul className="tb-bs-dropdown-menu">
            {categories.map((cat, key) => (
                <li
                  value={cat.categoryId}
                  key={key}
                  onClick={(e) => onChange({ target: { value: cat.categoryId, type: cat.type } })}
                >
                  <a value={cat.categoryId}>{unescape(cat[languageCode + "_name"] || cat.name)}</a>
                  {categoryGroup[cat.categoryId] === undefined ? null : (
                    <>
                      <i className="material-icons">chevron_right</i>
                      <ul>
                        {categoryGroup[cat.categoryId].map((subc, skey) => (
                          <li
                            key={skey}
                            value={subc.categoryId}
                            onClick={(e) =>{
                              onChange({ target: { value: subc.categoryId, type: cat.type } })
                              e.stopPropagation();
                            }
                            }
                          >
                            <a value={subc.categoryId}>
                              {unescape(
                                subc[languageCode + "_name"] || subc.name
                              )}
                            </a>
                          </li>
                        ))}
                      </ul>
                    </>
                  )}
                </li>
            ))}
          </ul>
        </DropdownModal>
        <div
          className={disabled ? "tb-dropwond-box disable" : "tb-dropwond-box"}
          ref={ref}
          onClick={(e) => {
            if (!disabled) setOpen(true);
          }}
        >
          {unescape(valueItem[languageCode + "_name"] || valueItem.name) ||
            placeholder}
          <i className="material-icons">chevron_right</i>
        </div>
      </>
    );
  };
  