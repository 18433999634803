import React, { Component } from 'react';
import Navbar from "./Navbar";
import ErrorPage from "./ErrorPage";
import SuccessPage from "./SuccessPage";
import Spinner from "./common/Spinner";
import {withTranslation} from 'react-i18next';

import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { activation } from "../actions/authActions";

import '../stylesheets/App.css'

class ActivationSuccess extends Component {
    state = {
        errors: {}
    };

    componentDidMount () {
        if(this.props.auth.isAuthenticated){
            this.props.history.push('/dashboard');
        }
        this.props.activation(this.props.location.search);
    }

    componentWillReceiveProps(nextProps){
        if(nextProps.errors){
            this.setState({errors: nextProps.errors});
        }
    }

    render() {
        const { message = 'success'} = this.state.errors;
        const t = this.props.t;
        let body;
            if(!message){
                body= ( <Spinner/> )
            }else if(message === 'success'){
                body= (<SuccessPage title={t("ACTIVATION_SUCCESSFUL")} body={t("ACTIVATED_SUCCESSFULLY")} hideSideNav={false}/>)
            }else {
                body=(<ErrorPage title={t("ERROR")} error={message}/>)
            }

        return (
            <div>
                <Navbar/>
                {body}
            </div>
        );
    }
}

ActivationSuccess.propTypes = {
    auth:PropTypes.object.isRequired,
    activation: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    auth: state.auth,
    errors:state.errors
});

export default withTranslation()(connect(mapStateToProps,{activation})(withRouter(ActivationSuccess)));
