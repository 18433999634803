import React, { Component } from 'react';
import Navbar from "./OnlyNavbar";
import Spinner from './common/Spinner'
import SuccessPage from "./SuccessPage";
import { history } from '../_helpers';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {acceptMultiUserInvitation,acceptGroupInvitation,logoutUserWithoutNavigate} from '../actions/authActions'
import {
    getProfileImage,
} from "../actions/changePassword";
import PropTypes from 'prop-types';
import {withTranslation, Trans} from 'react-i18next';
import '../stylesheets/App.css'
import {iconUrl} from "../actions/config";
//http://localhost:3000/inviteAccept/?token=1644998616876F2BeFk
//this component will be rendered when invitee rejects the invitation for group from the email
//https://web.timelybills.app/inviteAccept?token=token sample url for this page
//this page calls one action creator rejectMultiUserRequest
class InvitationRejected extends Component {
    constructor(props){
        super(props);
        localStorage.removeItem('jwtToken');
        this.state={
            status: '',
            error: {},
            imagePath: null
        };
        this.loading = false;
        //the token is received in the url
        //https://web.timelybills.app/inviteAccept?token=token
        if(this.props.location.search) this.token = this.props.location.search.slice(7);
        this.ownerDetail = '';
        this.inviteeDetail = '';
        this.data = {};
        if(this.token) {
            this.loading = true;
            // calls a api via an actioncreator to fetch inviter data and rejects the request from the invitee
            this.props.acceptMultiUserInvitation(this.token, (data) => {
                this.data=data;
                if (data.groupData.ownerFirstName) {
                    this.ownerDetail = data.groupData.ownerFirstName;
                    if (data.groupData.ownerLastName) {
                        this.ownerDetail += ' ' + data.groupData.ownerLastName;
                    }
                }
                else {
                    this.ownerDetail = data.groupData.ownerEmail;
                }
                if(data.groupData.inviteeProfilePic){
                    this.getAvatarImage(data.groupData.inviteeProfilePic,data.groupData.inviteeUserId)
                }
                if (data.groupData.inviteeFirstName) {
                    this.inviteeDetail = data.groupData.inviteeFirstName;
                }
                else {
                    this.inviteeDetail = data.groupData.inviteeEmail;
                }
                if(data.inviteData){
                     this.trxCount=data.inviteData.trxCount;
                     if(data.inviteData.trxCount===0) return this.handleAccept('delete');
                }
                this.loading = false;
                this.setState({status: 'success'});
            })
        }
        this.handleAccept = this.handleAccept.bind(this)
    }
    componentWillReceiveProps(nextProps) {

        if(nextProps.errors){
            if(nextProps.errors.type==='getInvitedUserDetail') {
                if (nextProps.errors.code === 304 || nextProps.errors.code === 339) {
                    history.push({
                         pathname:`/signup`,
                         state: {
                             token: this.token,
                             userData: nextProps.errors.data.groupData
                         }
                     });
                }
                else{
                    this.setState({status: 'failure'});
                    this.setState({error: nextProps.errors});
                }

            }
            if(nextProps.errors.type==='acceptGroupInvitation'){
                this.setState({status: 'failure'});
                this.setState({error: nextProps.errors});
            }
            if (nextProps.errors.code === 388) {
                this.setState({status: 'failure'});
                this.setState({error: nextProps.errors});
            }
            this.loading = false;
        }
    }

    getAvatarImage = (fileName, userId) => {
        if (fileName && fileName !== '') {
            getProfileImage(fileName, (imageData, status = true) => {
                if(status === true) {
                    this.setState({
                        imagePath: imageData
                    });
                }
            }, userId);
        }
        if(fileName === ''){
            this.setState({
                imagePath: iconUrl + "account.png"
            });
        }
    };

    handleAccept (type){
        if(this.props.auth.isAuthenticated) {this.props.logoutUserWithoutNavigate();}
        let form={
            token: this.token,
        };
        if(type==='merge'){
            form['migrateToParent']=true;
        }
        this.props.acceptGroupInvitation(form, ()=>{
            this.setState({status: 'successPage'});
        })
    };
    render() {
        //rendered if APIs runs successfully
        const renderSuccess = (
                <div className="row margin-0 center-align sign-in-section">
                    <div className="col l12 m12 s12">
                        <div className="card darken-2 auto-margin margin-bottom-50">
                            <div className="card-content">
                    {this.data && this.data.groupData && this.data.groupData.inviteeProfilePic ?<img className="accept-invite-logo margin-bottom-10" src={this.state.imagePath} />: <img className="accept-invite-logo margin-bottom-10" src={iconUrl + "account.png"}/>}
                <span className="card-title margin-bottom-20"> <h4 className='word-break-all'>{this.inviteeDetail}</h4></span>
                <span className="card-title margin-bottom-20"> <h4><Trans>ALREADY_USING_TB</Trans></h4></span>
                <div className="margin-top-35">
                    <span><h5><Trans>INVITATION_ACCEPT_TEXT_1</Trans></h5></span>
                    <span><h5><Trans>INVITATION_ACCEPT_TEXT_2</Trans></h5></span>
                    <div className="margin-bottom-50 margin-top-20">
                    <button className="btn waves-effect waves-light green-button accent-4 text-transform-none" style={{marginRight: '10px'}}
                    onClick={()=>this.handleAccept('merge')}><Trans>JOIN_WITH_TRANSACTIONS</Trans></button><button className="btn waves-effect waves-light light-blue accent-4 text-transform-none" onClick={()=>this.handleAccept('delete')}><Trans>JOIN_WITHOUT_TRANSACTIONS</Trans></button>
                    </div>
                </div>
                <div className="left-align padding-10">
                    <span className="input-block"><Trans>PLEASE_READ</Trans></span>
                    <span className="input-block"><img src={iconUrl + "check.svg"} className="point-icon"/><h6 className="grey-disclaimer"><Trans>ALREADY_USING_TB_WARNING_1</Trans></h6></span>
                    <span className="input-block"><img src={iconUrl + "check.svg"} className="point-icon"/><h6 className="grey-disclaimer"><Trans>ALREADY_USING_TB_WARNING_2</Trans></h6></span>
                    <span className="input-block"><img src={iconUrl + "check.svg"} className="point-icon"/><h6 className="grey-disclaimer"><Trans>ALREADY_USING_TB_WARNING_3</Trans></h6></span>
                    <span className="input-block"><img src={iconUrl + "check.svg"} className="point-icon"/><h6 className="grey-disclaimer"><Trans>ALREADY_USING_TB_WARNING_4</Trans></h6></span>
                    <span className="input-block"><img src={iconUrl + "check.svg"} className="point-icon"/><h6 className="grey-disclaimer"><Trans>ALREADY_USING_TB_WARNING_5</Trans></h6></span>
                </div>
            </div>
            </div>
                    </div>
                </div>
        );
        //rendered if APIs fails
        const renderFailure = (
            <div>
            <div className="col s2" />
            <div className="col m8 s12">
            <div className="card darken-1">
            <div className="card-content center-align">
                <img className="accept-invite-logo" src={iconUrl + 'icon_cross.png'} alt="MailImage"/>
                <span className="card-title"> <h3><Trans>INVITATION_ACCEPT_ATTEMPTED</Trans></h3></span>
                <span><h5>{this.state.error.message}</h5></span>
            </div></div></div></div>
        );
        return (
            <div>
                <Navbar />
                <div className="vertical-center">
                    {this.state.status==='successPage'?
                        <SuccessPage from={'acceptInvite'} title={'CONGRATULATIONS'} body={`YOU_HAVE_JOINED`} ownerDetail={this.ownerDetail} hideSideNav={true}/>
                        : <div className="row">
                                {this.loading ? <div className="loading-all-page backdrop-div">
                                <Spinner/>
                            </div> : this.state.status==='success' ? renderSuccess : renderFailure }
                    </div>}
                </div>
            </div>
        );
    }
}

InvitationRejected.propTypes = {
    auth:PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
    auth: state.auth,
    errors: state.errors,
});

export default withTranslation()(connect(mapStateToProps,{acceptMultiUserInvitation,acceptGroupInvitation,logoutUserWithoutNavigate})(withRouter(InvitationRejected)));


