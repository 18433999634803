import React, { Component } from 'react';
import Navbar from "./Navbar";
import {withRouter, Redirect, Link} from 'react-router-dom';
import {withTranslation, Trans} from 'react-i18next';
import ReCAPTCHA from "react-google-recaptcha";
import Swal from 'sweetalert2';
import {Helmet} from "react-helmet";

import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
    loginUser,
    resendActivation,
    clearErrors
} from "../actions/authActions";
import Spinner from "./common/Spinner";

import '../stylesheets/App.css'
import CookieDisclaimer from './common/CookieDisclaimer'
import {browserName} from "./common/Methods";
import {iconUrl, SITE_KEY} from "../actions/config";

class SignIn extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
			password: '',
			errors: {},
            recaptcha: null
        };
        this.handleChange = this.handleChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
        this.handleResendActivation = this.handleResendActivation.bind(this);
        if(props.errors && props.errors.code !== 401)
            this.props.clearErrors();
    }

    componentWillReceiveProps(nextProps){
        if(nextProps.errors){
            this.setState({ errors: nextProps.errors });
        }
    }

    handleChange(e){
        this.setState({[e.target.name]:e.target.value});
    }

    handleSubmit(e){
        e.preventDefault();
        if(this.state.recaptcha) {
            const userData = {
                email: this.state.email,
                password: this.state.password,
                deviceType: "WEB",
                deviceId: "WEB",
                deviceName: browserName()
            };
            this.props.loginUser(userData);
        }else {
            this.getAlertBox(this.props.t('CHECK_RECAPTCHA'), 'error');
        }
	}

    getAlertBox = (message, type) => {
        const t=this.props.t;
        Swal.fire({
            title:t(message),
            position: 'bottom-start',
            toast: true,
            showConfirmButton: false,
            timer: 2000,
            background: type==='success' ? '#8dc44e' : '#f1521c',
            customClass: {
                title:'sweet-alert',
                container: 'sweet-alert-container'
            }
        });
    };
	
	handleResendActivation() {
		const email = this.state.email;
		const t = this.props.t;
		const errors = {};
		if(email === '') {
			errors.message = t('EMAIL_TO_SEND_ACTIVATION_LINK');
		} else {
			this.props.resendActivation(email);
			errors.message = t('SENDING_ACTIVATION_LINK');
		}
		this.setState({errors});
	}

    onVerify = (data) => {
        this.setState({
            recaptcha: data
        });
    };

    onExpired = (data) => {
        this.setState({
            recaptcha: data
        });
    };

    render() {
        // console.log('in SingIn render()..', this.props);
        const {t} = this.props;

        const {loading} = this.props.loading;
        const {isAuthenticated} = this.props.auth;
        return (
            <div>
                <Helmet>
                <title>{t('SIGN_IN')}</title>
                </Helmet>
                { isAuthenticated ?
					<Redirect to='/loadingprofile'/> :
                    <div>
                        <Navbar />
                        <div className="vertical-center">
                            <div className="row margin-0 sign-in-section">
                                <div className="col l12 m12 s12">
                                    <div className="card darken-1">
                                        <div className="card-content">
                                            <div className="center-align">
                                                <img src={iconUrl + 'account.png'}  height="80px" alt="UserImage" />
                                                <span className="card-title"> <h4><Trans>SIGN_IN</Trans></h4></span>
                                                <span><Trans>TO_TIMELYBILLS_ACCOUNT</Trans></span>
                                            </div>
                                            <form onSubmit={this.handleSubmit}>
                                                <div className="row margin-0">
                                                    <div className="col m1" />
                                                    <div className="input-field col m10 s12">
                                                        <input id="icon_prefix" type="email" onChange={this.handleChange}
                                                               placeholder={''}
                                                               name="email" required/>
                                                        <label htmlFor="icon_prefix" className={'active'}><Trans>EMAIL</Trans></label>
                                                    </div>
                                                </div>

                                                <div className="row margin-0">
                                                    <div className="col m1" />
                                                    <div className="input-field col m10 s12">
                                                        <input type="password" onChange={this.handleChange} name="password"
                                                               placeholder={''}
                                                               required/>
                                                        <label htmlFor="icon_prefix" className={'active'}><Trans>PASSWORD</Trans></label>
                                                    </div>
                                                </div>

                                                <div className="row margin-0">
                                                    <div className="col m1" />
                                                    <div className="input-field col m10 s12 recaptcha">
                                                        <ReCAPTCHA
                                                            sitekey={SITE_KEY}
                                                            onChange={this.onVerify}
                                                            onExpired={this.onExpired}
                                                        />
                                                    </div>
                                                </div>

                                                <div className="row margin-0">
                                                    <div className="col m1" />
                                                    <div className="input-field col m10 s12">
                                                        <span style={this.state.errors.code ? {color: "red"} : {color: "green"}}>
                                                            {this.state.errors.message ? this.state.errors.message : null}
                                                        </span>
                                                    </div>
                                                </div>

                                                {
                                                    this.state.errors.code === 306 ?
                                                        <div className="row margin-0">
                                                            <div className="col m1" />
                                                            <div className="input-field col l5 m5 s12">
                                                                <span onClick={this.handleResendActivation}>
                                                                    <Trans>RESEND_ACTIVATION</Trans>
                                                                </span>
                                                            </div>
                                                        </div>
                                                        : null
                                                }

                                                <div className="row margin-0">
                                                    <div className="col m1" />
                                                    <div className="input-field col l6 m5 s6 width-100 signButton">
                                                        {
                                                            loading ?
                                                            <Spinner/> :
                                                            <button
                                                                className="btn waves-effect waves-light light-blue accent-4"
                                                                type="submit"
                                                                tabindex="3"
                                                                autofocus="autofocus"
                                                                name="action"><Trans>SIGN_IN</Trans>
                                                            </button>
                                                        }
                                                    </div>
                                                    <div className="input-field col l5 m5 s5 orLink width-100">
                                                        <Link to="/forgot-password" className="forgot-password-font"> <Trans>FORGOT_PASSWORD</Trans></Link>
                                                    </div>
                                                </div>
                                            </form>

                                        </div>
                                    </div>
                                    <div className="certificates-footer">
                                        <img src={iconUrl + "certificates.png"} alt="certificates not found"/>
                                        <img src={iconUrl + "siteseal.png"} alt="siteseal not found"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                <CookieDisclaimer/>
            </div>
        );
    }
}

SignIn.propTypes = {
    loginUser: PropTypes.func.isRequired,
    clearErrors: PropTypes.func.isRequired,
	resendActivation: PropTypes.func.isRequired,
    auth:PropTypes.object.isRequired,
    loading:PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
    auth: state.auth,
	errors: state.errors,
    loading: state.loading
});

export default
withTranslation() (connect(mapStateToProps, {
    loginUser,
    resendActivation,
    clearErrors
})(withRouter(SignIn)));
