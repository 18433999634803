import React from "react";
import Navbar from "../Navbar";
// import '../../stylesheets/Simple-sidebar.css';

export default ({ children, ...rest }) => (
  <div>
    {localStorage.getItem("jwtToken") ? (
      <>
        {" "}
        <Navbar />
        <div className="Sidenav">{children}</div>
      </>
    ) : (
      <>{children}</>
    )}
  </div>
);
