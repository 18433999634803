import {
    GET_BILL_STATS_SUCCESS,
    GET_EXPENSE_STATS_SUCCESS,
    GET_BY_CATEGORY_STATS_SUCCESS,
    GET_BY_TRENDS_STATS_SUCCESS,
    GET_BILL_STATS_LOADING,
    GET_EXPENSE_STATS_LOADING,
    GET_BY_CATEGORY_STATS_LOADING,
    GET_BY_TRENDS_STATS_LOADING,
    SEND_USER_STATEMENT, SEND_USER_STATEMENT_SUCCESS, GET_BY_TRENDS_CATEGORY_SUCCESS, GET_BY_TRENDS_CATEGORY_LOADING,
    STATEMENT_ACTION
} from '../actions/types';

const initialState = {
	billsChartData: {},
	expensesChartData: {},
	byCategoryChartData: {},
};

export default function (state = initialState, action) {
	// console.log('In Dashboard Reducer..', action);
    switch (action.type){
        case GET_BILL_STATS_SUCCESS:
            return {
                ...state,
                billsChartData: action.payload
			};
		case GET_EXPENSE_STATS_SUCCESS:
			return {
                ...state,
                expensesChartData: action.payload
			};
		case GET_BY_CATEGORY_STATS_SUCCESS:
			return {
				...state,
				byCategoryExpenseChartData: action.payload
			};
		case GET_BY_TRENDS_STATS_SUCCESS:
			return {
				...state,
				trendChartData: action.payload
			};
		case GET_BY_TRENDS_CATEGORY_SUCCESS:
			return {
				...state,
				trendCategoryData: action.payload
			};
		case SEND_USER_STATEMENT_SUCCESS:
			return {
				...state,
				sendStatementStatus: action.payload
			};
        case STATEMENT_ACTION:
            if(state.error) {
                delete state.error
            }
            return{
                ...state,
                [action.type] : action.state
            };
		case GET_BILL_STATS_LOADING:
		case GET_EXPENSE_STATS_LOADING:
		case GET_BY_CATEGORY_STATS_LOADING:
		case GET_BY_TRENDS_STATS_LOADING:
		case GET_BY_TRENDS_CATEGORY_LOADING:
		case SEND_USER_STATEMENT:
            return{
                ...state,
                [action.type] : action.state
            };
        default:
            return state;
    }
}