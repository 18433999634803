import React, { Component } from 'react';
import { unescape }from 'lodash';
import {DEFAULT_CAT_2, DEFAULT_CAT_1, s3Url, RIGHT_ARROW_ICON} from '../../actions/config';
import connect from "react-redux/es/connect/connect";
import {Trans, withTranslation} from "react-i18next";
import {withRouter} from "react-router-dom";
import {
    getAccountTypeById, getAccountTypeName, getServiceLogoUrl, getServiceProvider, 
    getServiceProviderName, filterCurrentUsersAccount
} from "./Methods";

class AccountSelector extends Component {
    constructor(props) {
        super(props);
        this.actType =  JSON.parse(localStorage.getItem('accountTypes'));
    }

    onChange = (e) => {
        this.props.onChange(e.target.value);
    };

    render() {
        const {
            t,
            auth: {
                userAccountList = []
            },
            type
        } = this.props;
        const style4Bg = {
            height: '30px'
        };
        const acctObj = this.props.value
            ? userAccountList.find(({accountId}) => accountId === this.props.value)
            : userAccountList.find(({accountId}) => !!accountId);

        let logoUrl = getServiceLogoUrl(acctObj);
        if(!logoUrl) {
            logoUrl = s3Url + 'icon_accounts_trolley_grey.png'
        }else {
            logoUrl = s3Url + logoUrl + '.png';
        }

        if(!this.props.value ) {
            logoUrl = s3Url + 'icon_accounts_trolley_grey.png';
        }

        let alreadyAddedDefault = false;
        userAccountList.forEach(acc => {
            if(!acc.accountId) {
                alreadyAddedDefault = true;
            }
        });
        if(userAccountList.length > 0 && !alreadyAddedDefault) {
            userAccountList.unshift({
                accountId: "",
                accountName: t(type === 1 ? 'FROM_ACCOUNT' : 'TO_ACCOUNT')
            });
        }else if(alreadyAddedDefault) {
            userAccountList.forEach((acc, index) => {
                if(!acc.accountId) {
                    userAccountList[index] = {
                        accountId: "",
                        accountName: t(type === 1 ? 'FROM_ACCOUNT' : 'TO_ACCOUNT')
                    }
                }
            });
        }

        return (
            <table
                className='table-category-selector'>
                <tbody>
                <tr>
                    <td style={{width: '3.9rem'}}>
                        <img
                            src={logoUrl}
                            onError={() => logoUrl = (s3Url + 'icon_accounts_trolley_grey.png')}
                            alt="icon"
                            className="leftMargin10 circle"
                            style={style4Bg}>
                        </img>
                    </td>
                    <td>
                        <select
                            onChange={this.onChange}
                            value={this.props.value}
                            disabled={this.props.disabled || false}
                            placeholder={t('SELECT_CATEGORY')}
                            style={{display: 'block', background: 'transparent'}}
                        >
                            {
                                // userAccountList.map((act, key) =>
                                (filterCurrentUsersAccount(userAccountList)||[]).map((act, key) =>
                                    <option
                                        value={act.accountId}
                                        key={key}
                                    >
                                        {unescape(getServiceProviderName(act))}
                                    </option>
                                )
                            }
                        </select>
                    </td>
                </tr>
                {acctObj && this.props.value ? <tr>
                    <td />
                    <td>
                        <div className="grey-text">
                            <Trans>{type === 1 ? 'FROM_ACCOUNT' : 'TO_ACCOUNT'}</Trans>: {
                                getAccountTypeName(acctObj.accountType) + (acctObj.accountName ? (RIGHT_ARROW_ICON + acctObj.accountName) : '')
                            }
                        </div>
                        {acctObj.onlineAccount ?
                        <div style={{color:'#ffb817'}}>{t('ONLINE_ACCOUNT_WARNING')}</div>
                        : null }
                    </td>
                </tr> : null}
                </tbody>
            </table>
        )
    }
}
AccountSelector.defaultProps = {
  type: 1
};
const mapStateToProps = (state) => {
    return {
        auth: state.auth
    }
};

export default withTranslation()(connect(mapStateToProps, {})(withRouter(AccountSelector)))