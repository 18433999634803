import React, { Component } from "react";
import {
  RECURRING_CATEGORY,
  RepeatCategoryKeys,
  getShortDate,
} from "./Methods";
import { LONG_DAYS } from "./ConstData";
import { withTranslation, Trans } from "react-i18next";
import moment from "moment";
import {covertTimeToRecurringValue} from '../../_helpers/genericFn'

class CustomRecurringSelector extends Component {
  constructor(props) {
    super(props);
    const t = this.props.t;
    this.state = {
      repeatDescription: t("FROM") + getShortDate(new Date(), t),
      recurringCategoryId: 1,
      repeatType: 0,
      recurringCount: 1,
      repeatTillDate: this.createDate(),
      repeatTillCount: 0,
      recurringRuleValue: 0,
    };
  }
  optionseel = []

  componentWillReceiveProps(nextProps) {
    if (!nextProps.dataLoading) {
      const recurringObj = {
        recurringCategoryId: nextProps.recurringId || 3,
        recurringCount: nextProps.recurringCount || 1,
      };
      if (nextProps.repeatTillDate) {
        recurringObj["repeatTillDate"] = this.createDate(
          nextProps.repeatTillDate
        );
        recurringObj["repeatType"] = 1;
      } else if (nextProps.repeatTillCount) {
        recurringObj["repeatTillCount"] = nextProps.repeatTillCount;
        recurringObj["repeatType"] = 2;
      }
      if (
        nextProps.recurringRule !== undefined && nextProps.recurringRule != null &&
        nextProps.recurringRule !== "" && nextProps.needToCheckRecurringRule
      ) {
        if(nextProps.recurringRule.startsWith('-1') && this.optionseel.length>2) {
            recurringObj.recurringRuleValue = 2;
        } else {
          recurringObj.recurringRuleValue = 1;
        }
      }
      this.getOptions();
      this.setState({
        ...recurringObj,
      });
    }
  }

  createDate(date = new Date()) {
    // const dateObj = new Date(date);
    return moment(date).format("YYYY-MM-DD");
  }

  changeRepeatType = (value) => {
    if(value === 0) {
      this.setState({
        repeatType: +value,
        repeatTillDate: "",
        repeatTillCount: 0
      });
    } else
    if(value === 1) {
    this.setState({
      repeatType: +value,
      repeatTillCount: 0
    });
  } else {
    this.setState({
      repeatType: +value,
      repeatTillDate: ""
    });
  }
  };

  setRepeatDate = (event) => {
    this.setState({
      repeatTillDate: this.createDate(event.target.value),
    });
  };

  setRepeatData = (key, event) => {
    this.setState({
      [key]: event.target.value,
    });
  };

  submitRecurring = () => {
    const recurringObj = {
      recurringCategoryId: this.state.recurringCategoryId,
      recurringCount: +this.state.recurringCount,
      repeatTillCount: 0,
      repeatTillDate: ""
    };
    
    if (this.state.repeatType === 1 && this.state.repeatTillDate) {
      recurringObj["repeatTillDate"] = new Date(
        this.state.repeatTillDate
      ).toISOString();
    } else if (this.state.repeatType === 2) {
      recurringObj["repeatTillCount"] = +this.state.repeatTillCount;
    }
    if (RepeatCategoryKeys.MONTHLY) {
      const optionSelectedValue = this.optionseel[this.state.recurringRuleValue]
      if(optionSelectedValue!==undefined){
        recurringObj.repeatDescription = optionSelectedValue.label;
        console.log('recurringsubmit', optionSelectedValue, recurringObj)
        if (
          this.state.recurringRuleValue+"" !== "0"
        ) {
          recurringObj["recurringRule"] = optionSelectedValue.value;
        } else {
          recurringObj["recurringRule"] = undefined
        }
      }else{
        recurringObj.repeatDescription = '';
      }

    }
    this.props.getCustomRecurringObj(recurringObj);
  };

  onChange = (e) => {
    const categoryId = +e.target.value;
    this.recurringDescription(categoryId);
    this.setState({
      recurringCategoryId: categoryId,
    });
  };

  getOptions = () => {
    const {t} = this.props;
    const ddd = new Date(this.props.time).getDate();
    this.optionseel = [
      { value: undefined, label: `${t('ON')} ${t('DAY')} ${ddd}` },
      ...covertTimeToRecurringValue(this.props.time, t)
    ];

    this.setState({ recurringRuleValue: 0 });
  };




  recurringDescription = (categoryId) => {
    const { t } = this.props;
    if (categoryId === RepeatCategoryKeys.NO_REPEAT) {
      this.setState({
        repeatDescription: t("NONE"),
      });
    } else if (categoryId === RepeatCategoryKeys.DAILY) {
      this.setState({
        repeatDescription:
          t("FROM") + getShortDate(new Date(this.props.time), t),
      });
    } else if (categoryId === RepeatCategoryKeys.MONTHLY) {
      this.setState({
        repeatDescription: "",
      });
      this.getOptions();
    } else if (categoryId === RepeatCategoryKeys.WEEKLY) {
      this.setState({
        repeatDescription:
          t("ON") + t(LONG_DAYS[new Date(this.props.time).getDay()]),
      });
    } else if (categoryId === RepeatCategoryKeys.YEARLY) {
      this.setState({
        repeatDescription: t("ON") + getShortDate(new Date(this.props.time), t),
      });
    }
  };

  checkRecurringCount = (e) => {
    if (e.target.value.indexOf(".") > -1) {
      this.setState({
        recurringCount: e.target.value.split(".")[0],
      });
    }
  };

  render() {
    const { t } = this.props;
    return (
      <div className="custom-recurring-model">
        <table className="table-category-selector">
          <tbody>
            <tr>
              <td style={{ display: "flex" }}>
                <h6>
                  <Trans>REPEAT_EVERY</Trans>
                </h6>
                <input
                  type="number"
                  value={this.state.recurringCount}
                  onChange={this.setRepeatData.bind(this, "recurringCount")}
                  onKeyUp={this.checkRecurringCount}
                  style={{
                    width: "6%",
                    textAlign: "center",
                    padding: "0px 5px",
                    height: "34px",
                  }}
                />
                <select
                  onChange={this.onChange}
                  value={this.state.recurringCategoryId}
                  placeholder={t("SELECT_CATEGORY")}
                  style={{ display: "block", width: "30%" }}
                >
                  {RECURRING_CATEGORY.map((cat, key) => {
                    return (
                      <React.Fragment key={key}>
                        {key !== 0 ? (
                          <option value={key}>{t(cat.name)}</option>
                        ) : null}
                      </React.Fragment>
                    );
                  })}
                </select>
              </td>
            </tr>
            <tr>
              <td colSpan={2}>
                {this.state.recurringCategoryId ===
                RepeatCategoryKeys.MONTHLY ? (
                  <select
                    onChange={(ec) => {
                      this.setState({ recurringRuleValue: ec.target.value });
                    }}
                    value={this.state.recurringRuleValue}
                    className="select-input"
                    
                  >
                    {this.optionseel.map((e, i) => (
                      <option value={i} data={e.value} key={i}>
                        {e.label}
                      </option>
                    ))}
                  </select>
                ) : (
                  <h6 style={{ color: "red" }}>
                    {this.state.repeatDescription}
                  </h6>
                )}
              </td>
            </tr>
            <tr>
              <th>
                <Trans>ENDS</Trans>
              </th>
            </tr>
            <tr>
              <td onClick={this.changeRepeatType.bind(this, 0)}>
                <input
                  type="radio"
                  name="repeatType"
                  checked={this.state.repeatType === 0}
                  onChange={this.changeRepeatType.bind(this, 0)}
                />
                <span className="custom-recurring-radio" />
                <Trans>NEVER</Trans>
              </td>
            </tr>
            <tr>
              <td onClick={this.changeRepeatType.bind(this, 1)}>
                <input
                  type="radio"
                  name="repeatType"
                  checked={this.state.repeatType === 1}
                  onChange={this.changeRepeatType.bind(this, 1)}
                />
                <span className="custom-recurring-radio" />
                <Trans>ON</Trans>
                <div className="input-income-date">
                  <input
                    type="date"
                    value={this.state.repeatTillDate}
                    style={{
                      width: "100%",
                      margin: "0px 10px 0px",
                      height: "18px",
                    }}
                    onChange={this.setRepeatDate}
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td onClick={this.changeRepeatType.bind(this, 2)}>
                <input
                  type="radio"
                  name="repeatType"
                  checked={this.state.repeatType === 2}
                  onChange={this.changeRepeatType.bind(this, 2)}
                />
                <span className="custom-recurring-radio" />
                <Trans>AFTER</Trans>
                <input
                  type="text"
                  style={{
                    width: "6%",
                    textAlign: "center",
                    padding: "0px 5px",
                    height: "34px",
                  }}
                  value={this.state.repeatTillCount}
                  onChange={this.setRepeatData.bind(this, "repeatTillCount")}
                />
                <Trans>OCCURRENCE</Trans>
              </td>
            </tr>
            <tr>
              <td colSpan={2}>
                <div className="modal-footer-sec">
                  <div className="input-field col m6 s12">
                    <button
                      className="btn waves-effect waves-light light-blue accent-4 modal-close"
                      onClick={this.submitRecurring}
                      type="button"
                      name="action"
                    >
                      DONE
                      <i className="material-icons right">done</i>
                    </button>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }
}
export default withTranslation()(CustomRecurringSelector);
