import {
    SAVE_USER_PROFILE_SUCCESS,
    GET_USER_SETTINGS_SUCCESS,
    USER_SETTINGS_CACHE, SETTING_DATA_ACTION,
    GET_USER_DETAILS_ACTION, GET_USER_DETAILS_SUCCESS
} from './types';
import setLoading from './appActions';
import { handleErrors } from './authActions';
import * as api from '../services/ApiCall';
import {getCurrentUTCTime} from "../components/common/Methods";

export const cacheSettings = (key, value) => dispatch => {
	settings[key] = value;
	dispatch({
		type: USER_SETTINGS_CACHE,
		payload: settings
	});
};

let settings = {};
//Fetch Profile
export const fetchProfile = (history) => dispatch => {
    settings = {};
    dispatch(setLoading(true));
    dispatch(getLoadingDispatch(SETTING_DATA_ACTION, ''));
    api.callGet('api/users/settings')
        .then(data => {
            settings = data.settings;
            dispatch({
                type: GET_USER_SETTINGS_SUCCESS,
                payload: settings
            });
            dispatch(setLoading(false));
        })
        .catch(err => dispatch(handleErrors(err)));
};

//Fetch User Profile Details
export const fetchUserProfileDetails = () => dispatch => {
    settings = {};
    dispatch(setLoading(true));
    dispatch(getLoadingDispatch(GET_USER_DETAILS_ACTION, ''));
    api.callGet('api/users/settings').then(data => {
        settings['fetchProfile'] = data.settings;
        return api.callGet(`api/admin/currency/${data.settings.currency}`);
    }).then(_data => {
        if (settings['fetchProfile']) {
            settings['fetchProfile'].currencyDetails = _data.currency;
            localStorage.setItem('profileDetails', JSON.stringify(settings['fetchProfile']));
        }
      return api.callGet('api/users/user');
    }).then(data =>{
        let navbarDetails = {
            'firstName' : data.user.firstName,
            'lastName': data.user.lastName,
            'profilePic': data.user.profilePic
        };
        localStorage.setItem('navbarDetails', JSON.stringify(navbarDetails));
        settings['fetchUserProfile']= data.user;
        dispatch({
            type: GET_USER_DETAILS_SUCCESS,
            payload: settings
        });
        dispatch(setLoading(false));
    })
};

//Save - Save user settings
export const saveSettings = ( settings, callback ) => dispatch => {
    settings.lastModifyTime = getCurrentUTCTime();
    dispatch(setLoading(true));
    api.callPost('api/users/settings', settings)
        .then(data => {
            dispatch({
                type: SAVE_USER_PROFILE_SUCCESS,
                payload: data.code
            });
            callback();
            dispatch(getLoadingDispatch(SETTING_DATA_ACTION, 'SETTING_UPDATED'));
            //dispatch(setLoading(false));
        })
        .catch(err => dispatch(handleErrors(err)));
};

export const sendDeletionOtp = (email) => dispatch => {
    const userInfo = {
        "email" : email
    }
    api.callPost('api/users/resendOTP', userInfo)
    .then((data) => {
    })
    .catch(err => dispatch(handleErrors(err)));
};

export const validateEmailAndSendOtp = async(email) =>  {
    const userInfo = {
        "email" : email
    }
    return api.callPost('api/users/email/presignin', userInfo)
    .then((data) => {
    })
    .catch(err => {console.log("err : ",err);throw(err)} );
}

export const deleteUserAccount = async(otp, email) => {
    const userInfo = {
        "otp" : otp,
        "email" : email
    }
    return api.callPost('api/users/deleteprofile', userInfo)
    .then((data) => {
    })
    .catch(err => {console.log("err : ",err);throw(err)} );
};

const getLoadingDispatch = (type, state) => ({type, state});
