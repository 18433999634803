import React, { Component } from 'react';
import {withTranslation, Trans} from "react-i18next";
import {iconUrl} from '../../actions/config';

let pageStyle = {
    backgroundSize:"cover",
    overflow:"hidden",
    color:"grey",
    height:"100vh"
};

class ComingSoon extends Component {
    render() {
        return(
            <div style={pageStyle}>
                <div className="vertical-center" >
                    <div className="row">
                        <div className="col m2 l3"> </div>
                        <div className="col l6 m8 s12">
                            <div className="center-align">
                                <h3 style={{marginTop:"70px", color:"#1976D2"}}><b>COMING SOON</b></h3>
                                <span>
                                <h5>
                                    <Trans>SECTION_COMING_SOON</Trans>
                                </h5>
                            </span>
                                <br/>
                                <span> <h5>
                                    <Trans>FOLLOW_FOR_REGULAR_UPDATES</Trans>
                                </h5></span>
                            </div>
                            <div className="row" style={{padding:"10px", height:"100px"}}>
                                <div className="col m4 l4 s3" />
                                <div className="col l6 m8 s12">
                                    <ul className="social-icons">
                                        <li>
                                            <a target="_blank" rel="noopener noreferrer"
                                               href="https://www.facebook.com/TimelyBillsApp/">
                                                <img alt="facebook" src={iconUrl + 'facebook-brands.svg'} style={{"width": "30px"}}
                                                     aria-hidden="true"/>
                                            </a>
                                        </li>
                                        <li>
                                            <a target="_blank" rel="noopener noreferrer"
                                               href="https://www.linkedin.com/company/timelybills">
                                                <img alt="linkedin" src={iconUrl + 'linkedin-brands.svg'} style={{"width": "30px"}}
                                                     aria-hidden="true"/>
                                            </a>
                                        </li>
                                        <li>
                                            <a target="_blank" rel="noopener noreferrer"
                                               href="https://twitter.com/@TimelyBills">
                                                <img alt="twitter" src={iconUrl + 'twitter-brands.svg'} style={{"width": "30px"}}
                                                     aria-hidden="true"/>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default withTranslation()(ComingSoon);


