import React, { Component } from "react";
import { withTranslation, Trans } from "react-i18next";
import { Row } from "react-materialize";
import "../stylesheets/App.css";
import EditMonthlyBudget from "./EditMonthlyBudget";
import { FormattedNumber } from "react-intl";
import { SHORT_MONTHS, LONG_MONTHS } from "./common/ConstData";
import { intNVL, isCreator } from "./common/Methods";
import moment from "moment";
import * as Swal from "sweetalert2";
import { iconUrl, RIGHT_ARROW_ICON } from "../actions/config";

class MonthlyBudgetDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editMode: false,
      amount: "",
      month: this.createDate(),
      monthId: "",
    };
  }

  componentWillMount() {
    if (this.props.viewData) {
      this.setState({
        amount: this.props.viewData.budgetAmt,
        month: this.props.viewData.month,
        time: this.props.viewData.time,
        monthId: this.props.viewData._id,
        carryForward: this.props.viewData.carryForward,
        familyShare: this.props.viewData.familyShare,
      });
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.viewData) {
      this.setState({
        amount: nextProps.viewData.budgetAmt,
        month: nextProps.viewData.month,
        time: nextProps.viewData.time,
        monthId: nextProps.viewData._id,
        carryForward: nextProps.viewData.carryForward,
        familyShare: nextProps.viewData.familyShare,
      });
    }
  }

  createDate(date = new Date()) {
    const dateObj = new Date(date);
    return (
      dateObj.getFullYear() +
      "-" +
      ("0" + (dateObj.getMonth() + 1)).slice(-2) +
      "-" +
      ("0" + dateObj.getDate()).slice(-2)
    );
  }

  createUpdateDate(date = new Date(), t) {
    const dateObj = new Date(date);
    let hours = dateObj.getHours();
    let minutes = dateObj.getMinutes();
    let ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? "0" + minutes : minutes;
    const strTime = hours + ":" + minutes + " " + ampm;

    return (
      t(SHORT_MONTHS[dateObj.getMonth()]) +
      ("0" + dateObj.getDate()).slice(-2) +
      ", " +
      dateObj.getFullYear() +
      "," +
      strTime
    );
  }

  onSubmitBudget = (data) => {
    data = { ...data };
    data.carryForward = this.state.carryForward;
    data.familyShare = this.state.familyShare;
    this.props.onSubmit(data);
    this.setState({
      editMode: false,
    });
  };

  backToDetail = () => {
    this.setState({
      editMode: false,
    });
  };
  setEditable() {
    const t = this.props.t;
    if (this.props.viewData.recurringCategoryId != 0) {
      Swal.fire({
        title: t("EDIT"),
        text: t("EDIT_INCOME_MESSAGE"),
        type: "question",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#3085d6",
        confirmButtonText: t("EDIT_ALL_FUTURE"),
        cancelButtonText: t("EDIT_THIS"),
      }).then(async (result) => {
        if (result.dismiss === "backdrop") {
          return;
        }
        if (result.value) {
          this.setState({
            editMode: true,
            editFuture: true,
          });
        } else {
          this.setState({
            editMode: true,
            editFuture: false,
          });
        }
      });
    } else {
      this.setState({
        editMode: true,
        editFuture: false,
      });
    }
  }
  onTextChange = (key, e) => {
    this.setState({
      [key]: e.target.value,
    });
  };

  onDateChange = (key, e) => {
    this.setState({
      [key]: moment(e.target.value).utc().valueOf(),
    });
  };
  canEdit() {
    var min = moment(moment().format("YYYY-MM"));
    var max = moment(moment().add("2", "M").format("YYYY-MM"));
    var date = new Date();
    date.setFullYear(this.props.year);
    date.setMonth(this.props.month);
    var now = moment(date.toISOString());
    return now.isBetween(min, max);
  }
  render() {
    const {
      userSettings: { currencyDetails },
      viewData,
      t,
    } = this.props;
    return (
      <div>
        {!this.state.editMode ? (
          <div>
            <Row>
              <div className="col m12 static-text">
                <div className="input-field col s12">
                  <h3>
                    {t(LONG_MONTHS[viewData.month]) +
                      " " +
                      new Date(viewData.time).getFullYear()}
                  </h3>
                </div>
              </div>
            </Row>
            <Row>
              <div className="col m12 static-text border-right">
                <div className="input-field col s6">
                  <h3>
                    <b>
                      <span className="nowrap">
                        <FormattedNumber
                          value={viewData.amount || 0}
                          style="currency"
                          currency={currencyDetails.currencyCode || "USD"}
                          minimumFractionDigits={2}
                          maximumFractionDigits={2}
                        />
                      </span>
                    </b>
                  </h3>
                  <h6>
                    <Trans>BUDGET</Trans>
                  </h6>
                  {viewData.carryForwardAmtAdded ? (
                    <h6>
                      <Trans>BUDGET_AMT</Trans>(
                      <span className="nowrap">
                        <FormattedNumber
                          value={viewData.budgetAmt || 0}
                          style="currency"
                          currency={currencyDetails.currencyCode || "USD"}
                          minimumFractionDigits={2}
                          maximumFractionDigits={2}
                        />
                      </span>
                      ) {" + "}
                      <Trans>ROLLOVER_AMT</Trans>(
                      <span className="nowrap">
                        <FormattedNumber
                          value={viewData.carryForwardAmount || 0}
                          style="currency"
                          currency={currencyDetails.currencyCode || "USD"}
                          minimumFractionDigits={2}
                          maximumFractionDigits={2}
                        />
                      </span>
                      )
                    </h6>
                  ) : null}
                </div>
                <div className="input-field col s6">
                  <h3>
                    <b>
                      <span className="nowrap">
                        <FormattedNumber
                          value={intNVL(viewData.expense || 0)}
                          style="currency"
                          currency={currencyDetails.currencyCode || "USD"}
                          minimumFractionDigits={2}
                          maximumFractionDigits={2}
                        />
                      </span>
                    </b>
                  </h3>
                  <h6>
                    <Trans>EXPENSE</Trans>
                  </h6>
                </div>
              </div>
            </Row>
            <Row>
              <div className="col m12 static-text">
                <div
                  className={
                    "input-field col s12 left-value " +
                    (viewData.expense > intNVL(viewData.amount) ? "danger" : "")
                  }
                >
                  <h3
                    className={
                      intNVL(viewData.amount - viewData.expense) < 0
                        ? "red-color"
                        : "green-color"
                    }
                  >
                    <b>
                      <span className="nowrap">
                        <FormattedNumber
                          value={Math.abs(
                            (viewData.amount || 0) -
                              intNVL(viewData.expense || 0)
                          )}
                          style="currency"
                          currency={currencyDetails.currencyCode || "USD"}
                          minimumFractionDigits={2}
                          maximumFractionDigits={2}
                        />
                      </span>
                    </b>
                  </h3>
                  <h6>
                    {viewData.expense > intNVL(viewData.amount)
                      ? t("OVER")
                      : t("LEFT")}
                  </h6>
                </div>
              </div>
            </Row>

            <Row>
              <div className="col m12 static-text">
                {viewData.carryForward === true ? (
                  <div>
                    <i
                      class="material-icons"
                      style={{
                        position: "relative",
                        top: "5px",
                        color: "#2196F3",
                      }}
                    >
                      check
                    </i>
                    <Trans>CARRY_FORWARED_AMTOUNT</Trans>
                  </div>
                ) : null}
              </div>
            </Row>
            {localStorage.getItem("familyShare") === "y" &&
            viewData.familyShare ? (
              <Row>
                <div>
                  <i
                    class="material-icons"
                    style={{
                      position: "relative",
                      top: "5px",
                      color: "#2196F3",
                    }}
                  >
                    check
                  </i>
                  <Trans>FAMILY_SHARE</Trans>
                </div>
              </Row>
            ) : null}
            {/* <Row>
              <div className="Delete-button">
                {this.canEdit() ? (
                  <div className="input-field col m6 s12">
                    <button
                      className="btn waves-effect waves-light light-blue accent-4"
                      onClick={this.setEditable.bind(this)}
                      type="button"
                      name="action"
                    >
                      <Trans>EDIT</Trans>
                      <i className="material-icons right">edit</i>
                    </button>
                  </div>
                ) : null}
                <div className="input-field col m6 s12">
                  <button
                    className="btn waves-effect waves-light red lighten-2 accent-4"
                    onClick={this.props.onDelete.bind(this, this.state)}
                    type="button"
                    name="action"
                  >
                    <Trans>DELETE</Trans>
                    <i className="material-icons right">delete</i>
                  </button>
                </div>
              </div>
            </Row> */}
            {!isCreator(
              viewData.createdUserId,
              this.props.userSettings.userId
            ) && localStorage.getItem(viewData.createdUserId) ? (
              <Row>
                <div className="static-text Delete-button border-top-0">
                  <div className="input-field col s2">
                    {this.props.auth &&
                    this.props.auth.groupImageList[viewData.createdUserId] ? (
                      <img
                        className="zero-margin height-54"
                        src={
                          this.props.auth.groupImageList[viewData.createdUserId]
                        }
                      />
                    ) : (
                      <h5 className="zero-margin-detail">
                        {JSON.parse(
                          localStorage.getItem(viewData.createdUserId)
                        )
                          .ownerDetail.slice(0, 1)
                          .toUpperCase()}
                      </h5>
                    )}
                  </div>
                  <div className="input-field col s10">
                    <h3>
                      {
                        JSON.parse(localStorage.getItem(viewData.createdUserId))
                          .ownerDetail
                      }
                    </h3>
                    <h6>
                      <Trans>CREATED_BY</Trans>
                    </h6>
                  </div>
                </div>
                <p className="grey-text" style={{ margin: 0 }}>
                  <Trans>UPDATED</Trans>{" "}
                  {this.createUpdateDate(new Date(viewData.updated_at), t)}
                </p>
              </Row>
            ) : (
              <p className="grey-text" style={{ margin: 0 }}>
                <Trans>UPDATED</Trans>{" "}
                {this.createUpdateDate(new Date(viewData.updated_at), t)}
              </p>
            )}
          </div>
        ) : (
          <EditMonthlyBudget
            {...this.props}
            {...this.state}
            onSubmitBudget={this.onSubmitBudget}
            onTextChange={this.onTextChange}
            onDateChange={this.onDateChange}
            backToDetail={this.backToDetail}
          />
        )}
      </div>
    );
  }
}

export default withTranslation()(MonthlyBudgetDetail);
