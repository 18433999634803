import React, { Component } from 'react';
import {withTranslation, Trans} from 'react-i18next';
import {
	Row,
	Col
} from 'react-materialize';
import { connect } from 'react-redux';
import AccountTypeSelector from './common/AccountTypeSelector';
import {
    getAccountBalanceLabelById,
    getAccountInitialBalanceLabelById,
    getAccountProviderType,
    onChangeInputWheel
} from "./common/Methods";
import '../stylesheets/App.css'
import {iconUrl} from '../actions/config';
import AccountProviderSelector from "./common/AccountProviderSelector";

const CURRECYIES = [
    {currencySymbol: 'R', currencyValue: 'Rupee', currencyName: 'Rupee' },
    {currencySymbol: '$', currencyValue: 'USD', currencyName: 'Dollar' },
    {currencySymbol: 'T', currencyValue: 'Taka', currencyName: 'Taka' }
]
const  CREDIT_ACCOUNTS_TYPE=[
    2,3,10,15
]
class AccountForm extends Component {
    constructor(props) {
        super(props);
        this.imageSelected = false;
        this.state = {
            actTypeId: 0,
            providerType: 'bank',
            providerTypeId: 0,
            amount: 0,
            actName: '',
            currencyCode: undefined,
            _id: ''
        };
        this.initialAmountMouseWheel = null;
    }

    componentWillReceiveProps(nextProps) {
        if(nextProps.transaction && nextProps.transaction._id) {
            
            this.updateState(nextProps.transaction);
        }else {
            this.resetState();
        }
    }

    resetState = () => {
        this.setState({
            actTypeId: 0,
            providerType: 'bank',
            providerTypeId: 0,
            amount: '',
            actName: '',
            currencyCode: undefined,
            _id: ''
        });
    };

    updateState = (props) => {
       const {accountType,currentBalance, accountBalance, serviceProviderId, _id, accountName,currencyCode} = props;
       this.setState({
           actTypeId: accountType,
           providerType: getAccountProviderType(accountType),
           providerTypeId: serviceProviderId,
           amount: this.getAccountBalance (accountType, currentBalance || accountBalance),
           actName: accountName,
           currencyCode:currencyCode,
           _id: _id
       })
    };

    onActTypeChange = (actTypeId) => {
        this.setState({
            actTypeId: actTypeId.code,
            providerType: actTypeId.providerType
        });
    };

    onAccountProviderChange = (actProvider) => {
        this.setState({
            providerTypeId: actProvider
        });
    };

    onTextChange = (key, e) => {
        this.setState({
            [key]: e.target.value
        });
    };

    submitForm = () => {
        const serviceProviders = JSON.parse(localStorage.getItem('serviceProviders'));
        const serviceP = serviceProviders.find(({providerId}) => providerId === `${this.state.providerTypeId}`);

        const data = {
            'accounts':[{
                'accountType': this.state.actTypeId,
                'accountName': this.state.actName,
                'accountBalance': this.state.amount,
                'currencyCode': this.state.currencyCode,
                'serviceProviderId': (serviceP || {}).providerType === this.state.providerType ? this.state.providerTypeId : 0
            }]
        };
        if(!data['accounts'][0].serviceProviderId) {
            delete data['accounts'][0].serviceProviderId;
        }
        if(this.props.transaction && this.props.transaction._id) {
            data['accounts'][0]._id = this.props.transaction._id;
            data['accounts'][0].accountId = this.props.transaction.accountId;
        }
        this.props.onSubmit(data);
    };

    changeWheel = (e) => {
        if(e==='amount') {
            onChangeInputWheel(this.initialAmountMouseWheel);
        }
    };
    getAccountBalance = (actType,balance) =>{

        return (CREDIT_ACCOUNTS_TYPE.includes(actType)?(0-balance):balance);
    }
    render() {
        const { currencyDetails } = JSON.parse(localStorage.getItem('profileDetails')) || {};
        const { t } = this.props;
        return <form className="add-edit-expense">
            { currencyDetails && currencyDetails.currencyCode && currencyDetails.currencyCode === 'USD' ? 
            <div style={{color: 'red'}}><Trans>ADD_ACCOUNT_USD_MESSAGE</Trans> </div> : ''}
            <Row>
                <Col s={12} m={12} l={12}>
                    <AccountTypeSelector
                        t={t}
                        onChange={this.onActTypeChange}
                        value={this.state.actTypeId}
                        type={1}
                    />
                </Col>
            </Row>

            <Row>
                <Col s={12} m={12} l={12}>
                    <AccountProviderSelector
                        t={t}
                        providerType={this.state.providerType}
                        onChange={this.onAccountProviderChange}
                        value={this.state.providerTypeId}
                    />
                </Col>
            </Row>

            

            <Row>
                <div className="input-field col m6 s12">
                    <i className="fas prefix">
                        <img style={{height: '30px', marginTop: '18px'}} src={iconUrl + 'icon_accounts_trolley_grey.png'} alt="account name" />
                    </i>
                    <input
                        id="icon_prefix"
                        type="text"
                        min={0}
                        name='account_name'
                        value={this.state.actName}
                        required
                        onChange={this.onTextChange.bind(this, 'actName')}
                    />
                    <label htmlFor="icon_prefix"
                           className={this.state.actName ? 'active' : ''}><Trans>ACCOUNT_NAME</Trans></label>
                </div>
            </Row>

            <Row>
                <CurrenyDropdown currencies={this.props.currencies} t={t} value={this.state.currencyCode} onChange={e=>this.setState({'currencyCode': e})} currencyDetails={currencyDetails} />
            </Row>

            <Row>
                <div className="input-field col m6 s12">
                    <i className={"fas prefix " + (currencyDetails.currencySymbol.length > 1 ? 'long-symbole' : '')}>
                        {getCurrencySymbol(this.props.currencies, currencyDetails, this.state.currencyCode)}
                    </i>
                    <input
                        id="icon_prefix"
                        type="number"
                        ref={c => this.initialAmountMouseWheel = c}
                        onWheel={() => this.changeWheel('amount')}
                        min={0}
                        name="amount"
                        value={this.state.amount}
                        required
                        onChange={this.onTextChange.bind(this, 'amount')}

                        />
                    <label htmlFor="icon_prefix" className={this.state.amount ? 'active' : ''}>
                        {getAccountBalanceLabelById(this.state.actTypeId)}
                    </label>
                </div>
            </Row>

            <Row>
                <div className="modal-footer-sec">
                    <div className="input-field col m6 s12">
                        <button className={'btn waves-effect waves-light light-blue accent-4 ' + (!this.imageSelected ?  'modal-close' : '')}
                                type="button" name="action" onClick={this.submitForm}><Trans>DONE</Trans>
                            <i className="material-icons right">done</i>
                        </button>
                    </div>
                </div>
            </Row>
        </form>
    }
}

const mapStateToProps = (state) => ({
    auth: state.auth,
    categories: state.categories
});

export default withTranslation()(connect(mapStateToProps)(AccountForm));


const getCurrencySymbol = (currencies, currecyObject, currencyCode) => {
    currencyCode =  currencyCode || currecyObject.currencyCode;
    
    if(currencyCode) {
        const item =    (currencies || []).find(se=>se.currencyCode === currencyCode)
        return item ? (item.currencySymbol || currencyCode) : '';
    }
    return '';
}

const CurrenyDropdown = ({value,currencies, currencyDetails, onChange, t}) => {
    value = value || currencyDetails.currencyCode;
return <>
    <i className="fas prefix">
    <img style={{height: '30px', marginTop: '18px'}} src={iconUrl + 'icon_accounts_trolley_grey.png'} alt="account name" />
    </i>
    <select
        placeholder={t('SELECT_CURRENCY')}
        onChange={e=>{
            onChange(e.target.value)
        }}
        style={{display: 'block', width: 'calc(100% - 60px)', float: 'right', marginTop: '15px'}}
    >
    {(currencies || []).map(se=>{
        return <option
                    s={se.currencyCode}
                    selected={value === se.currencyCode}
                    value={se.currencyCode}
                    key={se.currencyCode}
                    
                >
                { t(se.currencyCode) } - { t(se.currencyName)}
                </option>
    })}
    
</select>
</>

}
