import {
    FETCH_TEMPLATE_FAILED,
    POST_FEEDBACK_FAILED,
    POST_FEEDBACK_SUCCESS,
    FETCH_TEMPLATE_SUCCESS
} from './types';
import { handleErrors } from './authActions';
import * as api from '../services/ApiCall';

//fetches the feedback details like, successMessage, questions, errormessage, submitButtonMessage, title, subtitle etc
//while passing the templateId and feedbackID fetched from the url in the template page
export const fetchFeedbackDetails = (templateId, feedbackId) => dispatch => {
    // dispatch(getLoadingDispatch(FETCH_FEEDBACK_LOADING, true));
    api.feedbackGet('tem/' + templateId + '/' + feedbackId).then(feedback => {
        dispatch({
            type: FETCH_TEMPLATE_SUCCESS,
            payload: feedback
        });
    }).catch(err => {
        console.error({err});
        const errdata = handleErrors((err && err.data ? err.data : err), FETCH_TEMPLATE_FAILED);
        dispatch(errdata);
    });
};

//Submit Feedback Form by passing Form
export const submitFeedbackForm = (formData) => dispatch => {
    api.feedbackPut('feedback', formData).then(data => {
        dispatch({
            type: POST_FEEDBACK_SUCCESS,
            payload: data
        });
    }).catch(err => {
        const errdata = handleErrors((err && err.data ? err.data : err), POST_FEEDBACK_FAILED);
        if(err && err.data && err.data.status === 402) {
            errdata.code = err.data.status;
        }
        dispatch(errdata);
    });
};
