import React, { Component } from 'react';
import {SideNav,SideNavItem,Button} from 'react-materialize';
import {NavLink} from 'react-router-dom';
import '../stylesheets/SideNav.css'
import {connect} from 'react-redux';
import {withTranslation, Trans} from 'react-i18next';
import {iconUrl} from "../actions/config";
import {getCurrencies} from "../actions/authActions";

class Sidenav extends Component {

    componentDidMount() {
        if(!(this.props.auth && this.props.auth.currencies)) {
            this.props.getCurrencies();
        }
    }


    render() {
        return (
            <div>
                <SideNav
                    trigger={<Button style={{display: "none"}}><Trans>OPTION</Trans></Button>}
                    options={{closeOnClick: true, menuWidth: 250}}
                    fixed={true}
                    id="mobile-demo"
                >
                    <li className="bold">
                        <NavLink
							to="/dashboard"
							className="waves-effect waves-light black-text"
							activeClassName="blue lighten-4"
						>
                            <img src={iconUrl + 'dashboard.png'} alt="Dashboard" />
                            <Trans>DASHBOARD</Trans>
                        </NavLink>
                    </li>
                    <li className="bold">
                        <NavLink
							to="/bills"
							className="waves-effect waves-light black-text"
							activeClassName="blue lighten-4"
						>
                            <img src={iconUrl + 'icon_yellow_white_dollar.png'} alt="Bills" />
							<Trans>BILLS</Trans>
                        </NavLink>
                    </li>
                    <li className="bold">
                        <NavLink
							to="/expenses"
							className="waves-effect waves-light black-text"
							activeClassName="blue lighten-4"
						>
                            <img src={iconUrl + 'icon_expenses_red.png'} alt="Expenses" />
							<Trans>EXPENSES</Trans>
                        </NavLink>
                    </li>
                    <li className="bold">
                        <NavLink
                          to="/income"
                          className="waves-effect waves-light black-text"
                          activeClassName="blue lighten-4"
                        >
                            <img src={iconUrl + 'icon_income_green.png'} alt="Income" />
							<Trans>INCOME</Trans>
                        </NavLink>
                    </li>
                    <li className="bold">
                        <NavLink
							to="/budget"
							className="waves-effect waves-light black-text modal-trigger"
							activeClassName="blue lighten-4"
						>
                            <img src={iconUrl + 'icon_budget_blue.png'} alt="Budget" />
							<Trans>BUDGET</Trans>
                        </NavLink>
                    </li>
                    <li className="bold">
                        <NavLink to="/account-detail"
                                 activeClassName="blue lighten-4">
                            <img src={iconUrl + 'icon_accounts_trolley_grey.png'} alt="Account" />
                            <Trans>ACCOUNT_DETAIL</Trans>
                        </NavLink>
                    </li>
                    <SideNavItem divider/>
                    <li className="bold">
                        <NavLink to="/account#settings"
                                 activeClassName="blue lighten-4">
                            <i className="material-icons prefix">settings</i>
                            <Trans>SETTINGS</Trans>
                        </NavLink>
                    </li>
                    <li className="bold">
                        <NavLink
                            to="/reports"
                            className="waves-effect waves-light black-text modal-trigger"
                            activeClassName="blue lighten-4"
                        >
                            <i className="material-icons prefix">assessment</i>
							<Trans>REPORTS</Trans>
                        </NavLink>
					</li>
                    <li className="bold">
                        <NavLink
                            to="/import-data"
                            className="waves-effect waves-light black-text modal-trigger"
                            activeClassName="blue lighten-4"
                        >
                            <i className="material-icons prefix">upload</i>
							<Trans>IMPORT_TRANSACTIONS</Trans>
                        </NavLink>
					</li>

                    <div className="version-footer">
                        <span style={{fontSize: '14px'}}>© TimelyBills Software LLP</span>
                    </div>
                </SideNav>
            </div>

        );
    }
}

const mapStateToProps = (state) => {
    return state;
};

export default withTranslation() (connect(
    mapStateToProps,
    {
        getCurrencies
    })(Sidenav));