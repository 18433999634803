export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const GET_USER_ACCOUNTS = 'GET_USER_ACCOUNTS';

export const LOGOUT_USER = 'LOGOUT_USER';
export const GET_CATEGORIES = 'GET_CATEGORIES';
export const GET_CURRENCIES_SUCCESS = 'GET_CURRENCIES_SUCCESS';
export const GET_TIME_ZONES_SUCCESS = 'GET_TIME_ZONES_SUCCESS';
export const GET_LANGUAGES_SUCCESS = 'GET_LANGUAGES_SUCCESS';
export const SAVE_USER_PROFILE_SUCCESS = 'SAVE_USER_PROFILE_SUCCESS';
export const SETTING_DATA_ACTION = 'SETTING_DATA_ACTION';
export const GET_ERRORS = 'GET_ERRORS';
export const NOTIFICATION_DETAILS_UPDATED = 'NOTIFICATION_DETAILS_UPDATED';
export const SEARCH_TRANSACTION = 'SEARCH_TRANSACTION';
export const GET_CURRENCIES = 'GET_CURRENCIES';
export const LOADING = 'LOADING';
export const NOT_LOADING = 'NOT_LOADING';

export const TRANSACTION_TYPE_EXPENSE = 1;
export const TRANSACTION_TYPE_INCOME  = 2;
export const TRANSACTION_TYPE_BUDGET  = 3;
export const TRANSACTION_TYPE_BILL  = 4;
export const TRANSACTION_TYPE_RECURRING_BILL = 5;

export const GET_BILL_STATS_SUCCESS = 'GET_BILL_STATS_SUCCESS';
export const GET_EXPENSE_STATS_SUCCESS = 'GET_EXPENSE_STATS_SUCCESS';
export const GET_BY_CATEGORY_STATS_SUCCESS = 'GET_BY_CATEGORY_STATS_SUCCESS';
export const GET_BY_TRENDS_STATS_SUCCESS = 'GET_BY_TRENDS_STATS_SUCCESS';

export const GET_BILL_STATS_LOADING = 'GET_BILL_STATS_LOADING';
export const GET_EXPENSE_STATS_LOADING = 'GET_EXPENSE_STATS_LOADING';
export const GET_BY_CATEGORY_STATS_LOADING = 'GET_BY_CATEGORY_STATS_LOADING';
export const GET_BY_TRENDS_STATS_LOADING = 'GET_BY_TRENDS_STATS_LOADING';
export const GET_BY_TRENDS_CATEGORY_LOADING = 'GET_BY_TRENDS_CATEGORY_LOADING';
export const GET_BY_TRENDS_CATEGORY_SUCCESS = 'GET_BY_TRENDS_CATEGORY_SUCCESS';

export const SEND_USER_STATEMENT = 'SEND_USER_STATEMENT';
export const STATEMENT_ACTION = 'STATEMENT_ACTION';
export const SEND_USER_STATEMENT_SUCCESS = 'SEND_USER_STATEMENT_SUCCESS';

export const GET_USER_PROFILE_SUCCESS = 'GET_USER_PROFILE_SUCCESS';
export const GET_USER_PROFILE_FAILED = 'GET_USER_PROFILE_FALIED';
export const USER_SETTINGS_CACHE = 'USER_SETTINGS_CACHE';
export const GET_USER_SETTINGS_SUCCESS = 'GET_USER_SETTINGS_SUCCESS';
export const BILL_PAYMENT_FETCH_SUCCESS = 'BILL_PAYMENT_FETCH_SUCCESS';

export const EXPENSE_DATA_LOADING = 'EXPENSE_DATA_LOADING';
export const GET_EXPENSE_DATA_SUCCESS = 'GET_EXPENSE_DATA_SUCCESS';
export const EXPENSE_DATA_ACTION = 'EXPENSE_DATA_ACTION';
export const EXPENSE_DATA_FAILED = 'EXPENSE_DATA_FAILED';
export const GET_EXPENSE_IMAGE = 'GET_EXPENSE_IMAGE';
export const EXPENSE_IMAGE_LOADING = 'EXPENSE_IMAGE_LOADING';

export const GET_CATEGORIES_SUCCESS = 'GET_CATEGORIES_SUCCESS';
export const GET_ACCOUNT_TYPE_SUCCESS = 'GET_ACCOUNT_TYPE_SUCCESS';
export const GET_GROUP_ACCOUNT_LIST_SUCCESS = 'GET_GROUP_ACCOUNT_LIST_SUCCESS';
export const GET_SERVICE_PROVIDERS_SUCCESS = 'GET_SERVICE_PROVIDERS_SUCCESS';
export const BUDGET_DATA_ACTION = 'BUDGET_DATA_ACTION';
export const GROUP_USER_IMAGE_SUCCESS = 'GROUP_USER_IMAGE_SUCCESS';

export const PAID_BILLING_DATA_LOADING = 'PAID_BILLING_DATA_LOADING';
export const RECURRING_BILLING_DATA_LOADING = 'RECURRING_BILLING_DATA_LOADING';
export const UPCOMING_BILLING_DATA_LOADING = 'UPCOMING_BILLING_DATA_LOADING';
export const OVERDUE_BILLING_DATA_LOADING = 'OVERDUE_BILLING_DATA_LOADING';
export const GET_PAID_BILL_DATA_SUCCESS = 'GET_PAID_BILL_DATA_SUCCESS';
export const GET_RECURRING_BILL_DATA_SUCCESS = 'GET_RECURRING_BILL_DATA_SUCCESS';
export const GET_OVERDUE_BILL_DATA_SUCCESS = 'GET_OVERDUE_BILL_DATA_SUCCESS';
export const GET_UPCOMING_BILL_DATA_SUCCESS = 'GET_UPCOMING_BILL_DATA_SUCCESS';
export const BILL_DATA_LOADING = 'BILL_DATA_LOADING';
export const BILL_DATA_FAILED = 'BILL_DATA_FAILED';
export const BILL_DATA_ACTION = 'BILL_DATA_ACTION';
export const UPDATE_BILL_DATA_LIST = 'UPDATE_BILL_DATA_LIST';

export const INCOME_DATA_LOADING = 'INCOME_DATA_LOADING';
export const GET_INCOME_DATA_SUCCESS = 'GET_INCOME_DATA_SUCCESS';
export const INCOME_DATA_FAILED = 'INCOME_DATA_FAILED';
export const INCOME_IMAGE_LOADING = 'INCOME_IMAGE_LOADING';
export const INCOME_DATA_ACTION = 'INCOME_DATA_ACTION';
export const GET_INCOME_IMAGE = 'GET_INCOME_IMAGE';
export const GET_RECURRING_SUCCESS = 'GET_RECURRING_SUCCESS';
export const GET_RECURRING_SUCCESS_INCOME = 'GET_RECURRING_SUCCESS_INCOME';

export const BUDGET_MONTHLY_DATA_LOADING = 'BUDGET_MONTHLY_DATA_LOADING';
export const GET_MONTHLY_BUDGET_DATA_SUCCESS = 'GET_MONTHLY_BUDGET_DATA_SUCCESS';
export const GET_MONTHLY_BUDGET_DATA_FAILED = 'GET_MONTHLY_BUDGET_DATA_FAILED';

export const GET_CATEGORY_BUDGET_DATA_SUCCESS = 'GET_CATEGORY_BUDGET_DATA_SUCCESS';
export const GET_CATEGORY_BUDGET_DATA_FAILED = 'GET_CATEGORY_BUDGET_DATA_FAILED';
export const BUDGET_CATEGORY_DATA_LOADING = 'BUDGET_CATEGORY_DATA_LOADING';

export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS';
export const CHANGE_PASSWORD_FAILED = 'CHANGE_PASSWORD_FAILED';
export const CHANGE_PASSWORD_LOADING = 'CHANGE_PASSWORD_LOADING';
export const CHANGE_PASSWORD_ACTION = 'CHANGE_PASSWORD_ACTION';

export const USER_UPDATE_SUCCESS = 'USER_UPDATE_SUCCESS';
export const USER_UPDATE_FAILED = 'USER_UPDATE_FAILED';
export const USER_UPDATE_LOADING = 'USER_UPDATE_LOADING';
export const USER_UPDATE_ACTION = 'USER_UPDATE_ACTION';

export const ADD_UPDATE_BUDGET_LOADING = 'ADD_UPDATE_BUDGET_LOADING';
export const ADD_UPDATE_BUDGET_FAILED = 'ADD_UPDATE_BUDGET_FAILED';
export const ADD_UPDATE_BUDGET_SUCCESS = 'ADD_UPDATE_BUDGET_SUCCESS';

export const TRANSACTION_CACHE = 'TRANSACTION_CACHE';

export const DELETE_MONTHLY_TRANSACTION_LOADING = 'DELETE_MONTHLY_TRANSACTION_LOADING';
export const DELETE_MONTHLY_TRANSACTION_FAILED = 'DELETE_MONTHLY_TRANSACTION_FAILED';
export const DELETE_MONTHLY_TRANSACTION_SUCCESS = 'DELETE_MONTHLY_TRANSACTION_SUCCESS';

export const DELETE_CATEGORY_TRANSACTION_LOADING = 'DELETE_CATEGORY_TRANSACTION_LOADING';
export const DELETE_CATEGORY_TRANSACTION_FAILED = 'DELETE_CATEGORY_TRANSACTION_FAILED';
export const DELETE_CATEGORY_TRANSACTION_SUCCESS = 'DELETE_CATEGORY_TRANSACTION_SUCCESS';

export const BUDGET_DATA_FAILED = 'BUDGET_DATA_FAILED';

export const USER_AVATAR_LOADING = 'USER_AVATAR_LOADING';
export const USER_AVATAR_IMAGE = 'USER_AVATAR_IMAGE';
export const USER_AVATAR_ACTION = 'GET_INCOME_ACTION';
export const  GET_USER_AVATAR_IMAGE = 'GET_USER_AVATAR_IMAGE';

export const GET_SETTINGS_DETAILS_SUCCESS = 'GET_SETTINGS_DETAILS_SUCCESS';

export const USER_PROFILE_LOADING = 'USER_PROFILE_LOADING';
export const USER_PROFILE_SUCCESS = 'USER_PROFILE_SUCCESS';
export const USER_PROFILE_FAILED = 'USER_PROFILE_FAILED';
export const USER_PROFILE_ACTION = 'USER_PROFILE_ACTION';

//USER PROFILE in AUTHx
export const PROFILE_DATA_ACTION = 'PROFILE_DATA_ACTION';
export const PROFILE_DATA_SUCCESS = 'PROFILE_DATA_SUCCESS';

// Template
export const POST_FEEDBACK_FAILED = 'POST_FEEDBACK_FAILED';
export const POST_FEEDBACK_SUCCESS = 'POST_FEEDBACK_SUCCESS';

export const   FETCH_TEMPLATE_SUCCESS = 'FETCH_TEMPLATE_SUCCESS';
export const FETCH_TEMPLATE_FAILED = 'FETCH_TEMPLATE_FAILED';

export const GET_USER_DETAILS_ACTION = 'GET_USER_DETAILS_ACTION';
export const GET_USER_DETAILS_SUCCESS = 'GET_USER_DETAILS_SUCCESS';

export const AVATAR_IMAGE_LOADING = 'AVATAR_IMAGE_LOADING';
export const AVATAR_DATA_ACTION = 'AVATAR_DATA_ACTION';
export const AVATAR_DATA_FAILED = 'AVATAR_DATA_FAILED';
export const AVATAR_DATA_SUCCESS = 'AVATAR_DATA_SUCCESS';

export const GET_ACCOUNT_LIST_FAILED = 'GET_ACCOUNT_LIST_FAILED';
export const GET_ACCOUNT_LIST_SUCCESS = 'GET_ACCOUNT_LIST_SUCCESS';
export const GET_ACCOUNT_LIST_LOADING = 'GET_ACCOUNT_LIST_LOADING';