import React from 'react';
import {
    Row,
    Col,
    Icon
} from 'react-materialize';
import {FormattedNumber} from "react-intl";
import {
	SHORT_MONTHS,
	SHORT_DAYS,
	LONG_DAYS,
	LONG_MONTHS
} from './ConstData';
import { unescape } from 'lodash';
import moment from 'moment';
import {CAT_TYPE, CAT_TYPE_TEXT, iconUrl, RIGHT_ARROW_ICON, s3Url} from "../../actions/config";
import {Trans} from "react-i18next";

export const RECURRING_CATEGORY = [
	{ code: 'NO_REPEAT', name: 'NO_REPEAT', pluralName: 'NO_REPEAT', key: 0 },
	{ code: 'DAILY', name: 'DAY', pluralName: 'DAY', key: 1 },
	{ code: 'WEEKLY', name: 'WEEK', pluralName: 'WEEK', key: 2 },
	{ code: 'MONTHLY', name: 'MONTH', pluralName: 'MONTH', key: 3 },
	{ code: 'YEARLY', name: 'YEAR', pluralName: 'YEAR', key: 4 }
];

export const RepeatCategoryKeys = {
    NO_REPEAT: 0,
	DAILY: 1,
    WEEKLY: 2,
    MONTHLY: 3,
    YEARLY: 4
};

export const getRecurringType = (recurringKey) => {
    return RECURRING_CATEGORY[RECURRING_CATEGORY.findIndex((data) => {
    	if(data.key === recurringKey) {
    		return data;
		}
		return null;
	})];
};

export const getServiceProviderList = (_providerType = 'bank') => {
	const serviceProviders = JSON.parse(localStorage.getItem('serviceProviders'));
    return serviceProviders.filter(({providerType}) => providerType === `${_providerType}`);
};

export const RepeatCategory = {
    NO_REPEAT: { code: 'NO_REPEAT', regularName: 'NO_REPEAT', name: 'NO_REPEAT', pluralName: 'No Repeat', parentId: 0, index: 0 },
    DAILY: { code: 'DAILY', regularName: 'DAILY', name: 'DAY', pluralName: 'Days', parentId: 1, index: 1 },
    WEEKLY: { code: 'WEEKLY', regularName: 'WEEKLY', name: 'WEEK', pluralName: 'Weeks', parentId: 2, index: 2 },
    BI_WEEKLY: { code: 'BI_WEEKLY', regularName: 'BI_WEEK', name: 'BI_WEEK', pluralName: 'Bi-Weeks', parentId: 2, index: 4 },
    MONTHLY: { code: 'MONTHLY', regularName: 'MONTHLY', name: 'MONTH', pluralName: 'Months', parentId: 3, index: 3 },
    BI_MONTHLY: { code: 'BI_MONTHLY', regularName: 'BI_MONTH', name: 'BI_MONTH', pluralName: 'Bi-Months', parentId: 3, index: 5 },
    YEARLY: { code: 'YEARLY', regularName: 'YEARLY', name: 'YEAR', pluralName: 'Years', parentId: 4, index: 6 },
    CUSTOM: { code: 'CUSTOM', regularName: 'CUSTOM', name: 'CUSTOM', pluralName: 'Custom', parentId: 5, index: 7 }
};

export const dayOfYear = (date = new Date()) => {
	/*const dateObj = new Date(date);
    const start = new Date(new Date().getFullYear(), 0, 1);
    const nowDate = new Date(dateObj.getFullYear(), dateObj.getMonth(), dateObj.getDate());
    const diff = nowDate - start;
    const oneDay = 1000 * 60 * 60 * 24;*/
    return moment(date).dayOfYear();
};
export const getYear = (date = new Date()) => {
	/*const dateObj = new Date(date);
    const start = new Date(new Date().getFullYear(), 0, 1);
    const nowDate = new Date(dateObj.getFullYear(), dateObj.getMonth(), dateObj.getDate());
    const diff = nowDate - start;
    const oneDay = 1000 * 60 * 60 * 24;*/
    return moment(date).year();
};
export const weekOfYear = (date = new Date()) => {
	/*const dateObj = new Date(date);
    const start = new Date(new Date().getFullYear(), 0, 1);
    const nowDate = new Date(dateObj.getFullYear(), dateObj.getMonth(), dateObj.getDate());
    const diff = nowDate - start;
    const oneDay = 1000 * 60 * 60 * 24;*/
    return moment(date).week();
};
export const getCurrentUTCTime = (date = new Date()) => {
    return moment(date).utc().valueOf();
};

export const getShortDate = (time, t) => {
	const d = new Date(time);
	return `${t(SHORT_MONTHS[d.getMonth()])} ${d.getDate()}`;
};
export const getShortDateLongMonth = (time, t) => {
	const d = new Date(time);
	return `${t(LONG_MONTHS[d.getMonth()])} ${d.getDate()}`;
};
export const getShortDateYr = (time, t) => {
	const d = new Date(time);
	return `${t(SHORT_MONTHS[d.getMonth()])} ${d.getDate()}, ${d.getFullYear()}`;
};
export const getLongDateYr = (time, t) => {
    const d = new Date(time);
    return `${t(SHORT_DAYS[d.getDay()])}, ${d.getDate()} ${t(SHORT_MONTHS[d.getMonth()])} ${d.getFullYear()}`;
};
export const getShortDay = (time, t) => {
	const d = new Date(time);
	return t(SHORT_DAYS[d.getDay()]);
};
export const getLongDay = (time) => {
	const d = new Date(time);
	return LONG_DAYS[d.getDay()];
};
export const getCategoryNames = (categories) => {
	// console.log('categories..', categories);
	const unique = categories.reduce((red, {name}) => {red[unescape(name)] = 1; return red;}, {});
	return Object.keys(unique).join(', ');
};
export const sortArray = (arr, prp) => {
    if(prp === 'created_at') {
        return arr.sort((a,b) => moment(b[prp]) - moment(a[prp]))
    }
	return arr.sort((a,b) => b[prp] - a[prp])
};
export const sortArrayDesc = (arr, prp) => {
    if(prp === 'created_at') {
        return arr.sort((a,b) => moment(a[prp]) - moment(b[prp]))
    }
	return arr.sort((a, b) => a[prp] - b[prp])
};

export const sortAccordingToType=(arr)=>{
    // codes 
    //0 bank acc
//     // 1 cash
//     // 2 creduit card
//     // 3 loan
//     // 4 brokerage
//     // 5 investments
//     // 6 retirement
//     // 7 wallet
//     // 100 wallet

//Segregation on the basis of_types
     //cash credit and other acc
//     //bank account and cash in cash
//     // Credit card and loan accounts in Credit
//     // others account types on Other Accounts group

// Sample Obj
// //     NAME: "Bank account"
// // SEQ: 0
// // balanceLabel: "Balance"
// // code: 0
// // icon: "icon_bank_darkgrey"
// // initialBalanceLabel: "Starting balance"
// // providerType: "bank"
    const _types={'cash': [0,1, 7, 8,9,21], 'credit': [2,10], 'investments': [4,5,6,20,12], 'loans': [3,15,22] ,'ASSET': [11], 'other_accounts': []};
    const _type_map = {};

    let finalObj={
    }
    Object.keys(_types).forEach(e=>{
        const d = finalObj[e]=[]
        const vl = _types[e];
        vl.forEach(ee=>{
            _type_map[ee] = d;
        })
    })
    console.log(finalObj)
    arr.forEach(e=>{
        if(e._id){
            var arr = _type_map[e.accountType];
            if(!arr) {
                arr = finalObj['other_accounts'] 
            } 
            arr.push(e)

            // if(e.accountType===0|| e.accountType===1){
            //         if(finalObj['cash']&&finalObj['cash'].length>0){
            //             finalObj['cash'].push(e)
            //         }
            //         else{
            //             finalObj['cash']=[e]
            //         }
            // }
            // else if(e.accountType===2 ||e.accountType===3){
            //     if(finalObj['credit']&&finalObj['credit'].length>0){
            //         finalObj['credit'].push(e)
            //     }
            //     else{
            //         finalObj['credit']=[e]
            //     }
            // }
            // else if(e.accountType===4 ||e.accountType===5){
            //     if(finalObj['investments']&&finalObj['investments'].length>0){
            //         finalObj['investments'].push(e)
            //     }
            //     else{
            //         finalObj['investments']=[e]
            //     }
            // }
            // else{
            //     if(finalObj['other_accounts']&&finalObj['other_accounts'].length>0){
            //         finalObj['other_accounts'].push(e)
            //     }
            //     else{
            //         finalObj['other_accounts']=[e]
            //     }
            // }
        }
    })
    for(let key in finalObj){
        let _arr=[]
        _arr= sortArray( finalObj[key], 'currentBalance')
        finalObj[key]=_arr
    }
    return finalObj;
}

export const getRecurringCategory = (categoryId, recurringCount = 1, t) => {
    if(categoryId < 0 || categoryId > 4) {
        categoryId = 0
    }
	return (recurringCount > 1)
		? `${recurringCount} ${t(RECURRING_CATEGORY[categoryId].pluralName)}`
		: t(RECURRING_CATEGORY[categoryId].name);
};
export const getRecurringDate = (categoryId, time, t) => {
	const d = new Date(time);
    if(categoryId < 0 || categoryId > 4) {
        categoryId = 0
    }
	switch(RECURRING_CATEGORY[categoryId].code) {
		case 'DAILY': return `From ${getShortDateLongMonth(time, t)}`;
		case 'WEEKLY': return `on ${t(LONG_DAYS[d.getDay()])}`;
		case 'MONTHLY': return `on ${d.getDate()}`;
		case 'YEARLY': return `${t(SHORT_MONTHS[d.getMonth()])} ${d.getDate()}`;
		default: return '';
	}
};
export const getDateTimeFormat = (dateString) => {
	const d = new Date(dateString);
	return moment(d).format('MMM D, YYYY, h:mm A');
};
export const intNVL = (value, defVal = 0) => {
	if(value){
		return parseInt(value, 10);
	}
	return defVal;
};
export const getDayDiffFromToday = (date) => {
	const currentDate = new Date();
	currentDate.setHours(0,0,0,0);
	const a = moment(currentDate);
	const b = moment(new Date(date));
	return b.diff(a, 'days');
};
export const getDayNegativeDiffFromToday = (date) => {
	const currentDate = new Date();
	currentDate.setHours(0,0,0,0);
	const a = moment(currentDate);
	const b = moment(new Date(date));
	return a.diff(b, 'days');
};
export const smallFontByLanguage = (lang) =>{
    const languages = ['ja', 'ko']
    if (languages.indexOf(lang)>-1){
        return true
    }
    else return false
}
export const repeatTypeSelected = (selectedIndex = -1) => {
    if(selectedIndex> -1) {
        if(selectedIndex === RepeatCategory.NO_REPEAT.index){
            return {
            	recurringCategoryId: null,
                recurringCount: null
            };
        }else if(selectedIndex === RepeatCategory.CUSTOM.index) {
            console.log('custom');
        }else if(selectedIndex === RepeatCategory.DAILY.index) {
        	return {
                recurringCategoryId: RepeatCategory.DAILY.parentId,
                recurringCount: 1
            }
        }else if(selectedIndex === RepeatCategory.WEEKLY.index){
        	return {
                recurringCategoryId: RepeatCategory.WEEKLY.parentId,
                recurringCount: 1
            }
        }else if(selectedIndex === RepeatCategory.BI_WEEKLY.index){
            return {
                recurringCategoryId: RepeatCategory.BI_WEEKLY.parentId,
                recurringCount: 2
            }
        }else if(selectedIndex === RepeatCategory.MONTHLY.index){
            return {
                recurringCategoryId: RepeatCategory.MONTHLY.parentId,
                recurringCount: 1
            }
        }else if(selectedIndex === RepeatCategory.BI_MONTHLY.index){
            return {
                recurringCategoryId: RepeatCategory.BI_MONTHLY.parentId,
                recurringCount: 2
            }
        }else if(selectedIndex === RepeatCategory.YEARLY.index){
            return {
                recurringCategoryId: RepeatCategory.YEARLY.parentId,
                recurringCount: 1
            }
        }
    }
};

export const browserName = () => {
    const nAgt = navigator.userAgent;
    let browserName  = navigator.appName;
    let nameOffset,verOffset;

    if ((verOffset=nAgt.indexOf("OPR/"))!== -1)
        browserName = "Opera";
    else if ((verOffset=nAgt.indexOf("Opera"))!== -1)
        browserName = "Opera";
    else if ((verOffset=nAgt.indexOf("MSIE"))!== -1)
        browserName = "Microsoft Internet Explorer";
    else if ((verOffset=nAgt.indexOf("Chrome"))!== -1)
        browserName = "Chrome";
    else if ((verOffset=nAgt.indexOf("Safari"))!== -1)
        browserName = "Safari";
    else if ((verOffset=nAgt.indexOf("Firefox"))!== -1)
        browserName = "Firefox";
    else if ( (nameOffset=nAgt.lastIndexOf(' ')+1) < (verOffset=nAgt.lastIndexOf('/')) )
        browserName = nAgt.substring(nameOffset,verOffset);
    return browserName;
};

export const onChangeInputWheel = (ref) => {
    if(ref) {
        ref.blur();
    }
};
export const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
};
export const createDateFormat = (t, date = new Date()) => {
    return `${capitalizeFirstLetter(t(SHORT_DAYS[moment(date).get('day')]))}, ${capitalizeFirstLetter(t(SHORT_MONTHS[moment(date).get('month')]))} ${moment(date).get('date')}, ${(moment(date).get('year'))}`
};

export const isCreator = (createdUserId, currentUserId) => {
    if(!createdUserId) {
        return true;
    }
    return createdUserId === currentUserId;
};
export const filterCurrentUsersAccount = (arr) => {
    let _arr=[]
    if(arr.length>0 && localStorage.getItem('profileDetails')){
        const profileDetails =  (JSON.parse(localStorage.getItem('profileDetails')) || {})
        const userId = (profileDetails.userId || '')
        if(userId!==''){
            arr.forEach(e=>{
                if(e.userId===userId || e.accountId===''){
                    _arr.push(e)
                }
            })
            return _arr
        }
    }
    return []
}
export const isOwnerAccount = (account) =>{
    if(account && localStorage.getItem('profileDetails')){
        const profileDetails =  (JSON.parse(localStorage.getItem('profileDetails')) || {})
        const userId = (profileDetails.userId || '')
        if(userId!==''){
            if(account.userId===userId) return true
        }
    }
    return false;
}
export const isOfflineAccount = (account) =>{
    if(account!==undefined && account!==null){
        let value=  (account.onlineAccount!==null && account.onlineAccount!==undefined && account.onlineAccount==true  )?false:true ;
        return value;
    }else{
        return true;
    }
}

function generateRandomWord (size){

    let randomString = null;
    try{
        let length = Math.floor(Math.random() * 8)+size;
        let randomString           = '';
        let characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let charactersLength = characters.length;
        for ( let i = 0; i < length; i++ ) {
            randomString += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return randomString;
    }catch(error){
        randomString = "ZYX";
        console.error("Error occurred while generating random string ->>", error);
    }
    return randomString;
}
export const generateRandomLocalIdLong = () => {

    let randomId = null;
    try{
        randomId = (new Date().getTime())+generateRandomWord(5)
    }catch(error){
        console.error("Error occurred while generating random string ->>", error);
    }
    return randomId;
};

export const getServiceProvider = (provider) => {
    const serviceProvider = JSON.parse(localStorage.getItem('serviceProviders'));
    return serviceProvider.find(({providerId}) => providerId === `${provider}`);
};

export const getAccountTypeName = (typeId) => {
    const accountTypes = JSON.parse(localStorage.getItem('accountTypes'));
    return (((accountTypes || []).find(({code}) => code === typeId) || {}).NAME || '');
};

export const getAccountProviderType = (typeId) => {
    const accountTypes = JSON.parse(localStorage.getItem('accountTypes'));
    return (accountTypes.find(({code}) => code === typeId) || {}).providerType;
};

export const getAccountTypeById = (typeId) => {
    const accountTypes = JSON.parse(localStorage.getItem('accountTypes'));
    return ((accountTypes || []).find(({code}) => code === typeId) || {});
};

export const getAccountBalanceLabelById = (typeId) => {
    const accountTypes = JSON.parse(localStorage.getItem('accountTypes'));
    return (accountTypes.find(({code}) => code === typeId) || {}).balanceLabel;
};

export const getAccountInitialBalanceLabelById = (typeId) => {
    const accountTypes = JSON.parse(localStorage.getItem('accountTypes'));
    return (accountTypes.find(({code}) => code === typeId) || {}).initialBalanceLabel;
};

export const getCategoryById = (id, catType = 1) => {
    const categories = JSON.parse(localStorage.getItem('userCategories'));
    return categories.find(({categoryId, type}) => categoryId === id && type === catType);
};

export const finObjectByKey = (arrObj, value, key) => {
    return arrObj.length > 0 ? arrObj.find(data => data[key] === value) : {};
};

export const getServiceLogoUrl = (acctObj = false) => {
    let logoUrl = '';
    if(acctObj && (acctObj || {}).serviceProviderId) {
        logoUrl = getServiceProvider(acctObj.serviceProviderId);
        if(!logoUrl) {
            logoUrl = getAccountTypeById((acctObj || {}).accountType);
            logoUrl = logoUrl.icon;
        }else {
            logoUrl = logoUrl.logoURL;
        }
    }else {
        logoUrl = getAccountTypeById((acctObj || {}).accountType).icon;
    }
    return logoUrl;
};

export const getServiceProviderName = (acctObj = false) => {
    // (data.serviceProviderId ? (getServiceProvider(data.serviceProviderId).providerName) : getAccountTypeName(data.accountType) )
    let name = '';
    if(acctObj && (acctObj || {}).serviceProviderId) {
        name = getServiceProvider(acctObj.serviceProviderId);
        if(!name) {
            const typeName = getAccountTypeName((acctObj || {}).accountType);
            name = (typeName ? (typeName + RIGHT_ARROW_ICON) : '') + ((acctObj || {}).accountName || '');
        }else {
            name = (name.providerName ? (name.providerName + RIGHT_ARROW_ICON) : '') + getAccountTypeName((acctObj || {}).accountType);
        }
    }else {
        const typeName = getAccountTypeName((acctObj || {}).accountType);
        name = (typeName ? (typeName + RIGHT_ARROW_ICON) : '') + ((acctObj || {}).accountName || '');
    }
    return name;
};

/*export const getServiceProviderName = (acctObj = false) => {
    // (getServiceProvider(act.serviceProviderId) ?
    // (getServiceProvider(act.serviceProviderId) || {}).providerName + RIGHT_ARROW_ICON : '') +
    // (act.accountName ? act.accountName : getAccountTypeName(act.accountType))
    let name = '';
    if(acctObj && (acctObj || {}).serviceProviderId) {
        name = getServiceProvider(acctObj.serviceProviderId);
        if(!name) {
            name = getAccountTypeName((acctObj || {}).accountType);
        }else {
            name = name.providerName;
        }
    }else {
        name = getAccountTypeName((acctObj || {}).accountType);
    }
    return name;
};*/



export const getAccountDetailUI = (arrObj, value, type = true, createUserId='') => {
    const { userAccountList, groupAccountList } = arrObj;
    let data = userAccountList && userAccountList.length > 0 ? userAccountList.find(dataObj => (createUserId ? (createUserId===dataObj.userId) : true) &&  dataObj.accountId === value) : {};
    if(!(data && Object.keys(data).length > 0))
        data = groupAccountList && groupAccountList.length > 0 ? groupAccountList.find(data =>  (createUserId ? createUserId===data.userId : true) && data.accountId === value ) : {};
    if( data && Object.keys(data).length > 0) {
        const userSettings = JSON.parse(localStorage.getItem('profileDetails')) || {};
        const currencyDetails = userSettings.currencyDetails;
        return (
            <Row>
                <Col s={9} m={9} l={9} style={{position: 'relative'}}>
                    <div className='year-selector yearSelector ' style={{paddingTop: '13px'}}>
                        <img
                            src={s3Url + getServiceLogoUrl(data) + '.png'}
                            alt='icon'
                            className='leftMargin10 circle'
                            style={{
                                height: '54px'
                            }}
                        />
                        <div className="value">
                            <p className="title">{getServiceProviderName(data)}</p>
                            <div className="balance-label grey-text">
                                <p>
                                    <span>
                                        {type ?
                                            <span><Trans>{(type === 1 ? 'FROM_ACCOUNT' : 'TO_ACCOUNT')}</Trans>: </span>: ''
                                        }
                                        {data.accountName || ''}
                                    </span>&nbsp;
                                </p>
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
        )
    }
    return null;
};

export const getAccountDetailUIForBill = (arrObj, value, type = true, createUserId='') => {
    const { userAccountList, groupAccountList } = arrObj;
    let data = userAccountList && userAccountList.length > 0 ? userAccountList.find(dataObj => (createUserId ? (createUserId===dataObj.userId) : true) &&  dataObj.accountId === value) : {};
    if(!(data && Object.keys(data).length > 0))
        data = groupAccountList && groupAccountList.length > 0 ? groupAccountList.find(data =>  (createUserId ? createUserId===data.userId : true) && data.accountId === value ) : {};
    if( data && Object.keys(data).length > 0) {
        const userSettings = JSON.parse(localStorage.getItem('profileDetails')) || {};
        const currencyDetails = userSettings.currencyDetails;
        return (
            <Row className='margin-0'>
                <Col s={9} m={9} l={9} style={{position: 'relative'}}>
                    <div className='year-selector yearSelector ' style={{paddingTop: '4px'}}>
                        <img
                            src={s3Url + getServiceLogoUrl(data) + '.png'}
                            alt='icon'
                            className='leftMargin10 circle'
                            style={{
                                height: '35px'
                            }}
                        />
                        <div className="value">
                            <p className="title">{getServiceProviderName(data)}</p>
                            <div className="balance-label grey-text" style={{marginTop: '0'}}>
                                <p>
                                    <span>
                                        {type ?
                                            <span><Trans>ACCOUNT</Trans>: </span>: ''
                                        }
                                        {data.accountName || ''}
                                    </span>&nbsp;
                                </p>
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
        )
    }
    return null;
};

export const getMidDayTime = (time)=>{
    // 12 hours = 43,200,000 Milliseconds
    return time + 43200000;
}
export const isNegativeAccountBalance = (acctObj) => {
    const typeName = getAccountTypeName((acctObj || {}).accountType);
    return (typeName !== 'Loan' && typeName !== 'Credit Card' && acctObj.currentBalance < 0);
};

export const checkNatureOfAccountTransaction = (trns) => {
    // returns + for Income type - for expense 
    if(trns.type===1){
        return true;
    }
    else{
        return false;
    }
}