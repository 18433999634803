import React, { Component } from "react";
import { withTranslation, Trans } from "react-i18next";
import {
  Row,
  Col,
  Collection,
  CollectionItem,
  Modal,
  Button,
} from "react-materialize";
import { constant, unescape } from "lodash";
import Tabs from "./common/Tabs";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { FormattedNumber } from "react-intl";
import { LONG_MONTHS, SHORT_MONTHS, BUDGET_TYPE } from "./common/ConstData";
import { sortArray, intNVL, sortArrayDesc, isCreator } from "./common/Methods";
import YearSelector from "./common/YearSelector";
import MonthSelector from "./common/MonthSelector";
import {
  getCategoryBudgetList,
  getMonthlyBudgetList,
  addUpdateBudget,
  addUpdateCategoryBudget,
  deleteTransaction,
  deleteCategoryTransaction,
} from "../actions/budgetActions";
import Loader from "./Loader";
import AddBudget from "./AddBudget";
import MonthlyBudgetDetail from "./MonthlyBudgetDetail";
import CategoryBudgetDetail from "./CategoryBudgetDetail";
import Swal from "sweetalert2";
import "../stylesheets/budget.css";
import moment from "moment";
import { iconUrl, RIGHT_ARROW_ICON } from "../actions/config";

const getOverAmount = (data) => {
  if (data.budgetType === BUDGET_TYPE.INCOME) {
    return (data.income / data.amount) * 100;
  }
  return (data.expense / data.amount) * 100;
};

const BudgetItem = ({
  row,
  rowClick,
  viewData,
  currencyDetails,
  userSettings,
  isMonthRow,
  auth,
}) => (
  <CollectionItem
    key={row._id}
    className={viewData._id === row._id ? "active-record" : ""}
  >
    <table
      className={
        isMonthRow
          ? "budget-no-padding budget-monthly"
          : "budget-no-padding budget-monthly-category"
      }
      onClick={(e) => rowClick(row)}
      style={{ cursor: "pointer" }}
    >
      <tbody>
        <tr
          className={
            "" + (row.expenseincome > intNVL(row.amount) ? "danger" : "")
          }
        >
          {isMonthRow ? (
            <>
              <td rowSpan="2" width="13%">
                <div className="day-round">
                  <Trans>{SHORT_MONTHS[row.month]}</Trans>
                </div>
              </td>
              <td style={{ width: "57%" }}>
                <div className="date-label">
                  <Trans>{LONG_MONTHS[row.month]}</Trans>
                </div>
              </td>
            </>
          ) : (
            <>
              <td rowSpan="2" width="13%">
                <img
                  src={iconUrl + row.iconUrl + ".png"}
                  alt="icon"
                  className="leftMargin10 circle"
                  style={{
                    backgroundColor: row.iconColor,
                  }}
                />
              </td>
              <td style={{ width: "57%" }}>
                <div className="date-label">{unescape(row.name)}</div>
              </td>
            </>
          )}

          <td width="30%" className="right-align">
            <div className="flex-box-recurring right">
              {userSettings &&
              !isCreator(row.createdUserId, userSettings.userId) &&
              localStorage.getItem((row.createdUserId).toString()) ? (
                <div>
                  {auth && auth.groupImageList[row.createdUserId] ? (
                    <img
                      className="height-20 zero-margin"
                      src={auth.groupImageList[row.createdUserId]}
                    />
                  ) : (
                    <h5 className="zero-margin">
                      {JSON.parse(localStorage.getItem(row.createdUserId))
                        .ownerDetail.slice(0, 1)
                        .toUpperCase()}
                    </h5>
                  )}
                </div>
              ) : null}
              <span className="nowrap margin1-1">
                <FormattedNumber
                  value={row.amount}
                  style="currency"
                  currency={currencyDetails.currencyCode || "USD"}
                  minimumFractionDigits={2}
                  maximumFractionDigits={2}
                />
              </span>
              {row.carryForwardAmtAdded ? (
                <div>
                  &nbsp;
                  {true ? (
                    <img
                      src={iconUrl + "icon_rollover_green.png"}
                      style={{ margin: "0", float: "right", height: "23px" }}
                      alt="Income"
                    />
                  ) : (
                    <Trans>CARRY_FRWRD_ADDED</Trans>
                  )}
                </div>
              ) : null}
            </div>
          </td>
        </tr>
        <tr>
          <td colSpan="4">
            <div className="Progress">
              <div
                className={"Progress-Bars "}
                style={{
                  width: row.barwidth + "%",
                  background: row.barcolor,
                }}
              />
            </div>
          </td>
        </tr>
        <tr className="grey-text ">
          <td />
          <td className="">
            <Trans>{row.typeKey}</Trans>:&nbsp;
            <span className="nowrap">
              <FormattedNumber
                value={row.expenseincome || 0}
                style="currency"
                currency={currencyDetails.currencyCode || "USD"}
                minimumFractionDigits={2}
                maximumFractionDigits={2}
              />
            </span>
            <span className="percent">
              {" " + RIGHT_ARROW_ICON + " "}
              {row.expenseincome <= row.amount ? (
                Math.round((row.expenseincome / row.amount) * 100) + "%"
              ) : row.expenseincome > row.amount ? (
                <span className="nowrap">
                  <FormattedNumber
                    value={getOverAmount(row)}
                    minimumFractionDigits={2}
                    maximumFractionDigits={2}
                  />{" "}
                  %
                </span>
              ) : (
                "0%"
              )}
            </span>
          </td>
          <td className="right-align">
            <span>
              <Trans>{row.overText}</Trans>
            </span>
            :&nbsp;
            <span
              className="nowrap mobile-right"
              style={{ color: row.overcolor }}
            >
              <FormattedNumber
                value={Math.abs(
                  (row.amount || 0) - intNVL(row.expenseincome || 0)
                )}
                style="currency"
                currency={currencyDetails.currencyCode || "USD"}
                minimumFractionDigits={2}
                maximumFractionDigits={2}
              />
            </span>
          </td>
        </tr>
      </tbody>
    </table>
  </CollectionItem>
);

class Budget extends Component {
  constructor(props) {
    super(props);
    this.setActiveTab = this.setActiveTab.bind(this);
    const date = new Date();
    const year = date.getFullYear();
    const month = date.getMonth();
    const yearArr = [];
    const monthArr = LONG_MONTHS.map((name, id) => ({ id, name }));
    let categories = [];
    for (let i = 0; i < 12; i++) {
      let y = year + (i - 3);
      yearArr.push({ id: y, name: y });
    }
    this.state = {
      year,
      month,
      monthArr,
      yearArr,
      currentActiveTab: window.location.hash.replace("#", "") || "Monthly",
      currentMonthTransactions: [],
      currentTransaction: {},
      currentDayTransactions: [],
      isOnSingleTransaction: false,
      categories,
      editMonthlyData: "",
      categoryData: [],
      userSettings: JSON.parse(localStorage.getItem("profileDetails")) || {},
      allUserCategory: JSON.parse(localStorage.getItem("userCategories")) || [],
      viewMode: false,
      viewData: {},
      yearLabelValue: new Date().getFullYear(),
    };
  }

  componentDidMount() {
    this.props.getMonthlyBudgetList({ year: this.state.year });
    this.props.getCategoryBudgetList({
      month: this.state.month,
      year: this.state.year,
    });
  }

  getAlertBox = (message, type, timer) => {
    const { t } = this.props;
    Swal.fire({
      title: t(message),
      position: "bottom-start",
      toast: true,
      showConfirmButton: false,
      timer: timer || 2000,
      background: type === "success" ? "#8dc44e" : "#f1521c",
      customClass: {
        title: "sweet-alert",
        container: "sweet-alert-container",
      },
    });
  };

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.budget &&
      !nextProps.budget.BUDGET_CATEGORY_DATA_LOADING &&
      nextProps.budget.BUDGET_DATA_ACTION
    ) {
      if (nextProps.budget.BUDGET_DATA_ACTION === "UPDATE_BUDGET") {
        this.getAlertBox("BUDGET_UPDATED", "success", 15000);
      } else if (nextProps.budget.BUDGET_DATA_ACTION === "ADD_BUDGET") {
        this.getAlertBox("BUDGET_ADDED", "success", 15000);
      } else if (nextProps.budget.BUDGET_DATA_ACTION === "DELETE_BUDGET") {
        this.getAlertBox("ENTERY_DELETED", "success");
      }
    }
    if (nextProps.budget && !nextProps.budget.BUDGET_CATEGORY_DATA_LOADING) {
      if (nextProps.budget.error) {
        this.getAlertBox(nextProps.budget.error.message, "error");
      }
      let categoryData = [];
      const mapCategory = {};
      this.state.allUserCategory.forEach((category) => {
        mapCategory[category.type + ":" + category.categoryId] = category;
      });

      categoryData = nextProps.budget.categoryData.map((selectedCategory) => {
        if(selectedCategory.budgetType == undefined){
          selectedCategory.budgetType = 1;
        }
        const category =
          mapCategory[
            selectedCategory.budgetType + ":" + selectedCategory.categoryId
          ] || {};
        //
        // need to add carry forwed in amount
        // need to add flag in add budget popup Literal should be refered from Lang local (Start each new month with the previous month's leftover amount)
        const cate = {
          name: category.name,
          description: category.description,
          iconUrl: category.iconUrl,
          iconColor: category.iconColor,
          iconBackground: category.iconBackground,
          expenseDisplayOrder: category.expenseDisplayOrder,
          disableHiddenFlag: category.isHidden || false,
          disableDeletedFlag: category.isDeleted || false,
          ...selectedCategory,
        };
        this.getBarColor(cate);
        return cate;
      });

      this.setState({
        categoryData: categoryData.filter(category => category.status === 0),
      });
      if (this.state.viewData && this.state.viewData._id) {
        if (this.state.currentActiveTab !== "Monthly") {
          const d =
            this.state.categoryData.find(
              (data) => data._id === this.state.viewData._id
            ) || {};
          this.setViewData(d);
        } else {
          nextProps.budget.monthlyData.some((data) => {
            if (data._id === this.state.viewData._id) {
              this.setViewData(data);
              return true;
            }
            return false;
          });
        }
      }
    }
  }

  onYearChange = (year) => {
    this.props.getMonthlyBudgetList({ year });
    this.props.getCategoryBudgetList({
      month: this.state.month,
      year: year,
    });
    this.setState({
      year,
    });
  };

  onMonthChange = (month) => {
    let years = this.state.year;
    if (month < 0) {
      month = 11;
      this.setState({
        yearLabelValue: this.state.yearLabelValue - 1,
        year: this.state.year - 1,
      });
      years -= 1;
    }
    if (month > 11) {
      month = 0;
      this.setState({
        yearLabelValue: this.state.yearLabelValue + 1,
        year: this.state.year + 1,
      });
      years += 1;
    }
    this.setState({
      month,
    });
    this.props.getCategoryBudgetList({
      month,
      year: years,
    });
    this.props.getMonthlyBudgetList({ year: years });
  };

  addUpdateBudget = (data) => {
    if (this.state.currentActiveTab === "Monthly") {
      this.props.addUpdateBudget(data, this.state.year);
    } else {
      let dataObj = {
        amount: data.amount,
        date: moment(new Date(this.state.year, this.state.month, 5)).utc(),
        categoryId: data.categoryId,
        type: data.type,
      };
      if (data._id) {
        dataObj = {
          categoryId: data.categoryId,
          amount: data.amount,
          _id: data._id,
          date: new Date(),
        };
      }
      dataObj.selectedMonth = this.state.month;
      dataObj.selectedYear = this.state.year;
      dataObj.type =  data.viewData ? data.viewData.budgetType  : data.type;
      dataObj.carryForward = data.carryForward;
      dataObj.familyShare = data.familyShare;
      dataObj.editFuture = data.editFuture;
      this.props.addUpdateCategoryBudget(dataObj);
    }
  };

  onDelete = (stateDataObj) => {
    let stateData = { ...stateDataObj };
    stateData.selectedMonth = this.state.month;
    stateData.selectedYear = this.state.year;
    const { t } = this.props;

    if (this.state.viewData.recurringCategoryId != 0) {
      Swal.fire({
        title: t("DELETE"),
        text: t("BILL_DELETE_MESSAGE_1"),
        type: "warning",
        showCancelButton: !this.state.viewData.carryForward,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#b8b8b8",
        confirmButtonText: t("EDIT_ALL_FUTURE"),
        cancelButtonText: t("DELETE_THIS"),
      }).then((result) => {
        if (result.dismiss === "backdrop") {
          return;
        }
        if (result.value) {
          this.processToDelete(stateData, true);
        } else {
          this.processToDelete(stateData, false);
        }
      });
    } else {
      Swal.fire({
        title: t("DELETE"),
      text: t("BILL_DELETE_MESSAGE_1"),
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#b8b8b8",
      confirmButtonText: t("DELETE"),
      cancelButtonText: t("CANCEL"),
      }).then((result) => {
        if (result.value) {
          this.processToDelete(stateData, true);
        }
      });
    }
  };

  processToDelete(stateData, deleteFuture) {
    if (this.state.currentActiveTab === "Monthly") {
      let transactions = {
        deleteFuture,
        transactions: [
          {
            _id: stateData.monthId,
          },
        ],
        yearMonth: {
          year: this.state.year,
          month: stateData.month,
        },
      };
      this.props.deleteTransaction(transactions);
    } else {
      this.props.deleteCategoryTransaction({ ...stateData, deleteFuture });
    }
    this.setState({
      viewData: {},
      viewMode: false,
    });
  }

 

  setActiveTab = (tab) => {
    window.location = "#" + tab.id;
    this.setState({
      currentActiveTab: tab.id,
      viewData: {},
      viewMode: false,
    });
  };

  setModalHeader(time) {
    const dateObj = new Date(time);
    const { t } = this.props;
    return t(LONG_MONTHS[dateObj.getMonth()]) + " " + dateObj.getFullYear();
  }

  viewDataMode(data) {
    this.setState({
      viewData: data,
      viewMode: true,
      dailyClick: true,
    });
  }

  setViewData(data) {
    this.setState({
      viewData: data,
    });
  }

  getBarColor(row) {
    if (row.budgetType === BUDGET_TYPE.INCOME) {
      row.income = row.income || 0;
      row.amount = row.amount || 0;
      var calcp =
        row.income === 0 || row.amount === 0
          ? 0
          : Math.round((row.income / row.amount) * 100);
      row.calcp = calcp;
      row.expenseincome = row.income;
      row.typeKey = "RECEIVED";
      row.overText = "LEFT";
      row.overcolor = "";
      row.barcolor = "";
      row.barwidth = calcp;
      if (calcp >= 100) {
        // 'green'
        row.barcolor = "#8ec44e";
        row.barwidth = 100;
        row.overcolor = "green";
        row.overText = "OVER";
        return;
      }
      if(calcp > 0) {
      //yellow
      row.overcolor = "";
      row.barcolor = "#fdff8e";
      }
    } else {
      row.typeKey = "SPENT";
      row.expense = row.expense || 0;
      row.amount = row.amount || 0;
      row.expenseincome = row.expense;
      var aletp = row.alertPercentage || 100;
      var calcp =
        row.expense === 0 || row.amount === 0
          ? 0
          : Math.round((row.expense / row.amount) * 100);
      row.calcp = calcp;
      row.barwidth = calcp;
      if (calcp > 100) {
        // 'red'
        row.barcolor = "#ff4769";
        row.barwidth = 100;
        row.overcolor = "red";
        row.overText = "OVER";
        return;
      }
      if (calcp > aletp) {
        // yellow
        //row.overcolor = "yellow";
        row.barcolor = "#fdff8e";
        row.overText = "LEFT";
        return;
      }
      row.overText = "LEFT";
      // green
      row.overcolor = "green";
      row.barcolor = "#8ec44e";
      return "";
    }
  }

  removeDetailDropdown = () => {
    this.setState({
      dailyClick: false,
    });
  };

  canEdit() {
    
    var min = moment(moment().format('YYYY-MM'));
    var max = moment(moment().add('2', 'M').format('YYYY-MM'))
    var date = new Date();
    date.setFullYear(this.state.year);
    date.setMonth(this.state.month);
    var now = moment(date.toISOString());
  return now.isBetween(min, max);
  }

  render() {
    const {
      year,
      userSettings: { currencyDetails },
      month,
    } = this.state;
    let {
      budget: {
        monthlyData = [],
        BUDGET_MONTHLY_DATA_LOADING = false,
        BUDGET_CATEGORY_DATA_LOADING = false,
      },
      t,
    } = this.props;
    monthlyData = monthlyData.map((selectExpesnse) => {
      selectExpesnse = { ...selectExpesnse };
      this.getBarColor(selectExpesnse);
      return selectExpesnse;
    });
   
    return (
      <div>
        <div>
          <Row className="mb-0 mobile-view">
            <Col s={12} m={12} l={12} style={{ lineHeight: "30px" }}>
              &nbsp;
            </Col>
          </Row>
          <Row className="mb-0">
            <Col s={12} m={12} l={6} style={{ position: "relative" }}>
              <div className="overlap-tab-filter">
                {this.state.currentActiveTab === "Monthly" ? (
                  <YearSelector
                    value={year}
                    onChange={this.onYearChange}
                    style={{ paddingTop: "13px" }}
                  />
                ) : (
                  <MonthSelector
                    t={t}
                    yearValue={this.state.yearLabelValue}
                    value={month}
                    disableNext={true}
                    onChange={this.onMonthChange}
                    style={{ paddingTop: "13px" }}
                  />
                )}
              </div>
              <Col s={12} m={12} l={12} className="tab-mobile-fixed">
                <Tabs
                  reciveProps={true}
                  className="tab-demo z-depth-1"
                  activeTab={{
                    id: this.state.currentActiveTab,
                  }}
                  onClick={this.setActiveTab}
                >
                  <Tabs.Tab
                    title={
                      <div className="tab-title" title={t("MONTHLY")}>
                        <Trans>MONTHLY</Trans>
                      </div>
                    }
                    id="Monthly"
                  >
                    <Collection className="fix-height-tab">
                      {monthlyData.length ? (
                        sortArrayDesc(monthlyData, "month").map((month, i) => (
                          <BudgetItem
                            key={i}
                            userSettings={this.state.userSettings}
                            auth={this.props.auth}
                            isMonthRow={
                              this.state.currentActiveTab === "Monthly"
                            }
                            currencyDetails={currencyDetails}
                            viewData={this.state.viewData}
                            row={month}
                            rowClick={(row) => this.viewDataMode(row)}
                          />
                        ))
                      ) : (
                        <CollectionItem>
                          <div className="date-label center-align grey-text">
                            <Trans>NO_DATA_FOUND</Trans>
                          </div>
                        </CollectionItem>
                      )}
                    </Collection>
                  </Tabs.Tab>
                  <Tabs.Tab
                    title={
                      <div className="tab-title" title={t("CATEGORY")}>
                        <Trans>CATEGORY</Trans>
                      </div>
                    }
                    id="Category"
                  >
                    <Collection className="fix-height-tab">
                      {this.state.categoryData.length >0 ? (
                        sortArray(this.state.categoryData, "amount").map(
                          (category, i) => (
                            <BudgetItem
                              key={i}
                              auth={this.props.auth}
                              userSettings={this.state.userSettings}
                              currencyDetails={currencyDetails}
                              viewData={this.state.viewData}
                              row={category}
                              rowClick={(row) => this.viewDataMode(row)}
                            />
                          )
                        )
                      ) : (
                        <CollectionItem>
                          <div className="date-label center-align grey-text">
                            <Trans>NO_DATA_FOUND</Trans>
                          </div>
                        </CollectionItem>
                      )}
                    </Collection>
                  </Tabs.Tab>
                </Tabs>
              </Col>

              {/* <div className="add-budget-icon budget-modal">
                <Modal
                  header={
                    t("ADD") +
                    t(this.state.currentActiveTab.toUpperCase()) +
                    " " +
                    t("BUDGET")
                  }
                  id="addModel"
                  trigger={
                    this.state.currentActiveTab === "Monthly"  || this.canEdit() ? <Button
                      floating
                      large
                      className="blue"
                      waves="light"
                      icon="add"
                    /> : null
                    
                  }
                  actions={
                    <div>
                      <Button flat modal="close" waves="light">
                        <Trans>CANCEL</Trans>
                      </Button>
                    </div>
                  }
                >
                  <AddBudget
                    year ={this.state.year}
                    month ={this.state.month}
                    key={month._id}
                    activeTab={this.state.currentActiveTab}
                    transaction={{}}
                    onSubmit={this.addUpdateBudget}
                  />
                </Modal>
              </div> */}
            </Col>
            <Col s={12} m={12} l={6} style={{ position: "relative" }}>
              <div
                className={`mobile-no-view ${
                  this.state.dailyClick ? "detailView" : ""
                }`}
              >
                <div className="budget-section">
                  <div className="col s12">
                    <ul className="tabs z-depth-1 detail-tab">
                      <li className="tab col">
                        <div
                          className="back-button"
                          onClick={this.removeDetailDropdown}
                        >
                          <i className="material-icons"> arrow_back </i>{" "}
                          <span>Budget</span>
                          <a>
                            <Trans>DETAILS</Trans>
                          </a>
                        </div>
                        <a className="mobile-display-none">
                          <Trans>DETAILS</Trans>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="budget-detail">
                  <Collection>
                    {this.state.viewMode ? (
                      <CollectionItem>
                        {this.state.currentActiveTab === "Monthly" ? (
                          <MonthlyBudgetDetail
                            {...this.state}
                            onDelete={this.onDelete}
                            auth={this.props.auth}
                            onSubmit={this.addUpdateBudget}
                            viewData={this.state.viewData}
                          />
                        ) : (
                          <CategoryBudgetDetail
                            {...this.state}
                            onDelete={this.onDelete}
                            auth={this.props.auth}
                            onSubmit={this.addUpdateBudget}
                            viewData={this.state.viewData}
                          />
                        )}
                      </CollectionItem>
                    ) : (
                      <CollectionItem>
                        <div className="budget-date-label center-align grey-text">
                          <Trans>BUDGET_TO_SEE_DETAIL</Trans>
                        </div>
                      </CollectionItem>
                    )}
                  </Collection>
                </div>
              </div>
            </Col>
          </Row>
        </div>
        <Loader
          condition={
            BUDGET_MONTHLY_DATA_LOADING || BUDGET_CATEGORY_DATA_LOADING
          }
        />
      </div>
    );
  }
}

Budget.propTypes = {
  getCategoryBudgetList: PropTypes.func.isRequired,
  getMonthlyBudgetList: PropTypes.func.isRequired,
  addUpdateBudget: PropTypes.func.isRequired,
  addUpdateCategoryBudget: PropTypes.func.isRequired,
  deleteTransaction: PropTypes.func.isRequired,
  deleteCategoryTransaction: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  state = {...state}
  if(state.budget) {
    state.budget = {...state.budget}
  }
  
  if (state.budget && state.budget.monthlyData) {
    state.budget.monthlyData = state.budget.monthlyData.filter((e) => +e.amount > 0);
    state.budget.monthlyData = state.budget.monthlyData.map((selectExpesnse) => {
      selectExpesnse = {...selectExpesnse}
      selectExpesnse.carryForwardAmtAdded = selectExpesnse.carryForward === true &&
      selectExpesnse.carryForwardAmount != 0;
      selectExpesnse.budgetAmt = selectExpesnse.amount;
      selectExpesnse.carryForwardAmount =
      (selectExpesnse.carryForward === true
        ? selectExpesnse.carryForwardAmount
        : 0) || 0;
        selectExpesnse.amount =
        selectExpesnse.carryForward === true
        ? selectExpesnse.amount + (selectExpesnse.carryForwardAmount || 0)
        : selectExpesnse.amount;
        

          return selectExpesnse;
    });
  }
  
  if (state.budget && state.budget.categoryData) {
    state.budget.categoryData = state.budget.categoryData.filter((e) => +e.amount > 0);
    state.budget.categoryData = state.budget.categoryData.map((selectedCategory) => {
      selectedCategory = {...selectedCategory}
      selectedCategory.carryForwardAmtAdded = selectedCategory.carryForward === true &&
      selectedCategory.carryForwardAmount != 0;
      selectedCategory.budgetAmt = selectedCategory.amount;
      if (selectedCategory.type !== BUDGET_TYPE.INCOME) {
        selectedCategory.carryForwardAmount =
          (selectedCategory.carryForward === true
            ? selectedCategory.carryForwardAmount
            : 0) || 0;
        selectedCategory.amount =
          selectedCategory.carryForward === true
            ? selectedCategory.amount +
              (selectedCategory.carryForwardAmount || 0)
            : selectedCategory.amount;
      } else {
        selectedCategory.amount = selectedCategory.amount;
        selectedCategory.carryForwardAmount = 0;
        selectedCategory.carryForward = false;
      }
      return selectedCategory;
    });
  }
  return state;
};

export default withTranslation()(
  connect(mapStateToProps, {
    getCategoryBudgetList,
    getMonthlyBudgetList,
    addUpdateBudget,
    addUpdateCategoryBudget,
    deleteTransaction,
    deleteCategoryTransaction,
  })(withRouter(Budget))
);
