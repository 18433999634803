import React, {useState, useEffect} from 'react';
import {FormattedNumber} from "react-intl";
import {getAccountDetailUI, getDateTimeFormat, getLongDay, getShortDate, isCreator,isOfflineAccount} from "./common/Methods";
import {unescape} from 'lodash';
import {Trans} from "react-i18next";
import Loader from "./Loader";
import {CAT_TYPE, CAT_TYPE_TEXT, iconUrl} from "../actions/config";
import ExpenseForm from './AddEditExpense';
import IncomeForm from './AddEditIncome';
import {getIncomeExpenseImage} from "../actions/authActions";
import {
    Button,
    Modal
} from 'react-materialize';
import * as Swal from "sweetalert2";
import {getRecurringIncomeTrx, uploadMedia} from "../actions/accountActions";

export default (props) => {
    const {currentTransaction, t} = props;
    let transeditstart;
    const { currencyDetails } = JSON.parse(localStorage.getItem('profileDetails')) || {};
    const [imageData, setImageData] = useState(null);
    const [imageLoader, setImageLoader] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [lastDate, setLastDate] = useState(null);
    const date = new Date();
    const year = date.getFullYear();
    const [openIncomeModel, showIncomeModel] = useState(false);
    const [editSingleRecord, setEditSingleRecord] = useState(true);
    const [recurringTrxData, setRecurringTrxData] = useState({});
    useEffect(() => {
        showIncomeModel(false);
        if(currentTransaction.image) {
            setImageLoader(true);
            getIncomeExpenseImage(currentTransaction.image, (res, status = true) => {
                if(status === true) {
                    setImageData(res);
                }else {
                    getAlertBox((res || 'NO_IMAGE'), 'error');
                }
                setImageLoader(false);
            }, currentTransaction.userId,);
        }
    }, [currentTransaction]);

    const getAlertBox = (message, type) => {
        const t = props.t;
        Swal.fire({
            title:t(message),
            position: 'bottom-start',
            toast: true,
            showConfirmButton: false,
            timer: 2000,
            background: type==='success' ? '#8dc44e' : '#f1521c',
            customClass: {
                title:'sweet-alert',
                container: 'sweet-alert-container'
            }
        });
    };

    const addMedia = (imgObj, imgName) => {
        uploadMedia(imgObj, imgName, (data, res) => {
            if(res) {
                getAlertBox('IMAGE_UPLOADED', 'success');
            }else {
                console.log('==err=');
            }
        });
    };

    const addUpdateExpenseIncome = (trxData) => {
        const incomeDataObj = {...currentTransaction, ...trxData};
        if(editSingleRecord) {
            delete incomeDataObj.recurringCategoryId;
            delete incomeDataObj.recurringCount;
            delete incomeDataObj.repeatTillCount;
            delete incomeDataObj.repeatTillDate;
            delete incomeDataObj.recurringServerId;
        }
        props.onSubmit(incomeDataObj);
        showIncomeModel(false);
    };

    const getRecurringObj = (singleRecord = true) => {
        if (currentTransaction.recurringId || currentTransaction.recurringServerId || currentTransaction.recurringIdLong) {
            let recurringObj = {};
            if(currentTransaction.recurringIdLong) {
                recurringObj = {
                    recurringIdLong: currentTransaction.recurringIdLong
                }
            }else if(currentTransaction.recurringServerId) {
                recurringObj = {
                    recurringServerId: currentTransaction.recurringServerId
                }
            }else if(currentTransaction.recurringId) {
                recurringObj = {
                    recurringId: currentTransaction.recurringId,
                    type: 2,
                    deviceId: currentTransaction.deviceId
                }
            }
            getRecurringIncomeTrx(recurringObj, (recurringServerData, success = true) => {
                if(success) {
                    setRecurringTrxData(recurringServerData);
                }
            }, singleRecord);
        }
    };

    const setIncomeModel = async () => {
        await showIncomeModel(false);
        setLastDate(currentTransaction.time) 
        if(currentTransaction.recurringServerId || currentTransaction.recurringIdLong || currentTransaction.recurringId ) {
            Swal.fire({
                title: t('EDIT'),
                text: t('EDIT_INCOME_MESSAGE'),
                type: 'question',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#3085d6',
                confirmButtonText: t('EDIT_ALL_FUTURE'),
                cancelButtonText: t('EDIT_THIS')
            }).then(async (result) => {
                if (result.value) {
                    getRecurringObj(false);
                    setEditSingleRecord(false);
                    await showIncomeModel(true);
                    const divData = window.document.getElementById('addIncomeModel');
                    divData.scrollTop = 0;
                }else if(result.dismiss === 'cancel'){
                    getRecurringObj(true);
                    setEditSingleRecord(true);
                    await showIncomeModel(true);
                    const divData = window.document.getElementById('addIncomeModel');
                    divData.scrollTop = 0;
                }

            });
        }else {
            getRecurringObj(true);
            setEditSingleRecord(true);
            await showIncomeModel(true);
            const divData = window.document.getElementById('addIncomeModel');
            divData.scrollTop = 0;
        }

    };

    const showImage = () => {
        Swal.fire({
            html: '<img class="img-preview" src="' + imageData + '" alt="no image" />',
            showCloseButton: true,
            showCancelButton: false,
            showConfirmButton: false,
            focusConfirm: true,
            customClass: {
                title: 'sweet-alert',
                container: 'img-preview'
            }
        })
    };


    return (
        <div>
            <table
                className='no-padding'>
                <tbody>
                <tr>
                    <td colSpan="3">&nbsp;</td>
                </tr>
                <tr style={{borderBottom: '1px solid #e5e6ea'}}>
                    <td width='7%' style={{verticalAlign: 'top'}}>
                        <img
                            src={iconUrl + currentTransaction.category.iconUrl + '.png'}
                            alt='icon'
                            className='leftMargin10 circle'
                            style={{backgroundColor: currentTransaction.category.iconColor, height: '54px'}}
                        />
                    </td>
                    <td colSpan='2'>
                        <div className='date-label'>
                            {unescape(currentTransaction.category.name)}
                        </div>
                        <div>{currentTransaction.notes}</div>
                    </td>
                </tr>
                <tr>
                    <td style={{borderBottom: '1px solid #e5e6ea'}} />
                    <td style={{padding: '15px', borderBottom: '1px solid #e5e6ea', paddingLeft: '0'}}>
                        <div style={{borderRight: '1px solid #e5e6ea', padding: '10px', paddingLeft: '0'}}>
                            <div style={{fontSize: 'x-large', fontWeight: 'bold'}}>
                                <span className="nowrap">
                                    <FormattedNumber
                                        value = {currentTransaction.amount}
                                        style= 'currency'
                                        currency= {currencyDetails.currencyCode || 'USD'}
                                        minimumFractionDigits= {2}
                                        maximumFractionDigits= {2}
                                    />
                                </span>
                            </div>
                            <div><Trans>{CAT_TYPE[currentTransaction.type + (currentTransaction.isTransfer ? 't' : '')]}</Trans></div>
                        </div>
                    </td>
                    <td style={{borderBottom: '1px solid #e5e6ea'}}>
                        <div style={{fontSize: 'x-large', fontWeight: 'bold'}}>
                            {`${t(getShortDate(currentTransaction.time, t))}, ${year}`}
                        </div>
                        <div>
                            {t(getLongDay(currentTransaction.time))}
                        </div>
                    </td>
                </tr>
                {
                    currentTransaction && currentTransaction.accountId ?
                        <tr style={{padding: '15px', borderBottom: '1px solid #e5e6ea', paddingLeft: '0'}}>
                            <td colSpan="3">
                                {
                                    getAccountDetailUI(props.auth, currentTransaction.accountId, currentTransaction.type, (props.currentTransaction || {}).createdUserId)
                                }
                            </td>
                        </tr> : null
                }
                {
                    currentTransaction && currentTransaction.image ?
                        <tr>
                            <td />
                            <td style={{
                                'padding': '15px 15px 15px 0px',
                                'borderBottom': '1px solid rgb(229, 230, 234)'
                            }}>
                                <div className="expense-image">
                                    <Loader condition={imageLoader}/>
                                    {
                                        currentTransaction && currentTransaction.image && imageData ?
                                            <img style={{width: '35%'}} src={imageData} alt={t('NO_IMAGE')} onClick={showImage}/>
                                            : null
                                    }
                                </div>
                            </td>
                            <td style={{
                                'padding': '15px 15px 15px 0px',
                                'borderBottom': '1px solid rgb(229, 230, 234)'
                            }} />
                        </tr> : null }

                {props.userSettings && isCreator(currentTransaction.createdUserId, props.userSettings.userId) ? <tr>
                    <td />
                    <td style={{
                        'padding': '15px 15px 15px 0px',
                        'borderBottom': '1px solid rgb(229, 230, 234)'
                    }}>
                        {/* <div className='add-icon'>
                            {
                                ( isOfflineAccount(currentTransaction.selectedAccount) &&  (currentTransaction.type === CAT_TYPE_TEXT['EXPENSE'])) ?
                                (currentTransaction.isTransfer ? 
                                (
                                    <button
                                        onClick={() => {
                                            Swal.fire({
                                                title: t('ALERT'),
                                                text: t("EDIT_TRANSFER"),
                                            });
                                        }}
                                        className="btn waves-effect waves-light light-blue accent-4 p-2"
                                        type="button"
                                        name="action"
                                    >
                                        Edit
                                        <i className="material-icons right">edit</i>
                                    </button>
                                ) : 
                                    <Modal
                                        header={t("EDIT_EXPENSE")}
                                        trigger={<div><button
                                            onClick={e=>{setEditMode(false); setTimeout(e=> setEditMode(true), 100);}}
                                            className="btn waves-effect waves-light light-blue accent-4 p-2"
                                            type="button" name="action">Edit
                                            <i className="material-icons right">edit</i>
                                        </button></div>}>
                                           {editMode ? <ExpenseForm transaction={currentTransaction}
                                                         addMedia={addMedia}
                                                         onSubmit={addUpdateExpenseIncome}
                                                         expenseData={{
                                                             ...currentTransaction,
                                                             imageFile: imageData
                                                         }}/> : null }
                                    </Modal>
                                ) : null }
                            {
                               ( isOfflineAccount(currentTransaction.selectedAccount) && (currentTransaction.type === CAT_TYPE_TEXT['INCOME'] ) )?
                                    <button
                                        className="btn waves-effect waves-light light-blue accent-4 p-2"
                                        type="button" name="action" onClick={setIncomeModel}>
                                        <Trans>EDIT</Trans>
                                        <i className="material-icons right">edit</i>
                                    </button> : null       
                            }
                        </div> */}
                    </td>
                    {/* <td  style={{
                        'padding': '15px 15px 15px 0px',
                        'borderBottom': '1px solid rgb(229, 230, 234)'
                    }}>
                        {
                            isOfflineAccount(currentTransaction.selectedAccount )?
                            <button style={{display: 'flex'}}
                            className="btn waves-effect waves-light red lighten-2 accent-4"
                            onClick={props.onDeleteTransaction}
                            type="button" name="action">
                            <Trans>DELETE</Trans>
                            <i className="material-icons right">delete</i>
                            </button> :null
                         }  
                    </td> */}
                </tr>: <tr>
                            <td>{props.auth && props.auth.groupImageList && props.auth.groupImageList[currentTransaction.createdUserId] ? <img style={{width: '40px',
                                height: '40px',
                                borderRadius: '100%',
                                marginTop: '8px',
                                marginRight: '2px',
                                paddingLeft: '0'}} className="zero-margin height-54" src={props.auth.groupImageList[currentTransaction.createdUserId]}/>:<h5 className='zero-margin-detail'>{JSON.parse(localStorage.getItem(currentTransaction.createdUserId)).ownerDetail.slice(0,1).toUpperCase()}</h5>}</td>
                            <td style={{
                                padding: '15px',
                                borderBottom: '1px solid #e5e6ea',
                                paddingLeft: '0'
                            }}>
                                <div className="multi-user-creator">
                                    {JSON.parse(localStorage.getItem(currentTransaction.createdUserId)).ownerDetail}
                                </div>
                                <div className='grey-text'><Trans>CREATED_BY</Trans></div>
                            </td>
                        </tr>
                }
                <tr>
                    <td></td>
                    <td className='grey-text'>
                        <Trans>UPDATED</Trans>: {getDateTimeFormat(currentTransaction.updated_at)}
                    </td>
                </tr>
                </tbody>
            </table>
            {
                currentTransaction.type === CAT_TYPE_TEXT['INCOME'] ?
                    <Modal
                        header={t('EDIT') + ' ' + t('INCOME')}
                        id="addIncomeModel"
                        open={openIncomeModel}
                        actions={
                            <div>
                                <Button flat modal="close" waves="light" onClick={() => showIncomeModel(false)}>
                                    <Trans>CANCEL</Trans>
                                </Button>
                            </div>
                        }>
                        <IncomeForm addNewIncome={false}
                                    addMedia={addMedia}
                                    onSubmit={addUpdateExpenseIncome}
                                    disableRecurrence={editSingleRecord}
                                    incomeData={{
                                        ...currentTransaction,
                                        ...recurringTrxData,
                                        imageFile: imageData
                                    }}
                                    lastDate={lastDate}/>
                    </Modal> : null
            }
        </div>
    )
}