import React, { Component } from 'react';
import Navbar from "./Navbar";
import { iconUrl, umediaUrl} from "../actions/config";
import '../stylesheets/App.css';
import { withTranslation, Trans } from 'react-i18next';
import {
    Button,
    Preloader,
} from 'react-materialize';
import { connect } from 'react-redux';
import {
    fetchUserProfileDetails,
    deleteUserAccount,
    validateEmailAndSendOtp,
    sendDeletionOtp
} from "../actions/settingsActions";
import {
    logoutUserWithoutNavigate
} from "../actions/authActions";
import Swal from 'sweetalert2';
import setAuthToken from '../utils/setAuthToken';
import { LOGOUT_USER } from '../actions/types';
import i18n from '../local/i18n';
import { t } from 'i18next';


class DeleteUserProfile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            showSuccessConfirmation: false,
            email: '',
            isMounted: false,
            otp: '',
        };
    }

    componentDidMount() {
        this.setState({ isMounted: true });
    }
    
    componentWillUnmount() {
        this.setState({ isMounted: false });
    }

    resendOtp = () => {
        this.props.sendDeletionOtp(this.state.email);
    };

    handleDeleteUser = () => {
        try{
            const email = this.state.email; 
            const enteredOTP = this.state.otp;
            const t = this.props.t;
            this.setState({
                updateLoading: true
            });
            deleteUserAccount(enteredOTP, email)
            .then(() => {
                this.setState({
                    updateLoading: false,
                    showSuccessConfirmation:true
                });
            })
            .catch((error) => {
                this.setState({
                    updateLoading: false, 
                    gotoNextPage: false     
                });
                if(error.data.code == 339){
                    this.getAlertBox(('USER_ALREADY_DELETED'), 'error');
                }else{
                    this.getAlertBox(('INVALID_OTP'), 'error');
                }
                console.log(error);   
            });
        }catch(error){
            console.error(error);
        }
    }

    getAlertBox = (message, type) => {
        const t = this.props.t;
        Swal.fire({
            title: t(message),
            position: 'bottom-start',
            toast: true,
            showConfirmButton: false,
            timer: 5000,
            background: type === 'success' ? '#8dc44e' : '#f1521c',
            customClass: {
                title: 'sweet-alert',
                container: 'sweet-alert-container'
            }
        });
    };

    handleNextClick = () => {
        if (this.state.email.trim() !== '') {
            validateEmailAndSendOtp(this.state.email)
            .then(() => {
                this.setState({
                    gotoNextPage: true,
                });
                this.getAlertBox('OTP_SENT', 'success');
            }).catch((error) => {
                console.log(error);
                if (error.data.code === 340) {
                    this.getAlertBox('ACCOUNT_NOT_EXISTS', 'error');
                } else {
                    this.getAlertBox('INVALID_EMAIL', 'error');
                }
            });
        } else {
            this.getAlertBox('INVALID_EMAIL', 'error');
        }
    };
    

    handleChange = (e) => {
        this.setState({ email: e.target.value });
    }

    handleOtpChange = (e) => {
        this.setState({ otp: e.target.value });
    }

    handleCancel = () => {
        this.setState({
            gotoNextPage: false,
            otp:'',
            email:''
        });
    }

    render() {
        const {t} = this.props;
        if (this.state.showSuccessConfirmation) {
            this.props.logoutUserWithoutNavigate();
            return (
                // UI for deletion successful confirmation
                <div className="confirmation-message center-align" style={{ backgroundImage: `url('https://www.timelybills.app/images/bg-min.jpg')`, backgroundSize: 'cover', height: '100vh', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                    <h3 style={{ color: '#000000', fontSize: '2rem' }}>{t('DELETION_SUCCESSFUL')}</h3>
                </div>
            );
        }
        return (
            <>
            {this.state.loading ? (
                // Loading Spinner
                <div className="confirmation-message center-align" style={{ backgroundImage: `url('https://www.timelybills.app/images/bg-min.jpg')`, backgroundSize: 'cover', height: '100vh', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                <Preloader flashing size='big' className='center-block'/>
                </div>
               
            ) : (
            <>
                <div>
                    <div className="navbar-fixed">
                        <nav className="nav-extended blueback2 z-depth-0">
                            <div className="nav-wrapper">
                                <div className="brand-logo">
                                    <img src="https://static.timelybills.app/icons/logo_main.png" alt="logo" style={{ "height": "50px", verticalAlign: "middle" }} />
                                    <span className="logo_text">TimelyBills</span>
                                </div>
                            </div>
                        </nav>
                    </div>
                </div>

                <div className="vertical-center">
                    <div className="row margin-0 sign-in-section">
                        <div className="col l12 m12 s12">
                            <div className="card darken-1" style={{ boxShadow: 'none', border: 'none' }}>

                                <div className="card-content">

                                    <div className="center-align" style={{ marginBottom: '25px' }}>
                                        <img
                                            src="https://static.timelybills.app/icons/logo_main.png"
                                            height="60px"
                                            alt="DefaultUserImage"
                                        />
                                        <span className="card-title">
                                            <h5><Trans>DELETE_PROFILE</Trans></h5>
                                        </span>
                                    </div>
                                    {!this.state.gotoNextPage && (
                                        <>
                                        <div className='mb-2'>
                                            <span style={{ paddingLeft: '10px', fontWeight: 'bold' }}> <Trans>STEPS_TO_DELETE</Trans> </span>
                                            <ul style={{ listStyleType: 'none',paddingLeft: '10px', lineHeight: "2.5",fontSize:"12px" }}>
                                                <li><Trans>DELETE_MESSAGE_LIST1</Trans></li>
                                                <li><Trans>DELETE_MESSAGE_LIST2</Trans></li>
                                                <li><Trans>DELETE_MESSAGE_LIST3</Trans></li>
                                                <li><Trans>DELETE_MESSAGE_LIST4</Trans></li>
                                            </ul>
                                        </div>  
                                    <br />
                                    <div>
                                        <div className='m-2'>
                                            <span style={{ paddingLeft: '10px', fontWeight: 'bold' }}> <Trans>ENTER_EMAIL</Trans></span>
                                        </div>
                                        <div className='m-2'>
                                            <input 
                                                type="email" 
                                                style={{
                                                    height: '25px', 
                                                    padding: '5px', 
                                                    borderRadius: '5px',
                                                    border: '1px solid #ccc'
                                                }} 
                                                onChange={this.handleChange}
                                            />
                                        </div>
                                        <div className='m-2' style={{ textAlign: 'center' }}>
                                            <Button 
                                            onClick={this.handleNextClick} 
                                            className="btn waves-effect waves-light light-blue accent-4">
                                            <Trans>NEXT</Trans>
                                            </Button>
                                        </div>
                                    </div>
                                    </>
                                    )}
                                    {
                                        this.state.gotoNextPage && (
                                            <>
                                            <span style={{ fontWeight: 'bold' }}> <Trans>EMAIL_ID</Trans>{this.state.email}</span> 
                                            <div>
                                                <div className='mb-2'>
                                                    <span style={{ fontWeight: 'bold' }}><Trans>ENTER_OTP</Trans> : </span>
                                                </div>
                                                <div className='mb-2'>
                                                    <input 
                                                        type="number" 
                                                        style={{
                                                            height: '25px', 
                                                            padding: '5px',
                                                            paddingLeft: '10px',
                                                            borderRadius: '5px',
                                                            border: '1px solid #ccc'
                                                        }} 
                                                        onChange={this.handleOtpChange}
                                                    />
                                                </div>
                                                <div style={{ textAlign: 'right', marginTop:'-20px' }} className='mb-2'>
                                                    <span 
                                                        style={{ cursor: 'pointer', color: 'blue', fontSize: '12px'}} 
                                                        onClick={this.resendOtp}
                                                    >
                                                        <Trans>RESEND_OTP</Trans>
                                                    </span>
                                                </div>   
                                            </div> 
                                            <div className='mb-2'>
                                                <span style={{  fontWeight: 'bold' }}> <Trans>DELETE_MESSAGE_</Trans> </span>
                                                <ul style={{ listStyleType: 'none',fontSize:"12px" , lineHeight: "2.5"}}>
                                                    <li><Trans>DELETE_MESSAGE_ALERT1</Trans></li>
                                                    <li><Trans>DELETE_MESSAGE_ALERT2</Trans></li>
                                                </ul>
                                            </div> 
                                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                <Button 
                                                    className="btn waves-effect waves-light light-blue accent-4"
                                                    style={{ width: '45%', marginRight: '5px' }}
                                                    onClick={this.handleCancel}>
                                                    <Trans>BACK</Trans>
                                                </Button>
                                                <Button 
                                                    className="btn waves-effect waves-light light-blue accent-4"
                                                    style={{ width: '45%', marginLeft: '5px' }}
                                                    onClick={this.handleDeleteUser}>
                                                    <Trans>DELETE_NOW</Trans>
                                                </Button>
                                            </div>
                                            </>
                                        )
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
            )}
            </>
        );
    }
}

const mapStateToProps = state => ({
    loading: state.loading,
    showSuccessConfirmation: state.showSuccessConfirmation
});

const mapDispatchToProps = {
    fetchUserProfileDetails,
    sendDeletionOtp,
    logoutUserWithoutNavigate
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(DeleteUserProfile));

