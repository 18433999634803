import React, {Component} from 'react';
import {
    BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend
} from 'recharts';
import {
    Row,
    Col, Card,
    Icon,
    Preloader
} from 'react-materialize';
import {
    SHORT_MONTHS as _months,
} from '../common/ConstData';
import CategorySelector from "../common/CategorySelector";
import _ from 'lodash';

export default class TrendsReport extends Component {
    getChartData(data) {
        const trendsData = [];
        const { t } = this.props;
        data.forEach(d => trendsData[d._id] = d.amount);
        return _months.map((mon, index) => {
                return {
                    expense: trendsData[index] || 0,
                    month: t(mon)
                };
            }
        );
    }

    getCategoryIconColor = (id) => {
        const categories =  _.orderBy(JSON.parse(localStorage.getItem('userCategories')),  ['name'],['asc']);
        const categoryObj = (id)
            ? categories.find(
                ({type, categoryId}) =>
                    (type === 1 && categoryId === id))
            : categories[0];
        return categoryObj.iconColor;
    };

    render () {
        const {
            currencyDetails,
            stats: {
                result
            },
            isLoading,
            t
        } = this.props;
        const data = this.getChartData(result);
        return (
            <div className='padding15 m-t-52'>
                <Card className="hoverable">
                    <Row className='mb-0'>
                        <Col s={12} m={12} l={12} style={{lineHeight: '30px'}}>
                            &nbsp;
                        </Col>
                    </Row>
                    <Row className='mb-1' style={{marginBottom: '50px', color: '#000'}}>
                        <Col s={12} m={6} l={6}>
                            <CategorySelector
                                t={t}
                                onChange={this.props.onCategoryChange}
                                value={this.props.categoryId}
                                type={1}
                            />
                        </Col>
                    </Row>
                    <Row className='mb-0'>
                        <Col s={12} m={12} l={12} className='Trends-svg'>
                            {isLoading ?
                                <Preloader flashing size='big' className='center-block'/> :
                                <BarChart
                                    width={800}
                                    height={500}
                                    data={data}
                                    margin={{
                                        top: 5, right: 30, left: 20, bottom: 5,
                                    }}>
                                    <CartesianGrid strokeDasharray="3 3"/>
                                    <XAxis dataKey="month"/>
                                    <YAxis />
                                    <Tooltip />
                                    <Legend content={<CustomLagend t={t} currencyDetails={currencyDetails.currencySymbol}/>}/>
                                    <Bar dataKey="expense" fill={this.getCategoryIconColor(this.props.categoryId)} label={{position: 'top'}}/>
                                </BarChart>
                            }
                        </Col>
                    </Row>
                </Card>
            </div>
        )
    }
}

export const CustomLagend = ({currencyDetails, t}) => {
    return (
        <div className="trend-legend">
            <p><Icon className="box" />{t('EXPENSE')} {t('IN')} {currencyDetails}</p>
        </div>
    )
}