import React from 'react';
import {
    Row,
    Col,
    Icon
} from 'react-materialize';
import '../stylesheets/Responsive.css';
import {withTranslation, Trans} from 'react-i18next';
import {imageUrl} from "../actions/config";

export default withTranslation()((props) =>{
    return (
        <Col className="vertical-center">
            <Col className="row margin-0">
                <div className="col m1 l1"/>
                <Col className="col l12 m12 s12 ">
                    <Col className="pro-feature">
                        <Row>
                            <Col s={12} m={12} l={12}>
                                <div className="header-div action">
                                    <h4><Icon>block</Icon><Trans>PRO_NEEDED_TITLE</Trans></h4>
                                    <h6><Trans>PRO_NEEDED_SUBTITLE</Trans></h6>
                                </div>
                            </Col>
                        </Row>
                        <Row className="pro-feature-description">
                            <Col s={12} m={12} l={12}>
                                <Col s={12} m={2} l={2} />
                                <Col s={12} m={4} l={4}>
                                    <img src={imageUrl + 'web/pro_web_monthlyreport.png'} alt="pro-feature" />
                                </Col>
                                <Col s={12} m={4} l={4}>
                                    <img src={imageUrl + 'web/pro_web_familysharing.png'} alt="pro-feature" />
                                </Col>
                                <Col s={12} m={2} l={2} />
                            </Col>
                            <Col s={12} m={12} l={12}>
                                <Col s={12} m={2} l={2} />
                                <Col s={12} m={4} l={4}>
                                    <h6><Trans>PRO_NEEDED_SUBTITLE2</Trans></h6>
                                </Col>
                                <Col s={12} m={4} l={4}>
                                    <h6><Trans>PRO_NEEDED_SUBTITLE1</Trans></h6>
                                </Col>
                                <Col s={12} m={2} l={2} />
                            </Col>
                            <Col s={12} m={12} l={12}>
                                <Col s={12} m={2} l={2} />
                                <Col s={12} m={4} l={4}>
                                    <img src={imageUrl + 'web/pro_web_fullwebaccess.png'} alt="pro-feature" />
                                </Col>
                                <Col s={12} m={4} l={4}>
                                    <img src={imageUrl + 'web/pro_web_calendarsync.png'} alt="pro-feature" />
                                </Col>
                                <Col s={12} m={2} l={2} />
                            </Col>
                            <Col s={12} m={12} l={12}>
                                <Col s={12} m={2} l={2} />
                                <Col s={12} m={4} l={4}>
                                    <h6><Trans>PRO_NEEDED_SUBTITLE4</Trans></h6>
                                </Col>
                                <Col s={12} m={4} l={4}>
                                    <h6><Trans>PRO_NEEDED_SUBTITLE3</Trans></h6>
                                </Col>
                                <Col s={12} m={2} l={2} />
                            </Col>
                            <Col s={12} m={12} l={12}>
                                <div className="goto-pro-link">
                                    <a href={'https://www.timelybills.app/'+ props.i18n.language.split('-')[0] +'/pro'} className="btn waves-effect waves-light light-blue accent-4" target="_blank">
                                        <Trans>BTN_PRO_FEATURES</Trans>
                                        <span><i className="material-icons right">expand_more</i></span>
                                    </a>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Col>
            </Col>
        </Col>
    )
});