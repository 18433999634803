import React, {Component} from 'react';
import {withTranslation, Trans} from 'react-i18next';
import {
    Row,
    Col,
    Collection,
    CollectionItem,
    Button,
    Modal, Icon
} from 'react-materialize';
import Tabs from "./common/Tabs";
import {unescape} from 'lodash';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import PropTypes from 'prop-types';
import {FormattedNumber} from "react-intl";
import {
    LONG_MONTHS as _months,
    SHORT_MONTHS
} from './common/ConstData';
import {
    getShortDate,
    smallFontByLanguage,
    getShortDay,
    getLongDay,
    getCategoryNames,
    sortArray,
    getDateTimeFormat,
    getShortDateLongMonth, dayOfYear, getCurrentUTCTime, getAccountDetailUI,isCreator, weekOfYear, getYear
} from './common/Methods';
import YearSelector from './common/YearSelector';
import {
    getExpenses,
    addUpdateExpenses,
    deleteTransaction,
    uploadMedia,
    getMedia
} from "../actions/expenseActions";
import {
    getExpenseByCategory
} from "../actions/dashboardActions";
import {
    getCategories
} from "../actions/authActions";
import Loader from './Loader';
import ExpenseForm from './AddEditExpense';
import Swal from 'sweetalert2';
import moment from "moment";
import ExpenseMonthSelector from "./common/ExpenseMonthSelector";
import {iconUrl} from "../actions/config";
import i18n from "i18next";

class Expenses extends Component {
    constructor(props) {
        super(props);
        this.setActiveTab = this.setActiveTab.bind(this);
        const date = new Date();
        const year = date.getFullYear();
        const month = date.getMonth();
        const yearArr = [];
        const monthArr = _months.map((name, id) => ({id, name}));
        let categories = [];
        for (let i = 0; i < 12; i++) {
            let y = year + (i - 3);
            yearArr.push({id: y, name: y});
        }
        if (!!localStorage.getItem('userCategories')) {
            categories = JSON.parse(localStorage.getItem('userCategories'));
        } else {
            this.props.getCategories();
        }
        this.state = {
            year,
            month,
            monthArr,
            yearArr,
            currentActiveTab: 'monthly',
            currentMonthTransactions: [],
            currentTransaction: {},
            currentDayTransactions: [],
            isOnSingleTransaction: false,
            categories,
            singleTransactionIndex: '',
            monthTotalAmount: 0,
            dailyClick:false,
            contentFitFlag: false,
            userSettings: JSON.parse(localStorage.getItem('profileDetails')) || {},
        };
        this.changeYearData = false;
    }

    componentDidMount() {
        if(i18n.language==='ja'){
            this.setState({
                contentFitFlag: true
            })
        }
        this.onYearChange(this.state.year);
        this.props.getExpenseByCategory({
            month: this.state.month,
            year: this.state.year
        });
    }

    onYearChange = (year) => {
        this.props.getExpenses({year});
        this.setState({
            year,
            currentMonthTransactions: [],
            yearLabelValue: year,
            currentActiveTab: 'monthly',
        });
    };

    getAlertBox = (message, type) => {
        const t=this.props.t;
        Swal.fire({
            title:t(message),
            position: 'bottom-start',
            toast: true,
            showConfirmButton: false,
            timer: 2000,
            background: type==='success' ? '#8dc44e' : '#f1521c',
            customClass: {
                title:'sweet-alert',
                container: 'sweet-alert-container'
            }
        });
    };

    componentWillReceiveProps(nextProps) {
        const t = this.props.t;
        if (nextProps.expenses.error && !nextProps.expenses.EXPENSE_DATA_LOADING) {
            this.getAlertBox(nextProps.expenses.error.message, 'error');
        }

        if (!nextProps.expenses.EXPENSE_DATA_LOADING && nextProps.expenses.EXPENSE_DATA_ACTION === 'DELETE') {
            let currentDayTransactions = this.state.currentDayTransactions;
            currentDayTransactions.splice(this.state.singleTransactionIndex, 1);

            const expenseData = nextProps.expenses.expensesData;
            let data = this.groupTransactionsByDay(expenseData[this.state.dailyIndex]  || {transaction: []});
            if(this.state.dailyIndex !== null && this.state.dailyIndex !== undefined) {
                this.setState({
                    currentMonthTransactions: data
                });
            }
            let totalAmount = 0;
            let keys = Object.keys(data).sort((a, b) => parseInt(b, 10) - parseInt(a, 10));
            keys.forEach(key => {
                totalAmount += data[key].amount;
            });

            let currentMonthTransactions = this.state.currentMonthTransactions;
            if(currentDayTransactions.length <= 0) {
                let keys = Object.keys(currentMonthTransactions).sort((a, b) => parseInt(b, 10) - parseInt(a, 10));
                delete currentMonthTransactions[keys[this.state.singleDayIndex]];

                this.setState({
                    currentMonthTransactions: currentMonthTransactions,
                });
            }

            this.setState({
                currentDayTransactions: currentDayTransactions,
                monthTotalAmount: totalAmount
            });
            this.backFromSingleTransaction();
            this.getAlertBox(t('ENTRY_DELETED'), 'success');
        } else if (!nextProps.expenses.EXPENSE_DATA_LOADING && nextProps.expenses.EXPENSE_DATA_ACTION === 'UPDATE_EXPENSE') {
            const expenseData = nextProps.expenses.expensesData;
            let data = this.groupTransactionsByDay(expenseData[this.state.dailyIndex]   || {transaction: []});
            if(this.state.dailyIndex !== null && this.state.dailyIndex !== undefined) {
                this.setState({
                    currentMonthTransactions: data
                });
            }
            const keys = Object.keys(data).sort((a, b) => parseInt(b, 10) - parseInt(a, 10));
            let totalAmount = 0;
            keys.forEach(key => {
                totalAmount += data[key].amount;
            });

            let KeyVal = keys[this.state.singleDayIndex];
            if(!KeyVal) {
                KeyVal = keys[0];
            }
            this.setState({
                currentDayTransactions: data[KeyVal].transactions,
                monthTotalAmount: totalAmount
            });
            data = sortArray(data[KeyVal].transactions, 'createTime');
            data = data[this.state.singleTransactionIndex];
            if(data && data.image && !data.imageFile) {
                data.imageFile = nextProps.expenses.GET_EXPENSE_IMAGE
            }
            this.setState({
                currentTransaction: data
            });
            if(data===null){
                this.setState({isOnSingleTransaction: false})
            }
            this.removeDetailDropdown();
            this.getAlertBox(t('EXPENSE_UPDATED'), 'success');
        }else if(!nextProps.expenses.EXPENSE_DATA_LOADING && nextProps.expenses.EXPENSE_DATA_ACTION === 'ADD_EXPENSE') {
            const expenseData = nextProps.expenses.expensesData;
            let currentMonthTransactions = [];
            if(this.state.dailyIndex !== null && this.state.dailyIndex !== undefined) {
                currentMonthTransactions = this.groupTransactionsByDay(expenseData[this.state.dailyIndex]  || {transaction: []});
                const keys = Object.keys(currentMonthTransactions).sort((a, b) => parseInt(b, 10) - parseInt(a, 10));
                let totalAmount = 0;
                keys.forEach(key => {
                    totalAmount += currentMonthTransactions[key].amount;
                });
                this.setState({
                    currentMonthTransactions: currentMonthTransactions,
                    monthTotalAmount: totalAmount
                });
            }
            if(this.state.singleDayIndex !== null && this.state.singleDayIndex !== undefined) {
                const keys = Object.keys(currentMonthTransactions).sort((a, b) => parseInt(b, 10) - parseInt(a, 10));
                this.gotoSingleDay(currentMonthTransactions[keys[this.state.singleDayIndex]].transactions, this.state.singleDayIndex);
            }
            this.getAlertBox(t('EXPENSE_ADDED'), 'success');
        }else if(this.changeYearData && !nextProps.expenses.EXPENSE_DATA_LOADING) {
            this.getNextMonthData(this.state.month);
            this.changeYearData = false
        }
    }

    getExpenseImage = (fileName) => {
        if(fileName && fileName !== '') {
            this.props.getMedia(fileName);
        }
    };

    onMonthChange = (month) => {
        if (month < 0) {
            month = 11;
            this.setState({
                yearLabelValue: this.state.year - 1,
                month: month,
                year: this.state.year - 1
            });
            this.props.getExpenses({
                year: this.state.year - 1
            });
            this.changeYearData = true;
        }else if (month > 11) {
            month = 0;
            this.setState({
                yearLabelValue: this.state.year + 1,
                month: month,
                year: this.state.year + 1
            });
            this.props.getExpenses({
                year: this.state.year + 1
            });
            this.changeYearData = true;
        }else {
            this.changeYearData = false;
            this.getNextMonthData(month);
        }
    };

    getNextMonthData = (month) => {
        let exData = [];
        exData = sortArray(this.props.expenses.expensesData, '_id');
        let dailyIndex = this.state.dailyIndex;
        exData = exData.filter((expense, key) => {
            dailyIndex = key;
            return expense._id === month;
        });
        let expenseData = [];
        let totalAmount = 0;
        if(exData.length > 0 ) {
            expenseData = this.groupTransactionsByDay(exData[0]  || {transaction: []});
            exData[0].transactions.forEach(data => {
                totalAmount += data.amount;
            });
        }
        this.setState({
            month: month,
            currentMonthTransactions:  expenseData,
            dailyIndex: exData.length > 0 ? dailyIndex : this.state.dailyIndex,
            monthTotalAmount: totalAmount
        });
    };

    getCategorydetails = (_categoryId, _type, _userId) => {
        const categories = this.state.categories;
        const foundCategories = categories
            .filter(({type, categoryId, userId}) =>
                (type === _type && categoryId === _categoryId)
            );
        if (foundCategories.length > 1) {
            return foundCategories
                .find(
                    ({userId}) => userId === _userId);
        } else {
            return foundCategories[0] || {};
        }
    };

    addMedia = (dataFile, fileName) => {
        this.props.uploadMedia(dataFile, fileName, () => {
            this.getAlertBox('IMAGE_UPLOADED', 'success');
        });
    };

    groupTransactionsByDay = ({transactions = []}) => transactions.reduce(
        (red, crrr) => {
            const category = this.getCategorydetails(crrr.categoryId, crrr.type, crrr.userId);
            const { t } = this.props;
            if (red[crrr.dayOfYear]) {
                const currObj = red[crrr.dayOfYear];
                currObj.amount += crrr.amount;
                currObj.category.push(category);
                currObj.transactions.push(Object.assign(crrr, {category}));
                red[crrr.dayOfYear] = currObj;
            } else {
                red[crrr.dayOfYear] = {
                    date: getShortDate(crrr.time, t),
                    day: getShortDay(crrr.time, t),
                    key: crrr.time,
                    amount: crrr.amount,
                    category: [category],
                    transactions: [Object.assign(crrr, {
                        category,
                        date: getShortDateLongMonth(crrr.time, t),
                        day: getShortDay(crrr.time, t)
                    })]
                };
            }
            return red;
        }, {});

    gotoDaily = (monthlyTransactions, dailyIndex) => {
        let totalAmount = 0;
        monthlyTransactions.transactions.forEach(data => {
            totalAmount += data.amount;
        });
        this.setState({
            currentMonthTransactions: this.groupTransactionsByDay(monthlyTransactions   || {transaction: []}),
            currentActiveTab: 'daily',
            month: monthlyTransactions._id,
            dailyIndex: dailyIndex,
            monthTotalAmount: totalAmount,

        });
    };

    gotoSingleDay = (trns, index) => {
        this.setState({
            currentDayTransactions: trns,
            isOnSingleTransaction: false,
            singleDayIndex: index,
            dailyClick:true
        });
    };

    gotoSingleTransaction = (currentTransaction, index) => {
        if(currentTransaction.image) {
            this.setState({
                isOnSingleTransaction: true,
                singleTransactionIndex: index,
                currentTransaction
            });
			let id;
            if(currentTransaction.createdUserId){
				id=currentTransaction.createdUserId
			}
			else{
				id=currentTransaction.userId
			}
            this.props.getMedia(currentTransaction.image,id, (imageData) => {
                let currentTransactionData = currentTransaction;
                currentTransactionData.imageFile = imageData;
                this.setState({
                    currentTransaction: currentTransactionData
                });
            });
        }else {
            this.setState({
                isOnSingleTransaction: true,
                singleTransactionIndex: index,
                currentTransaction
            });
        }
    };

    addUpdateExpense = (data) => {
        if (data._id) {
            const dateObj = new Date(data.expenseDate);
            let dataObj = {
                
                _id: data._id,
                title: data.title,
                accountId: data.accountId,
                currencyCode: data.currencyCode,
                amount: data.amount,
                categoryId: data.categoryId,
                time: moment(data.expenseDate).utc().valueOf(),
                image: data.image || '',
                dayOfYear: dayOfYear(dateObj),
                notes: data.notes,
                lastModifyTime: getCurrentUTCTime(),
                month: dateObj.getMonth(),
                week:weekOfYear(dateObj),
                year: getYear(dateObj)
            };
            this.getExpenseImage(dataObj.image);
            this.props.addUpdateExpenses(dataObj, {
                year: this.state.year
            }, () => {
                if(dataObj.image) {
                    this.props.getMedia(dataObj.image, (imageData) => {
                        let currentTransactionData = this.state.currentTransaction;
                        currentTransactionData.imageFile = imageData;
                        this.setState({
                            currentTransaction: currentTransactionData
                        });
                    });
                }
            });
        } else {
            const dateObj = new Date(data.expenseDate);
            let dataObj = {
                title: data.title,
                currencyCode: data.currencyCode,
                amount: data.amount,
                categoryId: data.categoryId,
                time: moment(data.expenseDate).utc().valueOf(),
                image: data.image || '',
                dayOfYear: dayOfYear(dateObj),
                notes: data.notes,
                accountId: data.accountId,
                lastModifyTime: getCurrentUTCTime(),
                month: dateObj.getMonth(),
                createTime: getCurrentUTCTime(),
                week:weekOfYear(dateObj),
                year: getYear(dateObj)
            };
            this.props.addUpdateExpenses(dataObj, {
                month: this.state.month,
                year: this.state.year
            });
        }
    };

    onDeleteExpense() {
        const t = this.props.t;
        const expenseId = {transactions: [{_id: this.state.currentTransaction._id}]};
        Swal.fire({
            title: t('Delete?'),
            text:t("Are you sure you want to delete this entry?"),
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: '#b8b8b8',
            confirmButtonText: 'Delete'
        }).then((result) => {
            if (result.value) {
                this.props.deleteTransaction(expenseId, {
                    month: this.state.month,
                    year: this.state.year
                });
            }
        });
    }

    backFromSingleTransaction = () => {
        this.setState({
            isOnSingleTransaction: false
        })
    };

    setActiveTab = (tab) => {
        this.setState({
            currentActiveTab: tab.id
        });
    };

    showImage = () => {
        Swal.fire({
            html: '<img class="img-preview" src="' + this.state.currentTransaction.imageFile + '" alt="no image" />',
            showCloseButton: true,
            showCancelButton: false,
            showConfirmButton: false,
            focusConfirm: true,
            customClass: {
                title: 'sweet-alert',
                container: 'img-preview'
            }
        })
    };

    removeDetailDropdown=()=>{
        this.setState({
            dailyClick: false
        });
    };

    getDayDate = (data) => {
        const {t} = this.props;
        return getShortDate(data[0].time, t) + ' ' + getShortDay(data[0].time, t);
    };

    render() {
        const {
            year,
            currentMonthTransactions = [],
            currentTransaction = {},
            isOnSingleTransaction,
            currentDayTransactions = [],
            userSettings: {currencyDetails}
        } = this.state;
        const {
            expenses: {
                EXPENSE_DATA_LOADING = false,
                expensesData = []
            },
            t
        } = this.props;

        return (
            <div>
                <div>
                    <Row className='mb-0 mobile-line-height'>
                        <Col s={12} m={12} l={12} style={{lineHeight: '30px'}}>
                            &nbsp;
                        </Col>
                    </Row>
                    <Row className='mb-0'>
                        <Col s={12} m={12} l={6} style={{position: 'relative'}}>
                            <div className='overlap-tab-filter'>
                                <YearSelector
                                    value={year}
                                    onChange={this.onYearChange}
                                    style={{paddingTop: '13px'}}
                                />
                            </div>
                            <Col s={12} m={12} l={12}  className='tab-mobile-fixed'>
                            <Tabs
                                reciveProps={true}
                                activeTab={{
                                    id: this.state.currentActiveTab
                                }}
                                className='tab-demo z-depth-1'
                                onClick={() => this.setActiveTab}
                            >
                                <Tabs.Tab
                                    title={
                                        <div className='tab-title' title={t('MONTHLY')}>
                                            <Trans>MONTHLY</Trans>
                                        </div>
                                    }
                                    id="monthly"

                                >
                                    <Collection
                                        className='fix-height-tab mobile-height'>
                                        {
                                            expensesData.length ?
                                                sortArray(expensesData, '_id')
                                                    .map((expense, index) =>
                                                        <CollectionItem key={expense._id}>
                                                            <table
                                                                className='no-padding'
                                                                onClick={() => this.gotoDaily(expense, index)}
                                                                style={{cursor: 'pointer'}}
                                                            >
                                                                <tbody>
                                                                <tr>
                                                                    <td rowSpan='2' width="13%">
                                                                        <div className='day-round'>
                                                                            <Trans>
                                                                                {SHORT_MONTHS[expense._id]}
                                                                            </Trans>
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <div className='date-label'>
                                                                            <Trans>
                                                                                {_months[expense._id]}
                                                                            </Trans>
                                                                        </div>
                                                                    </td>
                                                                    <td rowSpan='2' width='20%' className='right-align'>
                                                                        <span className="nowrap">
                                                                            <FormattedNumber
                                                                                value={expense.amount}
                                                                                style='currency'
                                                                                currency={currencyDetails.currencyCode || 'USD'}
                                                                                minimumFractionDigits={2}
                                                                                maximumFractionDigits={2}
                                                                            />
                                                                        </span>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        {expense.transactions.length}&nbsp;
                                                                        {expense.transactions.length === 0
                                                                          ? <Trans>NO_TRANSACTION</Trans>
                                                                            : (expense.transactions.length === 1
                                                                            ? <Trans>TRANSACTION</Trans>
                                                                            : <Trans>TRANSACTIONS</Trans>)}
                                                                    </td>
                                                                </tr>
                                                                </tbody>
                                                            </table>
                                                        </CollectionItem>
                                                    ) :
                                                <CollectionItem>
                                                    <div className='date-label center-align grey-text'>
                                                        <Trans>NO_DATA_FOUND</Trans>
                                                    </div>
                                                </CollectionItem>
                                        }
                                    </Collection>
                                </Tabs.Tab>
                                <Tabs.Tab
                                    title={
                                      <div className='tab-title' title={t('DAILY')}>
                                        <Trans>DAILY</Trans>
                                      </div>
                                    }
                                    id="daily"
                                >
                                    <Row className='mb-0 month-selector'>
                                        <Col s={6} m={6} l={6} style={{position: 'relative'}}>
                                            <ExpenseMonthSelector
                                                t={t}
                                                yearValue={this.state.year}
                                                value={this.state.month}
                                                onChange={this.onMonthChange}
                                                style={{paddingTop: '13px'}}
                                            />
                                        </Col>
                                        <Col s={6} m={6} l={6} className="total-price">
                                            <span className="nowrap">
                                                <FormattedNumber
                                                    value={this.state.monthTotalAmount}
                                                    style='currency'
                                                    currency={currencyDetails.currencyCode || 'USD'}
                                                    minimumFractionDigits={2}
                                                    maximumFractionDigits={2}
                                                />
                                            </span>
                                        </Col>
                                    </Row>
                                    <Collection
                                        className='fix-height-tab height-expenses'>
                                        {
                                            Object.keys(currentMonthTransactions).length ?
                                                Object.keys(currentMonthTransactions).sort((a, b) => parseInt(b, 10) - parseInt(a, 10))
                                                    .map(key => currentMonthTransactions[key])
                                                    .map((trns, index) =>
                                                        <CollectionItem key={trns.key} className={index === this.state.singleDayIndex ? 'active-record' : ''}>
                                                            <table
                                                                className='no-padding'
                                                                style={{cursor: 'pointer'}}
                                                                onClick={() => this.gotoSingleDay(trns.transactions, index)}
                                                            >
                                                                <tbody>
                                                                <tr>
                                                                    <td rowSpan='2' width="13%">
                                                                        <div className={smallFontByLanguage(i18n.language)?'font-size-1rem day-round' : 'day-round'}>
                                                                            <Trans>{trns.day}</Trans>
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <div className='date-label'>
                                                                            {trns.date}
                                                                        </div>
                                                                    </td>
                                                                    <td rowSpan='2' width='20%' className='right-align'>
                                                                        <span className="nowrap">
                                                                            <FormattedNumber
                                                                                value={trns.amount}
                                                                                style='currency'
                                                                                currency={currencyDetails.currencyCode || 'USD'}
                                                                                minimumFractionDigits={2}
                                                                                maximumFractionDigits={2}
                                                                            />
                                                                        </span>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td className='categories'>
                                                                        {getCategoryNames(trns.category)}
                                                                    </td>
                                                                </tr>
                                                                </tbody>
                                                            </table>
                                                        </CollectionItem>
                                                    ) :
                                                <CollectionItem>
                                                    <div className='date-label center-align grey-text'>
                                                        <Trans>NO_DATA_FOUND</Trans>
                                                    </div>
                                                </CollectionItem>
                                        }
                                    </Collection>
                                </Tabs.Tab>
                            </Tabs>
                            </Col>
                            <div className='add-budget-icon budget-modal'>
                                <Modal
                                    header={t("ADD_EXPENSE")}
                                    trigger={<Button
                                        floating
                                        large
                                        className="blue"
                                        waves="light"
                                        icon="add"
                                    />}
                                    actions={
                                        <div>
                                            <Button flat modal="close" waves="light"><Trans>CANCEL</Trans></Button>
                                        </div>
                                    }
                                >
                                    <ExpenseForm transaction={{}}
                                                 {...this.props}
                                                 addMedia={this.addMedia}
                                                 onSubmit={this.addUpdateExpense}/>
                                </Modal>
                            </div>
                        </Col>
                        <Col s={12} m={12} l={6}>
                            <div className={`expenses-details-section mobile-no-view ${this.state.dailyClick? 'detailView':''}`}>
                                <div className="col s12">
                                    <ul className="tabs z-depth-1 detail-tab">
                                        <li className="tab col">
                                            <div className="back-button" onClick={this.removeDetailDropdown}><i className="material-icons"> arrow_back </i><span>Expenses</span></div>
                                            <a>
                                                {
                                                    currentDayTransactions.length ?
                                                        this.getDayDate(currentDayTransactions) :
                                                      <Trans>DETAILS</Trans>
                                                }
                                            </a>
                                        </li>
                                    </ul>
                                </div>

                                {
                                    isOnSingleTransaction && Object.keys(currentTransaction).length > 0
                                        ? <div>

                                        <table
                                            className='no-padding'
                                            style={{marginTop: '20px', marginLeft: '20px', width: '90%'}}
                                        >
                                            <tbody>
                                            <tr>
                                                <td colSpan={3}>&nbsp;</td>
                                            </tr>
                                            <tr>
                                                <td colSpan={3} style={{paddingBottom: '10px'}}>
                                                    <Button flat waves='light' onClick={this.backFromSingleTransaction}>
                                                      <Trans>BACK</Trans>
                                                        <Icon left>arrow_back_ios</Icon>
                                                    </Button>
                                                </td>
                                            </tr>
                                            <tr style={{borderBottom: '1px solid #e5e6ea'}}>
                                                <td width='7%' style={{verticalAlign: 'top'}}>
                                                    <img
                                                        src={iconUrl + currentTransaction.category.iconUrl + '.png'}
                                                        alt='icon'
                                                        className='leftMargin10 circle'
                                                        style={{
                                                            backgroundColor: currentTransaction.category.iconColor,
                                                            height: '54px'
                                                        }}
                                                    />
                                                </td>
                                                <td colSpan='2'>
                                                    <div className='date-label'>
                                                        {unescape(currentTransaction.category.name)}
                                                    </div>
                                                    <div>{currentTransaction.notes || ''}</div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style={{borderBottom: '1px solid #e5e6ea'}} />
                                                <td style={{
                                                    padding: '15px',
                                                    borderBottom: '1px solid #e5e6ea',
                                                    paddingLeft: '0'
                                                }}>
                                                    <div style={{
                                                        borderRight: '1px solid #e5e6ea',
                                                        padding: '10px',
                                                        paddingLeft: '0'
                                                    }}>
                                                        <div style={{fontSize: 'x-large', fontWeight: 'bold'}}>
                                                            <span className="nowrap">
                                                                <FormattedNumber
                                                                    value={currentTransaction.amount}
                                                                    style='currency'
                                                                    currency={currentTransaction.currencyCode || currencyDetails.currencyCode || 'USD'}
                                                                    minimumFractionDigits={2}
                                                                    maximumFractionDigits={2}
                                                                />
                                                            </span>
                                                        </div>
                                                        <div><Trans>EXPENSES</Trans></div>
                                                    </div>
                                                </td>
                                                <td style={{borderBottom: '1px solid #e5e6ea'}}>
                                                    <div style={{fontSize: 'x-large', fontWeight: 'bold'}}>
                                                        {`${getShortDate(currentTransaction.time, t)}, ${year}`}
                                                    </div>
                                                    <div>
                                                        {t(getLongDay(currentTransaction.time))}
                                                    </div>
                                                </td>
                                            </tr>

                                            {
                                                currentTransaction && currentTransaction.accountId ?
                                                    <tr style={{padding: '15px', borderBottom: '1px solid #e5e6ea', paddingLeft: '0'}}>
                                                        <td colSpan="3">
                                                            {
                                                                getAccountDetailUI(this.props.auth, currentTransaction.accountId, currentTransaction.type,currentTransaction.createdUserId)
                                                            }
                                                        </td>
                                                    </tr> : null
                                            }
                                            {!isCreator(currentTransaction.createdUserId, this.state.userSettings.userId) && localStorage.getItem(currentTransaction.createdUserId)? <tr>
                                                <td>{this.props.auth &&this.props.auth.groupImageList&& this.props.auth.groupImageList[currentTransaction.createdUserId] ? <img style={{width: '40px',
                                                    height: '40px',
                                                    borderRadius: '100%',
                                                    marginTop: '8px',
                                                    marginRight: '2px',
                                                    paddingLeft: '0'}} className="zero-margin height-54" src={this.props.auth.groupImageList[currentTransaction.createdUserId]}/>:<h5 className='zero-margin-detail'>{JSON.parse(localStorage.getItem(currentTransaction.createdUserId)).ownerDetail.slice(0,1).toUpperCase()}</h5>}</td>
                                                <td style={{
                                                    padding: '15px',
                                                    borderBottom: '1px solid #e5e6ea',
                                                    paddingLeft: '0'
                                                }}>
                                                    <div className="multi-user-creator">
                                                        {JSON.parse(localStorage.getItem(this.state.currentTransaction.createdUserId)).ownerDetail}
                                                    </div>
                                                    <div className='grey-text'><Trans>CREATED_BY</Trans></div>
                                                </td>
                                            </tr>: null}
                                            {
                                                currentTransaction && currentTransaction.image ?
                                                    <tr>
                                                <td />
                                                <td style={{
                                                    'padding': '15px 15px 15px 0px',
                                                    'borderBottom': '1px solid rgb(229, 230, 234)'
                                                }}>
                                                    <div className="expense-image">
                                                        <Loader condition={this.props.expenses.EXPENSE_IMAGE_LOADING}/>
                                                        {
                                                            currentTransaction && currentTransaction.imageFile ?
                                                                <img style={{width: '35%'}} onClick={this.showImage} src={currentTransaction.imageFile} alt="not found" /> : null
                                                        }
                                                    </div>
                                                </td>
                                                <td style={{
                                                    'padding': '15px 15px 15px 0px',
                                                    'borderBottom': '1px solid rgb(229, 230, 234)'
                                                }} />
                                            </tr> : null }
                                            {isCreator(currentTransaction.createdUserId, this.state.userSettings.userId) ? <tr className="button-div">
                                                <td />
                                                <td style={{
                                                    'padding': '15px 15px 15px 0px',
                                                    'borderBottom': '1px solid rgb(229, 230, 234)'
                                                }}>
                                                    <div className='add-icon'>
                                                        <Modal
                                                            header={t("EDIT_EXPENSE")}
                                                            trigger={<button
                                                                className="btn waves-effect waves-light light-blue accent-4 p-2"
                                                                type="button" name="action"><Trans>EDIT</Trans>
                                                                <i className="material-icons right">edit</i>
                                                            </button>}>
                                                            <ExpenseForm transaction={currentTransaction}
                                                                         {...this.props}
                                                                         addMedia={this.addMedia}
                                                                         onSubmit={this.addUpdateExpense}
                                                                         expenseData={currentTransaction}/>
                                                        </Modal>
                                                    </div>
                                                </td>
                                                <td  style={{
                                                    'padding': '15px 15px 15px 0px',
                                                    'borderBottom': '1px solid rgb(229, 230, 234)'
                                                }}>
                                                    <button
                                                        className="btn waves-effect waves-light red lighten-2 accent-4"
                                                        type="button" name="action"
                                                        onClick={this.onDeleteExpense.bind(this)}><Trans>DELETE</Trans>
                                                        <i className="material-icons right">delete</i>
                                                    </button>
                                                </td>
                                            </tr>: null}
                                            <tr>
                                                <td />
                                                <td className='grey-text'>
                                                    <Trans>UPDATED</Trans>&nbsp; {getDateTimeFormat(currentTransaction.updated_at)}
                                                </td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                        :
                                        <div className="view-expense">
                                            <Collection className="fix-height-tab fix-height-table">
                                                {
                                                    currentDayTransactions.length ?
                                                        sortArray(currentDayTransactions, 'createTime')
                                                            .map(
                                                                (trns, index) =>
                                                                    <CollectionItem key={trns._id}>
                                                                        <table
                                                                            className='no-padding table-align'
                                                                            style={{cursor: 'pointer'}}
                                                                            onClick={() => this.gotoSingleTransaction(trns, index)}
                                                                        >
                                                                            <tbody>
                                                                            <tr>
                                                                                <td rowSpan='2'>
                                                                                    <img
                                                                                        src={iconUrl + trns.category.iconUrl + '.png'}
                                                                                        alt='icon'
                                                                                        className='leftMargin10 circle'
                                                                                        style={{backgroundColor: trns.category.iconColor}}
                                                                                    />
                                                                                </td>
                                                                                <td>
                                                                                    <div className='date-label'>
                                                                                        {unescape(trns.category.name)}
                                                                                    </div>
                                                                                </td>
                                                                                <td className='right-align'>
                                                                                    <span className="nowrap amount-font-size">
                                                                                        <FormattedNumber
                                                                                            value={trns.amount}
                                                                                            style='currency'
                                                                                            currency={trns.currencyCode || currencyDetails.currencyCode || 'USD'}
                                                                                            minimumFractionDigits={2}
                                                                                            maximumFractionDigits={2}
                                                                                        />
                                                                                    </span>
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td colSpan={isCreator(trns.createdUserId, this.state.userSettings.userId)? '2':'1'} className='categories accountDetailCategories'>
                                                                                    <div className='break-text'>
                                                                                        {(trns.title ? (trns.title + '-') : '') + (trns.notes || '')}
                                                                                    </div>
                                                                                </td>
                                                                                {!isCreator(trns.createdUserId, this.state.userSettings.userId) && localStorage.getItem(trns.createdUserId) ? this.props.auth && this.props.auth.groupImageList && this.props.auth.groupImageList[trns.createdUserId] ? <td><img className='zero-margin right height-20' src={this.props.auth.groupImageList[trns.createdUserId]}/></td>:<td><h5 className='zero-margin right'>{JSON.parse(localStorage.getItem(trns.createdUserId)).ownerDetail.slice(0,1).toUpperCase()}</h5></td>: null}
                                                                            </tr>
                                                                            </tbody>
                                                                        </table>
                                                                    </CollectionItem>
                                                            ) :
                                                        <CollectionItem>
                                                            <div className='budget-date-label center-align grey-text'>
                                                              <Trans>EXPENSE_SEE_DETAILS</Trans>
                                                            </div>
                                                        </CollectionItem>
                                                }
                                            </Collection>
                                        </div>
                                }
                            </div>
                        </Col>
                    </Row>
                </div>
                <Loader condition={EXPENSE_DATA_LOADING}/>
            </div>
        );
    }
}

Expenses.propTypes = {
    getExpenses: PropTypes.func.isRequired,
    addUpdateExpenses: PropTypes.func.isRequired,
    deleteTransaction: PropTypes.func.isRequired,
    getExpenseByCategory: PropTypes.func.isRequired,
    getMedia: PropTypes.func.isRequired,
    uploadMedia: PropTypes.func.isRequired,
    getCategories: PropTypes.func.isRequired
};

const mapStateToProps = (state) => {
    return state;
};

export default withTranslation() (connect(
    mapStateToProps,
    {
        getExpenseByCategory,
        getMedia,
        getExpenses,
        addUpdateExpenses,
        deleteTransaction,
        uploadMedia,
        getCategories
    })(withRouter(Expenses)));
