import React, { Component } from 'react';
import {withTranslation, Trans} from 'react-i18next';
import {
    Card, Col,
    Preloader, Row,
} from 'react-materialize';
import {
    LineChart,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer
} from 'recharts';
import {
    SHORT_MONTHS as _months,
} from '../common/ConstData';
import { FormattedNumber } from "react-intl";

class CustomTooltip extends Component {
    render() {
        const { active, currencyDetails } = this.props;

        if (active) {
            const { payload, label } = this.props;
            return (
				<div className="custom-tooltip">
					<p className="label">{label}</p>
					<p className="label">
                        {payload[0].name}:&nbsp;
                        <span className="nowrap">
                            <FormattedNumber
                                value = {payload[0].value}
                                style= 'currency'
                                currency= {currencyDetails.currencyCode || 'USD'}
                                minimumFractionDigits= {2}
                                maximumFractionDigits= {2}
                            />
                        </span>
					</p>
					<p className="label">
                        {payload[1].name}:&nbsp;
                        <span className="nowrap">
                            <FormattedNumber
                                value = {payload[1].value}
                                style= 'currency'
                                currency= {currencyDetails.currencyCode || 'USD'}
                                minimumFractionDigits= {2}
                                maximumFractionDigits= {2}
                            />
                        </span>
					</p>
					<p className="label">
                        <Trans>SAVINGS</Trans>:&nbsp;
                        <span className="nowrap">
                            <FormattedNumber
                                value = {payload[0].value-payload[1].value}
                                style= 'currency'
                                currency= {currencyDetails.currencyCode || 'USD'}
                                minimumFractionDigits= {2}
                                maximumFractionDigits= {2}
                            />
                        </span>
					</p>
				</div>
            );
        }
        return null;
    }
}

class SavingChart extends Component {
    constructor(props) {
        super(props);
        this.getChartData = this.getChartData.bind(this);
        this.state = {
            finalStatus: {...this.setTotalSavingData(this.props)}
        };
    }

    componentWillReceiveProps(nextProps) {
        if(!nextProps.isLoading && nextProps.stats) {
            this.setState({
                finalStatus: {...this.setTotalSavingData(nextProps)}
            });
        }
    }

    setTotalSavingData = (props) => {
        let finalStatus = {
            expenses: 0,
            incomes: 0,
            savings: 0
        };
        if(!props.isLoading && props.stats) {
            const reducer = (map, {_id, amount}) => {
                map[_id] = amount;
                return map;
            };
            const earning = (props.stats.income || []).reduce(reducer, {});
            const expense = (props.stats.expense || []).reduce(reducer, {});
            _months.forEach((mon, index) => {
                finalStatus = {
                    expenses: finalStatus.expenses + (expense[index] || 0),
                    incomes: finalStatus.incomes + (earning[index] || 0),
                    savings: finalStatus.savings + ((+earning[index] || 0) - (+expense[index] || 0))
                };
            });
        }
        return finalStatus;
    };

    getChartData(data={}, year) {
        const reducer = (map, {_id, amount}) => {
            map[_id] = amount;
            return map;
        };
        const earning = (data.income || []).reduce(reducer, {});
        const expense = (data.expense || []).reduce(reducer, {});
        const { t } = this.props;
        return _months.map((mon, index) => {
                return {
                    month: `${t(mon)}`,
                    earning: earning[index] || 0,
                    expense: expense[index] || 0,
                    savings: ((+earning[index] || 0) - (+expense[index] || 0))
                };
            }
        );

    }

    render() {
        const {
            isLoading,
            currentYear,
            currencyDetails,
            t
        } = this.props;
        const data = this.getChartData(this.props.stats, currentYear);
        return (
			<div className='padding15 reports-section-page m-t-52'>
                <Card className='hoverable no-padding' style={{marginBottom: '25px'}}>
                    <Row className='mb-1 saving-report-label'>
                        <Col s={6} m={4} l={4}>
                            <div className="income-circle">
                                <b>
                                    <span className="nowrap">
                                        <FormattedNumber
                                            value = {this.state.finalStatus.incomes}
                                            style= 'currency'
                                            currency= {currencyDetails.currencyCode || 'USD'}
                                            minimumFractionDigits= {2}
                                            maximumFractionDigits= {2}
                                        />
                                    </span>
                                </b>
                                <p>
                                    <Trans>INCOME</Trans>
                                </p>
                            </div>
                        </Col>
                        <Col s={6} m={4} l={4}>
                            <div className="expenses-circle">
                                <b>
                                    <span className="nowrap">
                                        <FormattedNumber
                                            value = {this.state.finalStatus.expenses}
                                            style= 'currency'
                                            currency= {currencyDetails.currencyCode || 'USD'}
                                            minimumFractionDigits= {2}
                                            maximumFractionDigits= {2}
                                        />
                                    </span>
                                </b>
                                <p>
                                    <Trans>EXPENSE</Trans>
                                </p>
                            </div>
                        </Col>
                        <Col s={12} m={4} l={4}>
                            <div className={'saving-circle ' + (this.state.finalStatus.savings < 0 ? 'overdue' : 'saving')}>
                                <b>
                                    <span className="nowrap">
                                        <FormattedNumber
                                            value = {Math.abs(this.state.finalStatus.savings)}
                                            style= 'currency'
                                            currency= {currencyDetails.currencyCode || 'USD'}
                                            minimumFractionDigits= {2}
                                            maximumFractionDigits= {2}
                                        />
                                    </span>
                                </b>
                                <p>
                                    <Trans>{this.state.finalStatus.savings < 0 ? 'OVERSPENT' : 'SAVINGS'}</Trans>
                                </p>
                            </div>
                        </Col>
                    </Row>
                </Card>
				<Card
					className='hoverable Income-expense-padding'
					title={t('INCOME_EXPENSES')}>
                    { (data.length) ?
						<ResponsiveContainer width='100%' height={300} className='valign-wrapper'>
                            {
                                isLoading
                                    ? <Preloader flashing size='big' className='center-block'/>
                                    : <LineChart width={600} height={300} data={data}
												 margin={{top: 5, right: 30, left: 20, bottom: 5}}>
									<XAxis dataKey="month"/>
									<YAxis/>
									<CartesianGrid strokeDasharray="3 3"/>
									<Tooltip content={<CustomTooltip currencyDetails={currencyDetails}/>}/>
									<Legend  content={<CustomLagend />} horizontalAlign="middle" margin={{top:40}} />
									<Line name={t("INCOME")} type="monotone" dataKey="earning" stroke="#87af6a" isAnimationActive={true}/>
									<Line name={t("EXPENSES")} type="monotone" dataKey="expense" stroke="#d56049" isAnimationActive={true}/>
								</LineChart>
                            }
						</ResponsiveContainer>
                        :
						<h2 className='center grey-text'>
                            <Trans>NO_DATA_FOUND</Trans>
						</h2>
                    }
				</Card>
			</div>
        );
    }
}

export default withTranslation() (SavingChart);

export const CustomLagend = (props) => {
    const {payload} = props;
    return (
        <div className="saving-legend">
        {
            payload.map((legend, key) => {
                return (
                    <span key={key}>
                        {
                            legend.payload.strokeWidth ?
                                <span key={key} style={{color: legend.color}}>
                                    <span className="box" style={{background: legend.color}} />
                                        {legend.value}
                                </span> : null
                        }
                    </span>
                )
            })
        }
        </div>
    )
};
