import {
    BUDGET_CATEGORY_DATA_LOADING,
    BUDGET_MONTHLY_DATA_LOADING,
    GET_MONTHLY_BUDGET_DATA_SUCCESS,
    GET_CATEGORY_BUDGET_DATA_SUCCESS,
    ADD_UPDATE_BUDGET_SUCCESS,
    ADD_UPDATE_BUDGET_FAILED,
    BUDGET_DATA_FAILED,
    BUDGET_DATA_ACTION
} from '../actions/types';

const initialState = {
	monthlyData: [],
    categoryData: [],
    BUDGET_MONTHLY_DATA_LOADING: false,
    BUDGET_CATEGORY_DATA_LOADING: false
};

export default function (state = initialState, action) {
	// console.log('In Dashboard Reducer..', action);
    switch (action.type){
        case GET_MONTHLY_BUDGET_DATA_SUCCESS:
            if(state.error) {
                delete state.error
            }
            return {
                ...state,
                monthlyData: action.payload
			};
        case GET_CATEGORY_BUDGET_DATA_SUCCESS:
            if(state.error) {
                delete state.error
            }
            return {
                ...state,
                categoryData: action.payload
            };
        case BUDGET_DATA_ACTION:
            if(state.error) {
                delete state.error
            }
            return{
                ...state,
                [action.type] : action.state
            };
        case ADD_UPDATE_BUDGET_SUCCESS:
            if(state.error) {
                delete state.error
            }
            return {
                ...state,
                addUpdateBudget: action.payload
            };
        case BUDGET_CATEGORY_DATA_LOADING:
        case BUDGET_MONTHLY_DATA_LOADING:
        case ADD_UPDATE_BUDGET_FAILED:
            return{
                ...state,
                [action.type] : action.state
            };
        case BUDGET_DATA_FAILED:
            state[BUDGET_CATEGORY_DATA_LOADING] = false;
            state[BUDGET_MONTHLY_DATA_LOADING] = false;
            return {
                ...state,
                error: action.payload
            };
        default:
            return state;
    }
}
