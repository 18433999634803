import React, { Component } from 'react';
import {withTranslation, Trans} from 'react-i18next';
import {
	Row,
    Icon
} from 'react-materialize';

import { connect } from 'react-redux';
import {
    addtransaction,
    getcategories,
    searchtransaction,
    cacheTransaction
} from "../actions/transactionActions";

import '../stylesheets/App.css'
import { unescape } from 'lodash';
import Swal from 'sweetalert2';
import translationEN from '../local/en.json';

import moment from 'moment';
import {getCurrentUTCTime, getShortDateYr, onChangeInputWheel, createDateFormat} from "./common/Methods";
import { FormattedNumber } from "react-intl";
import {iconUrl, s3Url} from "../actions/config";
import AccountSelector from "./common/AccountSelector";

class BillsForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            amountPaid: '',
            paidTime: this.createDate(),
            addExpense: true,
            hasPaid: true,
            serviceProviderId: this.getDefaultServiceProviderId(3) || 0,
			userSettings: JSON.parse(localStorage.getItem('profileDetails')) || {},
            closeModal: true,
            notes: '',
            accountId: ''
        };
        this.markBillsMouseWheel = null;
        this.markBillsDateRef = null;
    }

    componentWillReceiveProps(nextProps) {
        if(nextProps.billsData && nextProps.billsData._id && !nextProps.addNewBill) {
            const billData = nextProps.billsData;
            this.setState({
                amountPaid: +billData.amount - +billData.amountPaid,
                notes: billData.notes,
                accountId: billData.accountId
            })
        }else {
            this.resetStateData();
        }
    }

    getSelectedAccountId = (val) => {
        this.setState({
            accountId: val
        })
    };

    resetStateData = () => {
        this.setState({
            amountPaid: '',
            paidTime: this.createDate(),
            addExpense: true,
            selectedRecurringCategoryId: 0,
            hasPaid: true,
            notes: ''
        });
    };

    getDefaultServiceProviderId = (category) => {
        const userSettings = JSON.parse(localStorage.getItem('profileDetails'));
        const selectedCategory =  (JSON.parse(localStorage.getItem('userCategories')) || []).find(({categoryId}) => categoryId === category );
        if(selectedCategory && selectedCategory.serviceProviderType) {
            const serviceProvider =  (JSON.parse(localStorage.getItem('serviceProviders')) || []).filter(({providerType, providerCurrency}) => (providerType === `${selectedCategory.serviceProviderType}` && (providerCurrency === 'ALL' || providerCurrency === userSettings.currencyDetails.currencyCode))) || [];
            const providerObj = serviceProvider[0] || {};
            return providerObj ? +providerObj.providerId : 0;
        }
        return null;
    };

    onTextChange = (key, e) => {
        if(key === 'amountPaid' && +e.target.value < 0 && ((+e.target.value + +this.props.billsData.amountPaid) > this.props.billsData.amount)) {
            this.setState({
                closeModal: false
            })
        }
        this.setState({
            [key]: e.target.value
        });
    };

    onDateChange = (key, e) => {
        if(e.target.value)
        this.setState({
            [key]: e.target.value
        });
    };

    onChangeCheck = (key, e) => {
        const {t} = this.props;
        if(key === 'hasPaid') {
            Swal.fire({
                title: t('PARTIAL_PAYMENT'),
                text: t('HAS_PAID_MARK_MESSAGE'),
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                confirmButtonText: t('YES'),
                cancelButtonText: t('NO')
            }).then((result) => {
                this.setState({
                    [key]: !result.value
                });
            });
        }else {
            this.setState({
                [key]: e.target.checked
            });
        }
    };

    createDate(date = new Date()) {
        // const dateObj = new Date(date);
        return moment(date).format('YYYY-MM-DD');
    }

    getAlertBox = (message, type) => {
        Swal.fire({
            title: message,
            position: 'bottom-start',
            toast: true,
            showConfirmButton: false,
            timer: 2000,
            background: type==='success' ? '#8dc44e' : '#f1521c',
            customClass: {
                title: 'sweet-alert',
                container: 'sweet-alert-container'
            }
        });
    };

    submitForm = () => {
        const t = this.props.t;
        if(+this.state.amountPaid < 0) {
            this.getAlertBox(t('VALID_AMOUNT'), 'error');
        }else {
            const billsData = this.props.billsData;
            const textMessage = t('TOTAL_PAID_MARK_MESSAGE_1') +
                    this.props.currencyDetails.currencySymbol +
                    (+this.state.amountPaid + +billsData.amountPaid) +
                    t('TOTAL_PAID_MARK_MESSAGE_2') ;

            const formData = {
                _id: this.props.billsData._id,
                amountPaid: +this.state.amountPaid,
                accountId: this.state.accountId,
                paidTime: moment(this.state.paidTime).utc().valueOf(),
                addExpense: this.state.addExpense,
                hasPaid: this.state.hasPaid,
                lastModifyTime: getCurrentUTCTime(),
                notes: this.state.notes
            };
            if(billsData && !billsData.hasPaid && billsData.amountPaid > 0 && this.state.hasPaid) {
                Swal.fire({
                    title: t('MARK_PAID'),
                    text: textMessage,
                    type: 'success',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    confirmButtonText: t('DONE'),
                    cancelButtonText: t('CANCEL'),
                }).then((result) => {
                    if(result.value) {
                        this.props.onSubmit(formData);
                    }else {
                        this.props.reOpenModel();
                        setTimeout(() => {
                            const divData = window.document.getElementById('markBillModel');
                            divData.scrollTop = 0;
                        }, 400);
                    }
                });
            }else {
                this.props.onSubmit(formData);
            }
        }
    };

    changeWheel = (e) => {
        if(e==='amount') {
            onChangeInputWheel(this.markBillsMouseWheel);
        }
        else {
            onChangeInputWheel(this.markBillsDateRef);
        }
    };

    getServiceProviderUI = () => {
        const selectedCategory =  JSON.parse(localStorage.getItem('userCategories')).find(({categoryId}) => categoryId === this.props.billsData.categoryId );
        const serviceProvider =  JSON.parse(localStorage.getItem('serviceProviders')).find(({providerId}) => +providerId === this.props.billsData.serviceProviderId);
        const style4Bg = {
            backgroundColor: serviceProvider && serviceProvider.iconColor ? serviceProvider.iconColor : serviceProvider ? selectedCategory.iconColor : 'transparent',
            height: '39px'
        };
        let logoUrl = serviceProvider && serviceProvider.logoURL ? s3Url + serviceProvider.logoURL : selectedCategory ? (iconUrl + selectedCategory.iconUrl) : '';
        logoUrl += '.png';
        return serviceProvider ? <div className="static-text col s12" style={{display: 'flex'}}>
                    <div className='expense-image'>
                        <img
                            src={logoUrl}
                            alt="icon"
                            className="leftMargin10 circle"
                            style={style4Bg}>
                        </img>
                    </div>
                    <div>
                        <h3>{unescape(serviceProvider.providerName)}</h3>
                        <label htmlFor="icon_prefix">{this.props.billsData.title}</label>
                    </div>
                </div> : null
    };

    render() {
        const {
			userSettings: { currencyDetails }
        } = this.state;
        const {
            billsData,
            t
        } = this.props;
        return <form className="add-edit-expense">
            <Row>
            {
                billsData.serviceProviderId ? this.getServiceProviderUI() :
                    billsData.category ?
                        <div className="input-field static-text col s12" style={{display: 'flex'}}>
                            <div className='expense-image'>
                                <img
                                    src={iconUrl + billsData.category.iconUrl + '.png'}
                                    alt="icon"
                                    className="leftMargin10 circle"
                                    style={{height: '39px', backgroundColor: billsData.category.iconColor}}
                                >
                                </img>
                            </div>
                            <div>
                                <h3>{unescape(billsData.category.name)}</h3>
                                <label htmlFor="icon_prefix" style={{left: '50px', top: '15px'}}>{billsData.title}</label>
                            </div>
                        </div> : ''
            }
            </Row>
            <Row>
                <hr/>
            </Row>
            
            <Row>
                <div className="input-field col m6 s12" style={{display: 'flex', alignItems: 'center'}}>
                    <i className="grey-text material-icons" style={{fontSize: '2rem', marginRight: '19px'}}>
                        access_time
                    </i>
                    <div>
                        <p style={{margin: 0, fontWeight: 'bold'}}>
                            <span className="nowrap">
                                <FormattedNumber
                                    value = {billsData.amount || 0}
                                    style= 'currency'
                                    currency= {currencyDetails.currencyCode || 'USD'}
                                    minimumFractionDigits= {2}
                                    maximumFractionDigits= {2}
                                />
                            </span>
                        </p>
                        <p style={{margin: '0px'}}><Trans>BY</Trans> {getShortDateYr(billsData.time, t)}</p>
                    </div>
                </div>
            </Row>

            {
                ((this.props.transactionType === 'paid' || this.props.transactionType === 'upcoming' || this.props.transactionType === 'overdue' )
                && billsData.hasPaid !== true
                && billsData.amountPaid > 0) &&
                <Row>
                    <div className="input-field col m6 s12" style={{display: 'flex', alignItems: 'center'}}>
                        <i className="white-text material-icons circle"
                           style={{fontSize: '2rem', backgroundColor: '#91c653', marginRight: 10}}>
                            done
                        </i>
                        <div>
                            <div style={{fontSize: '15px', fontWeight: 'bold', color: '#91c653'}}>
                                <span className="nowrap">
                                    <FormattedNumber
                                        value = {billsData.amountPaid}
                                        style= 'currency'
                                        currency= {currencyDetails.currencyCode || 'USD'}
                                        minimumFractionDigits= {2}
                                        maximumFractionDigits= {2}
                                    />
                                </span><Trans>PAID</Trans>
                            </div>
                            <div>
                                <span className="nowrap">
                                    <FormattedNumber
                                        value = {billsData.amount - billsData.amountPaid}
                                        style= 'currency'
                                        currency= {currencyDetails.currencyCode || 'USD'}
                                        minimumFractionDigits= {2}
                                        maximumFractionDigits= {2}
                                    />
                                </span>&nbsp;
                                <Trans>DUE_NOW</Trans>
                            </div>
                        </div>
                    </div>
                </Row>
            }
            <Row>
                <hr/>
            </Row>

            <Row>
                <div className="input-field col m6 s12">
                    <AccountSelector
                        value={this.state.accountId}
                        onChange={this.getSelectedAccountId}
                        type={1}
                    />
                </div>
            </Row>

            <Row>
                <div className="input-field col m6 s12">
                    <i className={"fas prefix " + (currencyDetails.currencySymbol.length > 1 ? 'long-symbole' : '')}>
                        {currencyDetails.currencySymbol}
                    </i>
                    <input type="number"
                           id="paid"
                           name="paid"
                           ref={c => this.markBillsMouseWheel = c}
                           onWheel={() => this.changeWheel('amount')}
                           value={this.state.amountPaid}
                           onChange={this.onTextChange.bind(this, 'amountPaid')}/>
                    <label htmlFor="paid" className={this.state.amountPaid ? 'active' : ''}>
                        <Trans>PAID</Trans>
                    </label>
                </div>
            </Row>

            <Row className="">
                <div className="input-field col m6 s12 input-expense-date">
                    <i className="fas prefix">
                        <Icon>date_range</Icon>
                    </i>
                    <span id="date-text" style={{width: 'calc(100% - 4rem)', float: 'left', marginLeft: '4rem', borderBottom: '1px solid #9e9e9e', height: '2rem'}}>{createDateFormat(t, this.state.paidTime)}</span>
                    <input  type="date"
                            id="paidTime"
                            name="paidTime"
                            className="date-input"
                            value={this.state.paidTime}
                            ref={c => this.markBillsDateRef = c}
                            onWheel={() => this.changeWheel('date')}
                            required
                            onChange={this.onDateChange.bind(this, 'paidTime')}/>
                    <label htmlFor="paidTime" className={this.state.paidTime ? 'active' : ''}>
                        <Trans>DATE</Trans>
                    </label>
                </div>
            </Row>

            <Row>
                <div className="input-field col m6 s12">
                    <i className="fas prefix">
                        <Icon>comments</Icon>
                    </i>
                    <input type="text"
                           id="notes"
                           name="notes"
                           value={this.state.notes}
                           onChange={this.onTextChange.bind(this, 'notes')}/>
                    <label htmlFor="notes" className={this.state.notes ? 'active' : ''}>
                        <Trans>Notes</Trans>
                    </label>
                </div>
            </Row>

            <Row>
                <div className="input-field col m6 s12" style={{position: 'relative'}}>
                    <input type="checkbox"
                           style={{height: '100%', width: '100%', pointerEvents: 'all'}}
                           id="hasPaid"
                           name="hasPaid"
                           onChange={this.onChangeCheck.bind(this, 'hasPaid')}
                           checked={this.state.hasPaid} />
                    <label htmlFor="hasPaid" style={{left: '3px', top: '-10px'}}>
                        <Trans>MARK_FULL_PAYMENT</Trans>
                    </label>
                </div>
            </Row>

            <Row>
                <div className="input-field col m6 s12" style={{position: 'relative'}}>
                    <input type="checkbox"
                           style={{height: '100%', width: '100%', pointerEvents: 'all'}}
                           id="addExpense"
                           name="addExpense"
                           onChange={this.onChangeCheck.bind(this, 'addExpense')}
                           checked={this.state.addExpense} />
                    <label htmlFor="addExpense" style={{left: '3px', top: '-10px'}}>
                        <Trans>EXPENSE_MARK_MESSAGE</Trans>
                    </label>
                </div>
            </Row>

            <Row>
                <div className="modal-footer-sec">
                    <div className="input-field col m6 s12">
                        <button className={'btn waves-effect waves-light light-blue accent-4 ' + (this.state.closeModal ? 'modal-close' : '')}
                                type="button" name="action" onClick={this.submitForm.bind(this)}>
                            <Trans>DONE</Trans>
                            <i className="material-icons right">done</i>
                        </button>
                    </div>
                </div>
            </Row>
        </form>
    }
}

const mapStateToProps = (state) => ({
    auth: state.auth,
    categories: state.categories,
    transactionData: state.transactionData

});

export default withTranslation()(connect(mapStateToProps, {
    addtransaction,
    getcategories,
    searchtransaction,
    cacheTransaction
})(BillsForm));