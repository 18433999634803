import {
    INCOME_DATA_LOADING,
    GET_INCOME_DATA_SUCCESS, INCOME_IMAGE_LOADING, INCOME_DATA_FAILED, INCOME_DATA_ACTION, GET_RECURRING_SUCCESS,
    GET_RECURRING_SUCCESS_INCOME,
} from '../actions/types';

const initialState = {
    INCOME_DATA_LOADING: false,
	incomesData: []
};

export default function (state = initialState, action) {
	// console.log('In Dashboard Reducer..', action);
    switch (action.type){
        case GET_INCOME_DATA_SUCCESS:
            if(state.error) {
                delete state.error
            }
            return {
                ...state,
                incomesData: action.payload
			};
        case 0:
            if(state.error) {
                delete state.error
            }
            return {
                ...state,
                recurringData: action.payload
			};
		case INCOME_DATA_LOADING:
            return{
                ...state,
                [action.type] : action.state
            };
        case INCOME_IMAGE_LOADING:
            return{
                ...state,
                [action.type] : action.state
            };
        case INCOME_DATA_ACTION:
            if(state.error) {
                delete state.error
            }
            return{
                ...state,
                [action.type] : action.state
            };
        case GET_RECURRING_SUCCESS_INCOME:
            if(state.error) {
                delete state.error
            }
            return {
                ...state,
                recurringData: action.payload
            };
        case INCOME_DATA_FAILED:
            state[INCOME_DATA_LOADING] = false;
            if(state.error) {
                delete state.error
            }
            return {
                ...state,
                error: action.payload
            };
        default:
            return state;
    }
}
