import React, { Component } from 'react';
import {withTranslation, Trans} from 'react-i18next';
import {
    Row,
    Col,
    Collection,
    CollectionItem,
    Modal,
    Button, Icon
} from 'react-materialize';
import { unescape } from 'lodash';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { FormattedNumber } from "react-intl";
import {
	LONG_MONTHS as _months,
	SHORT_MONTHS
} from './common/ConstData';
import {
    getShortDate,
    getShortDay,
    sortArray,
    getDateTimeFormat, getLongDay, getAccountDetailUI, isCreator
} from './common/Methods';
import Tabs from "./common/Tabs";
import YearSelector from './common/YearSelector';
import {
	getIncomeTransactions,
    addUpdateIncome,
    getMedia,
    uploadMedia,
    deleteTransaction,
    getRecurringDataById
} from "../actions/incomeActions";
import {
	getCategories
} from "../actions/authActions";
import Loader from './Loader';
import IncomeForm from './AddEditIncome';
import Swal from 'sweetalert2';
import {iconUrl} from "../actions/config";
class Income extends Component {
	constructor(props) {
		super(props);
		this.setActiveTab = this.setActiveTab.bind(this);
		const date = new Date();
		const year = date.getFullYear();
		const month = date.getMonth();
		const yearArr = [];
		const monthArr = _months.map((name, id) => ({id, name}));
		let categories = [];
		for(let i=0; i< 12; i++) {
			let y = year + (i-3);
			yearArr.push({id: y, name: y});
		}
		if (!!localStorage.getItem('userCategories')) {
			categories = JSON.parse(localStorage.getItem('userCategories'));
		} else {
			this.props.getCategories();
		}
		this.state = {
			year,
			month,
			monthArr,
			yearArr,
			lastDate: null,
			currentActiveTab: 'Monthly',
			currentMonthTransactions: [],
			currentTransaction: {},
			currentDayTransactions: [],
			isOnSingleTransaction: false,
            singleTransactionKey: '',
            dailyIndex: '',
			categories,
			userSettings: JSON.parse(localStorage.getItem('profileDetails')) || {},
            openIncomeModel: false,
            addNewIncome: false,
			editSingleRecord: true,
            dailyClick:false,
		};
	}

	componentDidMount () {
		this.props.getIncomeTransactions({year: this.state.year});
	}

    getAlertBox = (message, type) => {
		const { t } = this.props;
        Swal.fire({
            title: t(message),
            position: 'bottom-start',
            toast: true,
            showConfirmButton: false,
            timer: 2000,
            background: type==='success' ? '#8dc44e' : '#f1521c',
            customClass: {
                title: 'sweet-alert',
                container: 'sweet-alert-container'
            }
        });
    };

	componentWillReceiveProps(nextProps) {
        if (nextProps.incomes && !nextProps.incomes.INCOME_DATA_LOADING) {
            if (nextProps.incomes.error) {
                this.getAlertBox(nextProps.incomes.error.message, 'error');
            }
            if (nextProps.incomes.INCOME_DATA_ACTION === 'DELETE_INCOME') {
            	this.gotoDaily(sortArray(nextProps.incomes.incomesData, '_id')[this.state.dailyIndex], this.state.dailyIndex);
            	this.setState({
                    isOnSingleTransaction: false,
                    currentTransaction: {}
				});
                this.getAlertBox('ENTERY_DELETED', 'success');
            }else if(nextProps.incomes.INCOME_DATA_ACTION === 'ADD_INCOME') {
            	if(this.state.dailyIndex !== '' && this.state.dailyIndex !== undefined && this.state.dailyIndex !== null) {
                    this.gotoDaily(sortArray(nextProps.incomes.incomesData, '_id')[this.state.dailyIndex], this.state.dailyIndex);
				}
                this.getAlertBox('INCOME_ADDED', 'success');
			}else if(nextProps.incomes.INCOME_DATA_ACTION === 'UPDATE_INCOME') {
            	if(this.state.dailyIndex !== '' && this.state.dailyIndex !== undefined && this.state.dailyIndex !== null) {
                    this.gotoDaily(sortArray(nextProps.incomes.incomesData, '_id')[this.state.dailyIndex], this.state.dailyIndex);
				}
                this.getAlertBox('INCOME_UPDATE', 'success');
                this.removeDetailDropdown();
			}else if(nextProps.incomes.INCOME_DATA_ACTION === 'GET_RECURRING') {
				this.setState({
					currentTransaction: {...this.state.currentTransaction, ...nextProps.incomes.recurringData}
				})
			}else if(!nextProps.incomes.INCOME_IMAGE_LOADING && nextProps.incomes.INCOME_DATA_ACTION === 'ADD_MEDIA') {
                this.getAlertBox('IMAGE_UPLOADED', 'success');
			}
        }
	}

	onYearChange = (year) => {
		this.props.getIncomeTransactions({year});
		this.setState({
			year,
			currentActiveTab: 'Monthly',
		});
	};

	getCategorydetails = (_categoryId, _type, _userId) => {
		if(_type > 2) _type = 1; // TODO: HACK
		const categories = this.state.categories;
		const foundCategories = categories
			.filter(({type, categoryId, userId}) =>
				(type === _type && categoryId === _categoryId)
			);
		if (foundCategories.length > 1) {
			return foundCategories
				.find(
					({userId}) => userId === _userId);
		} else {
			return foundCategories[0] || {};
		}
	};

	getTransactionsByDay = ({transactions=[]}) =>
		transactions.map(
			(crrr) => {
				const category = this.getCategorydetails(crrr.categoryId, crrr.type, crrr.userId);
				const { t } = this.props;
				return Object.assign(
					crrr, {
						category,
						date: getShortDate(crrr.time, t),
						day: getShortDay(crrr.time, t)
					});
			});

	gotoSingleTransaction = (currentTransaction, singleTransactionKey) => {
		if(currentTransaction.image) {
            this.setState({
                isOnSingleTransaction: true,
				dailyClick: true,
                singleTransactionKey: singleTransactionKey,
                currentTransaction
			});
			
			let id;
			if(currentTransaction.createdUserId){
				id=currentTransaction.createdUserId
			}
			else{
				id=currentTransaction.userId
			}
            this.props.getMedia(currentTransaction.image, id,(imageData) => {
                let currentTransactionData = currentTransaction;
                currentTransactionData.imageFile = imageData;
                this.setState({
                    currentTransaction: currentTransactionData
                });
            });
		}else {
            this.setState({
                isOnSingleTransaction: true,
                dailyClick: true,
                singleTransactionKey: singleTransactionKey,
                currentTransaction
            });
        }
	};

	setActiveTab = (tab) => {
		this.setState({
			currentActiveTab: tab.id
		});
	};

	gotoDaily = (monthlyTransactions, dailyIndex) => {
		this.setState({
			currentMonthTransactions: monthlyTransactions ? this.getTransactionsByDay(monthlyTransactions) : [],
            dailyIndex: dailyIndex,
			currentActiveTab: 'Daily',
		});
	};

    addMedia = (dataFile, fileName) => {
        this.props.uploadMedia(dataFile, fileName);
    };

    addUpdateIncome = (data) => {
		let dataObj = {...data};
		if(data._id) {
            dataObj = {...this.state.currentTransaction, ...data};
            dataObj.category = this.getCategorydetails(dataObj.categoryId, dataObj.type);
            this.setState({
                currentTransaction: {},
				isOnSingleTransaction: false
			}, () => {
                if(this.state.singleTransactionKey !== '') {
                    this.gotoSingleTransaction(this.state.currentTransaction, this.state.singleTransactionKey);
                }
			});
            delete dataObj.createTime;
            delete dataObj.imageFile;
            if(this.state.editSingleRecord) {
            	delete dataObj.recurringCategoryId;
            	delete dataObj.recurringCount;
            	delete dataObj.repeatTillCount;
            	delete dataObj.repeatTillDate;
            	delete dataObj.recurringServerId;
			}
		}
        this.props.addUpdateIncome(dataObj, this.state.year);
    };

    onDeleteIncome() {
		let incomeId = {
			id: {_id: this.state.currentTransaction._id, type: 2}
		};

        const { t } = this.props;
        const currentTransaction = this.state.currentTransaction;
        let deleteMessage = t('BILL_DELETE_MESSAGE_1');
        let singleRecord = true;
        if(currentTransaction.recurringServerId || currentTransaction.recurringIdLong || currentTransaction.recurringId) {
            deleteMessage = t('DELETE_INCOME_MESSAGE');
            singleRecord = false;
        }

        Swal.fire({
            title: t('DELETE'),
            text: deleteMessage,
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: singleRecord ? '#b8b8b8' : '#d33',
            confirmButtonText: singleRecord ? t('DELETE') : t('EDIT_ALL_FUTURE'),
            cancelButtonText: singleRecord ? t('CANCEL') : t('DELETE_THIS')
        }).then((result) => {
            if (result.value) {
				if(currentTransaction.recurringServerId || currentTransaction.recurringIdLong || currentTransaction.recurringId) {
                    if(currentTransaction.recurringIdLong) {
                        incomeId = {
                            recurringIdLong: currentTransaction.recurringIdLong,
							type: 2,
							startTime: currentTransaction.time
                        };
                    }else if(currentTransaction.recurringServerId) {
                        incomeId = {
                            recurringServerId: currentTransaction.recurringServerId,
							type: 2,
							startTime: currentTransaction.time
                        };
                    }else if(currentTransaction.recurringId) {
                        incomeId = {
                            recurringId: currentTransaction.recurringId,
                            type: 4,
                            deviceId: currentTransaction.deviceId
                        };
                    }
                }
                const year = {year: this.state.year};
                this.props.deleteTransaction(incomeId, year, singleRecord);
            }else  if(result.dismiss === 'cancel'){
                if(!singleRecord) {
                    const year = {year: this.state.year};
                    this.props.deleteTransaction(incomeId, year, !singleRecord);
                }
			}
        });
    }

    openAddIncomeModel = async () => {
		await this.closeIncomeModel();
		this.setState({
            openIncomeModel: true,
			addNewIncome: true,
            editSingleRecord: false
		}, () => {
            const divData = window.document.getElementById('addIncomeModel');
            divData.scrollTop = 0;
        });
	};

    getRecurringObj = (editableRecord = true) => {
        if (this.state.currentTransaction.recurringId || this.state.currentTransaction.recurringServerId || this.state.currentTransaction.recurringIdLong) {
            let recurringObj = {};
            if(this.state.currentTransaction.recurringIdLong) {
                recurringObj = {
                    recurringIdLong: this.state.currentTransaction.recurringIdLong
                }
            }else if(this.state.currentTransaction.recurringServerId) {
                recurringObj = {
                    recurringServerId: this.state.currentTransaction.recurringServerId
                }
            }else if(this.state.currentTransaction.recurringId) {
                recurringObj = {
                    recurringId: this.state.currentTransaction.recurringId,
                    type: 2,
                    deviceId: this.state.currentTransaction.deviceId
                }
            }
            this.props.getRecurringDataById(recurringObj, editableRecord);
        }
    };

	openEditIncomeModel = async () => {
        await this.closeIncomeModel();
		const currentTransaction = this.state.currentTransaction;
		const { t } = this.props;
        if(currentTransaction.recurringServerId || currentTransaction.recurringIdLong || currentTransaction.recurringId ) {
            Swal.fire({
                title: t('EDIT'),
                text: t('EDIT_INCOME_MESSAGE'),
                type: 'question',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#3085d6',
                confirmButtonText: t('EDIT_ALL_FUTURE'),
                cancelButtonText: t('EDIT_THIS')
            }).then((result) => {
				this.setState({lastDate: currentTransaction.time}) 
                if (result.value) {
                    this.getRecurringObj(false);
                    this.setState({
                        openIncomeModel: true,
                        addNewIncome: false,
                        editSingleRecord: false
                    }, () => {
                        const divData = window.document.getElementById('addIncomeModel');
                        divData.scrollTop = 0;
					});
                }else if(result.dismiss === 'cancel'){
                    this.getRecurringObj(true);
                    this.setState({
                        openIncomeModel: true,
                        addNewIncome: false,
                        editSingleRecord: true
                    }, () => {
                        const divData = window.document.getElementById('addIncomeModel');
                        divData.scrollTop = 0;
                    });
                }

            });
        }else {
            this.getRecurringObj(true);
            this.setState({
                openIncomeModel: true,
                addNewIncome: false,
                editSingleRecord: true
            }, () => {
                const divData = window.document.getElementById('addIncomeModel');
                divData.scrollTop = 0;
            });
		}

	};

    closeIncomeModel = () => {
        const divData = window.document.getElementById('addIncomeModel');
        divData.scrollTop = 0;
        this.setState({
            openIncomeModel: false
        });
	};

    showImage = () => {
        Swal.fire({
            html: '<img class="img-preview" src="' + this.state.currentTransaction.imageFile + '" alt="no image" />',
            showCloseButton: true,
            showCancelButton: false,
            showConfirmButton: false,
            focusConfirm: true,
            customClass: {
                title: 'sweet-alert',
                container: 'img-preview'
            }
        })
    };
    removeDetailDropdown=()=>{
        this.setState({
            dailyClick: false
        });
    };

	render(){
		const {
			year,
			currentMonthTransactions = [],
			currentTransaction = {},
			userSettings: { currencyDetails },
		} = this.state;
		const {
			incomes: {
				INCOME_DATA_LOADING,
				incomesData = []
			},
			t
		} = this.props;
		return (
			<div>
				<div>
					<Row className='mb-0 mobile-view'>
						<Col s={12} m={12} l={12} style={{lineHeight: '30px'}}>
							&nbsp;
						</Col>
					</Row>
					<Row className='mb-0'>
						<Col s={12} m={12} l={6} style={{position: 'relative'}}>
							<div className='overlap-tab-filter'>
								<YearSelector
									value={year}
									onChange={this.onYearChange}
									style={{paddingTop: '13px'}}
								/>
							</div>
							<Col s={12} m={12} l={12}  className=' tab-mobile-fixed'>
								<Tabs
									reciveProps={true}
									activeTab={{
										id: this.state.currentActiveTab
									}}
									className='tab-demo z-depth-1'
									onClick={this.setActiveTab}
								>
									<Tabs.Tab
										title={
											<div className='tab-title' title={t('MONTHLY')}>
												<Trans>MONTHLY</Trans>
											</div>
										}
										id="Monthly"
									>
										<Collection
											className='fix-height-tab '>
											{
												incomesData.length ?
												sortArray(incomesData, '_id')
													.map((income, dailyIndex) =>
														<CollectionItem key={income._id}>
															<table
																className='no-padding income-table-monthly'
																onClick={() => this.gotoDaily(income, dailyIndex)}
																style={{cursor: 'pointer'}}
															>
																<tbody>
																	<tr>
																		<td rowSpan='2'>
																			<div className='day-round'>
																				<Trans>
																					{SHORT_MONTHS[income._id]}
																				</Trans>
																			</div>
																		</td>
																		<td>
																			<div className='date-label'>
																				<Trans>
																					{_months[income._id]}
																				</Trans>
																			</div>
																		</td>
																		<td rowSpan='2' className='right-align'>
																			<span className="nowrap">
																				<FormattedNumber
																					value = {income.amount}
																					style= 'currency'
																					currency= {income.currencyCode || currencyDetails.currencyCode || 'USD'}
																					minimumFractionDigits= {2}
																					maximumFractionDigits= {2}
																				/>
																			</span>
																		</td>
																	</tr>
																	<tr>
																		<td>
																			{income.transactions.length}&nbsp;
																			<Trans>TRANSACTION</Trans>
																		</td>
																	</tr>
																</tbody>
															</table>
														</CollectionItem>
													):
													<CollectionItem>
														<div className='date-label center-align grey-text'>
															<Trans>
																NO_DATA_FOUND
															</Trans>
														</div>
													</CollectionItem>
											}
										</Collection>
									</Tabs.Tab>
									<Tabs.Tab
										title={
											<div className='tab-title' title={t('DAILY')}>
												<Trans>DAILY</Trans>
											</div>
										}
										id="Daily"
									>
										<Collection
											className='fix-height-tab income-section-left'>
											{
												currentMonthTransactions.length ?
													sortArray(currentMonthTransactions, 'time')
													.map((trns, singleTransactionKey) =>
														<CollectionItem key={trns._id} className={this.state.singleTransactionKey === singleTransactionKey && this.state.isOnSingleTransaction ? 'active-record' : ''}>
															<table
																className='no-padding bills-monthly-category'
																style={{cursor: 'pointer'}}
																onClick={() => this.gotoSingleTransaction(trns, singleTransactionKey)}
															>
																<tbody>
																	<tr>
																		<td rowSpan='2' width="13%">
																			<img
																				src={iconUrl + trns.category.iconUrl + '.png'}
																				alt='icon'
																				className='leftMargin10 circle'
																				style={{backgroundColor: trns.category.iconColor, height: '54px'}}
																			/>
																		</td>
																		<td>
																			<div className='date-label'>
																				{unescape(trns.category.name)}
																			</div>
																		</td>
																		<td rowSpan='1'  className='right-align'>
																			<span className="nowrap amount-font-size">
																				<FormattedNumber
																					value = {trns.amount}
																					style= 'currency'
																					currency= {trns.currencyCode || currencyDetails.currencyCode || 'USD'}
																					minimumFractionDigits= {2}
																					maximumFractionDigits= {2}
																				/>
																			</span>
																		</td>
																	</tr>
																	<tr>
																		<td className='categories grey-text'>
																			{trns.date}
																		</td>
                                                                        {trns.recurringServerId || trns.recurringIdLong || trns.recurringId ?
																			<td>
																				<div className='flex-box-recurring right'>
																				<Icon className='grey-text' right>refresh</Icon>
                                                                                {!isCreator(trns.createdUserId, this.state.userSettings.userId) && localStorage.getItem(trns.createdUserId) ? this.props.auth && this.props.auth.groupImageList && this.props.auth.groupImageList[trns.createdUserId]  ? <img className='zero-margin height-20' src={this.props.auth.groupImageList[trns.createdUserId]}/>:<h5 className='zero-margin'>{JSON.parse(localStorage.getItem(trns.createdUserId)).ownerDetail.slice(0,1).toUpperCase()}</h5>: null}
																			</div>
																			</td> : !isCreator(trns.createdUserId, this.state.userSettings.userId) && localStorage.getItem(trns.createdUserId)? <td>{this.props.auth && this.props.auth.groupImageList && this.props.auth.groupImageList[trns.createdUserId]? <img className='zero-margin height-20 right' src={this.props.auth.groupImageList[trns.createdUserId]}/>:<h5 className='zero-margin' style={{float: 'right'}}>{JSON.parse(localStorage.getItem(trns.createdUserId)).ownerDetail.slice(0,1).toUpperCase()}</h5>}</td>: null}
																	</tr>
																</tbody>
															</table>
														</CollectionItem>
													):
													<CollectionItem>
														<div className='date-label center-align grey-text'>
															<Trans>
																NO_DATA_FOUND
															</Trans>
														</div>
													</CollectionItem>
											}
										</Collection>
									</Tabs.Tab>
								</Tabs>
							</Col>
							{/* <div className='add-budget-icon budget-modal'>
								<Button
									floating
									large
									className="blue"
									waves="light"
									icon="add"
									onClick={this.openAddIncomeModel}
								/>
							</div> */}
						</Col>
						<Col s={12} m={12} l={6}>
							<div className={`income-section mobile-no-view ${this.state.dailyClick? 'detailView':''}`}>
								<div className="col s12">
									<ul className="tabs z-depth-1 detail-tab">
										<li className="tab col">
											<div className="back-button" onClick={this.removeDetailDropdown}><i className="material-icons"> arrow_back </i> <span>Income</span>
												<a><Trans>
													DETAILS
												</Trans></a>
											</div>
											<a className="mobile-display-none">
												<Trans>
												DETAILS
											</Trans></a>
										</li>
									</ul>
								</div>
								{
									!!(currentTransaction.category) ?
									<div>
										<table
											className='no-padding'>
											<tbody>
												<tr>
													<td colSpan="3">&nbsp;</td>
												</tr>
												<tr style={{borderBottom: '1px solid #e5e6ea'}}>
													<td width='7%' style={{verticalAlign: 'top'}}>
														<img
															src={iconUrl + currentTransaction.category.iconUrl + '.png'}
															alt='icon'
															className='leftMargin10 circle'
															style={{backgroundColor: currentTransaction.category.iconColor, height: '54px'}}
														/>
													</td>
													<td colSpan='2'>
														<div className='date-label'>
															{unescape(currentTransaction.category.name)}
														</div>
														<div>{currentTransaction.notes}</div>
													</td>
												</tr>
												<tr>
													<td style={{borderBottom: '1px solid #e5e6ea'}} />
													<td style={{padding: '15px', borderBottom: '1px solid #e5e6ea', paddingLeft: '0'}}>
														<div style={{borderRight: '1px solid #e5e6ea', padding: '10px', paddingLeft: '0'}}>
															<div style={{fontSize: 'x-large', fontWeight: 'bold'}}>
																<span className="nowrap">
																	<FormattedNumber
																		value = {currentTransaction.amount}
																		style= 'currency'
																		currency= {currentTransaction.currencyCode ||  currencyDetails.currencyCode || 'USD'}
																		minimumFractionDigits= {2}
																		maximumFractionDigits= {2}
																	/>
																</span>
															</div>
															<div><Trans>INCOME</Trans></div>
														</div>
													</td>
													<td style={{borderBottom: '1px solid #e5e6ea'}}>
														<div style={{fontSize: 'x-large', fontWeight: 'bold'}}>
															{`${t(getShortDate(currentTransaction.time, t))}, ${year}`}
														</div>
														<div>
															{t(getLongDay(currentTransaction.time))}
														</div>
													</td>
												</tr>
												{
                                                    currentTransaction && currentTransaction.accountId ?
														<tr style={{padding: '15px', borderBottom: '1px solid #e5e6ea', paddingLeft: '0'}}>
															<td colSpan="3">
                                                                {
                                                                    getAccountDetailUI(this.props.auth, currentTransaction.accountId, currentTransaction.type,currentTransaction.createdUserId)
                                                                }
															</td>
														</tr> : null
												}
                                                {!isCreator(currentTransaction.createdUserId, this.state.userSettings.userId) && localStorage.getItem(currentTransaction.createdUserId)? <tr>
													<td>{this.props.auth && this.props.auth.groupImageList[currentTransaction.createdUserId] ? <img style={{width: '40px',
                                                        height: '40px',
                                                        borderRadius: '100%',
                                                        marginTop: '8px',
                                                        marginRight: '2px',
                                                        paddingLeft: '0'}} className="zero-margin height-54" src={this.props.auth.groupImageList[currentTransaction.createdUserId] }/>:<h5 className='zero-margin-detail'>{JSON.parse(localStorage.getItem(currentTransaction.createdUserId)).ownerDetail.slice(0,1).toUpperCase()}</h5>}</td>
													<td style={{
                                                        padding: '15px',
                                                        borderBottom: '1px solid #e5e6ea',
                                                        paddingLeft: '0'
                                                    }}>
														<div className="multi-user-creator">
                                                            {JSON.parse(localStorage.getItem(this.state.currentTransaction.createdUserId)).ownerDetail}
														</div>
														<div className='grey-text'><Trans>CREATED_BY</Trans></div>
													</td>
												</tr>: null}
                                                {
                                                    currentTransaction && currentTransaction.image ?
														<tr>
															<td />
															<td style={{
                                                                'padding': '15px 15px 15px 0px',
                                                                'borderBottom': '1px solid rgb(229, 230, 234)'
                                                            }}>
																<div className="expense-image">
																	<Loader condition={this.props.incomes.INCOME_IMAGE_LOADING}/>
                                                                    {
                                                                        currentTransaction && currentTransaction.imageFile ?
                                                                            <img style={{width: '35%'}} src={currentTransaction.imageFile} alt={t('NO_IMAGE')} onClick={this.showImage} />
																		 : null
                                                                    }
																</div>
															</td>
															<td style={{
                                                                'padding': '15px 15px 15px 0px',
                                                                'borderBottom': '1px solid rgb(229, 230, 234)'
                                                            }} />
														</tr> : null }

                                                {/* {isCreator(currentTransaction.createdUserId, this.state.userSettings.userId) ? <tr>
													<td />
													<td style={{
                                                        'padding': '15px 15px 15px 0px',
                                                        'borderBottom': '1px solid rgb(229, 230, 234)'
                                                    }}>
														<div className='add-icon'>
															<button
																className="btn waves-effect waves-light light-blue accent-4 p-2"
                                                                type="button" name="action" onClick={this.openEditIncomeModel}>
																<Trans>EDIT</Trans>
																<i className="material-icons right">edit</i>
															</button>
														</div>
													</td>
													<td  style={{
                                                        'padding': '15px 15px 15px 0px',
                                                        'borderBottom': '1px solid rgb(229, 230, 234)'
                                                    }}>
														<button
																className="btn waves-effect waves-light red lighten-2 accent-4"
                                                                type="button" name="action" onClick={this.onDeleteIncome.bind(this)}>
															<Trans>DELETE</Trans>
															<i className="material-icons right">delete</i>
														</button>
													</td>
												</tr>: null} */}
												<tr>
													<td/>
													<td className='grey-text'>
														<Trans>UPDATED</Trans>: {getDateTimeFormat(currentTransaction.updated_at)}
													</td>
												</tr>
											</tbody>
										</table>
									</div> :
										<Collection>
											<CollectionItem>
												<div className='budget-date-label center-align grey-text'>
													<Trans>INCOME_TO_SEE</Trans>
												</div>
											</CollectionItem>
										</Collection>
								}
							</div>
						</Col>
					</Row>
				</div>
				<div>
					<Modal
						header={this.state.addNewIncome ? t('ADD')+ ' ' + t('INCOME') : t('EDIT') + ' ' + t('INCOME')}
						id="addIncomeModel"
						open={this.state.openIncomeModel}
						actions={
							<div>
								<Button flat modal="close" waves="light" onClick={this.closeIncomeModel}>
									<Trans>CANCEL</Trans>
								</Button>
							</div>
						}
					>
						<div />
						<IncomeForm addNewIncome={this.state.addNewIncome}
									addMedia={this.addMedia}
									disableRecurrence={this.state.editSingleRecord}
									incomeAction={this.props.incomes.INCOME_DATA_ACTION}
									INCOME_IMAGE_LOADING={this.props.incomes.INCOME_IMAGE_LOADING}
									dataLoading={this.props.incomes.INCOME_DATA_LOADING}
									onSubmit={this.addUpdateIncome}
									incomeData={currentTransaction}
									lastDate={this.state.lastDate? this.state.lastDate: null}/>
					</Modal>
				</div>
				<Loader condition={INCOME_DATA_LOADING}/>
			</div>
		);
	}
}

Income.propTypes = {
	getIncomeTransactions: PropTypes.func.isRequired,
	getCategories: PropTypes.func.isRequired,
    addUpdateIncome: PropTypes.func.isRequired,
    getMedia: PropTypes.func.isRequired,
    uploadMedia: PropTypes.func.isRequired,
    deleteTransaction: PropTypes.func.isRequired,
    getRecurringDataById: PropTypes.func.isRequired
};

const mapStateToProps = (state) => {
	return state;
};

export default withTranslation() (connect(
	mapStateToProps,
	{
		getIncomeTransactions,
        addUpdateIncome,
		getCategories,
        getMedia,
        uploadMedia,
        deleteTransaction,
        getRecurringDataById
	})(withRouter(Income)));
