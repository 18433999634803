import React, { Component } from 'react';
import Navbar from "./Navbar";
import {iconUrl} from "../actions/config";

import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import {withTranslation, Trans} from 'react-i18next';

import '../stylesheets/App.css'

class VerifySignup extends Component {

    componentDidMount () {
        if(this.props.auth.isAuthenticated){
            this.props.history.push('/dashboard');
        }
        if(!this.props.location.state){
            this.props.history.push('/signup');
        }
    }

    render() {
        return (
            <div>
                <Navbar />
                <div className="vertical-center">
                    <div className="row">
                        <div className="col s2" />
                        <div className="col m8 s12 ">
                            <div className="darken-1">
                                <div className="card-content center-align">
                                    <img src={iconUrl + 'mail.png'} alt="MailImage"/>
                                    <span className="card-title"> <h3>Verify Email.</h3></span>
                                  <span> <h5><Trans>WE_HAVE_SEND_EMAIL_TO</Trans> <b>{this.props.location.state!==undefined?this.props.location.state.email: ""}</b>.</h5></span>
                                    <span> <h5><Trans>INSTRUCTION_TO_VERIFY_EMAIL</Trans></h5></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

VerifySignup.propTypes = {
    auth:PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
    auth: state.auth
});

export default withTranslation()(connect(mapStateToProps,{})(withRouter(VerifySignup)));





