import React, { Component } from 'react';
import {withTranslation} from 'react-i18next';
import {
	Card,
	Collection,
	CollectionItem,
	Badge,
	Preloader,
	Row,
	Col
} from 'react-materialize';
import {
	ResponsiveContainer,
	PieChart,
	Pie,
	Cell
} from 'recharts';
import { FormattedNumber } from "react-intl";

class ExpensesChart extends Component {
	onPieEnter = () => 'Name';
	getChartData = ({budget = 0, expense = 0, income = 0}) => {
		let remaining = 0;
        if(budget === 0 && expense === 0)
            return [{name: '', value: 100, color: '#bdb8b8'}];
        if(income > expense && income > budget) {
			expense = ((expense/income) * 100);
			budget = ((budget/income) * 100) - expense;
			remaining = 100 - (budget + expense);
		}else {
            if(expense > budget){
                budget = 0;
            } else {
                expense = (expense/budget) * 100;
                budget = 100-expense;
            }
		}
		return [
            {name: '', value: remaining, color: '#fff'},
			{name: 'budget', value: budget, color: '#e5e5e5'},
			{name: 'expenses', value: expense, color: '#FF9800'}
		];
	}
	getTableData = ({budget = 0, expense = 0, income = 0}) => {
		const t = this.props.t;
		return [
            {name: t('EXPENSES'), value: expense, color: '#FF9800'},
            {name: t('INCOME'), value: income, color: '#8bc34a'},
            //{name: t('BUDGET'), value: budget, color: '#e5e5e5'}
        ]
    };

	getIncomeData = ({budget = 0, expense = 0, income = 0}) => {
        let remaining = 0;
        if(income > expense && income > budget) {
            income = 100;
        }else {
        	if(budget < expense) {
        		income = (income/expense) * 100;
        		remaining = 100 - income;
			}else {
                income = (income/budget) * 100;
                remaining = 100 - income;
			}
        }
        return [
            {name: '', value: remaining , color: '#fff'},
            {name: 'income', value: income, color: '#8bc34a'}
        ];
	};

	render() {
		const {
			stats = {},
			currencyDetails = {},
			isLoading,
			t
		} = this.props;
		const chartData = this.getChartData(stats);
		const incomeData = this.getIncomeData(stats);
		const tableData = this.getTableData(stats);
		return (
			<div className='padding15'>
				<Card
					className='hoverable padding-remove min-height346'
					title={t('EXPENSES_VS_INCOME')}>
					<Row className="flex-container">
						<Col s={12} m={12} l={5}>
					<ResponsiveContainer width='100%' height={300} className='valign-wrapper'>
						{
							isLoading
							? <Preloader flashing size='big' className='center-block'/>
							: <PieChart
								onMouseEnter={this.onPieEnter}>
								<Pie
									data={chartData}
									onClick={this.props.onPieClick}
									innerRadius={60}
									outerRadius={100}
									dataKey={'value'}
									startAngle={90}
									endAngle={450}
								>
									{chartData.map(({name, color}) =>
										<Cell
											key={name}
											fill={color}
										/>)}
								</Pie>
                                <Pie
									data={incomeData}
									onClick={this.props.onPieClick}
									innerRadius={20}
									outerRadius={60}
									dataKey={'value'}
									startAngle={90}
									endAngle={450}
								>
									{incomeData.map(({name, color}) =>
										<Cell
											key={name}
											fill={color}
										/>)}
								</Pie>
							</PieChart>
						}
					</ResponsiveContainer>
						</Col>
						<Col s={12} m={12} l={7} className="no-padding-mobile">
					<Collection className='fix-height-collection border-remove'>
						{tableData.map(({name, value, color}, index) =>
							<CollectionItem key={index} className="pointer-class" onClick={(event)=>this.props.onPieClick(event, name)}>
								<Row className='mb-0'>
									<Col s={6} m={6} l={6}>
										<Badge className='leftBadge'
											   style={{backgroundColor: color}}>
										</Badge>
                                        {name}
									</Col>
									<Col s={6} m={6} l={6} className='right-align'>
										<span className="nowrap">
											<FormattedNumber
												value={value}
												style= 'currency'
												currency= {currencyDetails.currencyCode || 'USD'}
												minimumFractionDigits= {2}
												maximumFractionDigits= {2}
											/>
										</span>
									</Col>
								</Row>
							</CollectionItem>
						)}
					</Collection>
						</Col>
					</Row>
				</Card>
			</div>
		);
	}
}

export default withTranslation()(ExpensesChart);
