import {
    EXPENSE_DATA_LOADING,
    GET_EXPENSE_DATA_SUCCESS,
    EXPENSE_DATA_ACTION,
    EXPENSE_DATA_FAILED,
    GET_EXPENSE_IMAGE,
    EXPENSE_IMAGE_LOADING
} from './types';
import { handleErrors } from './authActions';
import * as api from '../services/ApiCall';
import {getCurrentUTCTime, getMidDayTime} from "../components/common/Methods";

const getLoadingDispatch = (type, state) => ({type, state});

//Get Expense data 
export const getExpenses = (year) => dispatch => {
    dispatch(getLoadingDispatch(EXPENSE_DATA_LOADING, true));
    api.callPost('api/userdata/getTransactionsByType', year)
        .then(data => {
            dispatch({
                type: GET_EXPENSE_DATA_SUCCESS,
                payload: data.result
            });
            dispatch(getLoadingDispatch(EXPENSE_DATA_LOADING, false));
        })
        .catch(err => dispatch(handleErrors((err && err.data ? err.data : err), EXPENSE_DATA_FAILED)));
};

export const addUpdateExpenses = (dataObj, year, cb) => dispatch => {
    dispatch(getLoadingDispatch(EXPENSE_DATA_LOADING, true));
    dataObj.lastModifyTime = getCurrentUTCTime();
    if(dataObj.time){
        dataObj.time = getMidDayTime(dataObj.time);
    }
    api.callPost('api/userdata/addUpdateExpense', dataObj)
        .then(data => {
            api.callPost('api/userdata/getTransactionsByType', year)
                .then(data => {
                    if(dataObj._id) {
                        dispatch(getLoadingDispatch(EXPENSE_DATA_ACTION, 'UPDATE_EXPENSE'));
                    }else {
                        dispatch(getLoadingDispatch(EXPENSE_DATA_ACTION, 'ADD_EXPENSE'));
                    }
                    dispatch({
                        type: GET_EXPENSE_DATA_SUCCESS,
                        payload: data.result
                    });
                    if(typeof cb === 'function') {
                        cb();
                    }
                    dispatch(getLoadingDispatch(EXPENSE_DATA_LOADING, false));
                    dispatch(getLoadingDispatch(EXPENSE_DATA_ACTION, false));
                })
        })
        .catch(err => dispatch(handleErrors((err && err.data ? err.data : err), EXPENSE_DATA_FAILED)));
};

export const deleteTransaction = (data, year) => dispatch => {
    let dataObj = {
        lastModifyTime: getCurrentUTCTime(),
        transactions: data.transactions
    };
    dispatch(getLoadingDispatch(EXPENSE_DATA_LOADING, true));
    api.callPost('api/userdata/deleteTransactionWeb', dataObj)
        .then(data => {
            api.callPost('api/userdata/getTransactionsByType', year)
                .then(data => {
                    dispatch(getLoadingDispatch(EXPENSE_DATA_ACTION, 'DELETE'));
                    dispatch({
                        type: GET_EXPENSE_DATA_SUCCESS,
                        payload: data.result
                    });
                    dispatch(getLoadingDispatch(EXPENSE_DATA_LOADING, false));
                    dispatch(getLoadingDispatch(EXPENSE_DATA_ACTION, false));
                })
        })
        .catch(err => dispatch(handleErrors((err && err.data ? err.data : err), EXPENSE_DATA_FAILED)));
};

export const uploadMedia = (dataFile, fileName, cb) => dispatch => {
    const formData = new FormData();
    formData.append('file', dataFile, fileName);
    dispatch(getLoadingDispatch(EXPENSE_IMAGE_LOADING, true));
    api.uMediaPost(formData)
        .then(data => {
            if(typeof cb === 'function') {
                cb();
            }
            dispatch(getLoadingDispatch(EXPENSE_DATA_ACTION, 'ADD_MEDIA'));
            dispatch(getLoadingDispatch(EXPENSE_IMAGE_LOADING, false));
        })
        .catch(err => {dispatch(getLoadingDispatch(EXPENSE_IMAGE_LOADING, false));dispatch(handleErrors((err && err.data ? err.data : err), EXPENSE_DATA_FAILED))});
};

export const getMedia = (fileName, userId=false,cb) => dispatch => {
    dispatch(getLoadingDispatch(EXPENSE_IMAGE_LOADING, true));
    if(!userId) {
        const userDetail = JSON.parse(localStorage.getItem('profileDetails'));
        userId = userDetail.userId;
    }
    api.umediaGet(`media/${userId}/${fileName}`)
        .then((imageData) => {
            dispatch({
                type: GET_EXPENSE_IMAGE,
                payload: imageData
            });
            if(typeof cb === 'function') {
                cb(imageData)
            }
            dispatch(getLoadingDispatch(EXPENSE_IMAGE_LOADING, false));
        }).catch((err)=>{dispatch(getLoadingDispatch(EXPENSE_IMAGE_LOADING, false));})
};




