import {
    GET_ACCOUNT_LIST_FAILED,
    GET_ACCOUNT_LIST_SUCCESS,
    GET_ACCOUNT_LIST_LOADING
} from './types';
import { handleErrors } from './authActions';
import * as api from '../services/ApiCall';
import {
    finObjectByKey, generateRandomLocalIdLong, getAccountTypeName,
    getCurrentUTCTime
} from "../components/common/Methods";
import {accountTypeConst, CAT_TYPE, CAT_TYPE_TEXT} from "./config";
import moment from "moment";

export const getLoadingDispatch = (type, state) => ({type, state});

export const getAccountBalanceCurrent = (account = {}, calculateAmount, income = CAT_TYPE_TEXT['EXPENSE']) => {
    if (account.currentBalance===undefined||account.currentBalance===null || account.currentBalance===0) {
        if(account.accountType == accountTypeConst['CREDIT_CARD'] || account.accountType == accountTypeConst['LOAN']){
            account.currentBalance =  (0-account.accountBalance)
        }
        else{
            account.currentBalance = account.accountBalance;
        }
    }
    if(account) {
        if(income === CAT_TYPE_TEXT['EXPENSE']){
            account.currentBalance = account.currentBalance - calculateAmount;
        }
        if(income === CAT_TYPE_TEXT['INCOME']){
            account.currentBalance = account.currentBalance + calculateAmount;
        }
    }
   
    return account;
};

export const filterAccountList = (list = []) => {
    let filterData = (list.accounts || list || []).filter((a) => a.status === 0);
    filterData.forEach((data, index) => {
        data.oldcurrentBalance = data.accountBalance;
        data.currentBalance  =  data.currentBalance !== undefined && data.currentBalance != null ?  data.currentBalance: 0;
            if(data.accountType == accountTypeConst['CREDIT_CARD'] || data.accountType == accountTypeConst['LOAN']){
                data.currentBalance =  (data.currentBalance)
            }
            else{
                data.currentBalance = data.currentBalance;
            }
    });

    // (list.balances || []).forEach(balance => {
    //     const data = filterData.filter((dataObj, index) => {
    //         if(balance.accoundId === dataObj.accountId && balance.userId === dataObj.userId) {
    //             filterData[index] = getAccountBalanceCurrent(filterData[index], balance.amount, balance.type);
    //             return filterData[index];
    //         }
    //     });
    // });
    // filterData.forEach(cd =>  {
    //     cd.currentBalance =  cd.onlineAccount ? cd.accountBalance : cd.currentBalance;
    // });
    return filterData;
};

export const filterCurrentMonthTrx = (list = [], type) => {
    return list;
};

export const getAccountList = () => dispatch => {
    dispatch(getLoadingDispatch(GET_ACCOUNT_LIST_LOADING, true));
    api.callPost('api/userdata/getAllAccounts')
        .then(res => {
            dispatch({
                type: GET_ACCOUNT_LIST_SUCCESS,
                payload: filterAccountList(res.results)
            });
            dispatch(getLoadingDispatch(GET_ACCOUNT_LIST_LOADING, false));
        })
        .catch(err => dispatch(handleErrors((err && err.data ? err.data : err), GET_ACCOUNT_LIST_FAILED)));
};

export const addUpdateAccount = (dataObj, cb) => dispatch => {
    dispatch(getLoadingDispatch(GET_ACCOUNT_LIST_LOADING, true));
    dataObj['accounts'][0].lastModifyTime = getCurrentUTCTime();
    let showMessage = 'ACCOUNT_UPDATED';
    if(!dataObj['accounts'][0]._id) {
        dataObj['accounts'][0].accountId = generateRandomLocalIdLong();
        showMessage = 'ACCOUNT_ADDED';
    }
    api.callPost('api/userdata/addAccountWeb', dataObj)
        .then(data => {
            return data;
        })
        .then(data => {
            return api.callPost('api/userdata/getAllAccounts')
        })
        .then(res => {
            dispatch({
                type: GET_ACCOUNT_LIST_SUCCESS,
                payload: filterAccountList(res.results)
            });
            dispatch(getLoadingDispatch(GET_ACCOUNT_LIST_LOADING, false));
            if(typeof cb === 'function') {
                cb(showMessage);
            }
        })
        .catch(err => dispatch(handleErrors((err && err.data ? err.data : err), GET_ACCOUNT_LIST_FAILED)));
};

export const getAccountTrxList = (acctId, cb, pageId = 0) => dispatch => {
    const data = {
        accountId: acctId,
        page: pageId
    };
    dispatch(getLoadingDispatch(GET_ACCOUNT_LIST_LOADING, true));
    api.callPost('api/userdata/getAccountTrx', data)
    .then(res => {
        cb({results: filterCurrentMonthTrx(res.results, CAT_TYPE_TEXT['EXPENSE'])});
        dispatch(getLoadingDispatch(GET_ACCOUNT_LIST_LOADING, false));
    })
    .catch(err => dispatch(handleErrors((err && err.data ? err.data : err), GET_ACCOUNT_LIST_FAILED)));
};

export const updateAccTrx = (trxData, trxType, accountId, cb, pageNumber = 0) => dispatch => {
    let query = 'api/userdata/addUpdateIncome';
    if(CAT_TYPE[trxType + ''] === 'EXPENSE_TYPE') {
        query = 'api/userdata/addUpdateExpense';
        trxData['time'] = moment(trxData.expenseDate).utc().valueOf();
    }
    trxData.lastModifyTime = getCurrentUTCTime();
    dispatch(getLoadingDispatch(GET_ACCOUNT_LIST_LOADING, true));
    let updatedAccObj = {};

    api.callPost(query, trxData)
    .then(resData => {
        return api.callPost('api/userdata/getAllAccounts')
    }).then(res => {
        const filterAccList = filterAccountList(res.results);
        dispatch({
            type: GET_ACCOUNT_LIST_SUCCESS,
            payload: filterAccList
        });
        updatedAccObj = finObjectByKey(filterAccList, accountId, 'accountId');
        let arr = new Array(pageNumber + 1).fill(0);
        return Promise.all(arr.map((data, index) =>{
            let dataObj = {
                accountId: accountId,
                page: index
            };
            return api.callPost('api/userdata/getAccountTrx', dataObj);
        }));
    })
    .then(res => {
        let resultData = [];
        res.map(data => resultData.push(...data.results));
        resultData = filterCurrentMonthTrx(resultData, CAT_TYPE_TEXT['EXPENSE'])
        cb(resultData,  'TRANSACTION_UPDATED', true, updatedAccObj);
        dispatch(getLoadingDispatch(GET_ACCOUNT_LIST_LOADING, false));
    })
    .catch(err => dispatch(handleErrors((err && err.data ? err.data : err), GET_ACCOUNT_LIST_FAILED)));
};

export const deleteAccountData = (acctId, cb) => dispatch => {
    const data = {
        accountId: acctId.accountId
    };
    dispatch(getLoadingDispatch(GET_ACCOUNT_LIST_LOADING, true));
    api.callPost('api/userdata/deleteAccount', data)
        .then(data => {
            return data;
        })
        .then(data => {
            return api.callPost('api/userdata/getAllAccounts')
        })
        .then(res => {
            dispatch({
                type: GET_ACCOUNT_LIST_SUCCESS,
                payload: filterAccountList(res.results)
            });
            dispatch(getLoadingDispatch(GET_ACCOUNT_LIST_LOADING, false));
            if(typeof cb === 'function') {
                cb('ACCOUNT_DELETE_SUCCESS');
            }
        })
        .catch(err => dispatch(handleErrors((err && err.data ? err.data : err), GET_ACCOUNT_LIST_FAILED)));
};

export const deleteAccountTransaction = (data, selectedAcct, cb) => dispatch => {
    let dataObj = {
        lastModifyTime: getCurrentUTCTime(),
        transactions: data.transactions
    };
    dispatch(getLoadingDispatch(GET_ACCOUNT_LIST_LOADING, true));
    api.callPost('api/userdata/deleteTransactionWeb', dataObj)
        .then(data => {
            return api.callPost('api/userdata/getAllAccounts')
        }).then(res => {
            const filterAccList = filterAccountList(res.results);
            dispatch({
                type: GET_ACCOUNT_LIST_SUCCESS,
                payload: filterAccList
            });
            return finObjectByKey(filterAccList, selectedAcct.accountId, 'accountId');
        }).then(res => {
            cb(res, 'delete');
        })
        .catch(err => dispatch(handleErrors((err && err.data ? err.data : err), GET_ACCOUNT_LIST_FAILED)));
};

export const uploadMedia = (dataFile, fileName, cb) => {
    const formData = new FormData();
    formData.append('file', dataFile, fileName);
    api.uMediaPost(formData)
        .then(data => {
            if(cb !== 'undefined') {
                cb(data, true);
            }
        })
        .catch(err => cb(err, false));
};

export const getRecurringIncomeTrx = (recurringServerId, cb, singleRecord = true) => {
    api.callPost('api/userdata/getRecurringParentTrx', recurringServerId)
        .then(({Transaction}) => {
            if(cb !== 'undefined') {
                const recurringData = singleRecord ? {
                    recurringCategoryId: Transaction.recurringCategoryId,
                    recurringCount: Transaction.recurringCount,
                    repeatTillCount: Transaction.repeatTillCount,
                    repeatTillDate: Transaction.repeatTillDate
                } : Transaction;

                cb(recurringData);
            }
        }).catch(err => cb(err, false));
};

export const deleteIncomeTrx = (data, selectedAcct, cb, isSingleRecord = true) => dispatch => {
    dispatch(getLoadingDispatch(GET_ACCOUNT_LIST_LOADING, true));
    let dataObj = data;
    let apiURL = 'api/userdata/deleteRecurringTrx';
    if(isSingleRecord) {
        apiURL = 'api/userdata/deleteTransactionWeb';
        dataObj = {
            transactions: [
                {
                    _id: data.id
                }
            ]
        }
    }
    dataObj.lastModifyTime = getCurrentUTCTime();
    api.callPost(apiURL, dataObj)
        .then(res => {
            return api.callPost('api/userdata/getAllAccounts')
        }).then(res => {
            const filterAccList = filterAccountList(res.results);
            dispatch({
                type: GET_ACCOUNT_LIST_SUCCESS,
                payload: filterAccList
            });
            return finObjectByKey(filterAccList, selectedAcct.accountId, 'accountId');
        }).then(res => {
            cb(res, 'delete');
        })
        .catch(err => dispatch(handleErrors((err && err.data ? err.data : err), GET_ACCOUNT_LIST_FAILED)));
};