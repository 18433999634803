import {
    PAID_BILLING_DATA_LOADING,
    UPCOMING_BILLING_DATA_LOADING,
    OVERDUE_BILLING_DATA_LOADING,
    RECURRING_BILLING_DATA_LOADING,
    GET_PAID_BILL_DATA_SUCCESS,
    GET_OVERDUE_BILL_DATA_SUCCESS,
    GET_UPCOMING_BILL_DATA_SUCCESS,
    GET_RECURRING_BILL_DATA_SUCCESS,
    BILL_DATA_FAILED,
    BILL_DATA_LOADING, 
    BILL_DATA_ACTION, 
    UPDATE_BILL_DATA_LIST,
    GET_RECURRING_SUCCESS,
    BILL_PAYMENT_FETCH_SUCCESS
} from '../actions/types';

const initialState = {
    PAID_BILLING_DATA_LOADING: false,
    UPCOMING_BILLING_DATA_LOADING: false,
    OVERDUE_BILLING_DATA_LOADING: false,
    RECURRING_BILLING_DATA_LOADING: false,
    paidTransactions: [],
    overdueTransactions: [],
    upcomingTransactions: [],
    recurringTransactions: [],
    billPaymentList:[]

};

export default function (state = initialState, action) {
	// console.log('In Dashboard Reducer..', action);
    switch (action.type){
        case GET_PAID_BILL_DATA_SUCCESS:
            return {
                ...state,
                paidTransactions: action.payload
			};
        case GET_OVERDUE_BILL_DATA_SUCCESS:
            return {
                ...state,
                overdueTransactions: action.payload
            };
        case GET_UPCOMING_BILL_DATA_SUCCESS:
            return {
                ...state,
                upcomingTransactions: action.payload
            };
        case BILL_PAYMENT_FETCH_SUCCESS:
            return {
                ...state,
                billPaymentList: action.payload
            };
        case GET_RECURRING_BILL_DATA_SUCCESS:
            return {
                ...state,
                recurringTransactions: action.payload
            };
        case BILL_DATA_ACTION:
            if(state.error) {
                delete state.error
            }
            return{
                ...state,
                [action.type] : action.state
            };
        case GET_RECURRING_SUCCESS:
            if(state.error) {
                delete state.error
            }
            return {
                ...state,
                recurringData: action.payload
            };
        case UPDATE_BILL_DATA_LIST:
            if(state.error) {
                delete state.error
            }
            return{
                ...state,
                [action.key] : action.payload
            };
        case BILL_DATA_FAILED:
            state[BILL_DATA_LOADING] = false;
            return {
                ...state,
                error: action.payload
            };
		case PAID_BILLING_DATA_LOADING:
		case UPCOMING_BILLING_DATA_LOADING:
        case OVERDUE_BILLING_DATA_LOADING:
        case RECURRING_BILLING_DATA_LOADING:
        case BILL_DATA_LOADING:
            if(state.error) {
                delete state.error
            }
            return{
                ...state,
                [action.type] : action.state
            };
        default:
            return state;
    }
}
