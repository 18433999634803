import React from 'react';
import {
    LONG_MONTHS
} from './ConstData';

export default (props) =>
	<div className='year-selector' style={props.style}>
		<div className='value'>{props.t(LONG_MONTHS[props.value])}</div>
        {props.yearValue !== new Date().getFullYear() ? <div className="year-value">{props.yearValue}</div> : null}
		<i className="material-icons" onClick={(e) => props.onChange(props.value-1)}>chevron_left</i>
		<i className="material-icons" onClick={(e) => props.onChange(props.value+1)}>chevron_right</i>
	</div>
