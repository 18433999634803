import {
    USER_PROFILE_FAILED,
    USER_PROFILE_LOADING,
    USER_PROFILE_SUCCESS,
    USER_PROFILE_ACTION,
} from '../actions/types';


const initialState = {
    USER_PROFILE_LOADING: false,
    profileData: []
};

export default function (state = initialState, action) {
    switch (action.type){
        case USER_PROFILE_SUCCESS:
            if(state.error) {
                delete state.error
            }
            return {
                ...state,
                profileData: action.payload
            };
        
        case USER_PROFILE_LOADING:
            return{
                ...state,
                [action.type] : action.state
            };

        case USER_PROFILE_ACTION:
            if(state.error) {
                delete state.error
            }
            return{
                ...state,
                [action.type] : action.state
            };
        case USER_PROFILE_FAILED:
            state[USER_PROFILE_LOADING] = false;
            if(state.error) {
                delete state.error
            }
            return {
                ...state,
                error: action.payload
            };
        default:
            return state;
    }
}

