import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Tabs from "./common/Tabs";
import {
    Row,
    Col
} from 'react-materialize';
import YearSelector from "./common/YearSelector";
import SavingChart from "./charts/SavingChart";
import {
    getIncomeExpenseTrend, sendStatement, getTrendByCategory
} from "../actions/dashboardActions";
import withRouter from "react-router-dom/es/withRouter";
import StatementReport from "./reportComponents/StatementReport";
import TrendsReport from "./reportComponents/TrendsReport";
import Swal from 'sweetalert2';
import {withTranslation, Trans} from 'react-i18next'

let pageStyle = {
    backgroundSize:"cover",
    color:"grey",
    height:"100vh"
};

class Report extends Component {
    constructor(props) {
        super(props);
        this.onYearChange = this.onYearChange.bind(this);
        const date = new Date();
        const year = date.getFullYear();
        const month = date.getMonth();
        this.state = {
            month,
            year,
            currentYear: year,
            userSettings: JSON.parse(localStorage.getItem('profileDetails')) || {},
            currentActiveTab: 'Savings',
            categoryId: 3,
        }
    }

    componentWillReceiveProps(nextProps) {
        if(!nextProps.dashboard.SEND_USER_STATEMENT && nextProps.dashboard.STATEMENT_ACTION) {
            this.getAlertBox('EMAIL_SENT', 'success');
        }
    }

    getAlertBox = (message, type) => {
        const t = this.props.t;
        Swal.fire({
            title: t(message),
            position: 'bottom-start',
            toast: true,
            showConfirmButton: false,
            timer: 2000,
            background: type === 'success' ? '#8dc44e' : '#f1521c',
            customClass: {
                title: 'sweet-alert',
                container: 'sweet-alert-container'
            }
        });
    }

    componentDidMount () {
        this.onYearChange(this.state.year);
    }

    onYearChange(year) {
        this.setState({
            year
        });
        this.props.getIncomeExpenseTrend({year: year});
        this.props.getTrendByCategory(year, this.state.categoryId);
    }

    setActiveTab = (tab, d) => {
        this.setState({
            currentActiveTab: tab.id
        });
    };

    sendStatement = (data) => {
        if(new Date(data.startTime).getTime() > new Date(data.endTime).getTime()) {
            return this.getAlertBox('FROM_DATE_VALIDATION', 'error');
        }
        this.props.sendStatement(data);
    };

    onCategoryChange = (category) => {
        this.setState({
            categoryId: category
        });
        this.props.getTrendByCategory(this.state.year, category);
    };

    render () {
        const {
            year,
            currentYear,
            userSettings = {},
        } = this.state;
        const {
            dashboard: {
                trendChartData,
                trendCategoryData,
                GET_BY_TRENDS_STATS_LOADING = true,
                GET_BY_TRENDS_CATEGORY_LOADING = true
            },
            t
        } = this.props;

        return (
            <div style={pageStyle}>
                <Row className='mb-0 mobile-view'>
                    <Col s={12} m={12} l={12}>
                        &nbsp;
                    </Col>
                </Row>
                <Row className='mb-0 reports-section'>
                    <Col s={12} m={12} l={12} style={{position: 'relative'}} >
                        <div className='overlap-tab-filter tab-filter-mobile'>
                            {this.state.currentActiveTab === 'Statement' ? null : <YearSelector
                                value={year}
                                onChange={this.onYearChange}
                                style={{paddingTop: '13px'}}
                            />}
                        </div>
                        <Col s={12} m={12} l={12}  className='tab-mobile-fixed'>
                            <Tabs
                            reciveProps={true}
                            activeTab={{
                                id: this.state.currentActiveTab
                            }}
                            className='tab-demo z-depth-1'
                            onClick={this.setActiveTab}
                        >
                            <Tabs.Tab
                                className="tab-title"
                                title={
                                    <div className='tab-title' title={t('SAVINGS')}>
                                        <Trans>SAVINGS</Trans>
                                    </div>}
                                id="Savings"
                            >
                                <SavingChart
                                    stats={trendChartData}
                                    currencyDetails={userSettings.currencyDetails}
                                    isLoading={GET_BY_TRENDS_STATS_LOADING}
                                    currentYear={currentYear}
                                />
                            </Tabs.Tab>
                            <Tabs.Tab
                                title={
                                    <div className='tab-title' title={t('TRENDS')}>
                                        <Trans>TRENDS</Trans>
                                    </div>
                                }
                                id="Trends"
                            >
                                <TrendsReport
                                    t={t}
                                    categoryId={this.state.categoryId}
                                    onCategoryChange={this.onCategoryChange}
                                    stats={trendCategoryData}
                                    isLoading={GET_BY_TRENDS_CATEGORY_LOADING}
                                    currencyDetails={userSettings.currencyDetails}
                                />
                            </Tabs.Tab>
                            {/* <Tabs.Tab
                                title={
                                    <div className='tab-title' title={t('STATEMENT')}>
                                        <Trans>STATEMENT</Trans>
                                    </div>
                                }
                                id="Statement"
                            >
                                <StatementReport onSubmit={this.sendStatement}/>
                            </Tabs.Tab> */}
                        </Tabs>
                        </Col>
                    </Col>
                </Row>
            </div>
        )
    }
}
Report.propTypes = {
    auth: PropTypes.object.isRequired,
    getIncomeExpenseTrend: PropTypes.func.isRequired,
    getTrendByCategory: PropTypes.func.isRequired,
    sendStatement: PropTypes.func.isRequired
};

const mapStateToProps = (state) => {
    // console.log('We have data..', state);
    return state;
};

export default withTranslation()(connect(
    mapStateToProps,
    {
        getIncomeExpenseTrend,
        getTrendByCategory,
        sendStatement
    })(withRouter(Report)));
