import React, { Component } from 'react';
import {withTranslation} from 'react-i18next';
import {
	Card,
    Row,
    Col,
	Collection,
	CollectionItem,
	Badge,
	Preloader
} from 'react-materialize';
import {
	ResponsiveContainer,
	PieChart,
	Pie,
	Cell
} from 'recharts';
import { FormattedNumber } from "react-intl";

class BillsChart extends Component {
	onPieEnter = () => 'Name';
	render() {
		// console.log('In Bill Chart..', this.props);
		const {
			stats: {
				overdue = 0,
				paid = 0,
				upcoming = 0,
			},
			currencyDetails = {},
			isLoading,
			t
		} = this.props;
		const data = [
			{name: t('UPCOMING'), value: upcoming, color: '#ffeb3b'},
			{name: t('OVERDUE'), value: overdue, color: '#F44336'},
			{name: t('PAID'), value: paid, color: '#8bc34a'},
		];
		if(upcoming === 0 && overdue === 0 && paid === 0)
			data.push({name: '', value: 100, color: '#bdb8b8'});
		return (
			<div className='padding15'>
				<Card
					className='hoverable padding-remove min-height346'
					title={t('BILLS')}>
					<Row className="flex-container">
						<Col s={12} m={12} l={5}>
							<ResponsiveContainer width='100%' height={300} className='valign-wrapper'>
                                {
                                    isLoading
                                        ? <Preloader flashing size='big' className='center-block'/>
                                        : <PieChart
											onMouseEnter={this.onPieEnter}
										>
											<Pie
												onClick={this.props.onPieClick}
												data={data}
												innerRadius={60}
												outerRadius={100}
												dataKey={'value'}
												startAngle={90}
												endAngle={450}
											>
                                                {data.map(({name, color}) =>
													<Cell
														key={name}
														fill={color}
													/>)}
											</Pie>
										</PieChart>
                                }
							</ResponsiveContainer>
						</Col>
						<Col s={12} m={12} l={7} className="no-padding-mobile">
							<Collection className='fix-height-collection border-remove'>
                                {data.filter(({name}) => name)
                                    .map(({name, value, color}) =>
											<CollectionItem key={name} className="pointer-class" onClick={(event)=>this.props.onPieClick(event, name)}>
												<Badge className='leftBadge'
													   style={{backgroundColor: color}}>
												</Badge>
                                                {name}
												<span className='floatRight'>
													<span className="nowrap">
														<FormattedNumber
															value={value}
															style='currency'
															currency={currencyDetails.currencyCode || 'USD'}
															minimumFractionDigits={2}
															maximumFractionDigits={2}
														/>
													</span>
								</span>
											</CollectionItem>
                                    )}
							</Collection>
						</Col>
					</Row>

				</Card>
			</div>
		);
	}
}
export default withTranslation()(BillsChart);