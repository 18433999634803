import React , { Component } from 'react';
import { Row, Col} from 'react-materialize';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import {withTranslation, Trans} from 'react-i18next';
import BillsChart from './charts/Bills';
import ExpensesChart from './charts/Expenses';
import ByCategoryExpensesChart from './charts/Categories';
import {
	getExpensesStats,
	getBillingStats,
	getExpenseByCategory,
	getIncomeExpenseTrend,
} from "../actions/dashboardActions";
import {
    getNoticeMessage,
    updateNotificationStatus
} from '../actions/authActions';
import {
	LONG_MONTHS as _months,
} from './common/ConstData';
import ExpenseMonthSelector from "./common/ExpenseMonthSelector";

class  Dashboard extends Component{
	constructor(props) {
		super(props);
		const date = new Date();
		const year = date.getFullYear();
		const month = date.getMonth();
		const monthArr = [];
		const { t } = this.props;
		for(let i=0; i< 12; i++) {
			let m = (month + 1) - i;
			monthArr
				.push((m >= 0 && m < 12)
					?{id: `${m}-${year}`, name: `${t(_months[m])}-${year}`}
					: m === 12 ? {id: `${0}-${year+1}`, name: `${t(_months[0])}-${year+1}`}
					: {id: `${12+m}-${year-1}`, name: `${t(_months[12 + m])}-${year-1}`});
		}

		this.state = {
			month,
			year,
			monthArr,
			noticeMessageObj: {},
			userSettings: JSON.parse(localStorage.getItem('profileDetails')) || {},
		}
	}
    componentDidMount () {
		if(!localStorage.getItem('hideNotice')) {
            this.props.getNoticeMessage((res) => {
            	this.setState({
                    noticeMessageObj: res
				});
			});
		}
        this.props.getExpensesStats({
				month: +this.state.month,
				year: +this.state.year
        	});
        this.props.getBillingStats({
            month: +this.state.month,
            year: +this.state.year
        });
        this.props.getExpenseByCategory({
            month: +this.state.month,
            year: +this.state.year
        });
        this.props.getIncomeExpenseTrend({
            year: +this.state.year
        });
    }
	hideTimeZonenotification = () => {
		this.props.updateNotificationStatus({key: 'timezone', value: false});
	};
	onMonthChange = (month) => {
		// console.log('In Dropdown on change.. ', value);
		let year = this.state.year;
        if (month < 0) {
            month = 11;
            year -= 1;
            this.setState({
                month: month,
                year: year
            });
        }else if (month > 11) {
            month = 0;
            year += 1;
            this.setState({
                month: month,
                year: year
            });
        }else {
            this.setState({
				month: month,
				year: year
			})
		}
		this.props.getExpensesStats({
            month: +month,
            year: +year
        });
		this.props.getBillingStats({
            month: +month,
            year: +year
        });
		this.props.getExpenseByCategory({
            month: +month,
            year: +year
        });
		this.props.getIncomeExpenseTrend({
            month: +month,
            year: +year
        });
	};

    onBillPieClick = (e, name) => {
		if(e.name)
			this.props.history.push('/bills#' + e.name.toLowerCase());
		else if(name){
            this.props.history.push('/bills#' + name.toLowerCase());
        }
	};

    onExpensesPieClick = (e, name) => {
    	if(e.name) {
            this.props.history.push('/' + e.name.toLowerCase());
		}
        else if(name){
            this.props.history.push('/' + name.toLowerCase());
        }
    };

    hideNoticeSection = () => {
    	this.setState({
            noticeMessageObj: {}
		});
    	localStorage.setItem('hideNotice', true);
	};

    makeNoticeUI = () => {
    	const {actionMsg, actionURL, image, message, title} = this.state.noticeMessageObj;
    	return (
    		<React.Fragment>
				{ Object.keys(this.state.noticeMessageObj).length > 0 ?
					<div className="notice-ui">
						{image ? <Col s={1} m={1} l={1}>
							<div>
								<span>
									<img src={image} alt="notice blank" />
								</span>
							</div>
						</Col> : null}
						<Col s={image ? 11  : 12} m={image ? 11  : 12} l={image ? 11  : 12}>
							<div>
								<span>
									{title ? <h6>{title}</h6> : null}
									<h6>{message} {actionURL ? <a href={actionURL} className="text-nowrap" target="_blank">{actionMsg}</a> : null } </h6>
								</span>
							</div>
						</Col>
						<i
							className="material-icons right"
							style={{cursor: 'pointer'}}
							onClick={this.hideNoticeSection}>
							close
						</i>
					</div> : null}
			</React.Fragment>
		)
	};

    render(){
		// console.log('In DashBoard this.props>>', this.props.dashboard);
		const {
			auth: {
				notification = {},
			},
			dashboard: {
				billsChartData,
				byCategoryExpenseChartData,
				expensesChartData,
				GET_BILL_STATS_LOADING = true,
				GET_BY_CATEGORY_STATS_LOADING = true,
				GET_EXPENSE_STATS_LOADING = true
			}
		} = this.props;
		const {
			month,
			year,
			userSettings = {},
		} = this.state;
		notification.timezone = (notification.timezone === undefined) ? true : notification.timezone;
        return (
			<div className="relative-pos">
				<Row>
                    {
                        !localStorage.getItem('hideNotice') ? this.makeNoticeUI() : null
                    }
                    {
                        (notification.timezone && userSettings.timeZoneId === undefined) ?
							<Col s={12} m={12} l={12}
								 className='center-align timezone-notice'>
								<div style={{display: 'inline-flex'}}>
									<span>
										<Trans>PLEASE_UPdDATE_YOUR_TIMEZONE_FROM</Trans>
										<Link to="/account#settings"> <Trans>SETTINGS</Trans>, </Link>
										<Trans>BETTER_EXPERIENCE</Trans>
									</span>
								</div>
								<i
									className="material-icons right"
									style={{cursor: 'pointer'}}
									onClick={this.hideTimeZonenotification}
								>
									close
								</i>
							</Col>
                            : ''
                    }

					<Col s={12} m={12} l={12} style={{'textAlign': 'center'}}>
						<ExpenseMonthSelector
							t={this.props.t}
							yearValue={year}
							value={month}
							onChange={this.onMonthChange}
							style={{paddingTop: '13px'}}
						/>
					</Col>
					<Col s={12} m={6} l={6} className='tablet-devices'>
						<BillsChart
                            onPieClick={this.onBillPieClick}
							stats={billsChartData}
							currencyDetails={userSettings.currencyDetails}
							isLoading={GET_BILL_STATS_LOADING}
						/>
					</Col>
					<Col s={12} m={6} l={6} className='tablet-devices'>
						<ExpensesChart
                            onPieClick={this.onExpensesPieClick}
							stats={expensesChartData}
							currencyDetails={userSettings.currencyDetails}
							isLoading={GET_EXPENSE_STATS_LOADING}
						/>
					</Col>
					<Col s={12} m={12} l={12}>
						<ByCategoryExpensesChart
							stats={byCategoryExpenseChartData}
							currencyDetails={userSettings.currencyDetails}
							isLoading={GET_BY_CATEGORY_STATS_LOADING}
						/>
					</Col>
				</Row>
				<Row>
					<Col s={12} m={12} l={12}>
						<div className="google-ads padding15">
							<script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js" />
							<ins className="adsbygoogle"
								 style={{'display': 'block'}}
								 data-ad-client="ca-pub-9227181377666082"
								 data-ad-slot="8792541623"
								 data-ad-format="auto"
								 data-full-width-responsive="true" />
							<script>
								(adsbygoogle = window.adsbygoogle || []).push({});
							</script>
						</div>
					</Col>
				</Row>
				<div>&nbsp;</div>
				<div style={{lineHeight: '80px'}}>&nbsp;</div>
			</div>
		);
    }
}

Dashboard.propTypes = {
    auth: PropTypes.object.isRequired,
	getBillingStats: PropTypes.func.isRequired,
	getExpensesStats: PropTypes.func.isRequired,
	getExpenseByCategory: PropTypes.func.isRequired,
	getIncomeExpenseTrend: PropTypes.func.isRequired,
	updateNotificationStatus: PropTypes.func.isRequired,
    getNoticeMessage: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
	// console.log('We have data..', state);
	return state;
};

export default  withTranslation()(connect (
	mapStateToProps,
	{
		getBillingStats,
		getExpensesStats,
		getExpenseByCategory,
		getIncomeExpenseTrend,
		updateNotificationStatus,
        getNoticeMessage
	})(withRouter(Dashboard)));
