import {
    SET_CURRENT_USER,
    GET_USER_PROFILE_SUCCESS,
    GET_CURRENCIES_SUCCESS,
    GET_TIME_ZONES_SUCCESS,
    SAVE_USER_PROFILE_SUCCESS,
    NOTIFICATION_DETAILS_UPDATED,
    USER_SETTINGS_CACHE,
    GET_USER_SETTINGS_SUCCESS,
    GET_CATEGORIES_SUCCESS,
    GET_SERVICE_PROVIDERS_SUCCESS,
    LOGOUT_USER, GET_LANGUAGES_SUCCESS, SETTING_DATA_ACTION,
    PROFILE_DATA_SUCCESS,
    GET_USER_DETAILS_SUCCESS,
    GET_SETTINGS_DETAILS_SUCCESS,
    GROUP_USER_IMAGE_SUCCESS,
    USER_UPDATE_SUCCESS, GET_ACCOUNT_TYPE_SUCCESS, GET_USER_ACCOUNTS, GET_ACCOUNT_LIST_SUCCESS, GET_GROUP_ACCOUNT_LIST_SUCCESS
} from '../actions/types';
import isEmpty from '../validation/is-empty';

const initialState = {
    isAuthenticated: false,
    user: {},
    userCategories: [],
    serviceProviders: [],
    details: {},
    userDetails: {},
    userAccountList: [],
    groupAccountList: [],
    groupImageList:{}
};

export default function (state = initialState, action) {
    switch (action.type) {
        case SET_CURRENT_USER:
            return {
                ...state,
                isAuthenticated: !isEmpty(action.payload),
                user: action.payload
            };
        case GET_ACCOUNT_LIST_SUCCESS:
            return {
                ...state,
                userAccountList: action.payload
            };
        case GET_GROUP_ACCOUNT_LIST_SUCCESS:
            return {
                ...state,
                groupAccountList: [...state.userAccountList, ...action.payload]
            };
        case GROUP_USER_IMAGE_SUCCESS:
            return {
                ...state,
                groupImageList: {...state.groupImageList,...action.payload}
            };
        case GET_USER_PROFILE_SUCCESS:
            return {
                ...state,
                userSettings: action.payload,
                settings: action.payload
            };
        case GET_CURRENCIES_SUCCESS:
            return {
                ...state,
                settingData: action.payload,
                currencies: action.payload
            };
        case GET_SETTINGS_DETAILS_SUCCESS:
            return {
                ...state,
                settingData: action.payload
            };
        case GET_TIME_ZONES_SUCCESS:
            return {
                ...state,
                timezones: action.payload
            };
        case GET_LANGUAGES_SUCCESS:
            return {
                ...state,
                languages: action.payload
            };
        case SAVE_USER_PROFILE_SUCCESS:
            return {
                ...state,
                message: action.payload
            };
        case SETTING_DATA_ACTION:
            if (state.error) {
                delete state.error
            }
            return {
                ...state,
                [action.type]: action.state
            };
        case NOTIFICATION_DETAILS_UPDATED:
            return {
                ...state,
                notification: action.payload
            };
        case USER_SETTINGS_CACHE:
            return {
                ...state,
                settings: action.payload
            };
        case GET_USER_SETTINGS_SUCCESS:
            return {
                ...state,
                settings: action.payload
            };
        case GET_CATEGORIES_SUCCESS:
            return {
                ...state,
                userCategories: action.payload
            };
        case GET_ACCOUNT_TYPE_SUCCESS:
            return {
                ...state,
                accountTypes: action.payload
            };
        case GET_SERVICE_PROVIDERS_SUCCESS:
            return {
                ...state,
                serviceProviders: action.payload
            };
        case PROFILE_DATA_SUCCESS:
            return {
                ...state,
                details: action.payload
            };
        case GET_USER_DETAILS_SUCCESS:
            return {
                ...state,
                userDetails: action.payload
            };
        case USER_UPDATE_SUCCESS:
            let oldUserData = {...state.userDetails.fetchUserProfile}; //copy
            let  newUserData ={
                _id: oldUserData._id,
                active: oldUserData.active,
                currency: oldUserData.currency,
                timeZoneOffset: oldUserData.timeZoneOffset,
                status: oldUserData.status,
                email: oldUserData.email,
                createdAt: oldUserData.createdAt,
                updatedAt: oldUserData.updatedAt,
                __v: oldUserData.__v,
                firstName: action.payload.firstName,
                lastName: action.payload.lastName,
                phoneNumber: action.payload.phoneNumber,
                profilePic: action.payload.profilePic
            };
            return {
                ...state,
                userDetails: {fetchUserProfile : newUserData, fetchProfile: state.userDetails.fetchProfile}
            };
        case LOGOUT_USER:
            return {};
        default:
            return state;
    }
}