import { combineReducers } from 'redux';
import authReducer from './authReducer';
import categoriesReducer from './categoriesReducer';
import errorReducer from './errorReducer';
import dashboardReducer from './dashboardReducer';
import loadingReducer from './loadingReducer';
import expensesReducer from './expensesReducer';
import billsReducer from './billsReducer';
import incomeReducer from './incomeReducer';
import budgetReducer from './budgetReducer';
import changePassword from './ChangePassword';
import userReducer from './userReducer';
import templateReducer from './templateReducer';
import accountReducer from './accountReducer';

export default combineReducers({
    auth: authReducer,
    errors: errorReducer,
	categories: categoriesReducer,
	dashboard: dashboardReducer,
    loading: loadingReducer,
    expenses: expensesReducer,
    bills: billsReducer,
    incomes: incomeReducer,
    budget: budgetReducer,
    changePasswordData: changePassword,
    userData: userReducer,
    templateData: templateReducer,
    account: accountReducer
});