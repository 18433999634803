import React from 'react';
import {
    Row,
} from 'react-materialize';
import { unescape }from 'lodash';
import {s3Url} from "../../actions/config";

export default (props) => {
    let serviceProvider;
    let providerObj;
    const { t } = props;
    if(props.providerType) {
        const serviceProviders = JSON.parse(localStorage.getItem('serviceProviders'));
        if(serviceProviders) {
            serviceProvider = serviceProviders.filter(({providerType, providerCurrency}) => (providerType === `${props.providerType}` ));
        }
        providerObj = props.value
            ? serviceProvider.find(({providerId}) => providerId === `${props.value}`)
            : serviceProvider[0];

        const otherObj = {
            created_at: new Date().toISOString(),
            logoURL: "",
            paymentURL: "",
            providerCurrency: "",
            providerId: "0",
            providerName: t('OTHERS'),
            providerType: "Others",
            updated_at: new Date().toISOString(),
            _id: "0"
        };
        serviceProvider.push(otherObj);
    }

    if(providerObj) {
        const style4Bg = {
            backgroundColor: providerObj && providerObj.iconColor ? providerObj.iconColor : 'transparent',
            height: '30px'
        };
        const onChange = (e) => {
            const categoryId = e.target.value;
            props.onChange(+categoryId);
        };
        let logoUrl = s3Url + providerObj.logoURL + '.png';

        return <Row><table
            className='table-category-selector'>
            <tbody>
            <tr>
                <td style={{width: '3.9rem'}}>
                    <img
                        src={logoUrl}
                        alt="icon"
                        className="leftMargin10 circle"
                        style={style4Bg}>
                    </img>
                </td>
                <td>
                    <select
                        onChange={onChange}
                        value={props.value}
                        placeholder={t('SELECT_PROVIDER')}
                        style={{display: 'block'}}
                    >
                        {
                            serviceProvider
                                .map(cat =>
                                    <option
                                        value={cat.providerId}
                                        key={cat.providerId}
                                    >
                                        {unescape(cat.providerName)}
                                    </option>
                                )
                        }
                    </select>
                </td>
            </tr>
            </tbody>
        </table>
        </Row>
    }else {
        return null
    }
}
