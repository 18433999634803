import React, {Component} from 'react';
import {withTranslation, Trans} from 'react-i18next';
import {
    Icon,
    Row,
    Col
} from 'react-materialize';

import {connect} from 'react-redux';
import {
    addtransaction,
    getcategories,
    searchtransaction,
    cacheTransaction
} from "../actions/transactionActions";
import CategorySelector from './common/CategorySelector';
import RecurringSelector from './common/RecurringSelector';
import '../stylesheets/App.css'
import * as Swal from "sweetalert2";
import ServiceProviderSelector from "./common/ServiceProviderSelector";
import ReminderSelector from "./common/ReminderSelector";
import {dayOfYear, getCurrentUTCTime, onChangeInputWheel, createDateFormat, weekOfYear,getYear} from "./common/Methods";
import moment from 'moment';
import AccountSelector from "./common/AccountSelector";

class BillsForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            categoryId: 3,
            amount: '',
            accountId: '',
            title: '',
            remindBeforeDays: -1,
            billDate: this.createDate(),
            paidTime: this.createDate(),
            notes: '',
            recurringCount: 0,
            repeatTillCount: 0,
            repeatTillDate: '',
            recurringCategoryId: 0,
            autoPaid: false,
            expenseNeeded: false,
            amountPaid: 0,
            selectedRecurringCategoryId: 0,
            serviceProviderId: this.getDefaultServiceProviderId(3) || 0,
            userSettings: JSON.parse(localStorage.getItem('profileDetails')) || {},
        };
        this.billsMouseWheel = null;
        this.billsDateRef = null;
        this.billsPaidDateRef = null;
        this.amountPaidMouseWheel = null;
    }
    componentDidMount() {
        this.componentWillReceiveProps(this.props)
    }
    componentWillReceiveProps(nextProps) {
        if (nextProps.billsData && nextProps.billsData._id && !nextProps.addNewBill) {
            const billData = nextProps.billsData;
            if (billData._id) {
                if(billData.nextDueDate) this.setState({billDate: this.createDate(billData.nextDueDate)});
                else this.setState({billDate: this.createDate(billData.time)});
                this.setState({
                    categoryId: billData.category.categoryId,
                    amount: +billData.amount,
                    accountId: billData.accountId,
                    amountPaid: +billData.amountPaid,
                    autoPaid: billData.autoPaid || false,
                    title: billData.title || '',
                    paidTime: this.createDate(billData.paidTime),
                    notes: billData.notes,
                    remindBeforeDays: billData.remindBeforeDays,
                    recurringCategoryId: billData.recurringCategoryId || 0,
                    selectedRecurringCategoryId: ( billData.repeatTillCount || billData.repeatTillDate ) ? 7 : (billData.recurringCategoryId || 0),
                    recurringCount: billData.recurringCount || 0,
                    repeatTillCount: billData.repeatTillCount || 0,
                    repeatTillDate: billData.repeatTillDate || '',
                    recurringRule: billData.recurringRule || '',
                    serviceProviderId: billData.serviceProviderId || 0,
                    expenseNeeded: billData.expenseNeeded || false,
                });
            }
        } else {
            this.resetStateData();
        }
    }

    resetStateData = () => {
        this.setState({
            categoryId: 3,
            amount: '',
            amountPaid: '',
            title: '',
            billDate: this.createDate(),
            paidTime: this.createDate(),
            notes: '',
            _id: '',
            accountId: '',
            serviceProviderId: 0,
            remindBeforeDays: -1,
            recurringCount: 0,
            repeatTillCount: 0,
            repeatTillDate: '',
            recurringCategoryId: 0,
            autoPaid: false,
            expenseNeeded: false,
            selectedRecurringCategoryId: 0,
        });
    };

    onCategoryChange = (category) => {
        this.setState({
            categoryId: category,
            serviceProviderId: this.getDefaultServiceProviderId(category) || 0
        });
    };

    getDefaultServiceProviderId = (category) => {
        const userSettings = JSON.parse(localStorage.getItem('profileDetails'));
        const selectedCategory = (JSON.parse(localStorage.getItem('userCategories')) || []).find(({categoryId}) => categoryId === category);
        if (selectedCategory && selectedCategory.serviceProviderType) {
            const serviceProvider = JSON.parse(localStorage.getItem('serviceProviders')).filter(({providerType, providerCurrency}) => (providerType === `${selectedCategory.serviceProviderType}` && (providerCurrency === 'ALL' || providerCurrency === userSettings.currencyDetails.currencyCode)));
            const providerObj = serviceProvider[0];
            return providerObj ? +providerObj.providerId : 0;
        }
        return null;
    };

    onRecurringChange = (recurringCategoryId) => {
        if (recurringCategoryId && recurringCategoryId !== 7) {
            this.setState({
                repeatTillCount: 0,
                repeatTillDate: null
            })
        }
        this.setState({
            selectedRecurringCategoryId: recurringCategoryId,
            recurringRule:undefined
        });
    };

    onServiceProviderChange = (serviceProviderId) => {
        this.setState({
            serviceProviderId: serviceProviderId
        });
    };

    getRecurringObj = (recurringObj) => {
        this.setState({
            ...recurringObj
        });
    };

    onTextChange = (key, e) => {
        this.setState({
            [key]: e.target.value
        });
    };

    onDateChange = (key, e) => {
        if(e.target.value) {
            this.setState({
                [key]: e.target.value
            });
        }
    };

    onChangeCheck = (key, e) => {
        this.setState({
            [key]: e.target.checked
        });
    };

    createDate(date = new Date()) {
        return moment(date).format('YYYY-MM-DD');
    }
    getAlertBox = (message, type) => {
        const t = this.props.t;
        Swal.fire({
            title: t(message),
            position: 'bottom-start',
            toast: true,
            showConfirmButton: false,
            timer: 2000,
            background: type === 'success' ? '#8dc44e' : '#f1521c',
            customClass: {
                title: 'sweet-alert',
                container: 'sweet-alert-container'
            }
        });
    };

    submitForm() {
        const dateObj = moment(this.state.billDate).valueOf();
        const formData = {
            amount: +this.state.amount,
            autoPaid: this.state.autoPaid,
            accountId: this.state.accountId,
            amountPaid: +this.state.amountPaid,
            lastModifyTime: getCurrentUTCTime(),
            serviceProviderId: this.state.serviceProviderId + '',
            categoryId: this.state.categoryId,
            dayOfYear: dayOfYear(dateObj),
            week : weekOfYear(dateObj),
            year: getYear(dateObj),
            month: moment(dateObj).get('month'),
            time: moment(this.state.billDate).utc().valueOf(),
            notes: this.state.notes,
            remindBeforeDays: this.state.remindBeforeDays,
            
            lastModifyDevice: 'WEB',
            deviceId: 'WEB',
            title: this.state.title || ''
        };
        
        if(this.state.recurringRule) {
                
            formData.recurringRule = this.state.recurringRule
        } else {
            formData.recurringRule = undefined
        }
        if (!this.props.addNewBill) {
            formData['_id'] = this.props.billsData._id;
            formData['paidTime'] = moment(this.state.paidTime).utc().valueOf();
        }
        if (this.props.addNewBill) {
            formData['createTime'] = moment().utc().valueOf();
        }
        if (this.props.billsData && this.props.billsData.time && this.props.billsData.time !== dateObj) {
            formData['dateUpdated'] = true;
        }
        if (this.state.recurringCategoryId) {
            formData['recurringCategoryId'] = this.state.recurringCategoryId;
            formData['recurringCount'] = this.state.recurringCount;
            formData['repeatTillCount'] = this.state.repeatTillCount;
            formData['repeatTillDate'] = this.state.repeatTillDate;
        }
        this.props.onSubmit(formData);
        if (!this.props.billsData._id) {
            this.resetStateData();
        }
    }

    onReminderSelect = (key) => {
        this.setState({
            remindBeforeDays: key
        });
    };
    changeWheel = (e) => {
        if (e === 'amount') {
            onChangeInputWheel(this.billsMouseWheel);
        }
        else if (e === 'paidDate') {
            onChangeInputWheel(this.billsPaidDateRef);
        }
        else if (e === 'amountPaid' ){
            onChangeInputWheel(this.amountPaidMouseWheel);
        }
        else {
            onChangeInputWheel(this.billsDateRef);
        }
    };

    getSelectedAccountId = (val) => {
        this.setState({
            accountId: val
        })
    };

    render() {
        const {
            userSettings: {currencyDetails}
        } = this.state;
        const {
            t
        } = this.props;
        console.log('this.props', this.props)
        return <form className="add-edit-expense">
            <Row>
                <Col s={12} m={12} l={12}>
                    <CategorySelector
                        t={t}
                        onChange={this.onCategoryChange}
                        value={this.state.categoryId}
                        type={1}
                    />
                </Col>
            </Row>

            <ServiceProviderSelector
                t={t}
                categoryId={this.state.categoryId}
                onChange={this.onServiceProviderChange}
                value={this.state.serviceProviderId}
            />

            <Row>
                <div className="input-field col m6 s12">
                    <input
                        id="icon_prefix"
                        type="text"
                        min={0}
                        name="title"
                        value={this.state.title}
                        required
                        onChange={this.onTextChange.bind(this, 'title')}
                    />
                    <label htmlFor="icon_prefix"
                           className={this.state.title ? 'active' : ''}><Trans>TITLE</Trans></label>
                </div>
            </Row>

            <Row>
                <div className="input-field col m6 s12">
                    <i className={"fas prefix " + (currencyDetails.currencySymbol.length > 1 ? 'long-symbole' : '')}>
                        {currencyDetails.currencySymbol}
                    </i>
                    <input
                        id="icon_prefix"
                        type="number"
                        ref={c => this.billsMouseWheel = c}
                        onWheel={() => this.changeWheel('amount')}
                        min={0}
                        name="amount"
                        value={this.state.amount}
                        required
                        onChange={this.onTextChange.bind(this, 'amount')}
                    />
                    <label htmlFor="icon_prefix"
                           className={this.state.amount ? 'active' : ''}><Trans>AMOUNT</Trans></label>
                </div>
            </Row>

            {this.props.billsData.hasPaid && this.state.amountPaid ?
                <Row>
                    <div className="input-field col m6 s12">
                        <i className={"fas prefix " + (currencyDetails.currencySymbol.length > 1 ? 'long-symbole' : '')}>
                            {currencyDetails.currencySymbol}
                        </i>
                        <input
                            id="icon_prefix"
                            type="number"
                            ref={c => this.amountPaidMouseWheel = c}
                            onWheel={() => this.changeWheel('amountPaid')}
                            min={0}
                            name="amount paid"
                            value={this.state.amountPaid}
                            required
                            onChange={this.onTextChange.bind(this, 'amountPaid')}
                        />
                        <label htmlFor="icon_prefix" className={this.state.amountPaid ? 'active' : ''}><Trans>AMOUNT_PAID</Trans></label>
                    </div>
                </Row> : null}

            <Row style={{marginBottom: '0px'}}>
                <div className="input-field col m6 s12 input-expense-date">
                    <i className="fas prefix">
                        <Icon>date_range</Icon>
                    </i>
                    <span id="date-text" style={{
                        width: 'calc(100% - 4rem)',
                        float: 'left',
                        marginLeft: '4rem',
                        borderBottom: '1px solid #9e9e9e',
                        height: '2rem',
                        marginTop: '10px'
                    }}>{createDateFormat(t, this.state.billDate)}</span>
                    <input type="date"
                           className="date-input"
                           name="date"
                           ref={c => this.billsDateRef = c}
                           value={this.state.billDate}
                           onWheel={() => this.changeWheel('date')}
                           required
                           onChange={this.onDateChange.bind(this, 'billDate')}/>
                    <label htmlFor="icon_prefix" className={this.state.billDate ? 'active' : ''}><Trans>DUE_DATE</Trans></label>
                </div>
            </Row>
            <Row>
                <div className="input-field col m6 s12" style={{position: 'relative'}}>
                    <input type="checkbox"
                           style={{height: '100%', width: '100%', pointerEvents: 'all'}}
                           id="autoPaid"
                           name="autoPaid"
                           onChange={this.onChangeCheck.bind(this, 'autoPaid')}
                           checked={this.state.autoPaid}/>
                    <label htmlFor="autoPaid"
                           style={{left: '3px', top: '-10px', paddingLeft: '4rem'}}><Trans>AUTO_PAID</Trans></label>
                </div>
            </Row>

            {this.state.autoPaid ?
                <>
                    <Row style={{marginBottom: '0px', marginTop: '20px'}}>
                        <AccountSelector
                            value={this.state.accountId}
                            onChange={this.getSelectedAccountId}
                        />
                    </Row>
                    <Row>
                        <div className="input-field col m6 s12" style={{position: 'relative'}}>
                                <input type="checkbox"
                                    style={{height: '100%', width: '100%', pointerEvents: 'all'}}
                                    id="expenseNeeded"
                                    name="expenseNeeded"
                                    onChange={this.onChangeCheck.bind(this, 'expenseNeeded')}
                                    checked={this.state.expenseNeeded} />
                                <label htmlFor="expenseNeeded" style={{left: '3px',  top: '-10px', paddingLeft: '4rem'}}>
                                    <Trans>EXPENSE_MARK_MESSAGE</Trans>
                                </label>
                        </div>
                    </Row>
                    <hr/>
                </>: null
            }
            {
                this.props.billsData.hasPaid && this.state.amountPaid ?
                    <Row style={{marginBottom: '0px', marginTop: '25px'}}>
                        <div className="input-field col m6 s12 input-expense-date">
                            <i className="fas prefix">
                                <Icon>date_range</Icon>
                            </i>
                            <span id="date-text" style={{
                                width: 'calc(100% - 4rem)',
                                float: 'left',
                                marginLeft: '4rem',
                                borderBottom: '1px solid #9e9e9e',
                                height: '2rem',
                                marginTop: '10px'
                            }}>{createDateFormat(t, this.state.paidTime)}</span>
                            <input type="date"
                                   className="date-input"
                                   value={this.state.paidTime}
                                   ref={c => this.billsPaidDateRef = c}
                                   onWheel={() => this.changeWheel('paidDate')}
                                   name="date"
                                   required
                                   onChange={this.onDateChange.bind(this, 'paidTime')}>
                            </input>
                            <label htmlFor="icon_prefix" className={this.state.paidTime ? 'active' : ''}><Trans>PAID_DATE</Trans></label>
                        </div>
                    </Row> : null
            }

            <Row>
                <ReminderSelector
                    onChange={this.onReminderSelect}
                    value={this.state.remindBeforeDays}
                />
            </Row>
            {!this.props.billsData.hasPaid  && <Row>
                <RecurringSelector
                    _id={this.props.billsData._id}
                    onChange={this.onRecurringChange}
                    value={this.state.selectedRecurringCategoryId}
                    recurringRule={this.state.recurringRule}
                    recurringCount={this.state.recurringCount}
                    recurringId={this.state.recurringCategoryId}
                    repeatTillDate={this.state.repeatTillDate}
                    repeatTillCount={this.state.repeatTillCount}
                    dataLoading={this.props.dataLoading}
                    time={this.state.billDate ? moment(this.state.billDate).valueOf() : getCurrentUTCTime}
                    getRecurringObj={this.getRecurringObj}
                    disabled={this.props.disableRecurrence || false}
                />
            </Row>}

            <Row>
                <div className="input-field col m6 s12 align-horizontal">
                    <i className="fas prefix">
                        <Icon>comments</Icon>
                    </i>
                    <input
                        id="icon_prefix"
                        type="text"
                        value={this.state.notes}
                        onChange={this.onTextChange.bind(this, 'notes')}
                        name="notes"/>
                    <label htmlFor="icon_prefix"
                           className={this.state.notes ? 'active' : ''}><Trans>NOTES</Trans></label>
                </div>
            </Row>

            <Row>
                <div className="modal-footer-sec">
                    <div className="input-field col m6 s12">
                        <button className='btn waves-effect waves-light light-blue accent-4 modal-close'
                                type="button" name="action" onClick={this.submitForm.bind(this)}><Trans>DONE</Trans>
                            <i className="material-icons right">done</i>
                        </button>
                    </div>
                </div>
            </Row>
        </form>
    }
}

const mapStateToProps = (state) => ({
    auth: state.auth,
    categories: state.categories,
    transactionData: state.transactionData

});

export default withTranslation()(connect(mapStateToProps, {
    addtransaction,
    getcategories,
    searchtransaction,
    cacheTransaction
})(BillsForm));