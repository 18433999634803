import React, { Component } from 'react';
import Navbar from "./Navbar";
import { withRouter } from 'react-router-dom';
import '../stylesheets/App.css'
import {
    Row,
    Col
} from 'react-materialize';
import {withTranslation, Trans} from 'react-i18next';
import '../stylesheets/App.css';
import {iconUrl} from "../actions/config";

class PageNotFound extends Component {
    render() {
        return (
            <div>
                <Navbar/>
                <div className="vertical-center">
                    <Row>
                        <Col s={12} m={12} l={12}>
                            <div className='container'>
                                <div className="card darken-1">
                                    <div className="card-content center-align not-found">
                                        <img src={iconUrl + 'icon_error.png'} alt="Error_Image"/>
                                        <span className="card-title"> <h3><Trans>PAGE_NOT_FOUND</Trans></h3></span>
                                        <span> <h5><Trans>PAGE_NOT_FOUND_SUBTITLE</Trans></h5> </span>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        );
    }
}

export default withTranslation()(withRouter(PageNotFound));





