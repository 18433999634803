import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { Input } from 'react-materialize';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {Helmet} from "react-helmet";
import Navbar from "./Navbar";
import Spinner from "./common/Spinner";
import CookieDisclaimer from './common/CookieDisclaimer'
import isEmpty from "../validation/is-empty";
import ReCAPTCHA from "react-google-recaptcha";
import Swal from 'sweetalert2';
import {withTranslation, Trans} from 'react-i18next';
import {
	registeruser,
	differentPasswords,
    getCurrencies,
    clearErrors
} from "../actions/authActions";
import '../stylesheets/App.css'
import {iconUrl, SITE_KEY} from "../actions/config";

class SignUp extends Component {
    constructor(props) {
        super(props);
        this.state = {
            firstname: '',
            email: '',
            password: '',
            password2: '',
            currency: '',
            errors: '',
            recaptcha: null,
            token: '',
            userData: {}
        };
        this.ownerDetail = '';
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.props.clearErrors();
    }

    componentDidMount () {
        if(this.props.auth.isAuthenticated){
            this.props.history.push('/dashboard')
        }
        //MultiUser Functionality
        if(this.props.location.state){
            this.handleChange({target:{name: 'email', value: this.props.location.state.userData.inviteeEmail}});
            if (this.props.location.state.userData.ownerFirstName) {
                this.ownerDetail = this.props.location.state.userData.ownerFirstName;
                if (this.props.location.state.userData.ownerLastName) {
                    this.ownerDetail += ' ' + this.props.location.state.userData.ownerLastName;
                }
            }
            else {
                this.ownerDetail = this.props.location.state.userData.ownerEmail;
            }
            this.setState({token:this.props.location.state.token});
            this.setState({userData:this.props.location.state.userData});
        }
        this.props.getCurrencies();
    }

    componentWillReceiveProps(nextProps){
        if(nextProps.errors){
            this.setState({errors: nextProps.errors});
        }
    }

    handleChange(e){
        this.setState({[e.target.name]:e.target.value});
    }

    handleSubmit(e){
        e.preventDefault();
        if(this.state.recaptcha) {
            if (this.state.password === this.state.password2) {
                let newUser = {
                    firstname: this.state.firstname,
                    email: this.state.email,
                    password: this.state.password,
                    currency: this.state.currency || 'USD',
                };
                //if Multiuser scenario then appends the grpToken in the form.
                if(this.state.token){
                    newUser['grpToken'] = this.state.token
                }
                this.props.registeruser(newUser, this.props.history);
            } else {
                this.props.differentPasswords();
            }
        }else {
            this.getAlertBox(this.props.t('CHECK_RECAPTCHA'), 'error');
        }
    }

    getAlertBox = (message, type) => {
        const t=this.props.t;
        Swal.fire({
            title:t(message),
            position: 'bottom-start',
            toast: true,
            showConfirmButton: false,
            timer: 2000,
            background: type==='success' ? '#8dc44e' : '#f1521c',
            customClass: {
                title:'sweet-alert',
                container: 'sweet-alert-container'
            }
        });
    };

    onVerify = (data) => {
        this.setState({
            recaptcha: data
        });
    };

    onExpired = (data) => {
        this.setState({
            recaptcha: data
        });
    };

    render() {
        const acceptInviteDiv = (
            <div>
                <span className="card-title mb-2 font-size-1rem"> <Trans>MULTIUSER_REGISTRATION_TEXT</Trans> {this.ownerDetail}</span>
            </div>
        );
        const {t} = this.props;

        const loading = this.props.loading.loading;
        const { currencies = [] } = this.props.auth;
        const errorRes = isEmpty(this.state.errors)? "" : this.state.errors;
        return (
            <div>
                <Helmet>
                <title>{t('CREATE_ACCOUNT')}</title>
                </Helmet>
                <Navbar/>
                <div className="vertical-center">
                    <div className="row margin-0 sign-in-section">
                        <div className="col l12 m12 s12 ">
                            <div className="card darken-1">
                                <div className="card-content">
                                    <div className="center-align">
                                        <img src={iconUrl + 'account.png'}  height="80px" alt="UserImage"/>
                                        <span className="card-title"> <h4><Trans>CREATE_ACCOUNT</Trans></h4></span>
                                        {this.state.token ? acceptInviteDiv : null}
                                    </div>
                                    {(currencies === null ) ?
										<div className='center-align'> <Spinner/> </div> :
                                        <form onSubmit={this.handleSubmit}>
                                            <div className="row margin-0">
                                                <div className="col m1" />
                                                <div className="input-field col m10 s12">
                                                    <input type="email"
                                                           onChange={this.handleChange}
                                                           name="email"
                                                           disabled={this.state.token}
                                                           value={this.state.email}
                                                           required/>
                                                    <label htmlFor="icon_prefix" className={this.state.email?'active':''}><Trans>EMAIL</Trans></label>
                                                </div>
                                            </div>

                                            <div className="row margin-0">
                                                <div className="col m1" />
                                                <div className="input-field col m10 s12">
                                                    <input type="password" onChange={this.handleChange} name="password"
                                                           required/>
                                                    <label htmlFor="icon_prefix" className={this.state.password ?'active':''}><Trans>PASSWORD</Trans></label>
                                                </div>
                                            </div>

                                            <div className="row margin-0">
                                                <div className="col m1" />
                                                <div className="input-field col m10 s12">
                                                    <input type="password" onChange={this.handleChange} name="password2"
                                                           required/>
                                                    <label htmlFor="icon_prefix" className={this.state.password2 ?'active':''}><Trans>CONFIRM_PASSWORD</Trans></label>
                                                </div>
                                            </div>

                                            <div className="row margin-0">
                                                <div className="col m1" />
                                                <div className="input-field  col m10 s12 last-input">
                                                    <Input s={12} type='select' defaultValue='0' onChange={this.handleChange} name="currency">
                                                        <option value='0' disabled>{this.props.t('SELECT_CURRENCY')}</option>
                                                        {
                                                            currencies
                                                                .map((currency) =>
                                                                    <option
                                                                        value={currency.currencyCode}
                                                                        key={currency.currencyCode}
                                                                    >
                                                                        {currency.currencyName}
                                                                    </option>
                                                                )
                                                        }
                                                    </Input>
                                                    <label htmlFor="icon_prefix" style={{top: '-1rem'}}><Trans>CURRENCY</Trans></label>
                                                </div>
                                            </div>

                                            <div className="row margin-0">
                                                <div className="col m1" />
                                                <div className="input-field col m10 s12 recaptcha">
                                                    <ReCAPTCHA
                                                        sitekey={SITE_KEY}
                                                        onChange={this.onVerify}
                                                        onExpired={this.onExpired}
                                                    />
                                                </div>
                                            </div>

                                            <div className="row margin-0">
                                                <div className="col m1" />
                                                <div className="input-field col m10 s12">
                                                    <span style={{color:"red"}}>
                                                        {errorRes.message}
                                                    </span>
                                                </div>
                                            </div>

                                            <div className="row margin-0 Already-div">
                                                <div className="input-field col m12 s12 text-align-center">
                                                    {(errorRes.code === "" && loading) ? <Spinner/> :
                                                        <button className="btn waves-effect waves-light light-blue accent-4"
                                                                type="submit" name="action" style={{width: 'auto'}}><Trans>SIGN_UP</Trans>
                                                        </button>
                                                    }
                                                </div>
                                                    <div className="input-field col m12 s12 orLink text-align-center " >
                                                    <span><Trans>ALREADY_HAVE_AN_ACCOUNT</Trans>
                                                        <Link to="/signin" style={{display: 'contents'}}><Trans>SIGN_IN</Trans></Link></span>
                                                </div>
                                            </div>

                                            <div className="row margin-0 by-clicking">
                                                <span><Trans>CLICKING_SIGNUP</Trans></span>
                                                <br/>
                                                <a href="https://timelybills.app/terms-of-service" target='_blank' rel='noopener noreferrer'> <Trans>TERMS_OF_SERVICE</Trans></a>
                                                <br/>
                                                <a href="https://timelybills.app/privacy-policy" target='_blank' rel='noopener noreferrer'> <Trans>PRIVACY_POLICY</Trans></a>
                                            </div>
                                        </form>
                                    }
                                </div>
                            </div>
                            <div className="certificates-footer">
                                <img src={iconUrl + "certificates.png"} alt="certificates not found"/>
                                <img src={iconUrl + "siteseal.png"} alt="siteseal not found"/>
                            </div>
                        </div>

                    </div>
                </div>
                <CookieDisclaimer />
            </div>
        );
    }
}

SignUp.propTypes = {
    registeruser: PropTypes.func.isRequired,
    differentPasswords: PropTypes.func.isRequired,
    auth:PropTypes.object.isRequired,
    loading:PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    auth: state.auth,
    errors: state.errors,
    currencies: state.currencies,
    loading: state.loading
});

export default withTranslation()(connect(mapStateToProps, {
    registeruser,
    differentPasswords,
    getCurrencies,
    clearErrors
})(withRouter(SignUp)));
