import React, {Component} from 'react';
import {
    Icon
} from 'react-materialize';
import {withTranslation} from 'react-i18next';
import {
    REMINDER
} from './ConstData';
import translationEN from '../../local/en.json';

class RemonderSelector extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modalOpen: false,
            recurringDescription: '',
        }
    }

	onChange = (e, b) => {
        this.props.onChange(+e.target.value);
	};

    render() {
        const { t } = this.props;
        return <table
            className='table-category-selector'>
            <tbody>
            <tr>
                <td style={{width: '3.9rem'}}>
                    <i className="fas prefix">
                        <Icon>alarm</Icon>
                    </i>
                </td>
                <td>
                    <select
                        onChange={this.onChange}
                        value={this.props.value}
                        placeholder={translationEN.SELECT_CATEGORY}
                        style={{display: 'block'}}
                    >
                        {
                            REMINDER.map((type, key) =>
                                    <option
                                        value={type.value}
                                        key={key}
                                    >
                                            {t(type.title)}
                                    </option>
                                )
                        }
                    </select>
                </td>
            </tr>
            </tbody>
        </table>
    }
}
RemonderSelector.defaultProps = {
    value: -1
};
export default withTranslation()(RemonderSelector);