import React, { useState, useRef } from "react";
import _ from "lodash";
import { LONG_MONTHS, SHORT_MONTHS, BUDGET_TYPE } from "../common/ConstData";
import i18n from "../../local/i18n";
import { DEFAULT_CAT_2, DEFAULT_CAT_1, iconUrl } from "../../actions/config";
import TBDropdown from './TBDropdown'

export default (props) => {
  const [catType, setCatType] = useState(props.type);
  const categories = _.orderBy(
    JSON.parse(localStorage.getItem("userCategories")),
    ["name"],
    ["asc"]
  ).map(e=>{
   if(props.type === BUDGET_TYPE.INCOME) {
    return {...e, groupId: e.groupId ? e.groupId : '932'}
   }else if (props.type === BUDGET_TYPE.EXPENSE) {
     if(e.groupId==0){
       delete e.groupId;
     }
    return e;
   }else{
    return e;
   }
   
  });
  const typeCondition = (type) => BUDGET_TYPE.UNKNOWN === props.type ? true : type === props.type;
  
  let categoryObj = !!props.value
    ? categories.find(
        ({ type, categoryId }) =>
        (props.type === BUDGET_TYPE.UNKNOWN ? true : catType === type) && categoryId === props.value
      ) || {categoryId: 60000}
    : categories[0];
    
  const onChange = (e, b) => {
    const categoryId = e.target.value;
    props.onChange(+categoryId, e.target.type);
    setCatType(e.target.type);
   
  };
  const { t } = props;
  const languageCode = i18n.language;

  let catValue = props.value;
  
  if (!categoryObj) {
    if (props.type === BUDGET_TYPE.INCOME) {
      catValue = 932;
    } else if(props.type === BUDGET_TYPE.EXPENSE) {
      catValue = DEFAULT_CAT_1;
    } else if(props.type === BUDGET_TYPE.UNKNOWN) {
      catValue = 20000;
    }
    categoryObj = categories.find(
      ({ type, categoryId }) => (props.type === BUDGET_TYPE.UNKNOWN ? true : catType === type) && categoryId === catValue
    );
  }
  const style4Bg = {
    backgroundColor:
      categoryObj && categoryObj.iconColor
        ? categoryObj.iconColor
        : "transparent",
    height: "30px",
  };
  return categoryObj && Object.keys(categoryObj).length > 0 ? (
    <table className="table-category-selector">
      <tbody>
        <tr>
          <td style={{ width: "3.9rem" }}>
            <img
              src={iconUrl + categoryObj.iconUrl + ".png"}
              alt="icon"
              className="leftMargin10 circle"
              style={style4Bg}
            ></img>
          </td>
          <td>
            <TBDropdown
              onChange={onChange}
              disabled={props.disabled || false}
              placeholder={t("SELECT_CATEGORY")}
              categories={categories.filter(
                ({ categoryId, type, isHidden = false, isDeleted = false }) =>
                  ((catValue && catValue === categoryId) ||
                    (!isDeleted && !isHidden)) && (+categoryId!==932)
                  && typeCondition(type)
              )}
              value={catValue}
              type={props.type === BUDGET_TYPE.UNKNOWN ? catType : props.type}
              languageCode={languageCode}
            />
            
          </td>
        </tr>
        <tr>
          <td />
          <td>
            <div className="grey-text">
              {categoryObj[languageCode + "_description"] ||
                categoryObj.description}
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  ) : null;
};
