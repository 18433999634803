import React, {Component} from 'react';
import {withTranslation, Trans} from 'react-i18next';
import {
    Row,
    Col,
    Input,
    Icon
} from 'react-materialize';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';

class Settings extends Component {
    constructor(props) {
        super(props);
        // to bind the props with settings component 'this'
        // in order to execute handleSave method from ChangePassword component
    }

    render() {
        const {t} = this.props;
        const {
            errors = {},
        } = this.props;
        let notify = '';
        if (this.props.notification) {
            notify = this.props.notification.toString();
            if (notify.endsWith('PM')) {
                notify = notify.replace('PM', '');
                notify = (12 + +notify.split(':')[0]) + ':' + notify.split(':')[1];
            } else {
                notify = notify.replace('AM', '');
            }
        }
        return (
            <React.Fragment>
                <Row>
                    <div className="input-block">
                        <i className="fas prefix">
                            <Icon>notifications</Icon>
                        </i>
                        <div className="input-box">
                            <label htmlFor="notificationTime" className='active'>
                                <Trans>ENTER_NOTIFICATION_TIME</Trans>
                            </label>
                            <input
                                name='notificationTime'
                                type='time'
                                className="datepicker"
                                placeholder={t('ENTER_NOTIFICATION_TIME')}
                                value={notify}
                                onChange={(e) => this.props.updateValue(e, 'notificationTime')}/>
                        </div>
                    </div>
                </Row>
                <Row>
                    <Col s={12} m={5} l={5}>
                <Row><label><Trans>APP_NOTIFICATIONS</Trans></label></Row>    
                <Row className='black-colored-checkbox'>
                    <Input
                        type='checkbox'
                        name='billNotifications'
                        label={t('BILL_NOTIFICATION')}
                        className='filled-in'
                        checked={this.props.bill}
                        onChange={(e) => this.props.updateValue(e, 'billNotifications')}/>
                </Row>
                <Row className='black-colored-checkbox'>
                    <Input
                        type='checkbox'
                        name='expenseNotifications'
                        label={t('EXPENSE_NOTIFICATION')}
                        className='filled-in'
                        checked={this.props.expense}
                        onChange={(e) => this.props.updateValue(e, 'expenseNotifications')}/>
                </Row>
                <Row className='black-colored-checkbox'>
                    <Input
                        type='checkbox'
                        name='budgetNotifications'
                        label={t('BUDGET_NOTIFICATION')}
                        className='filled-in'
                        checked={this.props.budget}
                        onChange={(e) => this.props.updateValue(e, 'budgetNotifications')}/>
                </Row>
                </Col>
                <Col s={12} m={6} l={6}>
                <Row><label><Trans>EMAIL_NOTIFICATIONS</Trans></label></Row>    
                <Row className='black-colored-checkbox'>
                    <Input
                        type='checkbox'
                        name='signInEmailNotificaitons'
                        label={t('SIGNIN_NOTIFICATION')}
                        className='filled-in'
                        checked={this.props.signinNotification}
                        onChange={(e) => this.props.updateValue(e, 'signInEmailNotificaitons')}/>
                </Row>
                <Row className='black-colored-checkbox'>
                    <Input
                        type='checkbox'
                        name='monthlyReportEmailNotificaitons'
                        label={t('REPORT_NOTIFICATION')}
                        className='filled-in'
                        checked={this.props.reportNotification}
                        onChange={(e) => this.props.updateValue(e, 'monthlyReportEmailNotificaitons')}/>
                </Row>
                </Col>
                </Row>
                <Row>
                    <Col s={12} l={8} m={8}>
                        <div>{errors.message}</div>
                    </Col>
                </Row>
                {/*<Loader condition={this.props.loading.loading}/>*/}
            </React.Fragment>
        );
    }
}

Settings.propTypes = {
};

const mapStateToProps = (state) => ({
});

export default withTranslation()(connect(
    mapStateToProps,
    {
    })(withRouter(Settings)));
