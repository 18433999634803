import React, { Component } from "react";
import { Modal, Icon, Button } from "react-materialize";
import { Trans, withTranslation } from "react-i18next";
import { unescape } from "lodash";
import {
  RepeatCategory,
  repeatTypeSelected,
  getShortDate,
  getLongDateYr,
} from "./Methods";
import { LONG_DAYS } from "./ConstData";
import CustomRecurringSelector from "./CustomRecurringSelector";
import moment from "moment";
import {completeRecurrenceDescription, recurringToDescription, recurringToDescriptionFullLabel} from '../../_helpers/genericFn'


class RecurringSelector extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalOpen: false,
      recurringDescription: "",
      needToCheckRecurringRule: true
    };
  }

  onChange = (e, b) => {
    this.setState({
      modalOpen: false,
    });
    const categoryId = +e.target.value;
    this.recurringDescription(categoryId);

    this.props.onChange(categoryId);
  };

  createDate(date = new Date()) {
    // const dateObj = new Date(date);
    return moment(date).format("YYYY-MM-DD");
  }

  componentWillReceiveProps(nextProps) {
   console.log("prosp", nextProps)
    if (!nextProps.dataLoading) {
      if (
       ( nextProps.value === 7 ||  nextProps.value === 3) && 
        (nextProps.repeatTillDate || nextProps.repeatTillCount || nextProps.recurringRule || nextProps.recurringCount)
      ) {
        const recurringObj = {
          recurringCategoryId: nextProps.recurringId,
          recurringCount: nextProps.recurringCount,
          repeatTillDate: nextProps.repeatTillDate,
          repeatTillCount: nextProps.repeatTillCount,
          recurringRule: nextProps.recurringRule,
          categoryId: nextProps.value,
          time: nextProps.time
        };
        if(!this.setbypartent || nextProps.time !== this.props.time) {
        console.log('ddd', this.setbypartent, nextProps.time, this.props.time)
        this.setRecurringDescription(recurringObj, nextProps.time, 'compo');
        this.setbypartent = true;
        }
      } else if (
        nextProps.time !== this.props.time ||
        nextProps._id !== this.props._id
      ) {
        this.recurringDescription(
          this.getRecurringValue(nextProps.value, nextProps.recurringCount),
          nextProps.time,
          false,
          nextProps
        );
      }
    }
  }

  recurringDescription = (
    categoryId,
    time = this.props.time,
    openCustomModal = true,
    props
  ) => {
    const { t } = this.props;
    
    if (categoryId === 7 && openCustomModal) {
      this.openCustomModal();
    } else if (categoryId !== 7) {
      let description = "";
      const selectedRecurringObj = repeatTypeSelected(categoryId);
      if (categoryId === 1) {
        description = t("REPEATS_EVERY_DAY") + getShortDate(new Date(time), t);
      } else if (categoryId === 2) {
        description =
          t("REPEATS_EVERY_WEEK") + t(LONG_DAYS[new Date(time).getDay()]);
      } else if (categoryId === 4) {
        description =
          t("REPEATS_EVERY_BI_WEEK") + t(LONG_DAYS[new Date(time).getDay()]);
      } else if (categoryId === 3) {
        description = 
          t("REPEATS_EVERY_MONTH") +  t('DAY') +" " + new Date(time).getDate() ;
      } else if (categoryId === 5) {
        description =
          t("REPEATS_EVERY_BI_MONTH") + t('DAY') +" " + new Date(time).getDate() ;
      } else if (categoryId === 6) {
        description = t("REPEATS_EVERY_YEAR") + getShortDate(new Date(time), t);
      }
      
      this.setState({
        recurringDescription: description,
      });

      this.props.getRecurringObj(selectedRecurringObj);
    } else if (categoryId === 7) {
        
      this.setParentRecurringObj(time, props);
    }
  };

  setRecurringObj = (recurrningObj) => {
    
    this.setState({needToCheckRecurringRule: recurrningObj.recurringRule !== undefined})
    this.setRecurringDescription(recurrningObj, undefined, 'adc');
    this.props.getRecurringObj(recurrningObj);
  };

  setParentRecurringObj = (time = this.props.time, props = this.props) => {
   // console.log('recurringdd', props.time, this.props.time)
      
      const {t} = props;
      const recurrningObj = {
          recurringCategoryId: props.recurringId,
          recurringCount: +props.recurringCount,
          repeatTillDate: props.repeatTillDate,
          repeatTillCount: props.repeatTillCount,
          recurringRule: props.recurringRule,
          categoryId: props.value,
          time: props.time
        };
        if (
            props.recurringRule !== undefined && props.recurringRule !== null &&
            props.recurringRule !== "" && this.state.needToCheckRecurringRule
          ) {
              recurrningObj.repeatDescription = recurringToDescription(props.recurringRule, this.props.t)
          } else {
            recurrningObj.repeatDescription = recurringToDescriptionFullLabel(recurrningObj, this.props.t)
          }
         
    this.setRecurringDescription(recurrningObj, time, 'ad');
    this.props.getRecurringObj(recurrningObj);
  };

  setRecurringDescription = (recurrningObj, time = this.props.time, form) => {
    const { t } = this.props;
    console.log('settingd parent', recurrningObj)
    recurrningObj.time = time;
    let description = completeRecurrenceDescription(recurrningObj, t);
    
    this.setState({
      modalOpen: false,
      recurringDescription: description,
    });
  };

  openCustomModal = () => {
    this.setState({
      modalOpen: true,
    });
  };

  closeRecurringModel = () => {
    this.setState({
      modalOpen: false,
    });
  };

  getRecurringValue = (
    value,
    recurringCount = this.props.recurringCount || 0
  ) => {
    if (value === 2 || value === 3) {
      if (recurringCount === 2) {
        return value === 2 ? 4 : 5;
      } else if (
        this.props.recurringRule !== undefined &&
        this.props.recurringRule !== ""
      ) {
        return 7;
      }
    }
    return value;
  };

  render() {
    const { t } = this.props;

    return (
      <table className="table-category-selector">
        <tbody>
          <tr>
            <td style={{ width: "3.9rem" }}>
              <i className="fas prefix">
                <Icon>refresh</Icon>
              </i>
            </td>
            <td>
              <select
                onChange={this.onChange}
                value={this.getRecurringValue(this.props.value)}
                disabled={this.props.disabled || false}
                placeholder={t("SELECT_CATEGORY")}
                style={{ display: "block" }}
              >
                {Object.keys(RepeatCategory).map((type, key) => (
                  <option value={RepeatCategory[type].index} key={key}>
                    {unescape(t(RepeatCategory[type].regularName))}
                  </option>
                ))}
              </select>
            </td>
          </tr>
          <tr>
            <td />
            <td>
              <div className="grey-text">
                {this.props.value ? (
                  <p
                    style={{cursor: "pointer"}}
                    onClick={
                      !this.props.disabled ? this.openCustomModal : () => null
                    }
                  >
                    {this.state.recurringDescription}
                    <span style={{color: "#92bace"}}>({this.props.t('CLICK_HERE_FOR_CHANGE')})</span>
                  </p>
                ) : null}
                <Modal
                  id="modal1"
                  open={this.state.modalOpen}
                  actions={
                    <div>
                      <Button
                        flat
                        modal="close"
                        waves="light"
                        onClick={this.closeRecurringModel}
                      >
                        <Trans>CANCEL</Trans>
                      </Button>
                    </div>
                  }
                >
                  <CustomRecurringSelector
                    getCustomRecurringObj={this.setRecurringObj}
                    needToCheckRecurringRule={this.state.needToCheckRecurringRule}
                    {...this.props}
                  />
                </Modal>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    );
  }
}
RecurringSelector.defaultProps = {
  value: 0,
};
export default withTranslation()(RecurringSelector);
