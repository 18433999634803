import { addLocaleData } from "react-intl";

import afLocaleData from "react-intl/locale-data/af";
import agqLocaleData from "react-intl/locale-data/agq";
import akLocaleData from "react-intl/locale-data/ak";
import amLocaleData from "react-intl/locale-data/am";
import arLocaleData from "react-intl/locale-data/ar";
import asLocaleData from "react-intl/locale-data/as";
import asaLocaleData from "react-intl/locale-data/asa";
import astLocaleData from "react-intl/locale-data/ast";
import azLocaleData from "react-intl/locale-data/az";
import basLocaleData from "react-intl/locale-data/bas";
import beLocaleData from "react-intl/locale-data/be";
import bemLocaleData from "react-intl/locale-data/bem";
import bezLocaleData from "react-intl/locale-data/bez";
import bgLocaleData from "react-intl/locale-data/bg";
import bhLocaleData from "react-intl/locale-data/bh";
import bmLocaleData from "react-intl/locale-data/bm";
import bnLocaleData from "react-intl/locale-data/bn";
import boLocaleData from "react-intl/locale-data/bo";
import brLocaleData from "react-intl/locale-data/br";
import brxLocaleData from "react-intl/locale-data/brx";
import bsLocaleData from "react-intl/locale-data/bs";
import caLocaleData from "react-intl/locale-data/ca";
import ceLocaleData from "react-intl/locale-data/ce";
import cggLocaleData from "react-intl/locale-data/cgg";
import chrLocaleData from "react-intl/locale-data/chr";
import ckbLocaleData from "react-intl/locale-data/ckb";
import csLocaleData from "react-intl/locale-data/cs";
import cuLocaleData from "react-intl/locale-data/cu";
import cyLocaleData from "react-intl/locale-data/cy";
import daLocaleData from "react-intl/locale-data/da";
import davLocaleData from "react-intl/locale-data/dav";
import deLocaleData from "react-intl/locale-data/de";
import djeLocaleData from "react-intl/locale-data/dje";
import dsbLocaleData from "react-intl/locale-data/dsb";
import duaLocaleData from "react-intl/locale-data/dua";
import dvLocaleData from "react-intl/locale-data/dv";
import dyoLocaleData from "react-intl/locale-data/dyo";
import dzLocaleData from "react-intl/locale-data/dz";
import ebuLocaleData from "react-intl/locale-data/ebu";
import eeLocaleData from "react-intl/locale-data/ee";
import elLocaleData from "react-intl/locale-data/el";
import enLocaleData from "react-intl/locale-data/en";
import eoLocaleData from "react-intl/locale-data/eo";
import esLocaleData from "react-intl/locale-data/es";
import etLocaleData from "react-intl/locale-data/et";
import euLocaleData from "react-intl/locale-data/eu";
import ewoLocaleData from "react-intl/locale-data/ewo";
import faLocaleData from "react-intl/locale-data/fa";
import ffLocaleData from "react-intl/locale-data/ff";
import fiLocaleData from "react-intl/locale-data/fi";
import filLocaleData from "react-intl/locale-data/fil";
import foLocaleData from "react-intl/locale-data/fo";
import frLocaleData from "react-intl/locale-data/fr";
import furLocaleData from "react-intl/locale-data/fur";
import fyLocaleData from "react-intl/locale-data/fy";
import gaLocaleData from "react-intl/locale-data/ga";
import gdLocaleData from "react-intl/locale-data/gd";
import glLocaleData from "react-intl/locale-data/gl";
import gswLocaleData from "react-intl/locale-data/gsw";
import guLocaleData from "react-intl/locale-data/gu";
import guwLocaleData from "react-intl/locale-data/guw";
import guzLocaleData from "react-intl/locale-data/guz";
import gvLocaleData from "react-intl/locale-data/gv";
import haLocaleData from "react-intl/locale-data/ha";
import hawLocaleData from "react-intl/locale-data/haw";
import heLocaleData from "react-intl/locale-data/he";
import hiLocaleData from "react-intl/locale-data/hi";
import hrLocaleData from "react-intl/locale-data/hr";
import hsbLocaleData from "react-intl/locale-data/hsb";
import huLocaleData from "react-intl/locale-data/hu";
import hyLocaleData from "react-intl/locale-data/hy";
import idLocaleData from "react-intl/locale-data/id";
import igLocaleData from "react-intl/locale-data/ig";
import iiLocaleData from "react-intl/locale-data/ii";
import inLocaleData from "react-intl/locale-data/in";
import isLocaleData from "react-intl/locale-data/is";
import itLocaleData from "react-intl/locale-data/it";
import iuLocaleData from "react-intl/locale-data/iu";
import iwLocaleData from "react-intl/locale-data/iw";
import jaLocaleData from "react-intl/locale-data/ja";
import jboLocaleData from "react-intl/locale-data/jbo";
import jgoLocaleData from "react-intl/locale-data/jgo";
import jiLocaleData from "react-intl/locale-data/ji";
import jmcLocaleData from "react-intl/locale-data/jmc";
import jvLocaleData from "react-intl/locale-data/jv";
import jwLocaleData from "react-intl/locale-data/jw";
import kaLocaleData from "react-intl/locale-data/ka";
import kabLocaleData from "react-intl/locale-data/kab";
import kajLocaleData from "react-intl/locale-data/kaj";
import kamLocaleData from "react-intl/locale-data/kam";
import kcgLocaleData from "react-intl/locale-data/kcg";
import kdeLocaleData from "react-intl/locale-data/kde";
import keaLocaleData from "react-intl/locale-data/kea";
import khqLocaleData from "react-intl/locale-data/khq";
import kiLocaleData from "react-intl/locale-data/ki";
import kkLocaleData from "react-intl/locale-data/kk";
import kkjLocaleData from "react-intl/locale-data/kkj";
import klLocaleData from "react-intl/locale-data/kl";
import klnLocaleData from "react-intl/locale-data/kln";
import kmLocaleData from "react-intl/locale-data/km";
import knLocaleData from "react-intl/locale-data/kn";
import koLocaleData from "react-intl/locale-data/ko";
import kokLocaleData from "react-intl/locale-data/kok";
import ksLocaleData from "react-intl/locale-data/ks";
import ksbLocaleData from "react-intl/locale-data/ksb";
import ksfLocaleData from "react-intl/locale-data/ksf";
import kshLocaleData from "react-intl/locale-data/ksh";
import kuLocaleData from "react-intl/locale-data/ku";
import kwLocaleData from "react-intl/locale-data/kw";
import kyLocaleData from "react-intl/locale-data/ky";
import lagLocaleData from "react-intl/locale-data/lag";
import lbLocaleData from "react-intl/locale-data/lb";
import lgLocaleData from "react-intl/locale-data/lg";
import lktLocaleData from "react-intl/locale-data/lkt";
import lnLocaleData from "react-intl/locale-data/ln";
import loLocaleData from "react-intl/locale-data/lo";
import lrcLocaleData from "react-intl/locale-data/lrc";
import ltLocaleData from "react-intl/locale-data/lt";
import luLocaleData from "react-intl/locale-data/lu";
import luoLocaleData from "react-intl/locale-data/luo";
import luyLocaleData from "react-intl/locale-data/luy";
import lvLocaleData from "react-intl/locale-data/lv";
import masLocaleData from "react-intl/locale-data/mas";
import merLocaleData from "react-intl/locale-data/mer";
import mfeLocaleData from "react-intl/locale-data/mfe";
import mgLocaleData from "react-intl/locale-data/mg";
import mghLocaleData from "react-intl/locale-data/mgh";
import mgoLocaleData from "react-intl/locale-data/mgo";
import mkLocaleData from "react-intl/locale-data/mk";
import mlLocaleData from "react-intl/locale-data/ml";
import mnLocaleData from "react-intl/locale-data/mn";
import moLocaleData from "react-intl/locale-data/mo";
import mrLocaleData from "react-intl/locale-data/mr";
import msLocaleData from "react-intl/locale-data/ms";
import mtLocaleData from "react-intl/locale-data/mt";
import muaLocaleData from "react-intl/locale-data/mua";
import myLocaleData from "react-intl/locale-data/my";
import mznLocaleData from "react-intl/locale-data/mzn";
import nahLocaleData from "react-intl/locale-data/nah";
import naqLocaleData from "react-intl/locale-data/naq";
import nbLocaleData from "react-intl/locale-data/nb";
import ndLocaleData from "react-intl/locale-data/nd";
import neLocaleData from "react-intl/locale-data/ne";
import nlLocaleData from "react-intl/locale-data/nl";
import nmgLocaleData from "react-intl/locale-data/nmg";
import nnLocaleData from "react-intl/locale-data/nn";
import nnhLocaleData from "react-intl/locale-data/nnh";
import noLocaleData from "react-intl/locale-data/no";
import nqoLocaleData from "react-intl/locale-data/nqo";
import nrLocaleData from "react-intl/locale-data/nr";
import nsoLocaleData from "react-intl/locale-data/nso";
import nusLocaleData from "react-intl/locale-data/nus";
import nyLocaleData from "react-intl/locale-data/ny";
import nynLocaleData from "react-intl/locale-data/nyn";
import omLocaleData from "react-intl/locale-data/om";
import orLocaleData from "react-intl/locale-data/or";
import osLocaleData from "react-intl/locale-data/os";
import paLocaleData from "react-intl/locale-data/pa";
import papLocaleData from "react-intl/locale-data/pap";
import plLocaleData from "react-intl/locale-data/pl";
import prgLocaleData from "react-intl/locale-data/prg";
import psLocaleData from "react-intl/locale-data/ps";
import ptLocaleData from "react-intl/locale-data/pt";
import quLocaleData from "react-intl/locale-data/qu";
import rmLocaleData from "react-intl/locale-data/rm";
import rnLocaleData from "react-intl/locale-data/rn";
import roLocaleData from "react-intl/locale-data/ro";
import rofLocaleData from "react-intl/locale-data/rof";
import ruLocaleData from "react-intl/locale-data/ru";
import rwLocaleData from "react-intl/locale-data/rw";
import rwkLocaleData from "react-intl/locale-data/rwk";
import sahLocaleData from "react-intl/locale-data/sah";
import saqLocaleData from "react-intl/locale-data/saq";
import sbpLocaleData from "react-intl/locale-data/sbp";
import sdhLocaleData from "react-intl/locale-data/sdh";
import seLocaleData from "react-intl/locale-data/se";
import sehLocaleData from "react-intl/locale-data/seh";
import sesLocaleData from "react-intl/locale-data/ses";
import sgLocaleData from "react-intl/locale-data/sg";
import shLocaleData from "react-intl/locale-data/sh";
import shiLocaleData from "react-intl/locale-data/shi";
import siLocaleData from "react-intl/locale-data/si";
import skLocaleData from "react-intl/locale-data/sk";
import slLocaleData from "react-intl/locale-data/sl";
import smaLocaleData from "react-intl/locale-data/sma";
import smiLocaleData from "react-intl/locale-data/smi";
import smjLocaleData from "react-intl/locale-data/smj";
import smnLocaleData from "react-intl/locale-data/smn";
import smsLocaleData from "react-intl/locale-data/sms";
import snLocaleData from "react-intl/locale-data/sn";
import soLocaleData from "react-intl/locale-data/so";
import sqLocaleData from "react-intl/locale-data/sq";
import srLocaleData from "react-intl/locale-data/sr";
import ssLocaleData from "react-intl/locale-data/ss";
import ssyLocaleData from "react-intl/locale-data/ssy";
import stLocaleData from "react-intl/locale-data/st";
import svLocaleData from "react-intl/locale-data/sv";
import swLocaleData from "react-intl/locale-data/sw";
import syrLocaleData from "react-intl/locale-data/syr";
import taLocaleData from "react-intl/locale-data/ta";
import teLocaleData from "react-intl/locale-data/te";
import teoLocaleData from "react-intl/locale-data/teo";
import thLocaleData from "react-intl/locale-data/th";
import tiLocaleData from "react-intl/locale-data/ti";
import tigLocaleData from "react-intl/locale-data/tig";
import tkLocaleData from "react-intl/locale-data/tk";
import tlLocaleData from "react-intl/locale-data/tl";
import tnLocaleData from "react-intl/locale-data/tn";
import toLocaleData from "react-intl/locale-data/to";
import trLocaleData from "react-intl/locale-data/tr";
import tsLocaleData from "react-intl/locale-data/ts";
import twqLocaleData from "react-intl/locale-data/twq";
import tzmLocaleData from "react-intl/locale-data/tzm";
import ugLocaleData from "react-intl/locale-data/ug";
import ukLocaleData from "react-intl/locale-data/uk";
import urLocaleData from "react-intl/locale-data/ur";
import uzLocaleData from "react-intl/locale-data/uz";
import vaiLocaleData from "react-intl/locale-data/vai";
import veLocaleData from "react-intl/locale-data/ve";
import viLocaleData from "react-intl/locale-data/vi";
import voLocaleData from "react-intl/locale-data/vo";
import vunLocaleData from "react-intl/locale-data/vun";
import waLocaleData from "react-intl/locale-data/wa";
import waeLocaleData from "react-intl/locale-data/wae";
import woLocaleData from "react-intl/locale-data/wo";
import xhLocaleData from "react-intl/locale-data/xh";
import xogLocaleData from "react-intl/locale-data/xog";
import yavLocaleData from "react-intl/locale-data/yav";
import yiLocaleData from "react-intl/locale-data/yi";
import yoLocaleData from "react-intl/locale-data/yo";
import zghLocaleData from "react-intl/locale-data/zgh";
import zhLocaleData from "react-intl/locale-data/zh";
import zuLocaleData from "react-intl/locale-data/zu";

export const localeData = [
	afLocaleData,
	agqLocaleData,
	akLocaleData,
	amLocaleData,
	arLocaleData,
	asLocaleData,
	asaLocaleData,
	astLocaleData,
	azLocaleData,
	basLocaleData,
	beLocaleData,
	bemLocaleData,
	bezLocaleData,
	bgLocaleData,
	bhLocaleData,
	bmLocaleData,
	bnLocaleData,
	boLocaleData,
	brLocaleData,
	brxLocaleData,
	bsLocaleData,
	caLocaleData,
	ceLocaleData,
	cggLocaleData,
	chrLocaleData,
	ckbLocaleData,
	csLocaleData,
	cuLocaleData,
	cyLocaleData,
	daLocaleData,
	davLocaleData,
	deLocaleData,
	djeLocaleData,
	dsbLocaleData,
	duaLocaleData,
	dvLocaleData,
	dyoLocaleData,
	dzLocaleData,
	ebuLocaleData,
	eeLocaleData,
	elLocaleData,
	enLocaleData,
	eoLocaleData,
	esLocaleData,
	etLocaleData,
	euLocaleData,
	ewoLocaleData,
	faLocaleData,
	ffLocaleData,
	fiLocaleData,
	filLocaleData,
	foLocaleData,
	frLocaleData,
	furLocaleData,
	fyLocaleData,
	gaLocaleData,
	gdLocaleData,
	glLocaleData,
	gswLocaleData,
	guLocaleData,
	guwLocaleData,
	guzLocaleData,
	gvLocaleData,
	haLocaleData,
	hawLocaleData,
	heLocaleData,
	hiLocaleData,
	hrLocaleData,
	hsbLocaleData,
	huLocaleData,
	hyLocaleData,
	idLocaleData,
	igLocaleData,
	iiLocaleData,
	inLocaleData,
	isLocaleData,
	itLocaleData,
	iuLocaleData,
	iwLocaleData,
	jaLocaleData,
	jboLocaleData,
	jgoLocaleData,
	jiLocaleData,
	jmcLocaleData,
	jvLocaleData,
	jwLocaleData,
	kaLocaleData,
	kabLocaleData,
	kajLocaleData,
	kamLocaleData,
	kcgLocaleData,
	kdeLocaleData,
	keaLocaleData,
	khqLocaleData,
	kiLocaleData,
	kkLocaleData,
	kkjLocaleData,
	klLocaleData,
	klnLocaleData,
	kmLocaleData,
	knLocaleData,
	koLocaleData,
	kokLocaleData,
	ksLocaleData,
	ksbLocaleData,
	ksfLocaleData,
	kshLocaleData,
	kuLocaleData,
	kwLocaleData,
	kyLocaleData,
	lagLocaleData,
	lbLocaleData,
	lgLocaleData,
	lktLocaleData,
	lnLocaleData,
	loLocaleData,
	lrcLocaleData,
	ltLocaleData,
	luLocaleData,
	luoLocaleData,
	luyLocaleData,
	lvLocaleData,
	masLocaleData,
	merLocaleData,
	mfeLocaleData,
	mgLocaleData,
	mghLocaleData,
	mgoLocaleData,
	mkLocaleData,
	mlLocaleData,
	mnLocaleData,
	moLocaleData,
	mrLocaleData,
	msLocaleData,
	mtLocaleData,
	muaLocaleData,
	myLocaleData,
	mznLocaleData,
	nahLocaleData,
	naqLocaleData,
	nbLocaleData,
	ndLocaleData,
	neLocaleData,
	nlLocaleData,
	nmgLocaleData,
	nnLocaleData,
	nnhLocaleData,
	noLocaleData,
	nqoLocaleData,
	nrLocaleData,
	nsoLocaleData,
	nusLocaleData,
	nyLocaleData,
	nynLocaleData,
	omLocaleData,
	orLocaleData,
	osLocaleData,
	paLocaleData,
	papLocaleData,
	plLocaleData,
	prgLocaleData,
	psLocaleData,
	ptLocaleData,
	quLocaleData,
	rmLocaleData,
	rnLocaleData,
	roLocaleData,
	rofLocaleData,
	ruLocaleData,
	rwLocaleData,
	rwkLocaleData,
	sahLocaleData,
	saqLocaleData,
	sbpLocaleData,
	sdhLocaleData,
	seLocaleData,
	sehLocaleData,
	sesLocaleData,
	sgLocaleData,
	shLocaleData,
	shiLocaleData,
	siLocaleData,
	skLocaleData,
	slLocaleData,
	smaLocaleData,
	smiLocaleData,
	smjLocaleData,
	smnLocaleData,
	smsLocaleData,
	snLocaleData,
	soLocaleData,
	sqLocaleData,
	srLocaleData,
	ssLocaleData,
	ssyLocaleData,
	stLocaleData,
	svLocaleData,
	swLocaleData,
	syrLocaleData,
	taLocaleData,
	teLocaleData,
	teoLocaleData,
	thLocaleData,
	tiLocaleData,
	tigLocaleData,
	tkLocaleData,
	tlLocaleData,
	tnLocaleData,
	toLocaleData,
	trLocaleData,
	tsLocaleData,
	twqLocaleData,
	tzmLocaleData,
	ugLocaleData,
	ukLocaleData,
	urLocaleData,
	uzLocaleData,
	vaiLocaleData,
	veLocaleData,
	viLocaleData,
	voLocaleData,
	vunLocaleData,
	waLocaleData,
	waeLocaleData,
	woLocaleData,
	xhLocaleData,
	xogLocaleData,
	yavLocaleData,
	yiLocaleData,
	yoLocaleData,
	zghLocaleData,
	zhLocaleData,
	zuLocaleData,
];

export const addAppLocaleData = () =>
  localeData.forEach(locale => addLocaleData(locale));
