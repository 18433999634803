import moment from 'moment';
import React from 'react';
import {
    SHORT_MONTHS
} from './ConstData';

export default (props) => {
	const date = new Date();
	date.setDate(1);
	date.setMonth(props.value)
	date.setFullYear(props.yearValue);
	let disableNext = props.disableNext && !moment(date).isBefore(moment().add(0, 'M'))
	return <div className='year-selector' style={props.style}>
		<i className="material-icons" onClick={(e) => props.onChange(props.value-1)}>chevron_left</i>
		<div className='value'>{props.t(SHORT_MONTHS[props.value])}</div>
        {props.yearValue !== new Date().getFullYear() ? <div className="year-value">{props.yearValue}</div> : null}
		<i className={disableNext ? "material-icons disable" : "material-icons"}  onClick={(e) =>{ if(!disableNext) {props.onChange(props.value+1)}}}>chevron_right</i>
	</div>
}
