import React, {Component} from 'react';
import {withTranslation, Trans} from 'react-i18next'
import {
    Row,
    Col,
    Icon,
    Card
} from 'react-materialize';
import moment from 'moment';
import {
    onChangeInputWheel, createDateFormat
} from "../common/Methods";

class StatementReport extends Component {
    constructor(props) {
        super(props);
        this.state = {
            startTime: this.createDate(),
            endTime: this.createDate(),
            type: 1,
            isAccountStatmentReq: 1
        };
        this.statementFromDateRef = null;
        this.statementEndDateRef = null;
    }

    createDate(date = new Date()) {
        // const dateObj = new Date(date);
        return moment(date).format('YYYY-MM-DD');
    }

    onDateChange = (key, e) => {
        if(e.target.value) {
            this.setState({
                [key]: e.target.value
            });
        }
    };

    submitForm = () => {
        let data = {...this.state};
        this.props.onSubmit(data);
    };

    onChangeType = (e) => {
        this.setState({
            type: +e.target.value
        });
    };

    onChangeReportType = (e) => {
        this.setState({
            isAccountStatmentReq: +e.target.value
        });
    };
    changeWheel = (e) => {
        if(e==='from') {
            onChangeInputWheel(this.statementFromDateRef);
        }
        else {
            onChangeInputWheel(this.statementEndDateRef);
        }

    };

    render () {
        const {
            t
        } = this.props;
        return (
            <div className='padding15 Statement-padding m-t-52'>
                <Card
                    className="hoverable"
                    title={t('GENRATE_STATEMENT')}
                >
                    <p>
                        <Trans>INCOME_EXPENSE_STATEMENT</Trans>
                    </p>
                    <Row className='mb-0'>
                        <Col s={12} m={12} l={12} style={{lineHeight: '30px'}}>
                            &nbsp;
                        </Col>
                    </Row>
                    <Row className='mb-1'>
                        <div className="input-field col m12 s12 input-expense-date">
                            <i className="fas prefix">
                                <Icon>date_range</Icon>
                            </i>
                            <span id="date-text" style={{width: 'calc(100% - 4rem)', float: 'left', marginLeft: '4rem', borderBottom: '1px solid #9e9e9e', height: '2rem'}}>{createDateFormat(t, this.state.startTime)}</span>
                            <input type="date"
                                   placeholder={t('FROM_DATE')}
                                   className="date-input"
                                   onWheel={() => this.changeWheel('from')}
                                   ref={c => this.statementFromDateRef = c}
                                   name="date"
                                   value={this.state.startTime}
                                   required
                                   onChange={this.onDateChange.bind(this, 'startTime')}>
                            </input>
                            <label htmlFor="date" className='active'>From Date</label>
                        </div>
                    </Row>
                    <Row className='mb-1'>
                        <div className="input-field col m12 s12 input-expense-date">
                            <i className="fas prefix">
                                <Icon>date_range</Icon>
                            </i>
                            <span id="date-text" style={{width: 'calc(100% - 4rem)', float: 'left', marginLeft: '4rem', borderBottom: '1px solid #9e9e9e', height: '2rem'}}>{createDateFormat(t, this.state.endTime)}</span>
                            <input type="date"
                                   placeholder={t('END_DATE')}
                                   className="date-input"
                                   name="date"
                                   onWheel={() => this.changeWheel('end')}
                                   ref={c => this.statementEndDateRef = c}
                                   value={this.state.endTime}
                                   required
                                   onChange={this.onDateChange.bind(this, 'endTime')}>
                            </input>
                            <label htmlFor="date" className='active'>To Date</label>
                        </div>
                    </Row>

                    <Row className='mb-1 radio-input-button'>
                        <div className="input-field col m12 s12 statement-radio ">
                            <label className={this.state.isAccountStatmentReq === 1 ? 'select' : ''}>
                                <input
                                    type="radio"
                                    name="reportType"
                                    value={1}
                                    checked={this.state.isAccountStatmentReq === 1}
                                    onChange={this.onChangeReportType}
                                />
                                <span style={{paddingLeft: '5px'}}>
                                    <Trans>CATEGORY_WISE</Trans>
                                </span>
                            </label>
                            <label className={this.state.isAccountStatmentReq === 2 ? 'select' : ''}>
                                <input type="radio"
                                       name="reportType"
                                       value={2}
                                       checked={this.state.isAccountStatmentReq === 2}
                                       onChange={this.onChangeReportType}
                                />
                                <span style={{paddingLeft: '5px'}}>
                                    <Trans>ACCOUNT_WISE</Trans>
                                </span>
                            </label>
                        </div>
                    </Row>
                    <Row className='mb-1 radio-input-button'>
                        <div className="input-field col m12 s12 statement-radio margin-bottom30">
                            <label className={this.state.type === 1 ? 'select' : ''}>
                                <input
                                    type="radio"
                                    name="statementType"
                                    value={1}
                                    checked={this.state.type === 1}
                                    onChange={this.onChangeType}
                                />
                                <span style={{paddingLeft: '5px'}}>
                                    <Trans>EXCEL</Trans>
                                </span>
                            </label>
                            <label className={this.state.type === 2 ? 'select' : ''}>
                                <input type="radio"
                                       name="statementType"
                                       value={2}
                                       checked={this.state.type === 2}
                                       onChange={this.onChangeType}
                                />
                                <span style={{paddingLeft: '5px'}}>
                                    <Trans>PDF</Trans>
                                </span>
                            </label>
                        </div>
                    </Row>

                    <Row>
                        <div className="input-field col m6 s12">
                            <button className={'btn waves-effect waves-light light-blue accent-4 ' + (!this.state.endTime || !this.state.startTime ? 'disabled' : '')}
                                    type="button" name="action" onClick={this.submitForm.bind(this)}
                                    disabled={!this.state.endTime || !this.state.startTime}
                            >
                                <Trans>SEND_EMAIL</Trans>
                            </button>
                        </div>
                    </Row>
                </Card>
            </div>
        )
    }
}
export default withTranslation()(StatementReport);