import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import translationEN from './en.json';
import translationPT from './pt.json';
import translationES from './es.json';
import translationFR from './fr.json';
import translationJA from './ja.json';
import translationKO from './ko.json';
import translationDE from './de.json';
import translationIT from './it.json';
import translationRU from './ru.json';
import translationRO from './ro.json';
import translationEL from './el.json';

// the translations
const resources = {
    en: {
        translation: translationEN
    }, pt: {
        translation: translationPT
    }, es: {
        translation: translationES
    }, fr: {
        translation: translationFR
    },ja: {
        translation: translationJA
    },de: {
        translation: translationDE
    },ko: {
        translation: translationKO
    },it: {
        translation: translationIT
    },ru: {
        translation: translationRU
    },ro: {
        translation: translationRO
    },el: {
        translation: translationEL
    }
};

i18n.use(initReactI18next).init({
        resources,
        fallbackLng: 'en',
        debug: true,
        interpolation: {
            escapeValue: false
        }
    });

export default i18n;