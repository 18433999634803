import React, { Component } from 'react';
import Navbar from "./OnlyNavbar";
import Spinner from './common/Spinner'
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {rejectMultiUserInvitation} from '../actions/authActions'
import PropTypes from 'prop-types';
import {withTranslation, Trans} from 'react-i18next';
import '../stylesheets/App.css'
import {iconUrl} from "../actions/config";

//this component will be rendered when invitee rejects the invitation for group from the email
//https://web.timelybills.app/inviteReject?token=token sample url for this page
//this page calls one action creator rejectMultiUserRequest
class InvitationRejected extends Component {
    constructor(props){
        super(props);
        this.state={
            status: '',
            error: {},
        };
        this.loading = false;
        //the token is received in the url
        //https://web.timelybills.app/inviteReject?token=token
        if(this.props.location.search)
        this.token = this.props.location.search.slice(7);
        this.ownerDetail = '';
        if(this.token) {
            this.loading = true;
            //calls a api via an actioncreator to fetch inviter data and rejects the request from the invitee
            this.props.rejectMultiUserInvitation(this.token, (data) => {
                //rejectMultiUserRequest actioncreator runs successfully
                if (data.groupData.ownerFirstName) {
                    this.ownerDetail = data.groupData.ownerFirstName;
                    if (data.groupData.ownerLastName) {
                        this.ownerDetail += ' ' + data.groupData.ownerLastName;
                    }
                }
                else {
                    this.ownerDetail = data.groupData.ownerEmail;
                }
                this.loading = false;
                this.setState({status: 'success'});
            })
        }
    }
    componentWillReceiveProps(nextProps) {
        if(nextProps.errors){
            //rejectMultiUserRequest actioncreator fails
            this.setState({status:'failure'});
            this.loading = false;
            this.setState({error: nextProps.errors});
        }
    }
    render() {
        //rendered if APIs runs successfully
        const renderSuccess = (
            <div className="card-content center-align">
                <img className="accept-invite-logo" src={iconUrl + 'icon_done.png'} alt="MailImage"/>
                <span className="card-title"> <h3><Trans>INVITATION_REJECTED</Trans></h3></span>
                <span><h5><Trans>INVITATION_REJECTED_TEXT</Trans> {this.ownerDetail}</h5></span>
            </div>
        );
        //rendered if APIs fails
        const renderFailure = (
            <div className="card-content center-align">
                <img className="accept-invite-logo" src={iconUrl + 'icon_cross.png'} alt="MailImage"/>
                <span className="card-title"> <h3><Trans>INVITATION_REJECTION_ATTEMPTED</Trans></h3></span>
                <span><h5>{this.state.error.message}</h5></span>
            </div>
        );
        return (
            <div>
                <Navbar />
                <div className="vertical-center">
                    <div className="row">
                        <div className="col s2"/>
                        <div className="col m8 s12">
                            <div className="card darken-1">
                                {this.loading ? Spinner() : this.state.status==='success' ? renderSuccess : renderFailure }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

InvitationRejected.propTypes = {
    auth:PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
    auth: state.auth,
    errors: state.errors,
});

export default withTranslation()(connect(mapStateToProps,{rejectMultiUserInvitation})(withRouter(InvitationRejected)));


