import {
    EXPENSE_DATA_LOADING,
    GET_EXPENSE_DATA_SUCCESS,
    EXPENSE_DATA_ACTION,
    EXPENSE_DATA_FAILED,
    GET_EXPENSE_IMAGE,
    EXPENSE_IMAGE_LOADING
} from '../actions/types';

const initialState = {
    EXPENSE_DATA_LOADING: false,
    EXPENSE_DATA_ACTION: false,
	expensesData: []
};

export default function (state = initialState, action) {
    switch (action.type){
        case GET_EXPENSE_DATA_SUCCESS:
            if(state.error) {
                delete state.error
            }
            return {
                ...state,
                expensesData: action.payload
			};
		case EXPENSE_DATA_LOADING:
            if(state.error) {
                delete state.error
            }
            return{
                ...state,
                [action.type] : action.state
            };
        case EXPENSE_IMAGE_LOADING:
            if(state.error) {
                delete state.error
            }
            return{
                ...state,
                [action.type] : action.state
            };
        case EXPENSE_DATA_ACTION:
            if(state.error) {
                delete state.error
            }
            return{
                ...state,
                [action.type] : action.state
            };
        case GET_EXPENSE_IMAGE:
            if(state.error) {
                delete state.error
            }
            return{
                ...state,
                [action.type] : action.payload
            };
        case EXPENSE_DATA_FAILED:
            state[EXPENSE_DATA_LOADING] = false;
            return {
                ...state,
                error: action.payload
            };
        default:
            return state;
    }
}
