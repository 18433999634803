import {
    LOADING,
    GET_ACCOUNT_LIST_SUCCESS,
    GET_ACCOUNT_LIST_FAILED, GET_ACCOUNT_LIST_LOADING
} from '../actions/types';

const initialState = {
    GET_ACCOUNT_LIST_LOADING: false
};

export default function (state = initialState, action) {
    if(action.type === 'NOT_LOADING') {
        state['GET_ACCOUNT_LIST_LOADING'] = false;
    }
    if(action.type !== 'GET_ACCOUNT_LIST_FAILED') {
        state['GET_ACCOUNT_LIST_FAILED'] = false;
    }
    switch (action.type){
        case GET_ACCOUNT_LIST_LOADING:
            return{
                ...state,
                [action.type] : action.state
            };
        case GET_ACCOUNT_LIST_SUCCESS:
            return{
                ...state,
                accList : action.payload
            };
        case GET_ACCOUNT_LIST_FAILED:
            return {
                ...state,
                [action.type]: action.payload
            };
        default:
            return state;
    }
}
