import React, { Component } from 'react';
import Navbar from "./Navbar";
import SuccessPage from "./SuccessPage";

import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import '../stylesheets/App.css'
import {withTranslation} from 'react-i18next';

class ResetSuccess extends Component {

    componentDidMount () {
        /*if(this.props.auth.isAuthenticated){
            this.props.history.push('/dashboard');
        }*/
    }

    render() {

        return (
            <div>
                <Navbar/>
                <SuccessPage title="PASSWORD_CHANGE_SUCCESSFUL" hideSideNav={false}/>
            </div>
        );
    }
}

ResetSuccess.propTypes = {
    auth:PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    auth: state.auth,
});

export default withTranslation()(connect(mapStateToProps,{})(withRouter(ResetSuccess)));





