// For Dev
export const ENV = {
    API: 'https://api.timelybills.app/v1/',
};

export const SITE_KEY = "6Lc2qMkUAAAAAO2PG3FY2gFBW70E0TfckVtrXlCU";
export const DEFAULT_CAT_1 = 999;
export const DEFAULT_CAT_2 = 99;
export const s3Url = 'https://static.timelybills.app/icons/';
export const umediaUrl = 'https://umedia.timelybills.app/';
export const CAT_TYPE = {
    '1': 'EXPENSE_TYPE',
    '2': 'INCOME_TYPE',
    '1t': 'SENT',
    '2t': 'RECEIVED'
};
export const CAT_TYPE_TEXT = {
    'EXPENSE': 1,
    'INCOME': 2
};
export const RIGHT_ARROW_ICON = ' › ';
export const accountTypeConst = {
    LOAN: 3,
    CREDIT_CARD: 2
};
export const imageUrl = "https://static.timelybills.app/img/";
export const iconUrl = "https://static.timelybills.app/icons/";
// For Production
// export const ENV = {
// 	API: 'https://api.timelybills.app/v1/',
// };
