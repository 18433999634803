import React, { Component } from 'react';

import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import '../stylesheets/App.css'
import {iconUrl} from "../actions/config";

class ErrorPage extends Component {
    render() {
        return (
            <div>
                <div className="vertical-center">
                    <div className="row">
                        <div className="col s2"></div>
                        <div className="col m8 s12 ">
                            <div className="card darken-1">
                                <div className="card-content center-align">
                                    <img src={iconUrl + 'icon_error.png'} alt="Error!"/>
                                    <span className="card-title"> <h3>{this.props.title}</h3></span>
                                    <span> <h5>{this.props.error}</h5></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

ErrorPage.propTypes = {
    auth:PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
    auth: state.auth
});

export default connect(mapStateToProps,{})(withRouter(ErrorPage));





