import React, { Component } from "react";
import { withTranslation, Trans } from "react-i18next";
import { Icon, Row } from "react-materialize";
import { onChangeInputWheel } from "./common/Methods";
import "../stylesheets/App.css";

import { LONG_MONTHS, SHORT_MONTHS, BUDGET_TYPE } from "./common/ConstData";
import CategorySelector from "./common/CategorySelector";
import moment from "moment";

class AddBudget extends Component {
  constructor(props) {
    super(props);
    this.state = {
      amount: "",
      month: this.createDate(),
      userSettings: JSON.parse(localStorage.getItem("profileDetails")) || {},
      categoryId: "0",
      type: 0,
      monthId: "",
      
    };
    this.dateRef = null;
    this.amountRef = null;
    this.categoryAmountRef = null;
  }

  createDate(date = new Date()) {
    const dateObj = new Date(date);
    return (
      dateObj.getFullYear() + "-" + ("0" + (dateObj.getMonth() + 1)).slice(-2)
    );
  }

  componentWillMount() {
    const allUserCategory = JSON.parse(localStorage.getItem("userCategories"));
    (allUserCategory || []).some((category) => {
      if (category.type === 1) {
        this.setState({
          categoryId: category.categoryId,
        });
        return true;
      }
      return null;
    });
  }

  componentWillReceiveProps(nextProps) {
    this.setState({month: this.createDate(new Date())})
    if (this.props.activeTab !== nextProps.activeTab) {
      this.setState({
        amount: "",
      });
    }
  }

  onTextChange = (key, e) => {
    this.setState({
      [key]: e.target.value,
    });
  };

  onDateChange = (key, e) => {
    this.setState({
      [key]: e.target.value,
    });
  };

  onCategoryChange = (categoryId, type) => {
    this.setState({
      categoryId: categoryId,
      type,
    });
  };

  changeWheel = (e) => {
    if (e === "amount") {
      onChangeInputWheel(this.amountRef);
    } else if (e === "category") {
      onChangeInputWheel(this.categoryAmountRef);
    } else {
      onChangeInputWheel(this.dateRef);
    }
  };

  getRecurringLabel() {

    let month = this.props.month;
      if(this.props.activeTab === "Monthly") {
        month = +this.state.month.split('-')[1]
        month--;  
      }   

      return  <div className="input-field col m12 s12"><h6>{this.props.t('OCCOURING_FROM_MONTH_STARTING')} {" "}
      {this.props.t(SHORT_MONTHS[month])}
      {", "} {this.props.year}</h6></div>
  }

  render() {
    const {
      categoryId,
      userSettings: { currencyDetails },
    } = this.state;
    const { t } = this.props;
    return this.props.activeTab === "Monthly" ? (
      <form>
        <Row>
          <div className="input-field col m12 s12 input-date">
            <i className="fas prefix">
              <Icon>date_range</Icon>
            </i>
            <input
              type="month"
              placeholder={t("SELECT_DATE")}
              min={moment().format('YYYY-MM')}
              max={moment().add(1, 'M').format('YYYY-MM')}
              name="date"
              ref={(c) => (this.dateRef = c)}
              onWheel={() => this.changeWheel("date")}
              value={this.state.month}
              required
              onChange={this.onDateChange.bind(this, "month")}
            ></input>
          </div>
        </Row>
        <Row>
          <div className="input-field col m12 s12">
            <i
              className={
                "fas prefix " +
                (currencyDetails.currencySymbol.length > 1
                  ? "long-symbole"
                  : "")
              }
            >
              {currencyDetails.currencySymbol}
            </i>
            <input
              id="icon_prefix"
              type="number"
              min={0}
              name="amount"
              ref={(c) => (this.amountRef = c)}
              onWheel={() => this.changeWheel("amount")}
              value={this.state.amount}
              required
              onChange={this.onTextChange.bind(this, "amount")}
            />
            <label
              htmlFor="icon_prefix"
              className={this.state.amount ? "active" : ""}
            >
              <Trans>AMOUNT</Trans>
            </label>
          </div>
        </Row>
        <Row>
          <div class="switch">
            <label>
              <input
                type="checkbox"
                checked={this.state.carryForward === true ? true : false}
                onChange={(e) =>
                  this.setState({ carryForward: e.target.checked })
                }
              />
              <span class="lever"></span>
              <Trans>CARRY_FORWARED_AMTOUNT</Trans>
            </label>
          </div>
        </Row>
        {localStorage.getItem('familyShare') === 'y' ? 
        <Row>
          <div class="switch">
            <label>
              <input
                type="checkbox"
                checked={this.state.familyShare === true ? true : false}
                onChange={(e) =>
                  this.setState({ familyShare: e.target.checked })
                }
              />
              <span class="lever"></span>
              <Trans>FAMILY_SHARE</Trans>
            </label>
          </div>
        </Row> : null}
        <Row>
          {this.getRecurringLabel()}
        </Row> 
        <Row>
          <div className="modal-footer-sec">
            <div className="input-field col m6 s12">
              <button
                className="modal-close btn waves-effect waves-light light-blue accent-4"
                type="button"
                name="action"
                onClick={this.props.onSubmit.bind(this, {...this.state,  type: this.state.isIncomeBudget ? 2: 1})}
              >
                <Trans>DONE</Trans>
                <i className="material-icons right">done</i>
              </button>
            </div>
          </div>
        </Row>
      </form>
    ) : (
      <form>
        <Row>
          <CategorySelector
            t={t}
            onChange={this.onCategoryChange}
            value={categoryId}
            type={BUDGET_TYPE.UNKNOWN}
          />
        </Row>
        <Row>
          <div className="input-field col m12 s12">
            <i
              className={
                "fas prefix " +
                (currencyDetails.currencySymbol.length > 1
                  ? "long-symbole"
                  : "")
              }
            >
              {currencyDetails.currencySymbol}
            </i>
            <input
              id="icon_prefix"
              type="number"
              min={0}
              name="amount"
              value={this.state.amount}
              ref={(c) => (this.categoryAmountRef = c)}
              onWheel={() => this.changeWheel("category")}
              required
              onChange={this.onTextChange.bind(this, "amount")}
            />
            <label
              htmlFor="icon_prefix"
              className={this.state.amount ? "active" : ""}
            >
              <Trans>AMOUNT</Trans>
            </label>
          </div>
        </Row>
        { 
        this.state.type === BUDGET_TYPE.EXPENSE ? 
        <Row>
          <div class="switch">
            <label>
              <input
                type="checkbox"
                checked={this.state.carryForward === true ? true : false}
                onChange={(e) =>
                  this.setState({ carryForward: e.target.checked })
                }
              />
              <span class="lever"></span>
              <Trans>CARRY_FORWARED_AMTOUNT</Trans>
            </label>
          </div>
        </Row> : null
  }
        {localStorage.getItem('familyShare') === 'y' ? 
        <Row>
          <div class="switch">
            <label>
              <input
                type="checkbox"
                checked={this.state.familyShare === true ? true : false}
                onChange={(e) =>
                  this.setState({ familyShare: e.target.checked })
                }
              />
              <span class="lever"></span>
              <Trans>FAMILY_SHARE</Trans>
            </label>
          </div>
        </Row> : null}
        <Row>
          {this.getRecurringLabel()}
        </Row> 
        
        <Row>
          <div className="modal-footer-sec">
            <div className="input-field col m6 s12">
              <button
                className="modal-close btn waves-effect waves-light light-blue accent-4"
                type="button"
                name="action"
                onClick={this.props.onSubmit.bind(this, this.state)}
              >
                <Trans>DONE</Trans>
                <i className="material-icons right">done</i>
              </button>
            </div>
          </div>
        </Row>
      </form>
    );
  }
}

export default withTranslation()(AddBudget);
