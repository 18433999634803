import axios from 'axios';
import setLoading from './appActions';
import {
    GET_CATEGORIES,
    SEARCH_TRANSACTION,
    TRANSACTION_CACHE
} from './types';

export const cacheTransaction = (object, key, value) => dispatch => {
	dispatch({
		type: TRANSACTION_CACHE,
		payload: Object.assign(object, {[key]: value})
	});
}


//Get Categories
export const getcategories = () => dispatch => {
    dispatch(setLoading());
    axios
        .get('https://api.timelybills.app/v1/api/userData/getUserCategories/1', {
            headers:{
                'Content-Type': 'application/json',
            }
        })
        .then(res => {
            // console.log(res.data);
                dispatch({
                    type: GET_CATEGORIES,
                    payload: res.data.results.categories
                })
            }
        )
        .catch(err => console.error(err));
};

//Add Transaction
export const addtransaction = (transactionData) => dispatch => {
    axios
        .post('https://api.timelybills.app/v1/api/userdata/updateTransactions',transactionData)
        .then(res => {
            // console.log(res.data);
        })
        .catch(err => console.error(err));
};

//Search Transaction
export const searchtransaction = (transactionID) => dispatch => {
    axios
        .get('--------',transactionID , {
            headers:{
                'Content-Type': 'application/json',
            }
        })
        .then(res => {
            // console.log(res.data);
            dispatch({
                type: SEARCH_TRANSACTION,
                payload: res.data.results
            })
        })
        .catch(err => console.error(err));
};