import React, { Component } from 'react';
import {withTranslation, Trans} from 'react-i18next';
import {
	Row,
	Col,
	Card,
	Collection,
	CollectionItem,
	Preloader
} from 'react-materialize';
import {
	ResponsiveContainer,
	PieChart,
	Pie,
	Cell
} from 'recharts';
import { unescape } from 'lodash';
import { FormattedNumber } from "react-intl";
import {iconUrl} from '../../actions/config';

class ByCategoryExpensesChart extends Component {
	onPieEnter = () => 'Name';

	render() {
		// console.log('In ByCategoryExpenses Chart..', this.props.stats);
		const {
			stats = {},
			currencyDetails = {},
			isLoading,
            t
		} = this.props;
		let total = 0;
		const data = (stats.expenses || [])
				.filter(expense => !!expense.category)
				.map(({category, amount=0, icon, color}) => {
					total += +amount;
                    return {
                        name: unescape(category.name),
                        value: amount,
                        color: category.iconColor,
                        icon: category.iconUrl,
                    };
                }
			);
		return (
			<div className='padding15'>
				<Card
					className='hoverable padding-remove Top-Spends-recharts'
					title={t('TOP_SPENDS')}>
						{ (data.length) ?
							<Row>
								<Col s={12} m={12} l={3}>
									<ResponsiveContainer width='100%' height={300} className='valign-wrapper'>
										{
											isLoading
											? <Preloader flashing size='big' className='center-block'/>
											: <PieChart
												onMouseEnter={this.onPieEnter}>
												<Pie
													data={data}
													innerRadius={60}
													outerRadius={100}
													dataKey={'value'}
													startAngle={90}
													endAngle={450}
												>
													{data.map(({name, color}) =>
														<Cell
															key={name}
															fill={color}
														/>)}
												</Pie>
											</PieChart>
										}
									</ResponsiveContainer>
								</Col>
								<Col s={12} m={12} l={9}>
									<div className="top-Spends-left">
										<Col s={12} m={12} l={12}>
											<Collection className='fix-height-collection bottom-border block-2'>
												{data.map(({name, value, icon, color}, index) =>
													<CollectionItem key={index}>
														<Row className='mb-0 mobile-font'>
															<Col s={8} m={8} l={8}>
																<img
																	src={iconUrl + icon + '.png'}
																	alt='icon'
																	className='leftMargin10 circle'
																	style={{backgroundColor: color}}
																/>
																{name}
																<br/>
																<span className="grey-text">{((+value/+total) * 100).toFixed(2)}%</span>
															</Col>
															<Col s={4} m={4} l={4} className='right-align'>
																<span className="nowrap">
																	<FormattedNumber
																		value={value}
																		style= 'currency'
																		currency= {currencyDetails.currencyCode || 'USD'}
																		minimumFractionDigits= {2}
																		maximumFractionDigits= {2}
																	/>
																</span>
															</Col>
														</Row>
													</CollectionItem>
												)}
											</Collection>
										</Col>
									</div>
								</Col>
							</Row>
							:
							<h3 className='center grey-text' style={{color: '#cccaca'}}>
								<Trans>NO_DATA_FOUND</Trans>
							</h3>
						}
					</Card>
				</div>
		);
	}
}

export default withTranslation() (ByCategoryExpensesChart);
