export const LONG_MONTHS = ['JANUARY', 'FEBRUARY', 'MARCH', 'APRIL', 'MAY', 'JUNE', 'JULY', 'AUGUST', 'SEPTEMBER', 'OCTOBER', 'NOVEMBER', 'DECEMBER'];
export const SHORT_MONTHS = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];
export const SHORT_DAYS = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'];
export const LONG_DAYS = ['SUNDAY', 'MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY'];
export const REMINDER = [{
    title: 'NO_REMINDER',
    value: -1
},{
    title: 'REMIND_SAME_DAY',
    value: 0
},{
    title: 'REMIND_1_DAY_BEFORE',
    value: 1
},{
    title: 'REMIND_2_DAYS_BEFORE',
    value: 2
},{
    title: 'REMIND_3_DAYS_BEFORE',
    value: 3
},{
    title: 'REMIND_4_DAYS_BEFORE',
    value: 4
},{
    title: 'REMIND_5_DAYS_BEFORE',
    value: 5
},{
    title: 'REMIND_10_DAYS_BEFORE',
    value: 10
},{
    title: 'REMIND_15_DAYS_BEFORE',
    value: 15
},{
    title: 'REMIND_20_DAYS_BEFORE',
    value: 20
},{
    title: 'REMIND_30_DAYS_BEFORE',
    value: 30
}];

export const BUDGET_TYPE = {
    INCOME : 2,
    EXPENSE: 1,
    UNKNOWN: 12121
}
export const AGGREGATOR_CONNECTION_STATUS={
    SUCCESS : 0,
    ERROR : 1,
    NOT_APPLICABLE : 2,
    IN_PROGRESS : 3,
    CHALLENGED : 4,
    MEMBER_DELETED : 5,
    MANUAL_DISCONNECTED : 6,
    DATE_CONFIRMATION_PENDING:10
}