import axios from 'axios';
import version from '../../package.json';

const setAuthToken = (token, lng = window.navigator.language) => {
    lng = lng.split('-')[0];
    if(token){
        // Apply to every request
        axios.defaults.headers.common['Authorization'] = token;
        axios.defaults.headers.common['accept-language'] = lng;
        axios.defaults.headers.common['app-version'] = version.version;
    }else {
        // Delete auth header
        delete axios.defaults.headers.common['Authorization'];
        delete axios.defaults.headers.common['accept-language'];
        delete axios.defaults.headers.common['app-version'];
    }
};

export default setAuthToken;