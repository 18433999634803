import React, { Component } from "react";
import { withTranslation, Trans } from "react-i18next";
import { Row } from "react-materialize";
import "../stylesheets/App.css";
import { onChangeInputWheel } from "./common/Methods";
import { unescape } from "lodash";
import { BUDGET_TYPE } from "./common/ConstData";

class EditCategoryBudget extends Component {
  componentWillReceiveProps(nextProps) {
    if (
      nextProps.viewData.disableHiddenFlag ||
      nextProps.viewData.disableDeletedFlag
    ) {
      nextProps.backToDetail();
    }
  }
  amountRef = null;
  changeWheel = () => {
    onChangeInputWheel(this.amountRef);
  };
  render() {
    const {
      userSettings: { currencyDetails },
    } = this.props;
    return (
      <div>
        <Row>
          <div className="input-field static-text col s12">
            <h3>{unescape(this.props.viewData.name)}</h3>
          </div>
        </Row>
        <Row>
          <div className="input-field col m6 s12">
            <i
              className={
                "fas prefix margin-top-10 " +
                (currencyDetails.currencySymbol.length > 1
                  ? "long-symbole"
                  : "")
              }
            >
              {currencyDetails.currencySymbol}
            </i>
            <input
              id="icon_prefix"
              type="number"
              min={0}
              name="amount"
              ref={(c) => (this.amountRef = c)}
              onWheel={() => this.changeWheel()}
              value={this.props.amount}
              required
              onChange={this.props.onTextChange.bind(this, "amount")}
            />
            <label
              htmlFor="icon_prefix"
              className={this.props.amount ? "active" : ""}
            >
              Amount
            </label>
          </div>
        </Row>
        {
          this.props.viewData.budgetType === BUDGET_TYPE.INCOME ? null : (this.props.editFuture? 
        <Row>
          <div class="switch">
            <label>
              <input
                type="checkbox"
                checked={this.props.carryForward === true}
                onChange={(e) =>  {
                    this.props.onTextChange("carryForward", {target: {value:  e.target.checked}})
                }
                }
              />
              <span class="lever"></span>
              <Trans>CARRY_FORWARED_AMTOUNT</Trans>
            </label>
          </div>
        </Row>
        : null )
  }
        {localStorage.getItem('familyShare') === 'y' ? 
        <Row>
          <div class="switch">
            <label>
              <input
                type="checkbox"
                checked={this.props.familyShare === true}
                onChange={(e) =>  {
                    this.props.onTextChange("familyShare", {target: {value:  e.target.checked}})
                }
                }
              />
              <span class="lever"></span>
              <Trans>FAMILY_SHARE</Trans>
            </label>
          </div>
        </Row> : null}
        <Row>
          <div className="input-field col m6 s6">
            <button
              className="btn waves-effect waves-light light-blue accent-4"
              type="button"
              name="action"
              onClick={this.props.onSubmitBudget.bind(this, this.props)}
            >
              Done
              <i className="material-icons right">done</i>
            </button>
          </div>
          <div className="input-field col m6 s6">
            <button
              className="btn waves-effect waves-light btn-flat"
              type="button"
              onClick={this.props.backToDetail.bind(this)}
            >
              Cancel
            </button>
          </div>
        </Row>
      </div>
    );
  }
}

export default withTranslation()(EditCategoryBudget);
