import moment from "moment";
import { LONG_DAYS } from "../components/common/ConstData";
import { RepeatCategory,
  repeatTypeSelected,
  getShortDate,
  getLongDateYr, } from "../components/common/Methods";

const da = ["SU", "MO", "TU", "WE", "TH", "FR", "SA"];

const weekKey = ["", 'FIRST', 'SECOND', 'THIRD', 'FOURTH', 'LAST']


export const recurringToDescription  = (value, t) => {
  if(value === undefined || value === null) {
    return ""
  }
  if(value.startsWith("-1") && value.length===4) {
    return t('ON') +  t('LAST') + " " + t(LONG_DAYS[da.indexOf(value.replace('-1', ''))]);
  } else if(value.length===3) {
    const v = +value.substring(0,1);
    const index = value.substring(1,4);
    return  t('ON') +  " " +  t(weekKey[+v]) + " " + t(LONG_DAYS[da.indexOf(index)]);
  }

  return value;
}


export const recurringToDescriptionFullLabel = (recurrningObj, t) => {
  recurrningObj = {...recurrningObj}
  recurrningObj.recurringRule = recurrningObj.recurringRule === undefined || recurrningObj.recurringRule === null?  "" : recurrningObj.recurringRule; 
  recurrningObj.repeatDescription = recurringToDescription(recurrningObj.recurringRule, t)
  if(recurrningObj.repeatDescription === "") {
       const ddd = new Date(recurrningObj.time).getDate();
      recurrningObj.repeatDescription  = t('ON')+ " " + t('DAY') + " " +ddd
     }
      const de = t("REPEATS_EVERY") +
        (recurrningObj.recurringCount > 1
          ? recurrningObj.recurringCount + " "+t("MONTHS")+" "
          : " "+t('MONTH') +" ") +
        recurrningObj.repeatDescription + " " + 
        (recurrningObj.repeatTillDate
          ? t("UNTIL") + getLongDateYr(recurrningObj.repeatTillDate, t)
          : " ") +
        (recurrningObj.repeatTillCount
          ? " " + (t("FOR") + recurrningObj.repeatTillCount + " "+ t("TIMES"))
          : "");
          return de;
}


export const covertTimeToRecurringValue = (time, t) => {
  var weekdate = moment(time).weekday();
  var weekdayDate = moment(time).startOf("month").day(weekdate);
  if (weekdayDate.date() > 7) weekdayDate.add(7, "d");
  var month = weekdayDate.month();
  let weekdaysCount = 0;
  let totalweekdaysCount = 0;
  while (month === weekdayDate.month()) {
    totalweekdaysCount++;
    if (weekdayDate.date() === moment(time).date()) {
      weekdaysCount = totalweekdaysCount;
    }
    weekdayDate.add(7, "d");
  }
  


  var d = [];

  if (weekdaysCount === totalweekdaysCount && weekdaysCount === 4) {
    d.push({
      value: weekdaysCount + da[weekdate],
      label: `${t("ON")} ${t(weekKey[+weekdaysCount])} ${t(LONG_DAYS[weekdate])}`,
    });
  }
  d.push({
    value:
      (weekdaysCount === totalweekdaysCount ? -1 : weekdaysCount) +
      da[weekdate],
    label: `${t("ON")} ${
      weekdaysCount === totalweekdaysCount
        ? t("LAST")
        : t(weekKey[+weekdaysCount])  
    } ${t(LONG_DAYS[weekdate])}`,
  });
  return d;
};


export const completeRecurrenceDescription  = (recurrningObj, t) => {
  recurrningObj = {...recurrningObj}
  let time = recurrningObj.time;
  let description = '';
  if (recurrningObj.recurringCategoryId === RepeatCategory.DAILY.parentId) {
    description =
      t("REPEATS_EVERY") +
      " "+(recurrningObj.recurringCount > 1
        ? recurrningObj.recurringCount + t('DAYS')
        : t("DAY")) +" " +
      t("FROM") +
      getShortDate(new Date(time), t) +
      "; " +
      (recurrningObj.repeatTillDate
        ? t("UNTIL") + getLongDateYr(recurrningObj.repeatTillDate, t)
        : "") +
      (recurrningObj.repeatTillCount
        ? " " + (t("FOR") + recurrningObj.repeatTillCount + " "+t('TIMES'))
        : " ");
  } else if (
    recurrningObj.recurringCategoryId === RepeatCategory.WEEKLY.parentId
  ) {
    description =
      t("REPEATS_EVERY") +
      " "+(recurrningObj.recurringCount > 1
        ? recurrningObj.recurringCount + " "+t('WEEKS')
        : t('WEEK')) + " "+
      t("ON") + " "+
      t(LONG_DAYS[new Date(time).getDay()]) +
      "; " +
      (recurrningObj.repeatTillDate
        ? t("UNTIL") + getLongDateYr(recurrningObj.repeatTillDate, t)
        : " ") +
      (recurrningObj.repeatTillCount
        ? " " + (t("FOR") + recurrningObj.repeatTillCount + " "+t('TIMES'))
        : "");
  } else if (
    recurrningObj.recurringCategoryId === RepeatCategory.MONTHLY.parentId
  ) {
    description = recurringToDescriptionFullLabel(recurrningObj, t)

  } else if (
    recurrningObj.recurringCategoryId === RepeatCategory.YEARLY.parentId
  ) {
    description =
      t("REPEATS_EVERY") +
      " "+(recurrningObj.recurringCount > 1
        ? recurrningObj.recurringCount + t('YEARS')
        : t('YEAR')) +" "+
      t("ON") +" "+
      getShortDate(new Date(time), t) +
      "; " +
      (recurrningObj.repeatTillDate
        ? t("UNTIL") + getLongDateYr(recurrningObj.repeatTillDate, t)
        : " ") +
      (recurrningObj.repeatTillCount
        ? " " + (t("FOR") + recurrningObj.repeatTillCount + " "+t('TIMES'))
        : "");
  }

  return description;
}